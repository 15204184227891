import React from "react";
import { connect } from "react-redux";
import { WraperForCLose, styleForMoreActionsSvg } from "./style";
import { closeVisibleList } from "../../../store/shopingList/duck/actions/actions";
import { SvgColorStyle } from "../../../utils/SvgColorStyle";
const closePath =
  "M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z";
const _CloseList = ({ theme, closeVisibleList, overflowAuto }) => {
  return (
    <WraperForCLose
      isDark={theme.isDark}
      onClick={() => {
        closeVisibleList();
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="times"
        className="svg-inline--fa fa-times fa-w-11"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 352 512"
        height={30}
        style={styleForMoreActionsSvg}
      >
        <path fill={SvgColorStyle(theme.isDark)} d={closePath}></path>
      </svg>
    </WraperForCLose>
  );
};

const mapDisaptchToProps = (dispatch) => ({
  closeVisibleList: (item) => dispatch(closeVisibleList(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
});
export const CloseList = connect(
  mapStateToProps,
  mapDisaptchToProps
)(_CloseList);
