import React from "react";
import { WithStore } from "pure-react-carousel";

class _SlidesNumberHandler extends React.Component {
  changeSlide(number) {
    if (number) {
      this.props.carouselStore.setStoreState({ currentSlide: number - 1 });
    }
  }
  componentDidMount() {
    // console.log(
    //   {
    //     pageNr: this.props.pageNr,
    //     currentSlide: this.props.currentSlide,
    //     pageFromDeals: this.props.pageFromDeals,
    //     newspaperId: this.props.newspaperId,
    //   },
    //   "componentDidMount"
    // );
    if (this.props.currentSlide) {
      this.props.setSlideNr(this.props.currentSlide);
    }
  }

  componentDidUpdate(prevProps) {
    // console.log(
    //   {
    //     pageNr: this.props.pageNr,
    //     currentSlide: this.props.currentSlide,
    //     pageFromDeals: this.props.pageFromDeals,
    //     newspaperId: this.props.newspaperId,
    //     prevProps,
    //   },
    //   "componentDidUpdate"
    // );
    if (this.props.currentSlide !== this.props.slideNr) {
      this.props.setSlideNr(this.props.currentSlide);
    }
  }

  render() {
    return null;
  }
}

export const SlidesNumberHandler = WithStore(_SlidesNumberHandler, (state) => ({
  currentSlide: state.currentSlide,
}));
