import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { HeaderSearchWrapper } from "./GetCities.style";
import { Theme } from "../../store/theme/theme";
import { getUserLocalization } from "../../store/localization/duck/actions/actions";
import { bgColor, defineThemeClassName, fColor } from "./GetCities.helpers";
import { API_URL } from "../../constant/constant";

const _GetCities = ({ theme, getUserLocalization, carusel, localization }) => {
  const [cities, setCities] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (selectedOption?.value?.name) {
      getUserLocalization({
        valueName: selectedOption?.value?.name,
        searchBar: true,
        coords: {
          latitude: selectedOption?.value?.lat,
          longitude: selectedOption?.value?.lng,
        },
      });
    }
  }, [selectedOption?.value?.name]);
  useEffect(() => {
    const city = async () => {
      if (typeof window !== "undefined") {
        try {
          const polCities = await fetch(
            `${API_URL}/server-media/polCities.json`
          );
          const allPolCities = await polCities.json();
          if (allPolCities?.length) {
            setCities(allPolCities);
          }
        } catch (error) {
          console.error("Error loading country-state-city", error);
        }
      }
    };
    city();
  }, []);

  useEffect(() => {
    if (
      localization?.cityName !== selectedOption?.value?.name &&
      cities?.length
    ) {
      const selectedCity = cities.filter(
        (item) =>
          item?.label?.toLowerCase() === localization?.cityName?.toLowerCase()
      );

      setSelectedOption(selectedCity[0]?.label);
    }
  }, [localization?.cityName, selectedOption?.value?.name, cities?.length]);
  return (
    <>
      <HeaderSearchWrapper>
        <p>{carusel ? `lub wyszukaj miasto` : "Wyszukaj miasto"}</p>
        <Select
          defaultValue={selectedOption}
          onChange={setSelectedOption}
          options={cities}
          placeholder={selectedOption || "Miasto"}
          className={defineThemeClassName(theme?.isDark, carusel)}
          styles={{
            input: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: bgColor(theme?.isDark, carusel),
              color: fColor(theme?.isDark, carusel),
            }),
            singleValue: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: bgColor(theme?.isDark, carusel),
              color: fColor(theme?.isDark, carusel),
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: bgColor(theme?.isDark, carusel),
              color: fColor(theme?.isDark, carusel),
              // borderColor: fColor(!theme?.isDark, carusel),
              borderColor: Theme.dark.thirdColor.white,
            }),
            indicatorContainer: (baseStyles, state) => ({
              ...baseStyles,
              color: fColor(theme?.isDark, carusel),
            }),
            menuList: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: bgColor(theme?.isDark, carusel),
              color: fColor(theme?.isDark, carusel),
            }),
          }}
        />
      </HeaderSearchWrapper>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getUserLocalization: (item) => dispatch(getUserLocalization(item)),
});

const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
  localization: state.localization,
});
export const GetCities = connect(
  mapStateToProps,
  mapDispatchToProps
)(_GetCities);
