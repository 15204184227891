import { Theme } from "../../../store/theme/theme";
import styled from "styled-components";
import { motion } from "framer-motion";
export const CaruselWrapper = styled.div`
  width: 100%;
  max-width: ${Theme.breakPoint.desktop}px;
  height: 100%;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media${Theme.device.tablet} {
    max-width: 100vw;
  }
`;
export const SlideHover = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
`;
export const BtnHover = styled(motion.div)`
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  border: 1px solid
    ${({ isDark }) => (isDark ? Theme.dark.primaryColor.dark : "white")};
  // transition: 0.3s;
  // &:hover {
  //   box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  // }
`;
export const DotWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const caruselStyle = {
  width: "100%",
  textAlign: "center",
};
export const slideStyle = {
  width: "100%",
  height: "100%",
  position: "relative",
};
export const sliderStyle = {
  height: "100%",
  width: "100%",
  textAlign: "center",
  color: `${Theme.light.primaryColor.white}`,
};
export const pageVariants = {
  hidden: {
    opacity: 0,
    // x:'-25%',
  },
  visible: (index) => ({
    opacity: 1,
    // x:0,
    transition: {
      duration: 0.3,
      // delay:index*0.1+0.5,
      delay: 0.3,
    },
  }),
  exit: {
    opacity: 0,
  },
};
export const buttonBackStyle = {
  width: "30px",
  height: "50px",
  backgroundColor: "transparent",
  border: "none",
  margin: "5px",
};
export const buttonNextStyle = {
  width: "30px",
  height: "50px",
  backgroundColor: "transparent",
  border: "none",
  margin: "5px",
};
export const btnStyle = {
  width: "25px",
};
