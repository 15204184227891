import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
import { motion } from "framer-motion";
export const singleItemModalVariants = {
  hidden: {
    opacity: 0,
  },
  visible: () => ({
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.6,
    },
  }),
  exit: {
    opacity: 0,
  },
};
export const modalWraperVariants = {
  hidden: {
    opacity: 0,
  },
  visible: (index) => ({
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
    },
  }),
  exit: {
    opacity: 0,
  },
};
export const MainWraperRodo = styled(motion.div).attrs((props) => ({
  style: {
    background: `${
      props.isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white
    }`,
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
    opacity: `${props.isVisible ? 1 : 0}`,
  },
}))`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: 0.3s opacity;
`;
export const ContentRodoWraper = styled(motion.div).attrs((props) => ({
  style: {
    background: `${
      props.isDark
        ? Theme.dark.primaryColor.dark
        : Theme.light.primaryColor.white
    }`,
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
    border: `1px solid ${props.isDark ? "black" : Theme.light.thirdColor.dark}`,
  },
}))`
  width: 50vw;
  height: 70vh;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  position: relative;
  & > p {
    height: 100px;
  }
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 0 5px 5px 0;
    background-color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border-left: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border-right: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    background-color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  @media${Theme.device.mobileL} {
    width: 85vw;
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 0 2px 2px 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      border-right: none;
      border-left: none;
    }
  }
`;
export const ContentRodo = styled.div.attrs((props) => ({
  style: {
    background: `${
      props.isDark
        ? Theme.dark.primaryColor.dark
        : Theme.light.primaryColor.white
    }`,
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  width: 100%;
  // height:100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  font-family: montserrat;
  line-height: 2rem;
  user-select: none;
  & > h4 {
    padding: 20px;
    text-align: center;
  }
  & > h4,
  & > div {
    width: 60%;
    margin: 20px;
  }
  & > div > p > a {
    margin: 5px;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > h4 > span {
    // text-transform: uppercase;

    padding: 10px;
    // border-bottom:1px solid  ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > div > p > ul {
    margin-left: 30px;
  }
  @media${Theme.device.tablet} {
    & > h4 {
      width: 90%;
    }
    & > div {
      width: 75%;
    }
  }
  @media${Theme.device.mobileM} {
    & > div > p {
      font-size: ${Theme.size.mobileM};
    }
  }
`;
export const Accept = styled.div`
  width: 45%;
  padding: 10px;
  // height: 100px;
  border: none;
  & > div {
    display: block;
    margin: 0 auto;
    // width: 300px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid black;
    border-radius: 5px;
    transform: scale(1);
    transition: 0.1s;
    cursor: pointer;
    background: ${Theme.light.secondaryColor.green};
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }

  & > div:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
  @media${Theme.device.mobileM} {
    width: 80%;
    font-size: ${Theme.size.mobileM};
  }
`;
export const Later = styled.div`
  width: 45%;
  padding: 10px;
  // height: 100px;
  border: none;
  & > div {
    display: block;
    margin: 0 auto;
    // width: 300px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid black;
    border-radius: 5px;
    transform: scale(1);
    transition: 0.1s;
    cursor: pointer;
    // background: ${Theme.light.secondaryColor.green};
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }

  & > div:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
  @media${Theme.device.mobileM} {
    width: 80%;
    font-size: ${Theme.size.mobileM};
  }
`;
export const ActionBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
