import React from "react";
import styled from "styled-components";
import { SelectSideCarusel } from "./SelectSideCarusel";
import { AdvContainer } from "./AdvContainer";

import { Theme } from "../../store/theme/theme";
const RightbarWrraper = styled.div`
  width: 13%;
  height: 100vh;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  font-family: montserrat;
  position: sticky;
  top: 0;
  user-select: none;
  @media${Theme.device.tablet} {
    width: 20%;
  }
  @media${Theme.device.tablet} and (orientation:landscape) {
    height: auto;
  }
  @media${Theme.device.mobileL} {
    width: 100%;
    margin-bottom: 20px;
  }
  @media${Theme.device.mobileL} and (orientation:portrait) {
    width: 100%;
    height: 50vh;
  }
`;

export const RightBar = ({ bookData, isDark, allSection, postDataLoading }) => {
  return (
    <>
      <RightbarWrraper isDark={isDark}>
        {bookData && (
          <SelectSideCarusel
            bookData={bookData}
            postDataLoading={postDataLoading}
          />
        )}
        <AdvContainer isDark={isDark} allSection={allSection} />
      </RightbarWrraper>
    </>
  );
};
