import React, { useState, useEffect } from "react";
import { BrandBar } from "./brandBar/BrandBar";
import { ItemsFromListBar } from "./itemsFromListBar/ItemsFromListBar";
import { CategoryBar } from "./categoryBar/CategoryBar";
import { CaruselShopingList } from "./caruselShopingLIst/CaruselShopingList";
import {
  WraperForUserList,
  WraperFor2Chart,
  SingleChart,
  FirstChart,
  WraperForMoney,
} from "../../style";
import { Theme } from "../../../../store/theme/theme";
import { Modal } from "./modal/Modal";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
const squareVariants = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 0.7 },
};
const SingleAnalise = ({
  isDark,
  item,
  index,
  activeItemIndexFromCarusel,
  setActiveIndexFromSlide,
  setActiveItemIndexFromCarusel,
}) => {
  const [showBars, setShowBars] = useState(false);
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const resPrice = item.result.item.list.map((item) =>
    parseFloat(
      ((parseFloat(item.price) || 0) * (parseFloat(item.howMany) || 0)).toFixed(
        2
      )
    )
  );
  const total = resPrice.reduce((total, item) => total + item, 0).toFixed(2);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
      setTimeout(() => {
        setShowBars(true);
      }, 600);
    }
  }, [controls, inView]);

  return (
    <WraperForUserList
      isDark={isDark}
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={squareVariants}
    >
      <h2>Analiza z dnia {item.day}</h2>
      {showBars && (
        <>
          <WraperFor2Chart>
            <SingleChart>
              <BrandBar day={item} isDark={isDark} />
            </SingleChart>
            <SingleChart>
              <CategoryBar day={item} isDark={isDark} />
            </SingleChart>
          </WraperFor2Chart>
          <WraperFor2Chart>
            <FirstChart>
              <ItemsFromListBar day={item} isDark={isDark} />
            </FirstChart>
            <WraperForMoney isDark={isDark}>
              <p>Wydałeś</p>
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: `${Theme.light.secondaryColor.green}`,
                }}
              >
                {total}
                {/* {parseFloat(
                  item.result.item.total ? item.result.item.total : 0
                ).toFixed(2)} */}
              </p>
              <p>PLN</p>
            </WraperForMoney>
          </WraperFor2Chart>
          <CaruselShopingList
            day={item}
            isDark={isDark}
            activeItemIndexFromCarusel={activeItemIndexFromCarusel}
            setActiveItemIndexFromCarusel={setActiveItemIndexFromCarusel}
            indexCarusel={index}
            setActiveIndexFromSlide={setActiveIndexFromSlide}
          />
        </>
      )}
    </WraperForUserList>
  );
};
export const UserList = ({ days, isDark, setDays }) => {
  const [activeItemIndexFromCarusel, setActiveItemIndexFromCarusel] =
    useState(null);
  const [activeIndexFromSlide, setActiveIndexFromSlide] = useState(null);
  const data = days?.time?.shoping.filter((item) => item.result);
  const modal = {
    carusel: activeItemIndexFromCarusel,
    slide: activeIndexFromSlide,
    singleSelectedOffer:
      days?.time?.shoping[activeItemIndexFromCarusel]?.result?.item?.list[
        activeIndexFromSlide
      ],
  };

  return (
    <>
      {modal.singleSelectedOffer && (
        <Modal
          isActive={modal?.singleSelectedOffer ? true : false}
          isDark={isDark}
          setActiveItemIndexFromCarusel={setActiveItemIndexFromCarusel}
          setActiveIndexFromSlide={setActiveIndexFromSlide}
          modal={modal}
          days={days}
          activeItemIndexFromCarusel={activeItemIndexFromCarusel}
          activeIndexFromSlide={activeIndexFromSlide}
          setDays={setDays}
        />
      )}
      {days?.time?.shoping.map((item, index) => {
        if (item.result) {
          return (
            <SingleAnalise
              key={index}
              isDark={isDark}
              item={item}
              activeItemIndexFromCarusel={activeItemIndexFromCarusel}
              setActiveIndexFromSlide={setActiveIndexFromSlide}
              index={index}
              setActiveItemIndexFromCarusel={setActiveItemIndexFromCarusel}
            />
          );
        } else {
          return null;
        }
      })}
    </>
  );
};
