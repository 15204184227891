import React, { useEffect, useState } from "react";
import { Spinner } from "../../tools/spiner/Spinner";
import { SingleDeal } from "../singleDeal/SingleDeal";
import { sortBySelected } from "./loadDeals.helper";

export const LoadDealsHomePage = ({
  dataFromHomePage,
  dealsDataOptioned,
  topDealsSelected,
  activeSortType,
  optionsConfirmed,
  theme,
}) => {
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);
  if (
    !result &&
    dataFromHomePage?.loading === false &&
    dealsDataOptioned?.loading === false &&
    dealsDataOptioned?.data?.selectedProductItems
  ) {
    setResult(dealsDataOptioned?.data?.selectedProductItems);
  }
  useEffect(() => {
    const arr = [
      {
        name: "dataFromHomePage",
        loading: dataFromHomePage?.loading,
      },
      {
        name: "dealsDataOptioned",
        loading: dealsDataOptioned?.loading,
      },
    ];
    setLoading(arr.findIndex((item) => item.loading === true) !== -1);
  }, [dataFromHomePage?.loading, dealsDataOptioned?.loading]);

  useEffect(() => {
    if (
      dataFromHomePage?.data?.forHomePage[0]?.name &&
      dealsDataOptioned?.data?.selectedProductItems
    ) {
      setResult(dealsDataOptioned?.data?.selectedProductItems);
    }
  }, [
    topDealsSelected,
    dealsDataOptioned?.loading,
    dealsDataOptioned?.data?.selectedProductItems?.length,
    activeSortType,
    loading,
  ]);

  useEffect(() => {
    return () => {
      setResult(null);
    };
  }, []);

  if (loading) {
    return <Spinner />;
  } else {
    if (result === false) {
      return null;
    } else if (result?.length === 0) {
      return <p>nie znaleźliśmy promocji</p>;
    } else {
      const filteredBySelected = sortBySelected(result, activeSortType);
      if (
        optionsConfirmed &&
        filteredBySelected &&
        filteredBySelected.length > 0
      ) {
        let i = 0;

        return filteredBySelected.map((item, index) => {
          i++;
          if (i <= optionsConfirmed) {
            return <SingleDeal item={item} key={item.id} />;
          }
          return null;
        });
      }
    }
  }
};
