import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  newspaperId,
  clearSelectPageFromDeal,
  clearTargetCordinates,
} from "../../store/select/duck/actions/actions";
import {
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  sliderStyle,
  buttonBackStyle,
  buttonNextStyle,
  btnStyle,
  sliderStyleHorizontal,
  buttonNextStyleHorizontal,
  buttonBackStyleHorizontal,
} from "./sideStylePack";
import { Theme } from "../../store/theme/theme";
import { ShowCarusel } from "./ShowCarusel";
import { PageReloaded } from "../../utils/PageReloaded";
import { SlidesNumberHandler } from "./SlidesNumberHandler";

const upPath =
  "M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z";
const downPath =
  "M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z";
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 60%;
  text-transform: uppercase;
  text-align: center;
  // background-color:white;
  margin-top: 20px;
  margin-bottom: 20px;
  @media${Theme.device.mobileL} {
    height: 260px;
    & > div {
      font-size: ${Theme.size.mobileM};
    }
  }
`;
const SliderWrapper = styled.div`
  display: block;
  width: 95%;
  height: 100%;
  position: relative;
  & > div > div > div > ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  & > div > div > div > div {
    display: flex;
    align-items: center;
  }
  & > div > div > div > div > li {
    width: 150px;
    height: 230px;
  }
  & > div > div > div > div > li > div > a {
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > div > div > div > li > div > a > div {
    height: 200px;
    width: 130px;
    border: 1px solid white;
    border-radius: 5px;
    transform: scale(1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > div > div > div > li > div > a > div:last-of-type {
    border: none;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > div > div > div > li > div > a > div:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
  & > div > div > div > li > div > a > div:last-of-type:hover {
    transform: scale(1);
    box-shadow: none;
  }
  @media${Theme.device.mobileL} {
    width: 100%;
  }
`;
const checkOrientationeOnLoad = (orientation, setOrientation) => {
  if (window.innerWidth > Theme.breakPoint.mobileL) {
    setOrientation("vertical");
  } else {
    setOrientation("horizontal");
  }
};
const checkOrientationeOnResize = (orientation, setOrientation) => {
  window.addEventListener("resize", () => {
    if (window.innerWidth > Theme.breakPoint.mobileL) {
      setOrientation("vertical");
    } else {
      setOrientation("horizontal");
    }
  });
};
const resizeScreen = (btnVisible, setBtnVisible, percent, width, maxWidth) => {
  const getNumberVisibleSLides = (percent, width, innerWidth, maxWidth) => {
    if (innerWidth > maxWidth) {
      return (maxWidth * percent) / width;
    }
    return (innerWidth * percent) / width;
  };
  window.addEventListener("resize", () => {
    const docWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    const nrSlides = getNumberVisibleSLides(percent, width, docWidth, maxWidth);

    if (window.innerWidth > Theme.breakPoint.desktop) {
      setBtnVisible({ visible: true, slidesNr: nrSlides });
    } else if (window.innerWidth > Theme.breakPoint.tablet) {
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    } else if (window.innerWidth > Theme.breakPoint.mobileL) {
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    } else if (window.innerWidth > Theme.breakPoint.mobileM) {
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    } else {
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    }
  });
};

const onLoadScreen2 = (btnVisible, setBtnVisible, percent, width, maxWidth) => {
  const getNumberVisibleSLides = (percent, width, innerWidth, maxWidth) => {
    if (innerWidth > maxWidth) {
      return (maxWidth * percent) / width;
    }
    return (innerWidth * percent) / width;
  };
  const docWidth =
    document.documentElement.clientWidth || document.body.clientWidth;
  const nrSlides = getNumberVisibleSLides(percent, width, docWidth, maxWidth);
  if (window.innerWidth > Theme.breakPoint.desktop) {
    setBtnVisible({ visible: true, slidesNr: nrSlides });
  } else if (window.innerWidth > Theme.breakPoint.tablet) {
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  } else if (window.innerWidth > Theme.breakPoint.mobileL) {
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  } else if (window.innerWidth > Theme.breakPoint.mobileM) {
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  } else {
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  }
};

const checkVerticalNrSlidesOnResize = (
  verticalNrSildes,
  setVerticalNrSlides,
  percent,
  height
) => {
  window.addEventListener("resize", () => {
    const docHeigth =
      document.documentElement.clientHeight || document.body.clientHeight;
    const nrSlides = (docHeigth * percent - 25) / height;
    setVerticalNrSlides(nrSlides);
  });
};
const checkVerticalNrSlidesOnLoad = (
  verticalNrSildes,
  setVerticalNrSlides,
  percent,
  height
) => {
  const docHeigth =
    document.documentElement.clientHeight || document.body.clientHeight;
  const nrSlides = (docHeigth * percent - 25) / height;
  setVerticalNrSlides(nrSlides);
};

const _SelectSideCarusel = ({
  bookData,
  newspaperId,
  theme,
  select,
  clearSelectPageFromDeal,
  clearTargetCordinates,
  postDataLoading,
}) => {
  const [brandBooks, setBrandBooks] = useState([]);
  const [brandName, setBrandName] = useState("");
  const [categoryBooks, setCategoryBooks] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [selectId, setSelectId] = useState("");
  const [brandBooksLength, setBrandBookslength] = useState(0);
  const [categoryBooksLength, setCategoryBookslength] = useState(0);
  const [orientation, setOrientation] = useState("vertical");
  const [btnVisible, setBtnVisible] = useState({ visible: true, slidesNr: 6 });
  const [verticalNrSildes, setVerticalNrSlides] = useState(3);
  const { isDark } = theme;
  const reload = PageReloaded();
  const [slideNr, setSlideNr] = useState(0);

  // let brandBooks, brandName, categoryName, categoryBooks = ''
  // let selectId
  useEffect(() => {
    checkOrientationeOnLoad(orientation, setOrientation);
    checkOrientationeOnResize(orientation, setOrientation);
  }, [reload]);
  useEffect(() => {
    onLoadScreen2(btnVisible, setBtnVisible, 1, 150, Theme.breakPoint.desktop);
    resizeScreen(btnVisible, setBtnVisible, 1, 150, Theme.breakPoint.desktop);
  }, [reload]);
  useEffect(() => {
    checkVerticalNrSlidesOnLoad(
      verticalNrSildes,
      setVerticalNrSlides,
      0.5,
      245
    );
    checkVerticalNrSlidesOnResize(
      verticalNrSildes,
      setVerticalNrSlides,
      0.5,
      245
    );
  }, [reload]);
  useEffect(() => {
    if (bookData) {
      if (bookData?.data?.bookNotId[0]) {
        setSelectId(select.newspaperId);
        setBrandName(bookData.data.bookNotId[0].name);
        setBrandBooks(
          bookData.data.bookNotId[0].brand[0].books.filter(
            (item) => item.status === "active" || item.status === "future"
          )
        );
        setCategoryName(bookData.data.bookNotId[0].brand[0].category[0].name);
        setCategoryBooks(
          bookData.data.bookNotId[0].brand[0].category[0].books.filter(
            (item) => item.status === "active" || item.status === "future"
          )
        );
        setBrandBookslength(brandBooks.length);
        setCategoryBookslength(categoryBooks.length);
      }
      if (bookData.post) {
        if (bookData.post.books.length > 0) {
          setBrandBooks(
            bookData.post.books.filter((item) => item.status !== "archive")
          );
          if (brandBooks.length) {
            setSelectId(brandBooks[0].id);
            setBrandName(brandBooks[0].name);
            setCategoryName(brandBooks[0].brand[0].category[0].name);
            setCategoryBooks(
              brandBooks[0].brand[0].category[0].books.filter(
                (item) => item.status !== "archive"
              )
            );
            setBrandBookslength(brandBooks.length);
            setCategoryBookslength(categoryBooks.length);
          }
        }
        if (bookData.post.books.length < 1) {
          setBrandBooks(
            bookData.post.category[0].category[0].books.filter(
              (item) => item.status !== "archive"
            )
          );
          if (brandBooks.lenght > 0) {
            setSelectId(brandBooks[0].id);
            setBrandName(brandBooks[0].name);
            setCategoryName(brandBooks[0].category[0].name);
            setCategoryBooks(brandBooks);
            setBrandBookslength(brandBooks.length);
            setCategoryBookslength(categoryBooks.length);
          }
        }
      }
    }
    return () => {};
  }, [bookData]);
  const title = () => {
    return (
      <div>
        {`Więcej wybranych:`}
        <h3>dla ciebie</h3>
      </div>
    );
  };

  return (
    <>
      <MainWrapper>
        {title()}
        <SliderWrapper isDark={isDark}>
          <CarouselProvider
            // totalSlides={
            //   brandBooks.length
            //     ? brandBooksLength
            //       ? brandBooksLength
            //       : brandBooks.length
            //     : brandBooks.length
            // }
            totalSlides={select?.sugestedNewspapers?.length}
            visibleSlides={
              orientation === "vertical"
                ? verticalNrSildes
                : btnVisible.slidesNr
            }
            orientation={orientation || "vertical"}
            naturalSlideWidth={150}
            naturalSlideHeight={230}
            dragEnabled={false}
            // style={providerStyle}
            touchEnabled={true}
            dragStep={btnVisible.slidesNr}
            currentSlide={0}
            // lockOnWindowScroll={true}
          >
            <Slider
              style={
                orientation === "vertical" ? sliderStyle : sliderStyleHorizontal
              }
            >
              <ShowCarusel
                brandBooks={brandBooks}
                brandName={brandName}
                categoryName={categoryName}
                categoryBooks={categoryBooks}
                newspaperId={newspaperId}
                clearSelectPageFromDeal={clearSelectPageFromDeal}
                clearTargetCordinates={clearTargetCordinates}
                isDark={isDark}
                selectId={selectId}
                booksLength={setBrandBookslength}
                bookDataLoading={bookData?.loading}
                slideNr={slideNr}
                postDataLoading={postDataLoading}
              />
            </Slider>

            <ButtonBack
              style={
                orientation === "vertical"
                  ? buttonBackStyle
                  : btnVisible.visible
                  ? buttonBackStyleHorizontal
                  : { display: "none" }
              }
            >
              {/* <UpArrow style={btnStyle} /> */}
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-up"
                className="svg-inline--fa fa-chevron-up fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                style={btnStyle}
                width={25}
              >
                <path fill="currentColor" d={upPath}></path>
              </svg>
            </ButtonBack>
            <ButtonNext
              style={
                orientation === "vertical"
                  ? buttonNextStyle
                  : btnVisible.visible
                  ? buttonNextStyleHorizontal
                  : { display: "none" }
              }
            >
              {/* <DownArrow style={btnStyle} /> */}
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-down"
                className="svg-inline--fa fa-chevron-down fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                style={btnStyle}
                width={25}
              >
                <path fill="currentColor" d={downPath}></path>
              </svg>
            </ButtonNext>
            <SlidesNumberHandler slideNr={slideNr} setSlideNr={setSlideNr} />
          </CarouselProvider>
        </SliderWrapper>
      </MainWrapper>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  newspaperId: (item) => dispatch(newspaperId(item)),
  clearSelectPageFromDeal: (item) => dispatch(clearSelectPageFromDeal(item)),
  clearTargetCordinates: (item) => dispatch(clearTargetCordinates(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
});
export const SelectSideCarusel = connect(
  mapStateToProps,
  mapDispatchToProps
)(_SelectSideCarusel);
