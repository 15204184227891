import React from "react";
import { SingleDeal } from "../../singleDeal/SingleDeal";
export const FromSecondToHowMany = ({
  howMany,
  deals,
  pagePagination,
  isDark,
  currentPage,
  setTopDealsShoppageFromNewspaper,
  topDealsPageFromNewsPaper,
  brandDataFromUrl,
}) => {
  const selectedByPagination =
    deals &&
    deals.slice(pagePagination * howMany - howMany, pagePagination * howMany);

  return selectedByPagination.map((item, index) => (
    // <Single
    //   key={index}
    //   item={deal}
    //   index={index}
    //   isDark={isDark}
    //   currentPage={currentPage}
    // />
    <SingleDeal
      key={index}
      item={item}
      id={item.id}
      setTopDealsShoppageFromNewspaper={setTopDealsShoppageFromNewspaper}
      topDealsPageFromNewsPaper={topDealsPageFromNewsPaper}
      brandDataFromUrl={brandDataFromUrl}
    />
  ));
};
