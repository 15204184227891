import React from "react";
import { connect } from "react-redux";
import { MainWrapper, MainWrapperLayer } from "./styleForUserLogin";
import { Validatione } from "./Validatione";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { usserLogged } from "../../store/shopingList/duck/actions/actions";
const _UserLogin = ({ theme, usserLogged }) => {
  return (
    <>
      <MainWrapper>
        <MainWrapperLayer isDark={theme.isDark}>
          <ErrorBoundary>
            <Validatione isDark={theme.isDark} usserLogged={usserLogged} />
          </ErrorBoundary>
        </MainWrapperLayer>
      </MainWrapper>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  usserLogged: (item) => dispatch(usserLogged(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
});

export const UserLogin = connect(
  mapStateToProps,
  mapDispatchToProps
)(_UserLogin);
