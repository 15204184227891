import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  MainContent,
  MailIconStyle,
  HeadLine,
  FormWrapper,
  eyeStyle,
  ThumbsUpIconStyle,
} from "./styleForUserLogin";
// import { TermsAccept } from "./TermsAccept";
import { Theme } from "../../store/theme/theme";
import { motion, AnimatePresence } from "framer-motion";
// import ReCAPTCHA from "react-google-recaptcha";
import { API_URL } from "../../constant/constant";
import {
  encryptFrontData,
  decryptFrontData,
} from "../../utils/Crypto/cryptoFront.controller";
import { ErrorFromData } from "../error/ErrorFromData";
// import alertify from "alertifyjs";
// import "alertifyjs/build/css/alertify.css";
// import "alertifyjs/build/css/themes/default.css";
import { Spinner } from "../tools/spiner/Spinner";
const enterPath =
  "M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z";
const eyePath =
  "M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM432 256c0 79.5-64.5 144-144 144s-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144zM288 192c0 35.3-28.7 64-64 64c-11.5 0-22.3-3-31.6-8.4c-.2 2.8-.4 5.5-.4 8.4c0 53 43 96 96 96s96-43 96-96s-43-96-96-96c-2.8 0-5.6 .1-8.4 .4c5.3 9.3 8.4 20.1 8.4 31.6z";
const eyeSlashPath =
  "M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c5.2-11.8 8-24.8 8-38.5c0-53-43-96-96-96c-2.8 0-5.6 .1-8.4 .4c5.3 9.3 8.4 20.1 8.4 31.6c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zm223.1 298L373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5z";
const sadFacePath =
  "M0 256C0 397.4 114.6 512 256 512s256-114.6 256-256S397.4 0 256 0S0 114.6 0 256zm240 80c0-8.8 7.2-16 16-16c45 0 85.6 20.5 115.7 53.1c6 6.5 5.6 16.6-.9 22.6s-16.6 5.6-22.6-.9c-25-27.1-57.4-42.9-92.3-42.9c-8.8 0-16-7.2-16-16zm-80 80c-26.5 0-48-21-48-47c0-20 28.6-60.4 41.6-77.7c3.2-4.4 9.6-4.4 12.8 0C179.6 308.6 208 349 208 369c0 26-21.5 47-48 47zM303.6 208c0-17.7 14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32s-32-14.3-32-32zm-128 32c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z";
const divVariants = {
  hidden: {
    opacity: 0,
    scale: 1.2,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};
const pVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
const VisiblePasswordHandler = ({
  passwordVisible,
  setPasswordVisible,
  isDark,
}) => {
  return !passwordVisible ? (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="envelope"
      className="svg-inline--fa fa-envelope fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      style={eyeStyle}
      width={15}
      onClick={() => setPasswordVisible(true)}
    >
      <path
        fill={
          !isDark
            ? Theme.dark.primaryColor.dark
            : Theme.light.primaryColor.white
        }
        d={eyePath}
      ></path>
    </svg>
  ) : (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="envelope"
      className="svg-inline--fa fa-envelope fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      style={eyeStyle}
      width={15}
      onClick={() => setPasswordVisible(false)}
    >
      <path
        fill={
          !isDark
            ? Theme.dark.primaryColor.dark
            : Theme.light.primaryColor.white
        }
        d={eyeSlashPath}
      ></path>
    </svg>
  );
};
export const Validatione = ({ isDark, usserLogged }) => {
  const [ReCAPTCHA, setReCAPTCHA] = useState(null);
  let history = useHistory();
  const [body, setBody] = useState({ email: "", password: "" });
  const [mainValidation, setMainValidation] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const recaptchaSiteKey =
    process?.env?.NODE_ENV === "development"
      ? process.env.REACT_APP_RECAPTCHA_SITE_KEY
      : "6LeJQA4bAAAAAPNPnqEEFjPt4z0u7LTJAjFNHUt0";
  const recaptchaRef = React.createRef();
  const [human, setHuman] = useState(false);
  const [inputTextValue, setInputTextValue] = useState("");
  const [inputPasswordValue, setInputPasswordValue] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [validPassword, setValidPassword] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [responseError, setResponseError] = useState(false);
  const [getCorrectResponse, setCorrectResponse] = useState(false);
  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  const [processing, setProcessing] = useState(false);
  const [alertify, setAlertify] = useState(null);

  // const regName = /^[a-zA-Z '.-]*$/i
  const regNameMultiLng =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
  const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const onChange = () => {
    setHuman(true);
    console.log("recaptcha resolved");
  };

  const passwordValidationHandle = (password) => {
    setInputPasswordValue(password);
    // if (responseError) {
    //   setResponseError("");
    // }

    if (password.length >= 6 && password.length < 33) {
      setPasswordError("");
    }
    if (!password) {
      setPasswordError("Wpisz proszę swoje hasło");
    }
    if (password && password.length < 6) {
      setPasswordError("Wpisane hasło jest zbyt krótkie, min 6 znaków");
    }
    if (password.length > 33) {
      setPasswordError("Wpisane hasło jest zbyt długie, max 33 znaki");
    }
    // if (!regNameMultiLng.test(password)) {
    //   setPasswordError("Wpisz poprawnie hasło");
    // }
    if (
      // regNameMultiLng.test(password) &&
      password.length >= 6 &&
      password.length <= 33
    ) {
      setPasswordError("");
      setValidPassword(true);
    }
  };

  const emailValidationHandle = (email) => {
    setInputTextValue(email);
    // if (responseError) {
    //   setResponseError("");
    // }
    if (!email) {
      setEmailError("Wpisz proszę swój e-mail");
    }
    if (email && !regEmail.test(email)) {
      setEmailError("Wpisany e-mail nie jest poprawny");
    }
    if (regEmail.test(email)) {
      setEmailError("");
      setValidEmail(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!human && recaptchaRef.current) {
      recaptchaRef.current.reset();
      // recaptchaRef.current.execute()
    }

    if (!inputTextValue) {
      setEmailError("Wpisz proszę swój e-mail");
    }
    if (!inputPasswordValue) {
      setPasswordError("Wpisz proszę swoje hasło");
    }

    if (validEmail && validPassword) {
      setBody({
        email: inputTextValue,
        password: inputPasswordValue,
      });
      setMainValidation(true);
    }
  };
  useEffect(() => {
    return () => {
      setAlertify(null);
    };
  }, []);
  useEffect(() => {
    const loadAlertify = async () => {
      if (typeof window !== "undefined") {
        try {
          const alertifyInstance = (
            await import("../../media/assets/alertify/alertify.js")
          ).default;
          await import("../../media/assets/alertify/alertify.min.css");
          await import("../../media/assets/alertify/default.min.css");
          setAlertify(alertifyInstance);
        } catch (error) {
          console.error("Error loading Alertify:", error);
        }
      }
    };

    loadAlertify();
  }, []);
  useEffect(() => {
    if (body?.email && body?.password && mainValidation) {
      setProcessing(true);
      if (responseError) {
        setResponseError("");
      }
      const frontDataToEncrypt = {
        email: body?.email,
        password: body?.password,
      };
      // encrypt data for fetch
      const ciphertext = encryptFrontData(
        frontDataToEncrypt,
        setErrorFromCrypto,
        null,
        "AfterValidatione.js-userLogin"
      );
      fetch(`${API_URL}/api/user/login`, {
        method: "POST",
        credentials: "include",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: ciphertext,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          // decrypt data from response
          return decryptFrontData(
            result.data,
            setErrorFromCrypto,
            null,
            "AfterValidatione.js-userLogin"
          );
        })
        .then((data) => {
          setProcessing(false);
          if (data?.userLoged === true) {
            if (responseError) {
              setResponseError("");
            }
            setInputTextValue("");
            setInputPasswordValue("");
            setBody({ email: "", password: "" });
            setCorrectResponse(true);
            const toLogin = {
              email: data?.email,
              isLoged: data?.userLoged,
            };
            usserLogged(toLogin);
            history.push("/");
            alertify.set("notifier", "position", "bottom-left");
            alertify.success(`Zalogowałeś się pomyślnie`);
          } else if (data?.userLoged === false && data?.error) {
            setResponseError(`${data?.error}`);
          } else {
            setResponseError(
              "Przepraszamy coś poszło nie tak :( ,spróbuj ponownie."
            );
          }
        });
    }
  }, [body?.email, body?.password, mainValidation]);
  useEffect(() => {
    return () => {
      setInputTextValue("");
      setInputPasswordValue("");
      setBody({ email: "", password: "" });
    };
  }, []);
  useEffect(() => {
    const loadReCAPTCHA = async () => {
      if (typeof window !== "undefined") {
        try {
          const ReCAPTCHAInstance = (await import("react-google-recaptcha"))
            .default;

          setReCAPTCHA(ReCAPTCHAInstance);
        } catch (error) {
          console.error("Error loading ReCAPTCHAInstance:", error);
        }
      }
    };

    loadReCAPTCHA();
  }, []);
  useEffect(() => {
    return () => {
      setReCAPTCHA(null);
    };
  }, []);

  if (errorFromCrypto) {
    return (
      <ErrorFromData
        errorInfo={errorFromCrypto}
        queryName={"AfterValidatione.js-userLogin"}
      />
    );
  }
  return (
    <MainContent isDark={isDark} layout>
      <HeadLine isDark={isDark}>
        <h4>Zaloguj Się</h4>
        <div>
          {/* <MailIcon style={MailIconStyle} /> */}

          <p>korzystaj w pełni z możliwości sewisu</p>
          <p>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="envelope"
              className="svg-inline--fa fa-envelope fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              style={MailIconStyle}
              width={130}
            >
              <path fill="currentColor" d={enterPath}></path>
            </svg>
          </p>
        </div>
      </HeadLine>
      <FormWrapper isDark={isDark}>
        {processing && <Spinner small={true} />}
        {!processing && <h5>Wypełnij formularz logowania</h5>}
        <AnimatePresence>
          {responseError && (
            <>
              <motion.div
                variants={divVariants}
                initial="hidden"
                animate="visible"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  style={ThumbsUpIconStyle}
                  width={150}
                >
                  <path fill="currentColor" d={sadFacePath}></path>
                </svg>
              </motion.div>
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {responseError}
              </motion.p>
            </>
          )}
        </AnimatePresence>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="twój adres e-mail"
            name="email"
            value={inputTextValue}
            onChange={(e) => emailValidationHandle(e.target.value)}
          />
          <AnimatePresence>
            {emailError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {emailError}
              </motion.p>
            )}
          </AnimatePresence>
          <div style={{ position: "relative" }}>
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="wpisz hasło"
              name="password"
              value={inputPasswordValue}
              onChange={(e) => passwordValidationHandle(e.target.value)}
            />
            <VisiblePasswordHandler
              passwordVisible={passwordVisible}
              setPasswordVisible={setPasswordVisible}
              isDark={isDark}
            />
          </div>

          <AnimatePresence>
            {passwordError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {passwordError}
              </motion.p>
            )}
          </AnimatePresence>
          <div style={{}}>
            <a href="/logowanie/reset-hasla" alt="zapomniałem hasła">
              zapomniałem hasła
            </a>
            <a href="/rejestracja" alt="rejestracja">
              rejestruj się
            </a>
          </div>

          {ReCAPTCHA && (
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={recaptchaSiteKey}
              onChange={onChange}
              size={"normal"}
              hl={"pl"}
              theme={isDark ? "dark" : "light"}
              style={{ width: "300px" }}
            />
          )}
          {processing ? null : (
            <motion.button whileTap={{ scale: 0.95 }} type="submit">
              Loguj
            </motion.button>
          )}
        </form>
      </FormWrapper>
    </MainContent>
  );
};
