import React, { useState } from "react";
import { MainImg, Content } from "./stylesForBlog";
import { splitAndJoin } from "../../helpers/convert/splitAndJoin";
import { BlogWrapper } from "./stylesForBlog";
import { Link } from "react-router-dom";
import { API_URL } from "../../constant/constant";
import ClampLines from "react-clamp-lines";
import { Theme } from "../../store/theme/theme";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
const imagePath =
  "M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z";
const ImgForSuspenseStyle = styled(motion.div)`
  width: 90%;
  height: 100%;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
`;
const imgSuspenseStyle = {
  width: "90%",
};
const imgSuspenseVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const CreatedDate = ({ created }) => (
  <p
    style={{
      width: "100%",
      textAlign: "right",
      paddingRight: "80px",
      fontSize: "12px",
    }}
  >
    utworzono:
    <span
      style={{
        paddingLeft: "10px",
      }}
    >
      {created
        ? new Date(created)?.toLocaleDateString("pl-PL", {
            timeZone: "Europe/Warsaw",
          })
        : new Date()?.toLocaleDateString("pl-PL", {
            timeZone: "Europe/Warsaw",
          })}
    </span>
  </p>
);
export const Post = ({ img, title, text, intro, isDark, created }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <Link
      to={`/blog/${title}`}
      variants={imgSuspenseVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {isLoaded ? null : (
        <ImgForSuspenseStyle
          isLoaded={isLoaded}
          variants={imgSuspenseVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          isDark={isDark}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="image"
            className="svg-inline--fa fa-image fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={imgSuspenseStyle}
            width={30}
          >
            <path fill="currentColor" d={imagePath}></path>
          </svg>
        </ImgForSuspenseStyle>
      )}
      {img && (
        <MainImg>
          {" "}
          <img
            src={`${API_URL}${img}`}
            alt={title}
            loading="lazy"
            onLoad={() => setIsLoaded(true)}
          />
        </MainImg>
      )}
      <Content>
        <h3>{splitAndJoin(title)}</h3>

        {intro && (
          <ClampLines
            text={intro}
            id={img}
            lines={5}
            ellipsis="..."
            // moreText="Expand"
            // lessText="Collapse"
            className="custom-class"
            innerElement="p"
            buttons={false}
          />
        )}
      </Content>
      <CreatedDate created={created} />
    </Link>
  );
};
