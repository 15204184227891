import {
  TOGGLE_THEME,
  MENU_OPEN,
  MENU_CLOSE,
  OVER_CLOSE,
  OVER_OPEN,
  CHECK_IS_MOBILE,
} from "../types/types";
export const toggleTheme = (item) => ({
  type: TOGGLE_THEME,
  item,
});
export const menuOpen = () => ({
  type: MENU_OPEN,
});
export const overflowHidden = () => ({
  type: OVER_CLOSE,
});
export const overflowAuto = () => ({
  type: OVER_OPEN,
});
export const menuClose = () => ({
  type: MENU_CLOSE,
});
export const checkIsMobile = (item) => ({
  type: CHECK_IS_MOBILE,
  item,
});
// export default {
//   toggleTheme,
//   menuOpen,
//   menuClose,
//   overflowHidden,
//   overflowAuto,
// };
