import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Theme } from "../../../store/theme/theme";
import { connect } from "react-redux";
import { sortType } from "../../../store/select/duck/actions/actions";

import { SelectContentFromOption } from "./SelectContentFromOption";
import { motion } from "framer-motion";

const downPath =
  "M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z";
const loginOptionsVariants = {
  hidden: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
  visible: {
    opacity: 1,
    // scale:1,
    y: 0,
    // transformOrigin:"bottom",
    transition: { duration: 0.3 },
  },
  exit: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
};

const SortSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-arround;
  height: 30px;
  line-height: 30px;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  user-select: none;
  padding: 0 20px 0 20px;
  & > span {
    text-transform: uppercase;
    padding: 10px;
  }
  @media${Theme.device.tablet} {
    margin-bottom: 20px;
  }
  @media${Theme.device.mobileL} {
    line-height: 18px;
    z-index: 3;
  }
`;
const SortSelect = styled.div`
  height: 30px;
  width: 150px;
  line-height: 30px;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  border: 1px solid
    ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  border-radius: 5px;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  transition: background 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: 2px 2px 7px black;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 3;
`;
const SelectContent = styled.div`
  text-transform: uppercase;
  padding-left: 10px;
  font-size: 0.9rem;
  color: ${Theme.light.thirdColor.dark};
`;
const UlSelectOption = styled(motion.div)`
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  & > ul {
    list-style-type: none;
  }
  & > ul > li {
    width: 100%;
    box-sizing: border-box;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 0.9rem;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
    transition: 0.3s;
  }
  & > ul > li:hover {
    border: 1px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  }
`;

const _SectionSort = ({
  isDark,
  isBrandActive,
  isBrandSelected,
  isCategoryActive,
  select,
  sortType,
}) => {
  const [isActive, setIsActive] = useState(false);
  const refs = React.createRef();

  const arrowStyle = useMemo(
    () => ({
      width: "15px",
      color: Theme.light.thirdColor.dark,
      paddingRight: "5px",
      transform: isActive ? `rotate(180deg)` : `rotate(0)`,
      transition: "transform .3s",
      transformOrigin: "25% 50%",
    }),
    []
  );

  const handleSelectOption = (value) => {
    sortType(value);
  };
  const handleClickOutside = (e) => {
    if (
      (isBrandActive === false && !isBrandSelected) ||
      (isBrandActive === true && !isBrandSelected) ||
      isCategoryActive === true
    ) {
      if (refs.current.contains(e.target)) {
        return document.removeEventListener("click", handleClickOutside);
      } else {
        setIsActive(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  });

  const createSelectOptions = select.sort.map((option) => (
    <SelectContentFromOption
      key={option.value}
      value={option.value}
      name={option.name}
      click={handleSelectOption}
    />
  ));
  const typeSortActive = () =>
    select.sort.map((value) => {
      if (value.active) {
        return value.value;
      }
      return null;
    });
  if (
    (isBrandActive === false && !isBrandSelected) ||
    (isBrandActive === true && !isBrandSelected) ||
    isCategoryActive === true
  ) {
    return (
      <SortSectionWrapper isDark={isDark}>
        <span> sortuj wg:</span>
        <SortSelect onClick={() => setIsActive(!isActive)} ref={refs}>
          <SelectContent isDark={isDark}>{typeSortActive()}</SelectContent>

          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="chevron-down"
            className="svg-inline--fa fa-chevron-down fa-w-14"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            style={arrowStyle}
            width={15}
          >
            <path fill="currentColor" d={downPath}></path>
          </svg>
          {isActive ? (
            <UlSelectOption
              variants={loginOptionsVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              isDark={isDark}
            >
              <ul>{createSelectOptions}</ul>
            </UlSelectOption>
          ) : null}
        </SortSelect>
      </SortSectionWrapper>
    );
  }
  return null;
};
const mapStateToProps = (state) => ({
  select: state.select,
});
const mapDispatchToProps = (dispatch) => ({
  sortType: (item) => dispatch(sortType(item)),
});
export const SectionSort = connect(
  mapStateToProps,
  mapDispatchToProps
)(_SectionSort);
