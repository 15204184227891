import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { chose } from "../../../../store/select/duck/actions/actions";
import { Theme } from "../../../../store/theme/theme";
import { ItemSelect } from "./ItemSelect";

const DisplayWrapper = styled.div`
  width: 100%;
  color: ${Theme.light.primaryColor.white};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  @media${Theme.device.mobileL} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
`;
const SelectTitle = styled.div`
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  user-select: none;
  margin-left: 10px;
  & > h3 {
    font-weight: normal;
  }
  @media${Theme.device.mobileL} {
    font-size: ${Theme.size.fontSizeMobileL}px;
    width: 100%;
    margin-bottom: 5px;
    text-align: center;
    margin-left: 0;
  }
`;
const ItemsWraper = styled.div`
  width: 70%;
  color: ${Theme.light.primaryColor.white};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  & > a {
    text-decoration: none;
    color: ${Theme.light.primaryColor.white};
  }
  @media${Theme.device.mobileL} {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    margin: 5px;
  }
`;

const useCheckUrl = (name, dataQuery) => {
  const { data, loading, error } = dataQuery;
  if (error) return { data: null, loading, error };
  if (!loading && data) {
    const value = Object.values(data);

    if (value && value[0]) {
      const foundedValue = value[0].filter((brandOrCategory) =>
        brandOrCategory.name.includes(name)
      );
      return foundedValue[0]
        ? { data: foundedValue[0], loading, error }
        : { data: null, loading, error };
    }
  } else return { data: null, loading, error };
};

const _DisplaySelect = ({ select, chose, theme, urlChecked }) => {
  const title = "gazetki wyświetlane według:".toLocaleUpperCase();
  // const options = [...select?.options];
  const items = select?.options?.length
    ? select?.options?.map((item, index) =>
        urlChecked ? (
          <Link to="/" key={item?.name}>
            <ItemSelect
              isDark={theme?.isDark}
              index={index}
              content={item?.name && item?.name?.toUpperCase()}
              active={item?.active}
              click={() => chose(item?.name)}
            />
          </Link>
        ) : (
          <Link to="/" key={item?.name}>
            <ItemSelect
              isDark={theme?.isDark}
              index={index}
              content={item?.name && item?.name?.toUpperCase()}
            />
          </Link>
        )
      )
    : null;
  return (
    <>
      <DisplayWrapper>
        <SelectTitle>
          <h3>{title}</h3>
        </SelectTitle>
        <ItemsWraper>{items}</ItemsWraper>
      </DisplayWrapper>
    </>
  );
};
const mapStateToProps = (state) => ({
  select: state.select,
  theme: state.theme,
});
const mapDisaptchToProps = (dispatch) => ({
  chose: (item) => dispatch(chose(item)),
});
export const DisplaySelect = connect(
  mapStateToProps,
  mapDisaptchToProps
)(_DisplaySelect);
