import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Theme } from "../../store/theme/theme";
import { PaginationSelect } from "./PaginationSelect";
import styled from "styled-components";
import {
  WrraperSection,
  MainContent,
  MainWrapperLayer,
  SectionHeader,
  SingleItemInfo,
  buttonBackStyle,
  buttonNextStyle,
  btnStyle,
  DotWrapper,
  BtnHover,
} from "./stylesForCloseToUserShops";
import { FindClosestShopInfo } from "../brandOrCategoryContainer/FindClosestShopInfo";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { ArroundSection } from "../mapSection/arroundSection/ArroundSection";
import { motion } from "framer-motion";
import { SvgDisplay } from "../tools/spiner/svgDisplay/SvgDisplay";
import { nextArrow, backArrow } from "../../media/svgPath/SvgPath";
const singleShopVariants = {
  hidden: {
    opacity: 0,
    x: "25%",
  },
  visible: (index) => ({
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      delay: index * 0.2 + 0.3,
    },
  }),
  exit: {
    opacity: 0,
  },
};
const pageVariants = {
  hidden: {
    opacity: 0,
    // x:'-25%',
  },
  visible: (index) => ({
    opacity: 1,
    // x:0,
    transition: {
      duration: 0.3,
      // delay:index*0.1+0.5,
      delay: 0.3,
    },
  }),
  exit: {
    opacity: 0,
  },
};
const CaruselWrapper = styled.div`
  width: 100%;
  max-width: ${Theme.breakPoint.desktop}px;
  //   margin: 0 auto;
  height: 100%;
  //   align-items: center;
  //   position: relative;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  // @media${Theme.device.tablet} {
  //   width: 100%;
  // }
`;
const sliderStyle = {
  height: "100%",
  width: "100%",
  textAlign: "center",
  color: `${Theme.light.primaryColor.white}`,
};
const slideStyle = {
  width: "100vw",
  height: "100%",
  position: "relative",
};
const caruselStyle = {
  width: "100%",
  textAlign: "center",
};

const SlideHover = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
`;
const Single = ({ item, index, theme, name, currentPage }) => {
  const [extended, setExtended] = useState(false);
  useEffect(() => {
    if (index !== currentPage) {
      setExtended(false);
    }
  }, [index, currentPage]);
  return (
    <SingleItemInfo
      key={index}
      isDark={theme.isDark}
      extended={extended}
      className={extended ? "extended" : null}
      // variants={singleShopVariants}
      // initial="hidden"
      // animate="visible"
      // exit="exit"
      // custom={index}
    >
      <FindClosestShopInfo
        localizationItem={item}
        key={index}
        option={true}
        setExtended={setExtended}
        extended={extended}
        name={name}
      />
    </SingleItemInfo>
  );
};
const FromSecondToHowMany = ({
  howMany,
  localization,
  pagePagination,
  theme,
  name,
  currentPage,
}) => {
  const shopsWithoutClosestShop = localization.slice(1, localization.length);

  const selectedByPagination =
    shopsWithoutClosestShop &&
    shopsWithoutClosestShop.slice(
      pagePagination * howMany - howMany,
      pagePagination * howMany
    );

  return selectedByPagination.map((shop, index) => (
    <Single
      key={index}
      item={shop}
      index={index}
      theme={theme}
      name={name}
      currentPage={currentPage}
    />
  ));
};
const _CloseToUserShops = ({ theme, localization, howMany, select, name }) => {
  const [pagePagination, setPagePagination] = useState(1);

  let slides = Math.ceil((localization.shopsByCoord.length - 1) / howMany);
  const wrapper = useRef();
  useEffect(() => {
    const shopsWrapper = document.getElementById("shopsWrapper");

    if (theme.isMobile && wrapper && wrapper.current && pagePagination > 1) {
      document.documentElement.scrollTop =
        window.scrollY + shopsWrapper.getBoundingClientRect().top;
    }
  }, [theme.isMobile, pagePagination]);
  const PagesForSlider = ({ name, localization }) => {
    const nrPages = slides;
    let arrayForPages = [];
    for (let i = 1; i <= nrPages; i++) {
      arrayForPages = [...arrayForPages, { page: i }];
    }

    return arrayForPages.map((item, index) => {
      return (
        <Slide index={pagePagination - 1} style={slideStyle} key={index}>
          <SlideHover
            variants={pageVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            isDark={theme.isDark}
          >
            <FromSecondToHowMany
              howMany={howMany}
              localization={localization}
              pagePagination={item.page}
              theme={theme}
              name={name}
              currentPage={pagePagination}
            />
          </SlideHover>
        </Slide>
      );
    });
  };

  if (
    localization.shopsByCoord.length > 1 &&
    (name || select.options.filter((item) => item.active)[0].valueName)
  ) {
    return (
      <>
        <WrraperSection isDark={theme.isDark} ref={wrapper} id="shopsWrapper">
          <MainWrapperLayer isDark={theme.isDark}>
            <MainContent isDark={theme.isDark} layout>
              <ErrorBoundary>
                <SectionHeader isDark={theme.isDark}>
                  <h4>
                    <span>
                      Kolejne {localization.shopsByCoord.length - 1} w okolicy
                    </span>
                  </h4>
                  <div style={{ paddingBottom: "20px" }}>
                    <ArroundSection isDark={theme.isDark} />
                  </div>
                </SectionHeader>

                <CaruselWrapper>
                  <CarouselProvider
                    naturalSlideWidth={800}
                    naturalSlideHeight={140}
                    totalSlides={slides}
                    visibleSlides={1}
                    orientation={"horizontal"}
                    isIntrinsicHeight={true}
                    step={1}
                    dragStep={1}
                    style={caruselStyle}
                    touchEnabled={false}
                    // dragEnabled={false}
                  >
                    <Slider style={sliderStyle}>
                      <PagesForSlider
                        name={name}
                        localization={localization.shopsByCoord}
                      />
                    </Slider>
                    <DotWrapper>
                      <ButtonBack style={buttonBackStyle}>
                        <BtnHover
                          isDark={theme.isDark}
                          whileTap={{ scale: 0.85 }}
                        >
                          <SvgDisplay
                            viewBox={backArrow.viewBox}
                            d={backArrow.d}
                            style={btnStyle}
                          />
                        </BtnHover>
                      </ButtonBack>
                      <DotGroup
                        className="caruselDotsShops"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          padding: "25px",
                        }}
                      >
                        <div style={{ width: "10px", margin: "2px" }}></div>
                      </DotGroup>
                      <ButtonNext style={buttonNextStyle}>
                        <BtnHover
                          isDark={theme.isDark}
                          whileTap={{ scale: 0.85 }}
                        >
                          <SvgDisplay
                            viewBox={nextArrow.viewBox}
                            d={nextArrow.d}
                            style={btnStyle}
                          />
                        </BtnHover>
                      </ButtonNext>
                    </DotWrapper>
                    <PaginationSelect
                      pagePagination={pagePagination}
                      setPagePagination={setPagePagination}
                    />
                  </CarouselProvider>
                </CaruselWrapper>
              </ErrorBoundary>
            </MainContent>
          </MainWrapperLayer>
        </WrraperSection>
      </>
    );
  }
  if (
    localization.shopsByCoord.length > 0 &&
    localization.shopsByCoord.length < 2 &&
    (name || select.options.filter((item) => item.active)[0].valueName)
  ) {
    return (
      <>
        <WrraperSection isDark={theme.isDark}>
          <MainWrapperLayer isDark={theme.isDark}>
            <MainContent isDark={theme.isDark}>
              <SectionHeader isDark={theme.isDark}>
                <h1>
                  <span>Brak więcej okolicy</span>
                </h1>
                <div style={{ paddingBottom: "20px" }}>
                  <ArroundSection isDark={theme.isDark} />
                </div>
              </SectionHeader>
              <h1>Spróbuj zwiększyć zakres poszukiwań</h1>
            </MainContent>
          </MainWrapperLayer>
        </WrraperSection>
      </>
    );
  } else {
    return null;
  }
};
const mapStateToProps = (state) => ({
  theme: state.theme,
  localization: state.localization,
  select: state.select,
});
export const CloseToUserShops = connect(mapStateToProps, {})(_CloseToUserShops);
