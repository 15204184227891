import React from "react";
import {
  HOME_PAGE,
  BRAND_PAGE,
  NEWSPAPER_PAGE,
  TOP_DEALS_PAGE,
} from "./loadDeals.type";
import { LoadDealsHomePage } from "./LoadDealsHomePage";
import { LoadDealsBrandPage } from "./LoadDealsBrandPage";
import { LoadDealsNewsPaperPage } from "./LoadDealsNewsPaperPage";
import { LoadDealsTopDealsPage } from "./LoadDealsTopDealsPage";

export const LoadDeals = ({
  brandDataFromUrl,
  brandItemProductsDataFromUrl,
  optionsConfirmed,
  activeSortType,
  topDealsSelected,
  loadingDeals,
  loadingSelectedDeals,
  setTopDealsShoppageFromNewspaper,
  topDealsPageFromNewsPaper,
  fromBookTopDeals,
  dataFromHomePage,
  dataFromTopDealsPage,
  bookData,
  dealsDataOptioned,
  dealsForTopDeals,
  theme,
  dealsType,
  setNumberOfDeals,
  setDealsNumber,
}) => {
  switch (dealsType) {
    case HOME_PAGE: {
      return (
        <LoadDealsHomePage
          dataFromHomePage={dataFromHomePage}
          dealsDataOptioned={dealsDataOptioned}
          topDealsSelected={topDealsSelected}
          activeSortType={activeSortType}
          optionsConfirmed={optionsConfirmed}
          theme={theme}
        />
      );
    }
    case BRAND_PAGE: {
      return (
        <LoadDealsBrandPage
          brandDataFromUrl={brandDataFromUrl}
          brandItemProductsDataFromUrl={brandItemProductsDataFromUrl}
          topDealsSelected={topDealsSelected}
          activeSortType={activeSortType}
          theme={theme}
        />
      );
    }
    case NEWSPAPER_PAGE: {
      return (
        <LoadDealsNewsPaperPage
          bookData={bookData}
          topDealsSelected={topDealsSelected}
          setTopDealsShoppageFromNewspaper={setTopDealsShoppageFromNewspaper}
          topDealsPageFromNewsPaper={topDealsPageFromNewsPaper}
          activeSortType={activeSortType}
          theme={theme}
        />
      );
    }
    case TOP_DEALS_PAGE: {
      return (
        <LoadDealsTopDealsPage
          dealsForTopDeals={dealsForTopDeals}
          dataFromTopDealsPage={dataFromTopDealsPage}
          topDealsSelected={topDealsSelected}
          activeSortType={activeSortType}
          setNumberOfDeals={setNumberOfDeals}
          setDealsNumber={setDealsNumber}
          theme={theme}
        />
      );
    }
    default:
      return null;
  }
};
