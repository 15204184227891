import React, { useState, useEffect } from "react";
// import { ReactComponent as ThumbsUp } from '../../media/svg/thumbs-up-solid.svg'
import {
  MainContent,
  ThumbsUpIconStyle,
  SectionHeader,
} from "./stylesForContact";
import { Spinner } from "../../components/tools/spiner/Spinner";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { API_URL } from "../../constant/constant";
const thumbsPath =
  "M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z";
const divVariants = {
  hidden: {
    opacity: 0,
    scale: 1.2,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};

const SvgWraper = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
  }
  & > div > p {
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%) !important;
    font-size: 22px;
  }
`;
const Correct = ({ isDark }) => {
  const [seconds, setSeconds] = useState(21);
  useEffect(() => {
    setInterval(() => {
      setSeconds((state) => state - 1);
    }, 1000);
  }, []);
  const pathDefinition = "M25,50a25,25 0 1,0 50,0a25,25 0 1,0 -50,0";
  return (
    <>
      <SectionHeader isDark={isDark}>
        <h1>
          <span>dzięki za kontakt</span>
        </h1>
      </SectionHeader>
      <motion.div variants={divVariants} initial="hidden" animate="visible">
        {/* <ThumbsUp style={ThumbsUpIconStyle} /> */}
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="thumbs-up"
          className="svg-inline--fa fa-thumbs-up fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          style={ThumbsUpIconStyle}
          width={150}
        >
          <path fill="currentColor" d={thumbsPath}></path>
        </svg>
      </motion.div>
      <p>postaramy się odpowiedzieć najszybciej jak to tylko możliwe.</p>
      <p>
        <b>tymczasem pozdrawiamy serdecznie</b>
      </p>
      <SvgWraper>
        <motion.svg
          aria-hidden="true"
          className="svgItem"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
        >
          <motion.path
            fill="none"
            d={pathDefinition}
            initial={{ pathLength: 0, pathOffset: 1 }}
            animate={{ pathLength: 1, pathOffset: 0 }}
            transition={{ duration: 22 }}
          ></motion.path>
        </motion.svg>
        <div>
          <AnimatePresence>
            <motion.p
              variants={divVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              {seconds}
            </motion.p>
          </AnimatePresence>
        </div>
      </SvgWraper>
      <p>ta wiadomość zostanie zaraz wyczyszczona</p>
    </>
  );
};

export const AfterValidationeContact = ({
  isDark,
  mainValidation,
  body,
  changeAfterBadResponse,
}) => {
  const [responseError, setResponseError] = useState(false);
  const [getCorrectResponse, setCorrectResponse] = useState(!false);
  const sendConfirmationEmail = async ({ email, first_name, msg }) => {
    // console.log(first_name, email)
    const dataContact = {
      first_name,
      email,
      msg,
    };
    const dataConfirmedContact = fetch(`${API_URL}/api/contact`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(dataContact), // body data type must match "Content-Type" header
    });
    const status = await dataConfirmedContact;
    if (status) {
      const response = await status.json().then((data) => data);
      if (response === true) {
        setCorrectResponse(true);
        setTimeout(() => changeAfterBadResponse(false), 20000);
      }

      if (response === false) {
        setResponseError(
          "Przepraszamy coś poszło nie tak :( ,spróbuj ponownie."
        );
        setTimeout(() => changeAfterBadResponse(false), 5000);
      }
    }
  };
  useEffect(() => {
    sendConfirmationEmail(body);
  }, []);

  return (
    <MainContent isDark={isDark}>
      {!getCorrectResponse && !responseError && <Spinner />}
      {getCorrectResponse && <Correct isDark={isDark} />}
      {responseError && <p>{responseError}</p>}
    </MainContent>
  );
};
