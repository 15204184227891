import React from "react";
import styled from "styled-components";
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";
import SpinnerLogo from "../../../media/svgLogo/cenniczek-icon-192.svg";
import { FRONT_URL } from "../../../constant/constant";
import { Theme } from "../../../store/theme/theme";
const override = css`
  display: block;
  margin: 0 auto;
`;
const SpinnerWrapper = styled.div`
  width: ${({ small }) => (small ? "50%" : "100%")};
  height: ${({ small }) => (small ? "50%" : "100%")};
  position: absolute;
  top: ${({ small }) => (small ? "25%" : "50%")};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${({ zIndexController }) =>
    zIndexController ? zIndexController : 101};
  @media${Theme.device.mobileL} {
    top: ${({ small }) => (small ? "10%" : "50%")};
  }
`;
const SpinnerIcons = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: ${({ zIndexController }) =>
    zIndexController ? zIndexController : 101};
  background: ${({ isDark, cut }) =>
    isDark && cut
      ? Theme.dark.sixColor.dark
      : !isDark && cut
      ? Theme.light.fiveColor.white
      : ""};
  border: ${({ isDark, cut }) =>
    isDark && cut
      ? `1px solid ${Theme.dark.primaryColor.dark}`
      : !isDark && cut
      ? "1px solid white"
      : ""};
`;

export const Spinner = ({ small, cut, isDark, zIndexController }) => {
  return (
    <SpinnerWrapper small={small} zIndexController={zIndexController}>
      <SpinnerIcons
        cut={cut}
        isDark={isDark}
        zIndexController={zIndexController}
      >
        <img
          loading="lazy"
          src={
            typeof window !== "undefined"
              ? `${SpinnerLogo}`
              : `${FRONT_URL}media/img/cenniczek-icon-192.svg`
          }
          style={{ width: "40px", margin: "5px" }}
        />
        <div className="sweet-loading">
          <PulseLoader
            css={override}
            size={10}
            color={"#be1e2d"}
            loading={true}
          />
        </div>
      </SpinnerIcons>
    </SpinnerWrapper>
  );
};
