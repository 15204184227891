import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Theme } from "../../../store/theme/theme";
import {
  usserLogged,
  usserLoggout,
} from "../../../store/shopingList/duck/actions/actions";
import { LoginOptions } from "./loginOptions/LoginOptions";

import { API_URL } from "../../../constant/constant";
import { PageReloaded } from "../../../utils/PageReloaded";
import { ErrorBoundary } from "../../errorBoundary/ErrorBoundary";
import { ErrorHandler } from "../../error/ErrorHandler";
import { decryptFrontData } from "../../../utils/Crypto/cryptoFront.controller";
// import alertify from "alertifyjs";
// import "alertifyjs/build/css/alertify.css";
// import "alertifyjs/build/css/themes/default.css";

const enterPath =
  "M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z";
const exitPath =
  "M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z";
const LoginButton = styled.div`
  width: 120px;
  height: 40px;
  border-radius: 5px;
  font-size: 1em;
  background: ${({ userLoged }) =>
    userLoged ? Theme.light.fourthColor.red : Theme.light.secondaryColor.green};
  color: ${Theme.light.primaryColor.white};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  &:hover {
    box-shadow: 2px 2px 7px black;
  }
  & > a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  & > svg {
    padding-right: 3px;
  }
  @media${Theme.device.mobileL} {
    width: 30px;
    height: 30px;
    & > svg {
      padding: 0;
    }
    & > p,
    & > a > p {
      display: none;
    }
  }
`;
export const styleForSvg = {
  height: "16px",
};

const _Login = ({ shopingList, usserLogged, usserLoggout, theme }) => {
  const [loginOptionsVisible, setLoginOtionsVisible] = useState(false);
  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  const [alertify, setAlertify] = useState(null);

  const reloaded = PageReloaded();
  useEffect(() => {
    return () => {
      setAlertify(null);
    };
  }, []);

  useEffect(() => {
    const loadAlertify = async () => {
      if (typeof window !== "undefined") {
        try {
          const alertifyInstance = (
            await import("../../../media/assets/alertify/alertify.js")
          ).default;
          await import("../../../media/assets/alertify/alertify.min.css");
          await import("../../../media/assets/alertify/default.min.css");
          setAlertify(alertifyInstance);
        } catch (error) {
          console.error("Error loading Alertify:", error);
        }
      }
    };

    loadAlertify();
  }, []);
  useEffect(() => {
    fetch(`${API_URL}/api/user-exist`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((result) => {
        // decrypt data from response
        return decryptFrontData(
          result.data,
          setErrorFromCrypto,
          null,
          "Login.js"
        );
      })
      .then((data) => {
        if (data) {
          usserLogged(data);
          if (data.isLoged && !data.email) {
            if (alertify) {
              alertify.set("notifier", "position", "top-center");
              alertify.alert(
                "Zalogowałeś się pomyślnie ale ...",
                "Nie znamy twojego e-maila, bez tego ograniczasz sobie funkcjonalności serwisu. Możesz go zapisać w naszej sekcji użytkownika albo skorzystać z innej platformy autoryzującej."
              );
            }
          } else if (data.isLoged && data.email) {
            if (alertify) {
              alertify.set("notifier", "position", "bottom-left");
              alertify.success(`Zalogowałeś się pomyślnie`);
            }
          }
        } else {
          usserLoggout();
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <LoginButton
      onMouseOver={() => setLoginOtionsVisible(true)}
      onMouseLeave={() => {
        setLoginOtionsVisible(false);
      }}
      userLoged={shopingList?.userLoged}
    >
      <ErrorBoundary>
        {errorFromCrypto && <ErrorHandler errorMsg={errorFromCrypto} />}
        {!shopingList.userLoged && (
          <>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="sign-in-alt"
              className="svg-inline--fa fa-sign-in-alt fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              style={styleForSvg}
            >
              <path fill="currentColor" d={enterPath}></path>
            </svg>
            <p>Konto</p>
          </>
        )}
        {shopingList.userLoged && (
          <a
            href={`${API_URL}/api/logout`}
            onClick={() => {
              alertify.set("notifier", "position", "bottom-left");
              alertify.success(`Wylogowałeś się pomyślnie`);
            }}
            style={{ color: Theme.light.primaryColor.white }}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="sign-out-alt"
              className="svg-inline--fa fa-sign-out-alt fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              style={styleForSvg}
            >
              <path fill="currentColor" d={exitPath}></path>
            </svg>
            <p>Wyloguj</p>
          </a>
        )}
        {loginOptionsVisible && !shopingList.userLoged && (
          <LoginOptions
            isDark={theme.isDark}
            setLoginOtionsVisible={setLoginOtionsVisible}
          />
        )}
      </ErrorBoundary>
    </LoginButton>
  );
};

const mapDispatchToProps = (dispatch) => ({
  usserLogged: (item) => dispatch(usserLogged(item)),
  usserLoggout: (item) => dispatch(usserLoggout(item)),
});
const mapStateToProps = (state) => ({
  shopingList: state.shopingList,
  theme: state.theme,
});
export const Login = connect(mapStateToProps, mapDispatchToProps)(_Login);
