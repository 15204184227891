import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { ErrorFromData } from "../../error/ErrorFromData";
import { LoadDeals } from "../loadDeals/LoadDeals";

const MainTopDealsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 40vh;
  position: relative;
`;

const _MainTopDealsContent = ({
  select,
  theme,
  options,
  brandExist,
  setTopDealsShoppageFromNewspaper,
  topDealsPageFromNewsPaper,
  fromBookTopDeals,
  brandName,
  brandDataFromUrl,
  brandItemProductsDataFromUrl,
  dataFromHomePage,
  dataFromTopDealsPage,
  bookData,
  dealsDataOptioned,
  dealsForTopDeals,
  dealsType,
  setNumberOfDeals,
  setDealsNumber,
}) => {
  let activeSortType;
  let optionsConfirmed = options;

  activeSortType =
    select?.sortDeals.filter((item) => item.active) || "recomended";

  if (dealsDataOptioned?.error) {
    return (
      <ErrorFromData
        errorInfo={dealsDataOptioned.error}
        queryName={"dealsDataOptioned._MainTopDealsContent"}
      />
    );
  }

  if (brandDataFromUrl?.error) {
    return (
      <ErrorFromData
        errorInfo={brandDataFromUrl.error}
        queryName={"brandDataFromUrl._MainTopDealsContent"}
      />
    );
  }

  if (brandItemProductsDataFromUrl?.error) {
    return (
      <ErrorFromData
        errorInfo={brandItemProductsDataFromUrl.error}
        queryName={"brandItemProductsDataFromUrl._MainTopDealsContent"}
      />
    );
  }
  if (dataFromHomePage?.error) {
    return (
      <ErrorFromData
        errorInfo={dataFromHomePage.error}
        queryName={"dataFromHomePage._MainTopDealsContent"}
      />
    );
  }
  if (dataFromTopDealsPage?.error) {
    return (
      <ErrorFromData
        errorInfo={dataFromTopDealsPage.error}
        queryName={"dataFromTopDealsPage._MainTopDealsContent"}
      />
    );
  }
  if (dealsForTopDeals?.error) {
    return (
      <ErrorFromData
        errorInfo={dealsForTopDeals.error}
        queryName={"dealsForTopDeals._MainTopDealsContent"}
      />
    );
  }
  if (bookData?.error) {
    return (
      <ErrorFromData
        errorInfo={bookData.error}
        queryName={"bookData._MainTopDealsContent"}
      />
    );
  }
  return (
    <MainTopDealsWrapper>
      <LoadDeals
        brandDataFromUrl={brandDataFromUrl}
        brandItemProductsDataFromUrl={brandItemProductsDataFromUrl}
        optionsConfirmed={optionsConfirmed}
        activeSortType={activeSortType && activeSortType[0]}
        topDealsSelected={select.topDealsSelected.name}
        setTopDealsShoppageFromNewspaper={setTopDealsShoppageFromNewspaper}
        topDealsPageFromNewsPaper={topDealsPageFromNewsPaper}
        fromBookTopDeals={fromBookTopDeals}
        dataFromHomePage={dataFromHomePage}
        dataFromTopDealsPage={dataFromTopDealsPage}
        bookData={bookData}
        dealsDataOptioned={dealsDataOptioned}
        dealsForTopDeals={dealsForTopDeals}
        theme={theme}
        dealsType={dealsType}
        setNumberOfDeals={setNumberOfDeals}
        setDealsNumber={setDealsNumber}
      />
    </MainTopDealsWrapper>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
});
export const MainTopDealsContent = connect(
  mapStateToProps,
  {}
)(_MainTopDealsContent);
