import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Theme } from "../../../store/theme/theme";
import { WraperForChartsAnalis, Analise, NoAnalise } from "./style";
import { Spinner } from "../../tools/spiner/Spinner";
// import alertify from "alertifyjs";
// import "alertifyjs/build/css/alertify.css";
// import "alertifyjs/build/css/themes/default.css";
import { BrandChart } from "./brandChart/BrandChart";
import { CategoryChart } from "./categoryChart/CategoryChart";
import { ItemsChart } from "./itemsChart/ItemsChart";
import { API_URL } from "../../../constant/constant";
import { ErrorBoundary } from "../../errorBoundary/ErrorBoundary";
import { ErrorHandler } from "../../error/ErrorHandler";
import {
  encryptFrontData,
  decryptFrontData,
} from "../../../utils/Crypto/cryptoFront.controller";
const countTotalPrice = (listItems, userItems) => {
  const concatList = [...listItems, ...userItems];
  const total = concatList.reduce((total, item) => {
    return (
      total +
      parseFloat(
        (parseFloat(item.price) || 0 * parseFloat(item.howMany) || 0).toFixed(2)
      )
    );
  }, 0);
  if (total > 0) {
    return total.toFixed(2);
  } else {
    return total;
  }
};

const _ChartAnalise = ({ theme, shopingList }) => {
  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  const [dataAnalise, setDataAnalise] = useState({
    ok: false,
    name: "",
    shopingList: [],
    error: "",
  });
  const [alertify, setAlertify] = useState(null);
  useEffect(() => {
    return () => {
      setAlertify(null);
    };
  }, []);
  useEffect(() => {
    const loadAlertify = async () => {
      if (typeof window !== "undefined") {
        try {
          const alertifyInstance = (
            await import("../../../media/assets/alertify/alertify.js")
          ).default;
          await import("../../../media/assets/alertify/alertify.min.css");
          await import("../../../media/assets/alertify/default.min.css");
          setAlertify(alertifyInstance);
        } catch (error) {
          console.error("Error loading Alertify:", error);
        }
      }
    };

    loadAlertify();
  }, []);

  useEffect(() => {
    if (
      shopingList.listItems &&
      shopingList.userItems &&
      (shopingList.listItems.length > 0 || shopingList.userItems.length > 0)
    ) {
      const frontDataToEncrypt = {
        list: [...shopingList.listItems, ...shopingList.userItems],
        total: countTotalPrice(shopingList.listItems, shopingList.userItems),
        email: shopingList.email,
        created: new Date().getTime(),
      };

      // encrypt data for fetch
      const ciphertext = encryptFrontData(
        frontDataToEncrypt,
        setErrorFromCrypto,
        null,
        "ChartAnalise.js"
      );
      fetch(`${API_URL}/api/listAnalise`, {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: ciphertext,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          // decrypt data from response
          return decryptFrontData(
            result.data,
            setErrorFromCrypto,
            null,
            "ChartAnalise.js"
          );
        })
        .then((data) => {
          if (data && data.ok) {
            setDataAnalise({
              ok: true,
              name: data.firstName,
              shopingList: data.shopingList,
              error: "",
            });
          } else {
            setDataAnalise({
              ok: false,
              name: data.firstName,
              shopingList: [],
              error: data.error,
            });
          }
        })
        .catch((err) => {
          alertify.set("notifier", "position", "bottom-left");
          alertify.error(`Błąd analizy`);
          console.log(err);
        });
    }
  }, []);

  return (
    <WraperForChartsAnalis isDark={theme.isDark}>
      <ErrorBoundary>
        <h2>analiza zakupów</h2>
        <p>z dnia : {new Date().toLocaleDateString()}</p>
        {shopingList.listItems &&
        shopingList.userItems &&
        (shopingList.listItems.length > 0 ||
          shopingList.userItems.length > 0) ? (
          <Analise>
            {!dataAnalise.ok && !dataAnalise.error && <Spinner />}
            {errorFromCrypto && <ErrorHandler errorMsg={errorFromCrypto} />}
            {dataAnalise.ok && (
              <>
                <BrandChart dataAnalise={dataAnalise} isDark={theme.isDark} />
                <CategoryChart
                  dataAnalise={dataAnalise}
                  isDark={theme.isDark}
                />
                <ItemsChart dataAnalise={dataAnalise} isDark={theme.isDark} />
              </>
            )}
            {dataAnalise.error && <h2>{dataAnalise.error}</h2>}
          </Analise>
        ) : (
          <NoAnalise>
            <h2>nie dodałeś żadnych pozycji do listy</h2>
          </NoAnalise>
        )}
      </ErrorBoundary>
    </WraperForChartsAnalis>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
  shopingList: state.shopingList,
});
export const ChartAnalise = connect(mapStateToProps, {})(_ChartAnalise);
