import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
import bgc from "../../media/img/newsletter-bgc.jpg";
import bgcMobile from "../../media/img/newsletter-bgc_mobile.jpg";
import { motion } from "framer-motion";
import { API_URL } from "../../constant/constant";
export const MainWrapper = styled.div`
  width: 100%;
  min-height: 90vh;
  background-image: url(${typeof window !== "undefined"
    ? bgc
    : `${API_URL}/media/img/newsletter-bgc.jpg`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  @media${Theme.device.mobileL} {
    background-image: url(${typeof window !== "undefined"
      ? bgcMobile
      : ` ${API_URL}/media/img/newsletter-bgc_mobile.jpg`});
  }
`;
export const MainWrapperLayer = styled.div`
  width: 100%;
  min-height: 90vh;
  height: 100%;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.dark.sixColor.dark};
  transition: background 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const MainContent = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
  font-family: montserrat;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-transform: uppercase;

  @media${Theme.device.mobileL} {
    width: 100%;
    & > h1 {
      font-size: 30px;
    }
    & > form > input {
      width: 80%;
    }
  }
`;
export const HeadLine = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
  & > div > p {
    margin: 15px;
    font-size: 1.5rem;
    user-select: none;
  }
  & > h4 {
    text-transform: uppercase;
    font-size: 40px;
    font-weight: 500;
    user-select: none;
  }
  & > h5 {
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 500;
    user-select: none;
  }
  @media${Theme.device.mobileL} {
    width: 100%;
    & > h4 {
      font-size: 30px;
      margin: 15px;
    }
  }
`;
export const ThumbsUpIconStyle = {
  width: "150px",
  margin: "40px",
};
