import React, { useEffect, useState } from "react";
import { Redirect, useHistory, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import {
  newspaperId,
  clearSugestedNewspapers,
  selectPageFromDeal,
  setTargetCordinates,
  clearSelectPageFromDeal,
  clearTargetCordinates,
} from "../../store/select/duck/actions/actions";

import { Spinner } from "../tools/spiner/Spinner";
import { getPostQuery } from "../../queries/queries";
import { RightBar } from "../rightBar/RightBar";
import { splitAndJoin } from "../../helpers/convert/splitAndJoin";
import { SinglePostLayout, PostContent, More } from "./stylesForBlog";
import { AdvForInfo } from "../infoSection/AdvForInfo";
import { Helmet } from "react-helmet";
import { API_URL } from "../../constant/constant";
import { ErrorFromData } from "../error/ErrorFromData";
import { Theme } from "../../store/theme/theme";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
const imagePath =
  "M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z";
const ImgForSuspenseStyle = styled(motion.div)`
  width: 90%;
  height: 325px;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
`;
const imgSuspenseStyle = {
  height: "320px",
  width: "100%",
};
const imgSuspenseVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const CreatedDate = ({ postData }) => (
  <p
    style={{
      width: "100%",
      textAlign: "right",
      paddingRight: "80px",
    }}
  >
    utworzono:
    <span
      style={{
        paddingLeft: "10px",
      }}
    >
      {new Date(postData?.data?.post?.created)?.toLocaleDateString("pl-PL", {
        timeZone: "Europe/Warsaw",
      })}
    </span>
  </p>
);

const _ShowPost = ({
  title,
  isDark,
  select,
  newspaperId,
  clearSugestedNewspapers,
  clearSelectPageFromDeal,
  clearTargetCordinates,
  selectPageFromDeal,
  setTargetCordinates,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  let history = useHistory();

  let books;
  let postData = useQuery(getPostQuery, {
    variables: { title: title },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    ssr: true,
  });
  if (postData.data) {
    books = postData.data;
  }

  const ActionsItems = ({ postData, content }) => {
    const {
      brand,
      bookData,
      startDuringTime,
      endDuringTime,
      page,
      bookId,
      cordinates,
    } = postData?.data?.post?.blogsItemProducts[0];
    return (
      <>
        <img
          src={`${API_URL}${postData.data.post.mainImg}`}
          alt={title}
          loading="lazy"
          onLoad={() => setIsLoaded(true)}
          onClick={() => {
            if (postData?.data?.post?.blogsItemProducts?.length) {
              clearSelectPageFromDeal();
              clearTargetCordinates();
              newspaperId(bookId);
              selectPageFromDeal(page);
              setTargetCordinates(cordinates);
              history.push(
                `/gazetka-promocyjna/${brand}-${bookData[0]?.alternativeFinalName}/${startDuringTime}-${endDuringTime}/${page}`
              );
              console.log(
                `/gazetka-promocyjna/${brand}-${bookData[0]?.alternativeFinalName}/${startDuringTime}-${endDuringTime}/${page}`
              );
            }
          }}
        />
        {content}
        <More whileTap={{ scale: 0.95 }} isDark={isDark}>
          <Link to={`/${brand}`} alt={brand}>
            więcej {brand}
          </Link>
        </More>
        <More
          whileTap={{ scale: 0.95 }}
          isDark={isDark}
          onClick={() => {
            if (postData?.data?.post?.blogsItemProducts?.length) {
              clearSelectPageFromDeal();
              clearTargetCordinates();
              newspaperId(bookId);
              selectPageFromDeal(page);
              setTargetCordinates(cordinates);
              history.push(
                `/gazetka-promocyjna/${brand}-${bookData[0]?.alternativeFinalName}/${startDuringTime}-${endDuringTime}/${page}`
              );
              console.log(
                `/gazetka-promocyjna/${brand}-${bookData[0]?.alternativeFinalName}/${startDuringTime}-${endDuringTime}/${page}`
              );
            }
          }}
        >
          <Link
            to={`/gazetka-promocyjna/${brand}-${bookData[0]?.alternativeFinalName}/${startDuringTime}-${endDuringTime}/${page}`}
            alt={`/gazetka-promocyjna/${brand}-${bookData[0]?.alternativeFinalName}/${startDuringTime}-${endDuringTime}/${page}`}
          >
            pokaż w gazetce
          </Link>
        </More>
      </>
    );
  };
  useEffect(() => {
    if (typeof window !== "undefined") document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    return () => {
      postData = {};
      clearSugestedNewspapers();
    };
  }, []);

  useEffect(() => {
    history.listen((location, action) => {
      if (action === "POP") {
        history.replace(location);
      }
    });
  }, []);
  if (postData.error) {
    return (
      <ErrorFromData
        errorInfo={postData.error}
        queryName={"getPostQuery._ShowPost"}
      />
    );
  }
  if (errorFromCrypto) {
    console.log(errorFromCrypto);
    return (
      <ErrorFromData
        errorInfo={errorFromCrypto}
        queryName={"getPostQuery.errorFromCrypto_ShowPost"}
      />
    );
  }
  if (postData && postData.loading === false && !postData?.data?.post) {
    return (
      <>
        <Redirect to={{ pathname: `/blog` }} />
      </>
    );
  }

  if (postData && postData.loading === false) {
    const content = postData.data.post.content.map((item, index) => (
      <div key={index}>
        {item.title && <h3>{item.title}</h3>}
        {item.text && <p>{item.text}</p>}
      </div>
    ));
    return (
      <>
        <Helmet>
          {title && postData?.data?.post?.content[0].text && (
            <meta
              property="og:description"
              content={`${postData?.data?.post?.content[0].text.slice(
                0,
                100
              )}...`}
            />
          )}
        </Helmet>
        <AnimatePresence>
          <SinglePostLayout
            isDark={isDark}
            variants={imgSuspenseVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <PostContent isDark={isDark}>
              <h2>{splitAndJoin(title)}</h2>
              {postData?.loading && <Spinner />}
              {isLoaded ? null : (
                <AnimatePresence>
                  <ImgForSuspenseStyle
                    isLoaded={isLoaded}
                    variants={imgSuspenseVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    isDark={isDark}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="far"
                      data-icon="image"
                      className="svg-inline--fa fa-image fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      style={imgSuspenseStyle}
                      width={30}
                    >
                      <path fill="currentColor" d={imagePath}></path>
                    </svg>
                  </ImgForSuspenseStyle>
                </AnimatePresence>
              )}

              {postData?.data?.post?.mainImg &&
              postData?.data?.post?.blogsItemProducts?.length ? (
                <ActionsItems postData={postData} content={content} />
              ) : (
                <>
                  {postData?.data?.post?.mainImg && (
                    <img
                      src={`${API_URL}${postData?.data?.post?.mainImg}`}
                      alt={title}
                      loading="lazy"
                      onLoad={() => setIsLoaded(true)}
                    />
                  )}
                  {content}
                  <More whileTap={{ scale: 0.95 }} isDark={isDark}>
                    <Link
                      to={`/${postData?.data?.post?.brandName}`}
                      alt={postData?.data?.post?.brandName}
                    >
                      więcej {postData?.data?.post?.brandName}
                    </Link>
                  </More>
                </>
              )}
              <CreatedDate postData={postData} />
            </PostContent>
            <RightBar
              bookData={books}
              isDark={isDark}
              postDataLoading={postData?.loading}
            />
          </SinglePostLayout>
        </AnimatePresence>
        <AdvForInfo />
      </>
    );
  }
  return (
    <PostContent isDark={isDark}>
      <h2>{splitAndJoin(title)}</h2>
      {postData?.loading && <Spinner />}
    </PostContent>
  );
};

const mapDispatchToProps = (dispatch) => ({
  newspaperId: (item) => dispatch(newspaperId(item)),
  clearSugestedNewspapers: (item) => dispatch(clearSugestedNewspapers(item)),
  selectPageFromDeal: (item) => dispatch(selectPageFromDeal(item)),
  setTargetCordinates: (item) => dispatch(setTargetCordinates(item)),
  clearTargetCordinates: (item) => dispatch(clearTargetCordinates(item)),
  clearSelectPageFromDeal: (item) => dispatch(clearSelectPageFromDeal(item)),
});
const mapStateToProps = (state) => ({
  select: state.select,
});
export const ShowPost = connect(mapStateToProps, mapDispatchToProps)(_ShowPost);
