import React from "react";
import { Policy } from "../components/policySection/Policy";
import { Footer } from "../components/footerSection/Footer";
import { Helmet } from "react-helmet";

export const PolicyPage = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://cenniczek.com/polityka`} />
        <meta name="description" content="Polityka - Cenniczek.com" />
        <meta property="og:description" content="Polityka - Cenniczek.com" />
        <meta name="keywords" content="polityka - Cenniczek.com"></meta>
        <meta property="og:title" content="Cenniczek.com-polityka" />
        <meta property="og:url" content={`https://cenniczek.com/polityka`} />
        <title>Cenniczek.com-polityka</title>
      </Helmet>
      <Policy />
      <Footer />
    </>
  );
};
