import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { Helmet } from "react-helmet";
const WrraperSection = styled.div`
  width: 100%;
  position: relative;
  // margin-top: 70px;
  user-select: none;
  // height:50vh;
`;
const MainWrapperLayer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  transition: background 0.3s;
  display: flex;
  justify-content: space-around;
  // align-items:flex-start;
`;
const MainContent = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  padding: 20px 0 20px 0;
  max-width: ${Theme.breakPoint.desktop};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  font-family: montserrat;
  line-height: 2rem;
  user-select: none;
  & > h4 {
    margin: 20px;
  }
  & > h4 > span {
    text-transform: uppercase;
    padding: 10px;
    border-bottom: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > div {
    margin-bottom: 30px;
  }
  & > div > p,
  & > div > h4 {
    margin-bottom: 20px;
  }
  @media${Theme.device.tablet} {
    width: 90%;
  }
`;
const _Policy = ({ theme }) => {
  if (typeof window !== "undefined") document.documentElement.scrollTop = 0;
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <WrraperSection isDark={theme.isDark}>
        <MainWrapperLayer isDark={theme.isDark}>
          <MainContent isDark={theme.isDark}>
            <ErrorBoundary>
              <h4>
                <span>Polityka prywatności i danych</span>
              </h4>
              <div>
                <h4>1. Uwagi ogólne</h4>
                <p>
                  Niniejsza polityka dotyczy następujących witryn internetowych
                  obsługiwanych lub kontrolowanych przez PrudentCode Sp. z o.o.
                  zwanym dalej PrudentCode: https://cenniczek.com,
                  https://prudentcode.pl.PrudentCode szanuje prywatność
                  odwiedzających („Użytkownicy”) na swoich stronach
                  internetowych („Witryny”), swoich zarejestrowanych członków
                  („Członkowie”),subskrynentów ("Subskrynenci"), którym świadczy
                  usługi, jak określono w Ogólnych Warunkach Użytkowania
                  („Warunki”) dostępnych pod adresem
                  https://cenniczek.com/regulamin. W niniejszej Polityce
                  prywatności odniesienia do Członków oznaczają zarówno
                  indywidualnych Członków, jak i Subskrynentów. Czasami
                  niniejsza Polityka prywatności odnosi się do tych kategorii
                  osób łącznie jako „Podmioty danych”.PrudentCode przetwarza
                  dane osobowe zgodnie z obowiązującymi przepisami o ochronie
                  danych, w szczególności ogólnym rozporządzeniem o ochronie
                  danych (UE) 2016/679 („RODO”).PrudentCode zastrzega sobie
                  prawo do okresowej modyfikacji Polityki Prywatności poprzez
                  publikowanie zaktualizowanej wersji na stronach internetowych
                  („Witryny”). Powinieneś wracać do niej regularnie. Jeśli
                  jednak wprowadzimy jakiekolwiek istotne zmiany, powiadomimy
                  Cię za pomocą powiadomienia na tej stronie lub, jeśli jesteś
                  Członkiem,Subskrynentem pocztą elektroniczną (wysłaną na adres
                  e-mail) przed wejściem zmiany w życie.Niniejsza Polityka
                  Prywatności opisuje, w jaki sposób PrudentCode przetwarza dane
                  osobowe, a w szczególności w jaki sposób gromadzimy i
                  wykorzystujemy dane osobowe, które przekazujesz za
                  pośrednictwem naszych stron internetowych oraz w inny sposób.
                  Opisuje również dostępne opcje dotyczące korzystania przez nas
                  z Twoich danych osobowych, w tym sposób, w jaki możesz uzyskać
                  dostęp do tych informacji i je aktualizować lub usuwać.
                </p>
              </div>
              <div>
                <h4>2.Administrator danych</h4>
                <p>
                  PrudentCode Sp. z o.o., z siedzibą pod adresem ul. Towarowa
                  23, 43-100 Tychy (Polska) jest podmiotem, który działa jako
                  jedyny administrator danych do celów niniejszej Polityki
                  prywatności. Wszelkie pytania lub prośby dotyczące
                  przetwarzania tych danych można kierować na
                  adres:kontakt@cenniczek.com, kontakt@prudentcode.pl.
                </p>
              </div>
              <div>
                <h4>3.Zebrane dane</h4>
                <p>3.1 Gromadzenie danych osobowych</p>
                <p>
                  PrudentCode gromadzi lub uzyskuje dane osobowe od podmiotów
                  danych z następujących źródeł: PrudentCode uzyskuje dane
                  osobowe, gdy dane te są dostarczane przez Podmioty danych (np.
                  gdy wnioskodawca dostarcza CV lub Podmioty danych kontaktują
                  się z PrudentCode za pośrednictwem poczty elektronicznej,
                  telefonu lub w jakikolwiek inny sposób);PrudentCode gromadzi
                  lub uzyskuje dane osobowe w zwykłym toku swoich relacji z
                  Podmiotami danych (np. gdy PrudentCode Sp. z o.o. świadczy
                  usługi na rzecz Członków, Użytkowników);PrudentCode gromadzi
                  lub uzyskuje dane osobowe, które Podmioty danych wyraźnie
                  zdecydują się upublicznić, w tym za pośrednictwem mediów
                  społecznościowych lub innych forów publicznych (np.
                  PrudentCode może wykorzystywać informacje związane z
                  profilem(-ami) w mediach społecznościowych Interesariusza, o
                  wydarzeniach, w których uczestniczył, oraz wszelkie
                  komunikaty, które udostępnia publicznie);PrudentCode gromadzi
                  lub uzyskuje dane osobowe, gdy Osoby, których dane dotyczą,
                  odwiedzają Witryny lub korzystają z jakichkolwiek funkcji lub
                  zasobów dostępnych na Witrynach lub za ich
                  pośrednictwem;PrudentCode gromadzi lub uzyskuje dane osobowe,
                  gdy jej Członkowie,Subskrynenci, Użytkownicy korzystają lub
                  rejestrują się w celu korzystania ze Stron Internetowych lub
                  jakichkolwiek Usług (np. poprzez formularz rejestracyjny
                  newslettera wypełniony przez Subskrynenta);PrudentCode tworzy
                  również dane osobowe o podmiotach danych w określonych
                  okolicznościach, takie jak rejestry interakcji z podmiotami
                  danych.
                </p>
                <p>3.2 Dane osobowe przetwarzane przez PrudentCode.</p>
                <p>
                  PrudentCode przetwarza, w celach wymienionych w punkcie 4,
                  następujące rodzaje danych osobowych: Dane identyfikacyjne,
                  m.in. nazwa użytkownika, prawdziwe imię i nazwisko, adres
                  e-mail oraz zawodowe dane kontaktowe;Dane pracodawcy, m.in. w
                  przypadku, gdy podmiot danych wchodzi w interakcję z
                  PrudentCode jako pracownik strony trzeciej, imię i nazwisko,
                  adres, numer telefonu i adres e-mail pracodawcy, w odpowiednim
                  zakresie;Poglądy i opinie m.in. wszelkie poglądy i opinie,
                  które Osoba, której dane dotyczą, zdecyduje się wysłać do
                  PrudentCode lub opublikować publicznie na temat PrudentCode na
                  platformach mediów społecznościowych lub innych forach
                  publicznych;Informacje dostarczone przez kandydatów, takie jak
                  ich CV; oraz wszelkie inne informacje dobrowolnie przekazane
                  PrudentCode przez Członka w określonym celu.
                </p>
                <p>3.3 „Ciasteczka”</p>
                <p>
                  Gdy odwiedzasz Witryny, umieszczamy pliki cookie na Twoim
                  urządzeniu lub odczytujemy pliki cookie znajdujące się już na
                  Twoim urządzeniu, zawsze pod warunkiem uzyskania Twojej zgody,
                  jeśli jest to wymagane, zgodnie z obowiązującym prawem.Pliki
                  cookie to małe pliki tekstowe, które mogą być przechowywane na
                  Twoim komputerze lub innym urządzeniu podczas odwiedzania
                  strony internetowej. Są one zazwyczaj używane do działania
                  witryn internetowych, ułatwienia przeglądania witryny,
                  śledzenia ruchów użytkownika w witrynie, zapamiętywania danych
                  logowania, optymalizacji zarządzania technicznego i tak
                  dalej.PudentCode używa ściśle niezbędnych plików cookie do
                  prawidłowego funkcjonowania strony internetowej. Na podstawie
                  Twojej zgody PudentCode wykorzystuje również pliki cookie do
                  tworzenia anonimowych i zbiorczych statystyk:analizowania
                  sposobu korzystania ze strony internetowej poprzez poprawę
                  użytkowania i funkcjonalności strony internetowej; oraz aby
                  dostosować stronę i produkty do potrzeb i preferencji
                  odbiorców. PudentCode nie wykorzystuje zebranych informacji do
                  tworzenia profili odwiedzających.Witryna PudentCode korzysta
                  wyłącznie z plików cookie Google Analytics. Google Analytics
                  to usługa analizy internetowej firmy Google Ireland Limited,
                  która mierzy sposób interakcji użytkowników z witryną. W
                  imieniu PudentCode i na podstawie Twojej zgody, Google
                  wykorzysta te informacje do oceny korzystania przez Ciebie ze
                  strony internetowej, do sporządzania raportów dotyczących
                  aktywności na stronie oraz do świadczenia PudentCode innych
                  usług związanych z korzystaniem ze strony internetowej i
                  Internetu. W ramach świadczenia usług Google może przekazywać,
                  przechowywać i przetwarzać Twoje dane osobowe w Stanach
                  Zjednoczonych lub innym kraju, w którym Google prowadzi swoje
                  placówki na podstawie standardowych klauzul umownych. Dane są
                  automatycznie usuwane po 5 latach. Możesz uniemożliwić Google
                  zbieranie danych związanych z korzystaniem z witryny i innych
                  witryn internetowych, instalując to narzędzie do rezygnacji{" "}
                  <a
                    target="_blank"
                    rel="nofollow"
                    href="https://tools.google.com/dlpage/gaoptout?hl=en"
                  >
                    link
                  </a>
                  . Więcej informacji na temat warunków użytkowania i ochrony
                  danych znajdziesz na{" "}
                  <a
                    target="_blank"
                    rel="nofollow"
                    href="https://support.google.com/analytics/answer/6004245"
                  >
                    link
                  </a>
                  . Możesz również usunąć lub zablokować pliki cookie,
                  zmieniając ustawienia w przeglądarce. Aby uzyskać więcej
                  informacji na temat konfiguracji przeglądarki, odwiedź stronę{" "}
                  <a
                    target="_blank"
                    rel="nofollow"
                    href="https://www.allaboutcookies.org/manage-cookies/"
                  >
                    link
                  </a>
                  . Jeśli korzystasz z różnych urządzeń, aby uzyskać dostęp do
                  naszych stron internetowych, musisz upewnić się, że każda
                  przeglądarka na każdym urządzeniu jest ustawiona na
                  preferencje dotyczące plików cookie
                </p>
                <p>3.4 Dane rejestrowane na naszych serwerach</p>
                <p>
                  Gdy Użytkownik, Członek uzyskuje dostęp do Witryn, odpowiednie
                  serwery automatycznie rejestrują następujące dane, które nie
                  są widoczne w Witrynach: rodzaj domeny, z której Użytkownik
                  lub Członek łączy się z Internetem, przydzielony adres IP,datę
                  i godzinę dostępu do Witryny, przeglądane strony i podejmowane
                  działania, rodzaj używanej przeglądarki, platformę i/lub
                  używany system operacyjny, wyszukiwarkę i słowa kluczowe
                  używane do wyszukiwania Serwisów.Podczas rejestracji jako
                  Członek zapisywane sa dane dotyczące adresu email,Imienia,płci
                  a podczas wysyłania listy zakupowej jej zawartości oraz dane
                  geolokalizacyjne jeśli Członek udzielił na nią
                  akceptacji.Informacje o zarządzaniu ustawieniami
                  geolokalizacji na urządzeniu z{" "}
                  <a
                    target="_blank"
                    rel="nofollow"
                    href="https://support.google.com/accounts/answer/3467281?hl=pl"
                  >
                    androidem
                  </a>
                  ;{" "}
                  <a
                    target="_blank"
                    rel="nofollow"
                    href=" https://support.microsoft.com/pl-pl/windows/windows-us%C5%82ugi-lokalizacji-i-prywatno%C5%9Bci-3a8eee0a-5b0b-dc07-eede-2a5ca1c49088"
                  >
                    windows 10
                  </a>
                  ;{" "}
                  <a
                    target="_blank"
                    rel="nofollow"
                    href="https://support.apple.com/pl-pl/HT207092"
                  >
                    apple
                  </a>
                </p>
              </div>
              <div>
                <h4>
                  4.Cele przetwarzania danych oraz podstawy prawne przetwarzania
                </h4>
                <p>4.1 Cele ogólne</p>
                <p>
                  Cele, dla których PrudentCode może przetwarzać dane osobowe
                  Podmiotów danych, z zastrzeżeniem obowiązujących przepisów
                  prawa, obejmują: Udostępnianie Serwisów i Usług: udostępnianie
                  Serwisów Użytkownikom, Członkom; dostarczanie Użytkownikom,
                  Członkom artykułów promocyjnych na ich żądanie; zarządzanie i
                  oceną udziału; oraz komunikowanie się z Użytkownikami,
                  Członkami w związku z tymi usługami; Ulepszanie Serwisów i
                  usług: identyfikacja problemów z Serwisami lub usługami;
                  planowanie ulepszeń Serwisów lub usług; oraz tworzenie nowych
                  stron internetowych lub usług; Komunikacja marketingowa:
                  komunikowanie się z podmiotami danych za pomocą dowolnych
                  środków (w tym za pośrednictwem poczty elektronicznej,
                  telefonu, wiadomości tekstowych, mediów społecznościowych,
                  poczty lub osobiście) wiadomości i innych informacji, którymi
                  mogą być zainteresowane, pod warunkiem zapewnienia, że ​​taka
                  komunikacja jest przekazywana Danym Podmiotom zgodnie z
                  obowiązującym prawem;Operacje komunikacyjne i informatyczne:
                  zarządzanie systemami komunikacyjnymi PrudentCode; działanie
                  bezpieczeństwa informatycznego; oraz audyty bezpieczeństwa IT;
                  Zgodność z prawem: zgodność z obowiązkami prawnymi i
                  regulacyjnymi PrudentCode wynikającymi z obowiązującego prawa;
                  Rekrutacja i podania o pracę: działania rekrutacyjne; reklama
                  stanowisk; czynności związane z rozmowami kwalifikacyjnymi;
                  analiza przydatności na odpowiednie stanowisko; zapisy decyzji
                  o zatrudnieniu; szczegóły oferty i szczegóły akceptacji.
                </p>
                <p>4.2 Wrażliwe dane osobowe</p>
                <p>
                  PrudentCode nie stara się gromadzić ani w inny sposób
                  przetwarzać Wrażliwych Danych Osobowych w zwykłym toku swojej
                  działalności. Jeżeli z jakiegokolwiek powodu konieczne będzie
                  przetwarzanie Wrażliwych Danych Osobowych Podmiotów Danych,
                  PrudentCode zrobi to wyłącznie zgodnie z prawem
                </p>
                <p>4.3 Podstawy prawne przetwarzania</p>
                <p>
                  Przetwarzając Twoje dane osobowe w związku z celami
                  określonymi w niniejszej Polityce prywatności, możemy opierać
                  się na jednej lub kilku z następujących podstaw prawnych, w
                  zależności od okoliczności w niej określonych: Przetwarzanie
                  jest niezbędne do wykonania umowy, której stroną jest
                  Użytkownik; Przetwarzanie jest niezbędne dla uzasadnionych
                  interesów PrudentCode, jak określono w niniejszym dokumencie
                  (np. przetwarzanie danych osobowych do celów
                  administracyjnych); PrudentCode jest zobowiązana do
                  przestrzegania prawnego lub ustawowego zobowiązania w UE lub
                  państwie członkowskim.
                </p>
              </div>
              <div>
                <h4>5.Udostepnianie danych stronom trzecim</h4>
                <p>
                  Twoje dane osobowe będziemy udostępniać stronom trzecim
                  wyłącznie w sposób opisany w niniejszej Polityce prywatności.
                  Nie sprzedajemy Twoich danych osobowych podmiotom trzecim.
                  Możemy przekazywać Twoje dane osobowe firmom, które świadczą
                  usługi pomagające nam w naszej działalności biznesowej
                  („podmioty przetwarzające dane”). Firmy te są upoważnione do
                  wykorzystywania Twoich danych osobowych tylko w zakresie
                  niezbędnym do świadczenia nam tych usług. PrudentCode
                  przekazuje dane osobowe Podmiotów danych do usług stron
                  trzecich w zakresie, w jakim dane te są wymagane do
                  świadczenia odpowiednich Usług. Żaden taki partner nie będzie
                  przekazywać tych danych osobowych stronom trzecim, z wyjątkiem
                  następujących sytuacji: (1) jeśli i w zakresie, w jakim taka
                  komunikacja jest konieczna do wykonania umowy, oraz (2) jeśli
                  partner jest zobowiązany do przekazania określonych informacji
                  lub dokumentów do organu publicznego, zgodnie z wymogami
                  prawa, takich jak wezwania sądowego lub podobnego procesu
                  prawnego oraz (3) jeśli PrudentCode jest zaangażowana w fuzję,
                  przejęcie lub sprzedaż całości lub części swoich aktywów,
                  zostaniesz powiadomiony e-mailem i/lub widocznym
                  powiadomieniem na naszych Stronach Internetowych o wszelkich
                  zmianach w zakresie własności lub wykorzystania Twoich danych
                  osobowych, a także o Twoich wyborach dotyczących Twoich danych
                  osobowych. Przekazywanie informacji osobom wymienionym w ppkt
                  (2) jest w każdym przypadku ograniczone do tego, co jest
                  absolutnie konieczne lub wymagane przez obowiązujące prawo.
                  Jeśli PrudentCode zaangażuje podmiot zewnętrzny do
                  przetwarzania danych osobowych Podmiotów danych, podmiot
                  przetwarzający będzie podlegał wiążącym zobowiązaniom umownym
                  do: (1) przetwarzania danych osobowych wyłącznie zgodnie z
                  wcześniejszymi pisemnymi instrukcjami PrudentCode; oraz (2)
                  stosować środki ochrony poufności i bezpieczeństwa danych
                  osobowych; wraz z wszelkimi dodatkowymi wymogami wynikającymi
                  z obowiązującego prawa.
                </p>
              </div>
              <div>
                <h4>6.Międzynarodowy transfer danych osobowych</h4>
                <p>
                  Niektórzy z naszych podmiotów przetwarzających dane znajdują
                  się poza UE („państwa trzecie”). Niektóre państwa trzecie
                  zostały oficjalnie uznane przez Komisję Europejską za
                  zapewniające odpowiedni poziom ochrony. Listę tych krajów
                  znajdziesz na stronie{" "}
                  <a
                    target="_blank"
                    rel="nofollow"
                    href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"
                  >
                    link
                  </a>
                  . PrudentCode stosuje środki fizyczne, techniczne i
                  administracyjne w celu ochrony informacji Użytkowników przed
                  utratą, kradzieżą oraz nieuprawnionym wykorzystaniem,
                  ujawnieniem lub modyfikacją. Chociaż PrudentCode dokłada
                  wszelkich starań, aby chronić informacje, które przechowuje,
                  nie może zagwarantować w 100% bezpieczeństwa jakichkolwiek
                  informacji, które użytkownicy nam przesyłają, ponieważ żadna
                  metoda przesyłania lub przechowywania danych nie jest w 100%
                  bezpieczna
                </p>
              </div>
              <div>
                <h4>7.Okresy przechowywania</h4>
                <p>
                  PrudentCode będzie przechowywać dane osobowe Użytkowników i
                  Członków tylko tak długo, jak będzie to konieczne do
                  realizacji celów, dla których zostały zebrane i przetworzone,
                  w tym w celu spełnienia wszelkich wymogów prawnych,
                  regulacyjnych, księgowych lub sprawozdawczych. Ustalenie
                  odpowiedniego okresu przechowywania danych osobowych
                  Użytkowników odbywa się na podstawie ilości, charakteru i
                  wrażliwości danych osobowych, potencjalnego ryzyka wyrządzenia
                  szkody w wyniku nieuprawnionego wykorzystania lub ujawnienia,
                  celów, dla których je przetwarzamy oraz tego, czy może
                  osiągnąć te cele za pomocą innych środków i obowiązujących
                  wymogów prawnych. Dla niektórych celów wymienionych w punkcie
                  4.1. lub w celach, o których możesz zostać poinformowany
                  oddzielnie, PrudentCode może anonimizować dane osobowe
                  Użytkownika, aby nie można ich było już z nim powiązać, w
                  którym to przypadku nie są one już uważane za dane osobowe. Na
                  przykład PrudentCode anonimizuje dane osobowe w celu tworzenia
                  statystyk dotyczących korzystania ze swoich Witryn, które
                  PrudentCode może wykorzystywać do ulepszania swoich Witryn. Po
                  upływie obowiązującego okresu przechowywania PrudentCode
                  bezpiecznie zniszczy dane osobowe zgodnie z obowiązującymi
                  przepisami i regulacjami
                </p>
              </div>
              <div>
                <h4>8.Prawa osób, których dane dotyczą</h4>
                <p>
                  Każdy Członek,Subskrynent i Uzytkownik może w każdej chwili
                  uzyskać dostęp do swoich danych osobowych poprzez wysłanie
                  stosownego zgłoszenia do PrudentCode. Osoby, których dane
                  dotyczą, mogą również zażądać dostępu, sprostowania i
                  usunięcia swoich danych osobowych, z wyjątkiem tych, które
                  PrudentCode jest prawnie zobowiązane do zachowania w bazie
                  danych PrudentCode, kierując pisemny wniosek do administratora
                  danych pod następującym adresem : PrudentCode Sp. z o.o.,ul.
                  Towarowa 23, 43-100 Tychy (Polska). Osoby, których dane
                  dotyczą, mogą zażądać przekazania swoich danych osobowych
                  innemu administratorowi w ustrukturyzowanym, powszechnie
                  używanym i nadającym się do odczytu maszynowego formacie.Każdy
                  podmiot danych może zażądać ograniczenia przetwarzania swoich
                  danych osobowych. W przypadku, gdy PrudentCode przetwarza dane
                  osobowe podmiotu danych na podstawie zgody, podmiot danych ma
                  prawo wycofać tę zgodę (chociaż wycofanie to nie wpływa na
                  zgodność z prawem przetwarzania dokonanego przed datą, w
                  której PrudentCode otrzyma powiadomienie o takim wycofaniu i
                  nie uniemożliwia przetwarzania danych osobowych na innych
                  dostępnych podstawach prawnych). PrudentCode podejmie wówczas
                  wszelkie niezbędne kroki w celu szybkiego spełnienia takiego
                  żądania. Każda osoba, której dane dotyczą, ma prawo do
                  wniesienia skargi dotyczącej przetwarzania jej danych
                  osobowych do organu ochrony danych (którym może być dowolny
                  organ ochrony danych państwa członkowskiego UE, w którym
                  mieszka, w którym pracuje lub w którym domniemano naruszenia).
                  Z zastrzeżeniem obowiązujących przepisów prawa, każdemu
                  Podmiotowi Danych mogą przysługiwać następujące dodatkowe
                  prawa dotyczące przetwarzania jego danych osobowych:prawo do
                  wniesienia sprzeciwu, z przyczyn związanych z jego szczególną
                  sytuacją, wobec przetwarzania jego danych osobowych przez
                  PrudentCode;prawo do wniesienia sprzeciwu wobec przetwarzania
                  jego danych osobowych przez PrudentCode w celach marketingu
                  bezpośredniego;prawo do usunięcia jego danych osobowych.
                </p>
              </div>
              <div>
                <h4>9.Osoby poniżej 18 roku życia</h4>
                <p>
                  Osoby, które nie ukończyły 18 roku życia oraz osoby, które nie
                  posiadają pełnej zdolności do czynności prawnych nie mogą
                  korzystać z Serwisów, jak również nie mogą przekazywać nam
                  swoich danych osobowych. PrudentCode nie gromadzi ani nie
                  przechowuje świadomie danych osobowych dzieci poniżej 18 roku
                  życia, chyba że jest to dozwolone przez prawo. Jeśli
                  PrudentCode dowie się, że zebrała dane osobowe dziecka poniżej
                  18 roku życia, usunie te informacje ze swojej bazy danych.
                </p>
              </div>
              <div>
                <h4>10.Dodatkowe informacje</h4>
                <p>10.1 Referencje</p>
                <p>
                  Za Twoją zgodą możemy opublikować Twoje referencje wraz z
                  Twoim imieniem i nazwiskiem, ale zawsze sprawdzimy, czy jesteś
                  zadowolony, że to zrobimy w pierwszej kolejności. Jeśli chcesz
                  zaktualizować lub usunąć swoją opinię, możesz skontaktować się
                  z nami pod adresem kontakt@prudentcode.pl,
                  kontakt@cenniczek.com.
                </p>
                <p>10.2 Łącza do witryn innych firm</p>
                <p>
                  Nasze Witryny internetowe zawierają łącza do innych witryn
                  internetowych, których praktyki ochrony prywatności mogą
                  różnić się od praktyk PrudentCode. Jeśli prześlesz dane
                  osobowe do którejkolwiek z tych witryn, Twoje informacje
                  podlegają ich polityce prywatnośc
                </p>
              </div>
              <div>
                <h4>11.Skontaktuj się z nami</h4>
                <p>
                  Możesz skontaktować się z nami z dowolnego powodu w związku z
                  niniejszą Polityką prywatności pod adresem:
                </p>
                <p>PrudentCode Sp. z o.o.</p>
                <p>ul. Towarowa 23,</p>
                <p>43-100 Tychy (Polska)</p>
                <p>kontakt@cenniczek.com</p>
                <p>kontakt@prudentcode.pl</p>
              </div>
            </ErrorBoundary>
          </MainContent>
        </MainWrapperLayer>
      </WrraperSection>
    </>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
});
export const Policy = connect(mapStateToProps, {})(_Policy);
