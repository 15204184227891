import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
import { motion } from "framer-motion";
export const MainMetricsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
`;
export const WrraperUserSection = styled.div`
  width: 100%;
  min-height: 70vh;
  position: relative;
  user-select: none;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.thirdColor.dark};
  background-image: linear-gradient(
    315deg,
    #e8dbfc 0%,
    ${({ isDark }) =>
        isDark ? Theme.dark.sixColor.dark : Theme.light.thirdColor.dark}
      74%
  );

  // height:50vh;
`;
export const MainWrapperLayer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  transition: background 0.3s;
  display: flex;
  justify-content: space-around;
  // align-items:flex-start;
`;
export const UserMainContent = styled.div`
  position: relative;
  width: 97%;
  height: 100%;
  min-height: 70vh;
  max-width: ${Theme.breakPoint.desktop}px;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  font-family: montserrat;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;
export const UserSectionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 100px;
  & > h1 {
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
  }

  & > h1 > span {
    text-align: left;
    width: 300px;
    padding-bottom: 20px;
    display: block;
    text-transform: uppercase;
    border-bottom: 1px solid
      ${({ isDark }) =>
        !isDark
          ? Theme.dark.primaryColor.dark
          : Theme.light.primaryColor.white};
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  }
  @media${Theme.device.mobileL} {
    padding-left: 20px;
    & > h1 {
      font-size: 1.2em;
    }
    & > h1 > span {
      width: 255px;
    }
  }
`;
export const UserLoginItemWraper = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
  padding: 5px;
  height: 60px;
  cursor: pointer;
  position: relative;
  overflow: visible;
  & > svg {
    width: 40px;
  }
  & > * {
    transform: scale(1);
    transition: 0.3s;
  }
  &:hover > p {
    color: ${Theme.light.secondaryColor.green};
  }
  &:hover > svg {
    transform: scale(1.1);
    color: ${Theme.light.secondaryColor.green};
  }
`;
export const ResponseWraper = styled.div`
  width: 100%;
  min-height: 40vh;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > h2 {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
`;
export const WraperForUserList = styled(motion.div)`
  width: 100%;
  min-height: 80vh;
  height: 97vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 30px;
  border-radius: 5px;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  & > h2 {
    margin-top: 10px;
  }
  @media${Theme.device.desktopM} {
    height: auto;
  }
  @media${Theme.device.desktop} {
    min-height: 50vh;
  }
`;
export const WraperFor2Chart = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
export const FirstChart = styled.div`
  width: 75%;
  // height: 300px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px;
  position: relative;
  @media${Theme.device.tablet} {
    width: 70%;
  }
  @media${Theme.device.mobileL} {
    width: 95%;
    margin-top: 30px;
  }
`;
export const DoubleChart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media${Theme.device.mobileL} {
    width: 100%;
  }
`;
export const SingleChart = styled.div`
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px;
  @media${Theme.device.mobileL} {
    width: 100%;
    padding: 8px;
    margin-top: 30px;
  }
`;
export const WraperForMoney = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  border: 5px solid
    ${({ isDark }) =>
      !isDark
        ? Theme.light.secondaryColor.green
        : Theme.light.secondaryColor.green};
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
`;

export const caruselWraperVariants = {
  hidden: {
    opacity: 0,
    x: "-25%",
  },
  visible: (index) => ({
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      delay: index * 0.1 + 0.5,
    },
  }),
  exit: {
    opacity: 0,
  },
};

export const CaruselWrapper = styled.div`
  max-width: ${Theme.breakPoint.desktop}px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  align-items: center;
  position: relative;
  user-select: none;
`;
export const SlideHoverShopingList = styled(motion.div)`
position:absolute;
top:0;
left:0;
width:100%;
height:100%;
color:white;
z-index:2;
user-select:none;
color: ${({ isDark }) =>
  isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
background: ${({ isDark }) =>
  isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
&:hover{
    box-shadow:inset -1px -1px 3px ${({ isDark }) =>
      isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white}
},
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
text-align:center;
&>img{
    display:block;
    margin:0 auto;
    width:140px;
    height:auto;
}
&>h2{
    font-size:14px;
    padding-top:5px;
    font-weight:normal;
    text-transform:uppercase;
}
`;
export const ImageAndDataHover = styled(motion.div)`
  position: absolute;
  top: 25px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  & > img {
    display: block;
    margin: 0 auto;
    width: 140px;
    height: auto;
  }
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    // padding-top: 5px;
    margin: 5px;
    font-weight: normal;
    text-transform: uppercase;
  }
  & > div > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
    font-size: 12px;
    padding: 2px;
    font-weight: normal;
    text-transform: uppercase;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    background: ${({ isDark }) =>
      isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
    border-radius: 5px;
  }
`;
export const BrandDataHover = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    display: block;
    margin: 0 auto;
    width: 55px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  & > p {
    width: 100%;
    height: 55px;
    line-height: 55px;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    background: ${({ isDark }) =>
      isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    margin-left: 55px;
  }
`;
export const AddNewItemToList = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 75px;
  background: ${Theme.light.secondaryColor.green};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
`;
export const AddHover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 20px;
`;
export const WraperMobile = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  height: 100%;
  transition: 2s background;
  &::-webkit-scrollbar {
    height: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    height: 4px;
    border-radius: 2px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    background-color: ${({ tap, isDark }) =>
      tap
        ? isDark
          ? Theme.light.fiveColor.white
          : Theme.dark.sixColor.dark
        : "transparent"};
  }
`;
export const ThumbsUpIconStyle = {
  width: "150px",
  margin: "40px",
};
export const MailIconStyle = {
  width: "70px",
  margin: "5px",
};
// export const imgSuspenseStyle = {
//   width: "999%",
//   color: `${Theme.dark.fiveColor.white}`,
//   padding: "5px",
// };
export const imgSuspenseStyleDark = {
  width: "50%",
  color: `${Theme.light.fiveColor.white}`,
  padding: "5px",
};
export const imgSuspenseStyleLight = {
  width: "50%",
  color: ` ${Theme.dark.sixColor.dark}`,
  padding: "5px",
};
export const imgSuspenseStyleForBrand = {
  width: "45%",
  color: ` ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark}`,
  padding: "5px",
};
export const imgSuspenseStyleForBrandDark = {
  width: "45%",
  color: `${Theme.light.fiveColor.white}`,
  padding: "5px",
};
export const imgSuspenseStyleForBrandLight = {
  width: "45%",
  color: ` ${Theme.dark.sixColor.dark}`,
  padding: "5px",
};
export const imgPlusStyleDark = {
  width: "45%",
  color: ` ${Theme.dark.primaryColor.dark}`,
};
export const imgPlusStyleLight = {
  width: "45%",
  color: `${Theme.light.primaryColor.white}`,
};
