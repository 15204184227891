import { Theme } from "../../../../store/theme/theme";
import { motion } from "framer-motion";
import styled from "styled-components";
export const sliderStyle = {
  height: "140px",
  textAlign: "center",
  color: `${Theme.light.primaryColor.white}`,
};
export const slideStyle = {
  height: "120px",
  width: "160px",
  margin: "10px 30px",
  border: "1px solid white",
  borderRadius: "5px",
  overflow: "hidden",
  cursor: "pointer",
  transition: ".3s",
  position: "relative",
};
export const slideStyleActive = {
  height: "120px",
  width: "160px",
  margin: "10px 30px",
  border: "3px solid white",
  borderRadius: "5px",
  overflow: "hidden",
  cursor: "pointer",
  transition: ".3s",
  position: "relative",
};
export const mobileSlideStyle = {
  height: "95px",
  width: "125px",
  margin: "5px 15px",
  border: "1px solid white",
  borderRadius: "5px",
  overflow: "hidden",
  cursor: "pointer",
  transition: ".3s",
  position: "relative",
};
export const mobileSlideStyleActive = {
  height: "95px",
  width: "125px",
  margin: "5px 15px",
  border: "3px solid white",
  borderRadius: "5px",
  overflow: "hidden",
  cursor: "pointer",
  transition: ".3s",
  position: "relative",
};
export const buttonBackStyle = {
  position: "absolute",
  top: "50%",
  left: "15px",
  transform: "translate(-45px,-50%)",
  width: "30px",
  height: "40px",
  backgroundColor: "transparent",
  border: "none",
};
export const buttonNextStyle = {
  position: "absolute",
  top: "50%",
  right: "15px",
  transform: "translate(45px,-50%)",
  width: "30px",
  height: "40px",
  backgroundColor: "transparent",
  border: "none",
};
export const btnStyle = {
  width: "25px",
};
export const imgSuspenseStyle = {
  width: "55%",
  color: `${Theme.dark.fiveColor.white}`,
};
export const caruselWraperVariants = {
  hidden: {
    opacity: 0,
    x: "-25%",
  },
  visible: (index) => ({
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      delay: index * 0.1 + 0.7,
    },
  }),
  exit: {
    opacity: 0,
  },
};
export const CaruselWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 100%;
  // border:1px solid white;
  align-items: center;
  position: relative;
  user-select: none;
  //   &>div{
  //     display:flex;
  //     justify-content:center;
  //     align-items:center;
  //   }
  //   &>div>h3{
  //       text-align:center;
  //       font-weight:normal;
  //       color:${Theme.dark.thirdColor.white};
  //       line-height:140px;
  //   }
  @media${Theme.device.mobileL} {
    width: 100vw;
    overflow: hidden;
  }
`;
export const SlideHover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  z-index: 2;
  text-align: center;
  &:hover {
    box-shadow: inset -1px -1px 3px white;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > img {
    display: block;
    margin: 0 auto;
    width: 110px;
    height: 100px;
  }
  & > h3 {
    font-size: 14px;
    padding-top: 16px;
    font-weight: normal;
    text-transform: uppercase;
  }
  @media${Theme.device.tablet} {
    & > img {
      width: 85px;
      height: 80px;
    }
    & > h3 {
      font-size: 12px;
      padding-top: 15px;
    }
  }
`;
export const WraperMobile = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  height: 100%;
  transition: 2s background;
  &::-webkit-scrollbar {
    height: 5px;
    // border-radius: 0 5px 5px 0;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    height: 4px;
    border-radius: 2px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${({ tap }) =>
      tap ? Theme.light.fiveColor.white : "transparent"};
  }
`;
