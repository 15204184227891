import React from "react";
import styled from "styled-components";
import { Theme } from "../../store/theme/theme";

const LeftbarWrraper = styled.div`
  width: 15%;
  height: 100vh;
  @media${Theme.device.tablet} {
    display: none;
  }
`;
export const LeftBar = () => {
  return (
    <>
      <LeftbarWrraper></LeftbarWrraper>
    </>
  );
};
