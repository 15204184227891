import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { TopWrapper } from "./ScrollToTop.style";
import { arrowUp } from "../../media/svgPath/SvgPath";
import { SvgDisplay } from "../../components/tools/spiner/svgDisplay/SvgDisplay";
import { AnimatePresence } from "framer-motion";

const topVariants = {
  hidden: {
    opacity: 0,
    y: 50,
    scale: 0.6,
  },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    y: 50,
    scale: 0.6,
    transition: {
      duration: 0.3,
    },
  },
};
const _ScrollToTop = ({ theme }) => {
  const toTopVisible = 3000;
  const [scrollDim, setScrollDim] = useState(0);
  const [active, setActive] = useState(false);

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollDim(window.scrollY);
    });
  }, []);
  useEffect(() => {
    if (scrollDim >= toTopVisible && active === false) {
      setActive(true);
    } else if (scrollDim < toTopVisible && active === true) {
      setActive(false);
    }
  }, [scrollDim]);

  return active ? (
    <AnimatePresence active={active}>
      <TopWrapper
        isDark={theme.isDark}
        onClick={scrollToTop}
        whileTap={{ scale: 0.95 }}
        variants={topVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        active={active}
      >
        <SvgDisplay
          d={arrowUp.d}
          viewBox={arrowUp.viewBox}
          style={{ width: "30px", height: "30px" }}
        />
      </TopWrapper>
    </AnimatePresence>
  ) : null;
};

const mapStateToProps = (state) => ({
  theme: state.theme,
});

export const ScrollToTop = connect(mapStateToProps, {})(_ScrollToTop);
