import styled from "styled-components";
import { Theme } from "../../store/theme/theme";

export const WrraperSection = styled.div`
  width: 100%;
  position: relative;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.thirdColor.dark};
  background-image: linear-gradient(
    315deg,
    #e8dbfc 0%,
    ${({ isDark }) =>
        isDark ? Theme.dark.sixColor.dark : Theme.light.thirdColor.dark}
      74%
  );
  user-select: none;
  @media${Theme.device.mobileL} {
    z-index: 5;
    background: ${({ isDark }) =>
      isDark ? Theme.dark.primaryColor.dark : Theme.light.thirdColor.dark};
    background-image: linear-gradient(
      315deg,
      #e8dbfc 0%,
      ${({ isDark }) =>
          isDark ? Theme.dark.primaryColor.dark : Theme.light.thirdColor.dark}
        74%
    );
  }
`;

export const MainWrapperLayer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  transition: background 0.3s;
  display: flex;
  justify-content: space-around;
  // align-items:flex-start;
`;
export const MainContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 25vh;
  max-width: ${Theme.breakPoint.desktop}px;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  font-family: montserrat;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
export const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: no-wrap;
  padding-left: 100px;
  & > h4 {
    margin: 10px;
    font-size: 30px;
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
  }

  & > h4 > span {
    text-align: left;
    // width: 130%;
    padding-bottom: 20px;
    display: block;
    text-transform: uppercase;
    border-bottom: 1px solid
      ${({ isDark }) =>
        !isDark
          ? Theme.dark.primaryColor.dark
          : Theme.light.primaryColor.white};
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  }
  @media${Theme.device.mobileL} {
    padding-left: 0;
    flex-wrap: wrap;
    width: 95%;
    & > h4 {
      font-size: 20px;
    }
    & > h4 > span {
      width: 100%;
      font-size: 20px;
    }
  }
`;
// ----------------------------------------SinglePageLayout--------------------------
export const SingleBrandOrCategoryLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  border-radius: 5px 5px 0 0;
  @media${Theme.device.mobileM} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
export const SinglePostLayout = styled.div`
  width: 90%;
  height: 100%;
  min-height: 150px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  // border-radius:5px 0 0 0;
  margin-left: 15px;
  text-transform: uppercase;
  @media${Theme.device.mobileM} {
    margin-left: 0;
  }
`;
export const Localization = styled.div`
  width: 95%;
  display: flex;
  //   flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    display: flex;
    width: 40%;
    height: 145px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
    background: ${({ isDark }) =>
      isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  }
  & > div > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  & > div > div > span {
    font-weight: bold;
    // font-size: ${Theme.size.h1};
    // width:50px;
    // height:50px;
    // line-height:50px;
    margin: 0 10px 0 10px;
    display: flex;
    // flex-direction:column;
    justify-content: center;
    align-items: center;
  }
  & > div > h2 {
    font-size: ${Theme.size.h1};
    font-weight: bold;
    margin: 10px;
  }
  & > div > div > div > span {
    font-weight: bold;
    font-size: 40px;
    // width:50px;
    height: 50px;
    line-height: 50px;
    margin: 10px;
    transition: 0.3s;
  }
  & > div > div > div {
    dispaly: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  & > div > div > div > p {
    font-weight: bold;
    text-align: center;
    // width:50px;
    height: 50px;
    // line-height: 50px;
    transition: 0.3s;
    padding-left: 10px;
  }
  & > div > div > span > a {
    display: block;
    font-weight: bold;
    text-decoration: none;
    color: ${({ isDark }) =>
      !isDark ? Theme.light.fourthColor.red : Theme.light.fourthColor.red};
    // margin:10px;
    transform: scale(1);
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > div > div > span > a > span {
    padding-right: 10px;
  }
  & > div > div > span > a:hover {
    transform: scale(1.1);
  }
  @media${Theme.device.tablet} {
    width: 100%;
    & > div {
      width: 100%;
    }
  }
  @media${Theme.device.mobileM} {
    width: 100%;
    flex-wrap: wrap;
    & > div {
      font-size: ${Theme.size.fontSizeMobileM};
      padding-bottom: 10px;
    }
    & > div > div > span {
      font-size: ${Theme.size.fontSizeMobileM};
    }
    & > div > div > div > span {
      font-size: ${Theme.size.fontSizeMobileM};
    }
  }
`;
export const MainImg = styled.div`
  flex-basis: 150px;
  width: 150px;
  height: 145px;
  margin: 10px;
  border: 1px solid
    ${({ isDark }) =>
      isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  }
  & > svg {
    display: ${typeof window !== "undefined" ? "block" : "none"};
  }
`;
export const CloseItem = styled.div``;
