import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
import { motion } from "framer-motion";
export const WrraperSection = styled.div`
  width: 100%;
  position: relative;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.thirdColor.dark};
  background-image: linear-gradient(
    315deg,
    #e8dbfc 0%,
    ${({ isDark }) =>
        isDark ? Theme.dark.sixColor.dark : Theme.light.thirdColor.dark}
      74%
  );
  user-select: none;
  @media${Theme.device.mobileL} {
    z-index: 4;
  }
`;
export const MainWrapperLayer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  transition: background 0.3s;
  display: flex;
  justify-content: space-around;
  // align-items:flex-start;
`;
export const MainContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 70vh;
  max-width: ${Theme.breakPoint.desktop}px;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  font-family: montserrat;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  //   &>h1{
  //       text-transform:uppercase;
  //       font-size:${Theme.size.h1};
  //       font-weight:500;
  //       user-select:none;
  //   }
  //   &>div{
  //       display:flex;
  //       justify-content:center;
  //       align-items:center;
  //       user-select:none;

  //     }
  //   &>div>p{
  //       margin:15px;
  //     font-size:1.5rem;
  //     user-select:none;
  //   }
`;
export const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 100px;
  cursor: pointer;
  & > h2 {
    margin-top: 10px;
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
    font-size: 30px;
  }

  & > h2 > span {
    text-align: left;
    // width: 300px;
    padding-bottom: 20px;
    display: block;
    text-transform: uppercase;
    border-bottom: 1px solid
      ${({ isDark }) =>
        !isDark
          ? Theme.dark.primaryColor.dark
          : Theme.light.primaryColor.white};
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  }
  & > h3 {
    margin-top: 10px;
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
    font-size: 30px;
  }

  & > h3 > span {
    text-align: left;
    // width: 300px;
    padding-bottom: 20px;
    display: block;
    text-transform: uppercase;
    border-bottom: 1px solid
      ${({ isDark }) =>
        !isDark
          ? Theme.dark.primaryColor.dark
          : Theme.light.primaryColor.white};
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  }
  @media${Theme.device.mobileL} {
    padding-left: 20px;
    flex-wrap: wrap;
    & > h2 {
      font-size: 22px;
    }
    & > h2 > span {
      width: 240px;
    }
    & > h3 {
      font-size: 22px;
    }
    & > h3 > span {
      width: 240px;
    }
  }
`;
export const More = styled(motion.div)`
  width: 100%;
  height: 100px;
  border: none;
  & > a {
    display: block;
    margin: 15px auto;
    width: 300px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
    border-radius: 30px;
    transform: scale(1);
    transition: 0.1s;
    background: ${Theme.light.secondaryColor.green};
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
  }
  & > a:hover {
    background: none;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
  @media${Theme.device.mobileS} {
    & > a {
      width: 240px;
    }
  }
`;
export const MainPostWraper = styled(motion.div)`
  width: 100%;
  height: 100%;
  min-height: 50vh !important;
  min-width: 50vw !important;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
  position: relative;
  & > div > a {
    width: 400px;
    height: 540px;
    border: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
    border-radius: 25px;
    margin: 20px;
    padding-bottom: 10px;
    transform: scale(1);
    transition: 0.1s;
    text-decoration: none;
    background: ${({ isDark }) =>
      isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  & > div > a:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
  & > div > a:hover img {
    transform: translate(-50%, -50%) scale(1.1);
  }
  @media${Theme.device.mobileL} {
    & > div > a {
      height: 400px;
    }
  }
`;

export const MainImg = styled.div`
  width: 90%;
  height: 290px;
  margin: 15px;
  // border:1px solid ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    max-width: 110%;
    max-height: 110%;
    transition: 1s;
  }
  @media${Theme.device.mobileL} {
    height: 45%;
  }
`;
export const Content = styled.div`
  width: 90%;
  // height: 30%;
  margin: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & > h3 {
    margin: 10px;
    text-transform: uppercase;
  }
  & > p {
    text-align: left;
    font-size: 14px;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  @media${Theme.device.mobileL} {
    & > h3 {
      font-size: ${Theme.size.fontSizeMobileL};
      margin: 5px;
    }
    & > p {
      line-height: 24px;
      font-size: ${Theme.size.fontSizeMobileM};
    }
  }
`;
export const BlogWrapper = styled.div`
  min-width: 30vw;
  width: 85%;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  @media${Theme.device.desktop} {
    width: 75%;
  }
  @media${Theme.device.mobileL} {
    width: 100%;
  }
`;

// ----------------------------------------SinglePageLayout--------------------------
export const SinglePostLayout = styled(motion.div)`
  width: 100%;
  height: 100%;
  min-height: 70vh !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  border-radius: 5px;
  padding-bottom: 30px;
  @media${Theme.device.desktop} {
    width: 95%;
  }
  @media${Theme.device.mobileL} {
    flex-direction: column;
    align-items: center;
  }
`;
export const PostContent = styled.div`
  width: 80%;
  height: 100%;
  min-height: 50vh !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};

  & > h2 {
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    margin: 20px;
  }
  & > img {
    width: 400px;
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
  }
  & > div {
    width: 80%;
  }
  & > div > h3 {
    text-transform: uppercase;
  }
  & > div > p {
    text-align: left;
    line-height: 24px;
    padding: 30px;
    font-size: 1.2rem;
    // color: ${({ isDark }) =>
      isDark ? Theme.light.fiveColor.white : Theme.light.thirdColor.dark};
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  // @media${Theme.device.mobileL} and (orientation:landscape){
  //   width:70%;
  //   padding:5px;
  // }
  @media${Theme.device.mobileL} {
    width: 100%;
    padding: 5px;
    & > div {
      width: 100%;
    }
    & > img {
      width: 300px;
    }
    & > div > p {
      font-size: 1rem;
    }
  }
  @media${Theme.device.mobileM} {
    & > div > p {
      padding: 10px;
    }
  }
`;
