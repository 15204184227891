import { Theme } from "../../../store/theme/theme";
import { motion } from "framer-motion";
import styled from "styled-components";
export const sliderStyle = {
  // height: "400px",
  textAlign: "center",
  color: `${Theme.light.primaryColor.white}`,
  position: "relative",
  // width: `${(caruselwidth) => caruselwidth}px`,
};
export const slideStyle = {
  height: "100%",
  width: "100%",
  margin: "10px 30px",
  border: "1px solid white",
  borderRadius: "5px",
  overflow: "hidden",
  cursor: "pointer",
  transition: ".3s",
  position: "relative",
};
export const slideStyleActive = {
  height: "120px",
  width: "160px",
  margin: "10px 30px",
  border: "3px solid white",
  borderRadius: "5px",
  overflow: "hidden",
  cursor: "pointer",
  transition: ".3s",
  position: "relative",
};
export const buttonBackStyle = {
  position: "absolute",
  top: "50%",
  left: "15px",
  transform: "translate(-45px,-50%)",
  width: "30px",
  height: "40px",
  backgroundColor: "transparent",
  border: "none",
};
export const buttonNextStyle = {
  position: "absolute",
  top: "50%",
  right: "15px",
  transform: "translate(45px,-50%)",
  width: "30px",
  height: "40px",
  backgroundColor: "transparent",
  border: "none",
};
export const btnStyle = {
  width: "25px",
};
export const imgSuspenseStyle = {
  width: "55%",
  color: `${Theme.dark.fiveColor.white}`,
};
export const caruselWraperVariants = {
  hidden: {
    opacity: 0,
    x: "-25%",
  },
  visible: (index) => ({
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      delay: index * 0.1 + 0.7,
    },
  }),
  exit: {
    opacity: 0,
  },
};
export const CaruselWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  height: 100%;
  // border:1px solid white;
  align-items: center;
  position: relative;
  user-select: none;
  overflow: hidden;
  @media${Theme.device.tablet} {
    max-width: 100vw;
    width: 100vw;
  }
`;
export const SlideHover = styled.div`
position:absolute;
top:0;
left:0;
width:100%;
height:100%;
color:white;
z-index:2;
&:hover{
    box-shadow:inset -1px -1px 3px white
},
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
&>img{
    display:block;
    margin:0 auto;
    width:110px;
    height:100px;
}
&>h2{
    font-size:14px;
    padding-top:5px;
    font-weight:normal;
    text-transform:uppercase;
}
`;
