// import { gql } from "apollo-boost";
import { gql } from "@apollo/client";

const getFirstOtionedProductItemsQuery = gql`
  query GetFirstOtionedProductItemsQuery(
    $options: Int
    $selectType: String
    $sortType: String
  ) {
    selectedProductItems(
      options: $options
      selectType: $selectType
      sortType: $sortType
    ) {
      id
      name
      recognizedString
      categoryProductId
      categoryKeyProductName
      categorySubKeyProductName
      brand
      book
      page
      statusBook
      startDuringTime
      endDuringTime
      bookId
      confidence
      similarityRating
      direction
      linkHref
      popular
      imgUrl
      price
      brandData {
        id
        imgUrl
      }
      bookData {
        id
        alternativeFinalName
      }
      cordinates {
        xStart
        yStart
        xEnd
        yEnd
      }
      bbox {
        x0
        y0
        x1
        y1
      }
      howMany
      typeSelect
      aditionalName
      err
    }
  }
`;

const getProductItemsQuery = gql`
  {
    productItems {
      id
      name
      recognizedString
      categoryProductId
      categoryKeyProductName
      categorySubKeyProductName
      brand
      book
      page
      statusBook
      startDuringTime
      endDuringTime
      bookId
      confidence
      similarityRating
      direction
      linkHref
      popular
      imgUrl
      price
      brandData {
        id
        imgUrl
      }
      bookData {
        id
        alternativeFinalName
      }
      cordinates {
        xStart
        yStart
        xEnd
        yEnd
      }
      bbox {
        x0
        y0
        x1
        y1
      }
      howMany
      typeSelect
      aditionalName
      err
    }
  }
`;
const getProductItemsFromCategoryKeys = gql`
  query GetProductItemsFromCategoryKeys(
    $brand: String
    $name: String
    $options: Int
    $sortType: String
  ) {
    productItemsFromCategoryKeys(
      brand: $brand
      name: $name
      options: $options
      sortType: $sortType
    ) {
      id
      name
      recognizedString
      categoryProductId
      categoryKeyProductName
      categorySubKeyProductName
      brand
      book
      page
      statusBook
      startDuringTime
      endDuringTime
      bookId
      confidence
      similarityRating
      direction
      linkHref
      popular
      imgUrl
      price
      brandData {
        id
        imgUrl
      }
      bookData {
        id
        alternativeFinalName
      }
      cordinates {
        xStart
        yStart
        xEnd
        yEnd
      }
      bbox {
        x0
        y0
        x1
        y1
      }
      howMany
      typeSelect
      aditionalName
      err
    }
  }
`;

const getItemProduct = gql`
  query GetItemProduct($id: ID) {
    itemProduct(id: $id) {
      id
      name
      recognizedString
      categoryProductId
      categoryKeyProductName
      categorySubKeyProductName
      brand
      book
      page
      statusBook
      startDuringTime
      endDuringTime
      bookId
      confidence
      similarityRating
      cordinates {
        xStart
        yStart
        xEnd
        yEnd
      }
      bbox {
        x0
        y0
        x1
        y1
      }
      direction
      linkHref
      popular
      imgUrl
      price
      itemsFromSubKeyProducts {
        id
        name
        brand
        book
        page
        statusBook
        startDuringTime
        endDuringTime
        bookId
        imgUrl
      }
      categoryKeyProducts {
        id
        name
        categoryProductId
      }
      categoryProducts {
        id
        name
        categoryBooksId
        categoryProduktId
      }
      book {
        id
        name
        categoryId
        brandId
        startDuringTime
        endDuringTime
        numberOfPages
        imgUrl
        thumbnail
        status
        publicatedDateBook
        alternativeFinalName
        recomended
      }
      howMany
      typeSelect
      aditionalName
      err
    }
  }
`;
// this
const getProductKeysCategoriesQuery = gql`
  query GetProductKeysCategory($name: String) {
    productKeysCategory(name: $name) {
      id
      name
      categoryProductId
      imgUrl
      brand
      productsFromBrandAndCategory {
        id
        name
      }
    }
  }
`;
const getProductFromKeysCategoriesQuery = gql`
  query GetProductFromKeysCategory($name: String) {
    productKeysFromCategory(name: $name) {
      id
      name
      categoryProductId
      imgUrl
      brand
      productsFromBrandAndCategory {
        id
        name
      }
    }
  }
`;
// const getProductKeysCategoriesQuery = gql`
//   {
//     productKeysCategory {
//       id
//       name
//       categoryProductId
//       imgUrl
//     }
//   }
// `;

const getCategoryKeyProduct = gql`
  query GetCategoryKeyProduct($id: ID) {
    categoryKeyProduct(id: $id) {
      id
      name
      categoryProductId
      imgUrl
    }
  }
`;

const getProductCategoriesQuery = gql`
  {
    productCategories {
      id
      name
      categoryBooksId
      categoryProduktId
      imgUrl
    }
  }
`;

const getCategoryProduct = gql`
  query GetCategoryProduct($id: ID) {
    categoryProduct(id: $id) {
      id
      name
      categoryBooksId
      categoryProduktId
      imgUrl
      categoriesBooks {
        id
        name
        books {
          id
          name
          categoryId
          startDuringTime
          endDuringTime
          numberOfPages
          imgUrl
          thumbnail
          status
          publicatedDateBook
          alternativeFinalName
          recomended
        }
      }
    }
  }
`;

const getBlogQuery = gql`
  {
    blog {
      id
      author
      intro
      title
      created
      publicated_date
      content {
        title
        text
        img
        adv
      }
      mainImg
    }
  }
`;
const getForBlogData = gql`
  {
    forBlog {
      allBrands {
        id
        name
        categoryId
        imgUrl
      }
      allCategories {
        id
        name
        categoryId
        imgUrl
      }
    }
  }
`;

const getPostQuery = gql`
  query GetPost($title: String) {
    post(title: $title) {
      id
      author
      intro
      title
      brandName
      created
      publicated_date
      content {
        title
        text
        img
        adv
      }
      books {
        id
        name
        categoryId
        startDuringTime
        endDuringTime
        numberOfPages
        imgUrl
        thumbnail
        status
        recomended
        alternativeFinalName
        brand {
          id
          imgUrl
          name
          category {
            id
            name
            books {
              id
              name
              categoryId
              startDuringTime
              endDuringTime
              numberOfPages
              imgUrl
              thumbnail
              status
              publicatedDateBook
              alternativeFinalName
            }
          }
        }
      }
      category {
        id
        name
        category {
          id
          name
          books {
            id
            name
            categoryId
            startDuringTime
            endDuringTime
            numberOfPages
            imgUrl
            thumbnail
            status
            publicatedDateBook
            alternativeFinalName
          }
        }
      }
      mainImg
      blogsItemProducts {
        id
        name
        recognizedString
        categoryProductId
        categoryKeyProductName
        categorySubKeyProductName
        brand
        book
        page
        statusBook
        startDuringTime
        endDuringTime
        bookId
        confidence
        similarityRating
        direction
        linkHref
        popular
        imgUrl
        price
        brandData {
          id
          imgUrl
        }
        bookData {
          id
          alternativeFinalName
        }
        cordinates {
          xStart
          yStart
          xEnd
          yEnd
        }
        bbox {
          x0
          y0
          x1
          y1
        }
        howMany
        typeSelect
        aditionalName
        err
      }
    }
  }
`;

const getBooksQuery = gql`
  {
    books {
      id
      name
      categoryId
      startDuringTime
      endDuringTime
      numberOfPages
      imgUrl
      thumbnail
      status
      publicatedDateBook
      alternativeFinalName
      recomended
      bookCategories
      brand {
        id
        imgUrl
      }
    }
  }
`;

const getBrandsQuery = gql`
  {
    brands {
      id
      name
      categoryId
      brandId
      imgUrl
      describe {
        text
      }
      books {
        id
        name
        categoryId
        startDuringTime
        endDuringTime
        numberOfPages
        imgUrl
        thumbnail
        status
        publicatedDateBook
        alternativeFinalName
        recomended
        bookCategories
        brand {
          id
          imgUrl
        }
      }
    }
  }
`;
const getCategoriesQuery = gql`
  {
    categories {
      id
      name
      categoryId
      imgUrl
      books {
        id
        name
        categoryId
        startDuringTime
        endDuringTime
        numberOfPages
        imgUrl
        thumbnail
        status
        publicatedDateBook
        alternativeFinalName
        recomended
        bookCategories
        brand {
          id
          imgUrl
        }
      }
      altCategoryBooks {
        id
        name
        categoryId
        startDuringTime
        endDuringTime
        numberOfPages
        imgUrl
        thumbnail
        status
        publicatedDateBook
        alternativeFinalName
        recomended
        bookCategories
        brand {
          id
          imgUrl
        }
      }
    }
  }
`;
const GetBrand = gql`
  {
    brand {
      id
      name
      categoryId
      brandId
      imgUrl
    }
  }
`;
const getBrandQuery = gql`
  query GetBrand($id: ID) {
    brand(id: $id) {
      id
      name
      categoryId
      imgUrl
      category {
        id
        name
        categoryId
      }
      books {
        id
        name
        categoryId
        startDuringTime
        endDuringTime
        numberOfPages
        imgUrl
        thumbnail
        status
        publicatedDateBook
        alternativeFinalName
        recomended
        bookCategories
        brand {
          id
          imgUrl
        }
      }
    }
  }
`;
const getBrandByNameQuery = gql`
  query GetBrandByName($name: String) {
    brandByName(name: $name) {
      id
      name
      categoryId
      imgUrl
      category {
        id
        name
        categoryId
      }
      describe {
        text
      }
      books {
        id
        name
        categoryId
        startDuringTime
        endDuringTime
        numberOfPages
        imgUrl
        thumbnail
        status
        publicatedDateBook
        alternativeFinalName
        recomended
        bookCategories
        brand {
          id
          imgUrl
        }
      }
      productKeysCategory {
        id
        name
        categoryProductId
        imgUrl
      }
      allBrands {
        id
        name
        categoryId
        imgUrl
      }
      allCategories {
        id
        name
        categoryId
        imgUrl
      }
      promoBlog {
        id
        author
        intro
        title
        created
        createdMilisec
        publicated_date
        publicated_date_milisec
        archived_date
        archived_date_milisec
        content {
          title
          text
          img
          adv
        }
        mainImg
      }
    }
  }
`;
const getBrandItemProductsQuery = gql`
  query GetBrandItemProductsQuery($brandName: String) {
    brandItemProducts(brandName: $brandName) {
      id
      name
      recognizedString
      categoryProductId
      categoryKeyProductName
      categorySubKeyProductName
      brand
      book
      page
      statusBook
      startDuringTime
      endDuringTime
      bookId
      confidence
      similarityRating
      cordinates {
        xStart
        yStart
        xEnd
        yEnd
      }
      bbox {
        x0
        y0
        x1
        y1
      }
      bookData {
        id
        name
        categoryId
        brandId
        startDuringTime
        endDuringTime
        numberOfPages
        imgUrl
        thumbnail
        status
        publicatedDateBook
        alternativeFinalName
        recomended
      }
      brandData {
        id
        imgUrl
      }
      direction
      linkHref
      popular
      imgUrl
      price
      howMany
      typeSelect
      aditionalName
      err
    }
  }
`;
const getBookQuery = gql`
  query GetBook($id: ID) {
    book(id: $id) {
      id
      name
      categoryId
      startDuringTime
      endDuringTime
      numberOfPages
      imgUrl
      thumbnail
      status
      publicatedDateBook
      alternativeFinalName
      recomended
      bookCategories
      brand {
        id
        name
        categoryId
        brandId
        imgUrl
        books {
          id
          name
          categoryId
          startDuringTime
          endDuringTime
          numberOfPages
          imgUrl
          thumbnail
          status
          publicatedDateBook
          alternativeFinalName
          recomended
        }
        category {
          id
          name
          books {
            id
            name
            categoryId
            startDuringTime
            endDuringTime
            numberOfPages
            imgUrl
            thumbnail
            status
            publicatedDateBook
            alternativeFinalName
            recomended
          }
        }
      }
      productsItems {
        id
        name
        recognizedString
        categoryProductId
        categoryKeyProductName
        categorySubKeyProductName
        brand
        book
        page
        statusBook
        startDuringTime
        endDuringTime
        bookId
        confidence
        similarityRating
        direction
        linkHref
        popular
        imgUrl
        price
        brandData {
          id
          imgUrl
        }
        bookData {
          id
          alternativeFinalName
        }
        cordinates {
          xStart
          yStart
          xEnd
          yEnd
        }
        bbox {
          x0
          y0
          x1
          y1
        }
        howMany
        typeSelect
        aditionalName
        err
      }
    }
  }
`;

const getBookNotIdQuery = gql`
  query GetBookNotId(
    $name: String
    $startDuringTime: String
    $endDuringTime: String
    $alternativeFinalName: String
  ) {
    bookNotId(
      name: $name
      startDuringTime: $startDuringTime
      endDuringTime: $endDuringTime
      alternativeFinalName: $alternativeFinalName
    ) {
      id
      name
      categoryId
      startDuringTime
      endDuringTime
      numberOfPages
      imgUrl
      thumbnail
      status
      publicatedDateBook
      alternativeFinalName
      recomended
      bookCategories
      brand {
        id
        name
        categoryId
        brandId
        imgUrl
        books {
          id
          name
          categoryId
          startDuringTime
          endDuringTime
          numberOfPages
          imgUrl
          thumbnail
          status
          publicatedDateBook
          alternativeFinalName
          recomended
        }
        category {
          name
          id
          books {
            id
            name
            categoryId
            startDuringTime
            endDuringTime
            numberOfPages
            imgUrl
            thumbnail
            status
            publicatedDateBook
            alternativeFinalName
            recomended
          }
        }
      }
      productsItems {
        id
        name
        recognizedString
        categoryProductId
        categoryKeyProductName
        categorySubKeyProductName
        brand
        book
        page
        statusBook
        startDuringTime
        endDuringTime
        bookId
        confidence
        similarityRating
        direction
        linkHref
        popular
        imgUrl
        price
        brandData {
          id
          imgUrl
        }
        bookData {
          id
          alternativeFinalName
        }
        cordinates {
          xStart
          yStart
          xEnd
          yEnd
        }
        bbox {
          x0
          y0
          x1
          y1
        }
        howMany
        typeSelect
        aditionalName
        err
      }

      productKeysCategory {
        id
        name
        categoryProductId
        imgUrl
      }
    }
  }
`;

const getCategoryQuery = gql`
  query GetCategory($id: ID) {
    category(id: $id) {
      name
      id
      books {
        id
        name
        categoryId
        startDuringTime
        endDuringTime
        numberOfPages
        imgUrl
        thumbnail
        status
        publicatedDateBook
        alternativeFinalName
        recomended
        bookCategories
        brand {
          id
          imgUrl
        }
      }
      altCategoryBooks {
        id
        name
        categoryId
        startDuringTime
        endDuringTime
        numberOfPages
        imgUrl
        thumbnail
        status
        publicatedDateBook
        alternativeFinalName
        recomended
        bookCategories
        brand {
          id
          imgUrl
        }
      }
    }
  }
`;

const getCategoryByNameQuery = gql`
  query GetCategoryByName($name: String) {
    categoryByName(name: $name) {
      name
      id
      books {
        id
        name
        categoryId
        startDuringTime
        endDuringTime
        numberOfPages
        imgUrl
        thumbnail
        status
        publicatedDateBook
        alternativeFinalName
        recomended
        bookCategories
        brand {
          id
          imgUrl
        }
      }
      altCategoryBooks {
        id
        name
        categoryId
        startDuringTime
        endDuringTime
        numberOfPages
        imgUrl
        thumbnail
        status
        publicatedDateBook
        alternativeFinalName
        recomended
        bookCategories
        brand {
          id
          imgUrl
        }
      }
      allBrands {
        id
        name
        categoryId
        imgUrl
      }
      allCategories {
        id
        name
        categoryId
        imgUrl
      }
    }
  }
`;

const getDataForHomePageQuery = gql`
  query GetForHomePage($name: String, $options: Int, $sortType: String) {
    forHomePage(name: $name, options: $options, sortType: $sortType) {
      id
      name
      allBooks {
        id
        name
        categoryId
        startDuringTime
        endDuringTime
        numberOfPages
        imgUrl
        thumbnail
        status
        publicatedDateBook
        alternativeFinalName
        recomended
        bookCategories
        brand {
          id
          imgUrl
        }
      }
      allBrands {
        id
        name
        categoryId
        imgUrl
      }
      allCategories {
        id
        name
        categoryId
        imgUrl
      }
      blogLimited {
        id
        author
        intro
        title
        created
        publicated_date
        content {
          title
          text
          img
          adv
        }
        mainImg
      }
      productKeysCategory {
        id
        name
        categoryProductId
        imgUrl
      }
    }
  }
`;
const getDataForAllNewspaperQuery = gql`
  query GetForAllNewspaperQuery($name: String, $sortType: String) {
    forAllNewspaperPage(name: $name, sortType: $sortType) {
      id
      name
      allBooks {
        id
        name
        categoryId
        startDuringTime
        endDuringTime
        numberOfPages
        imgUrl
        thumbnail
        status
        publicatedDateBook
        alternativeFinalName
        recomended
        bookCategories
        brand {
          id
          imgUrl
        }
      }
      allBrands {
        id
        name
        categoryId
        imgUrl
      }
      allCategories {
        id
        name
        categoryId
        imgUrl
      }
      blogLimited {
        id
        author
        intro
        title
        created
        publicated_date
        content {
          title
          text
          img
          adv
        }
        mainImg
      }
    }
  }
`;
const getDataForRightSideCaruselQuery = gql`
  query GetForRightSideCaruselQuery($name: String, $sortType: String) {
    forAllBooksInRightSideCarusel(name: $name, sortType: $sortType) {
      id
      name
      allBooks {
        id
        name
        categoryId
        startDuringTime
        endDuringTime
        numberOfPages
        imgUrl
        thumbnail
        status
        publicatedDateBook
        alternativeFinalName
        recomended
        bookCategories
        brand {
          id
          imgUrl
        }
      }
    }
  }
`;

const getDataForTopDealsQuery = gql`
  query GetForAllTopDealsQuery($name: String) {
    forTopDealsPage(name: $name) {
      id
      name
      productKeysCategory {
        id
        name
        categoryProductId
        imgUrl
        categoryItems
      }
      allBrands {
        id
        name
        categoryId
        imgUrl
      }
      allCategories {
        id
        name
        categoryId
        imgUrl
      }
    }
  }
`;
const getDealsForTopDealsQuery = gql`
  query GetDealsForAllTopDealsQuery(
    $sortType: String
    $offset: Int
    $limit: Int
    $category: String
  ) {
    dealsTopDealsPage(
      sortType: $sortType
      offset: $offset
      limit: $limit
      category: $category
    ) {
      id
      name
      recognizedString
      categoryProductId
      categoryKeyProductName
      categorySubKeyProductName
      brand
      book
      page
      statusBook
      startDuringTime
      endDuringTime
      bookId
      confidence
      similarityRating
      direction
      linkHref
      popular
      imgUrl
      price
      brandData {
        id
        imgUrl
      }
      bookData {
        id
        alternativeFinalName
      }
      cordinates {
        xStart
        yStart
        xEnd
        yEnd
      }
      bbox {
        x0
        y0
        x1
        y1
      }
      howMany
      typeSelect
      aditionalName
      err
    }
  }
`;

const popularItemProductMutation = gql`
  mutation PopularItemProduct($id: ID!) {
    popularItemProduct(id: $id) {
      popular
    }
  }
`;
export {
  getBooksQuery,
  getCategoriesQuery,
  getBookQuery,
  getCategoryQuery,
  getBrandsQuery,
  getBrandQuery,
  GetBrand,
  getPostQuery,
  getBlogQuery,
  getProductItemsQuery,
  getItemProduct,
  getProductKeysCategoriesQuery,
  getCategoryKeyProduct,
  getProductCategoriesQuery,
  getCategoryProduct,
  getProductItemsFromCategoryKeys,
  popularItemProductMutation,
  getFirstOtionedProductItemsQuery,
  getBookNotIdQuery,
  getProductFromKeysCategoriesQuery,
  getBrandByNameQuery,
  getCategoryByNameQuery,
  getDataForHomePageQuery,
  getDataForAllNewspaperQuery,
  getDataForTopDealsQuery,
  getDealsForTopDealsQuery,
  getBrandItemProductsQuery,
  getDataForRightSideCaruselQuery,
  getForBlogData,
};
