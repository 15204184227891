import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PageReloaded } from "../../utils/PageReloaded";
import { useParams, useHistory } from "react-router-dom";
import { Theme } from "../../store/theme/theme";
import { checkIsMobile } from "../../store/theme/duck/actions/actions";
import {
  trigerVisibleList,
  closeVisibleList,
} from "../../store/shopingList/duck/actions/actions";
import {
  MobileMenuWrapper,
  ShopingListWrapper,
  HomeWrapper,
  MetricsWrapper,
  svgStyle,
} from "./MobileMenu.style";
import {
  shopingListPath,
  houseSolidPath,
  metricsPath,
} from "../../media/svgPath/SvgPath";
import { NumberOfShopingItems } from "../shopingList/NumberOfShopingItems";
import { SvgDisplay } from "../tools/spiner/svgDisplay/SvgDisplay";

const _MobileMenu = ({
  theme,
  select,
  checkIsMobile,
  shopingList,
  trigerVisibleList,
  closeVisibleList,
}) => {
  const reload = PageReloaded();
  let history = useHistory();
  const [home, setHome] = useState(false);
  const [metrics, setMetrics] = useState(false);
  const [shoping, setShopingList] = useState(false);

  useEffect(() => {
    if (shopingList.isListVisible) {
      setShopingList(true);
      setHome(false);
      setMetrics(false);
      return;
    }
    if (history.location.pathname === "/") {
      setShopingList(false);
      setHome(true);
      setMetrics(false);
      return;
    }
    if (history.location.pathname === "/twoje-zakupy") {
      setShopingList(false);
      setHome(false);
      setMetrics(true);
      return;
    }
    if (
      !shopingList.isListVisible &&
      history.location.pathname !== "/" &&
      history.location.pathname !== "/twoje-zakupy"
    ) {
      setShopingList(false);
      setHome(false);
      setMetrics(false);
      return;
    }
  }, [history?.location?.pathname, shopingList?.isListVisible]);
  useEffect(() => {
    if (window.innerWidth < Theme.breakPoint.tablet) {
      checkIsMobile(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < Theme.breakPoint.tablet) {
        checkIsMobile(true);
      } else {
        checkIsMobile(false);
      }
    });
  }, []);

  return theme?.isMobile ? (
    <MobileMenuWrapper isDark={theme.isDark} isMainMenuOpen={theme.isMenuOpen}>
      <MetricsWrapper
        whileTap={{ scale: 0.9 }}
        isActive={metrics}
        onClick={() => {
          setShopingList(false);
          setHome(false);
          setMetrics(true);
          history.push("/twoje-zakupy");
          if (shopingList?.isListVisible) {
            closeVisibleList();
          }
        }}
      >
        <SvgDisplay
          viewBox={metricsPath.viewBox}
          d={metricsPath.d}
          style={svgStyle}
        />
        <p>Wydatki</p>
      </MetricsWrapper>
      <HomeWrapper
        whileTap={{ scale: 0.9 }}
        isActive={home}
        onClick={() => {
          setShopingList(false);
          setHome(true);
          setMetrics(false);
          history.push("/");
          if (shopingList?.isListVisible) {
            closeVisibleList();
          }
        }}
      >
        <SvgDisplay
          viewBox={houseSolidPath.viewBox}
          d={houseSolidPath.d}
          style={svgStyle}
        />
        <p>Home</p>
      </HomeWrapper>
      <ShopingListWrapper
        whileTap={{ scale: 0.9 }}
        isActive={shoping}
        onClick={() => {
          setShopingList(true);
          setHome(false);
          setMetrics(false);
          trigerVisibleList();
        }}
      >
        <SvgDisplay
          viewBox={shopingListPath.viewBox}
          d={shopingListPath.d}
          style={svgStyle}
        />
        <p>Lista</p>
        <NumberOfShopingItems />
      </ShopingListWrapper>
    </MobileMenuWrapper>
  ) : null;
};
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
  shopingList: state.shopingList,
});
const mapDispatchToProps = (dispatch) => ({
  checkIsMobile: (item) => dispatch(checkIsMobile(item)),
  trigerVisibleList: (item) => dispatch(trigerVisibleList(item)),
  closeVisibleList: (item) => dispatch(closeVisibleList(item)),
});
export const MobileMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(_MobileMenu);
