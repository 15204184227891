import React, { useEffect, useRef } from "react";
import { useParams, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { SingleCategory } from "../components/singleCategory/SingleCategory";
import { BrandOrCategoryContainer } from "../components/brandOrCategoryContainer/BrandOrCategoryContainer";
import { MapContainer } from "../components/mapSection/MapContainer";
import { CloseToUserShops } from "../components/closeToUserShops/CloseToUserShops";
import { InfoSection } from "../components/infoSection/InfoSection";
import { Footer } from "../components/footerSection/Footer";
import { getCategoryByNameQuery } from "../queries/queries";
import { ErrorFromData } from "../components/error/ErrorFromData";
import { API_URL, CATEGORY_NAME } from "../constant/constant";
import { useQuery } from "@apollo/client";

const _CategoryPage = ({
  setUrlChecked,
  selectViewData,
  setSelectViewData,
  theme,
}) => {
  const { name, section } = useParams();
  const sectionRef = useRef();

  const regNameMultiLng =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšśŚžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9\s ,.'-]+$/u;

  const titleParams = name && name.split("-").join(" ").toLowerCase();
  // const urlMatchedBrand = CATEGORY_NAME.filter(
  //   (item) => item === titleParams.toLowerCase()
  // );
  const categoryDataFromUrl = useQuery(getCategoryByNameQuery, {
    fetchPolicy: "network-only",
    variables: {
      name: `gazetki ${titleParams}`,
    },
    ssr: true,
  });
  // console.log(categoryDataFromUrl, "categoryDataFromUrl");
  useEffect(() => {
    if (categoryDataFromUrl?.client) {
      categoryDataFromUrl.client.resetStore();
    }
  }, []);
  useEffect(() => {
    categoryDataFromUrl.refetch();
  }, [name]);
  useEffect(() => {
    setSelectViewData(categoryDataFromUrl?.data?.categoryByName[0]);
  }, [categoryDataFromUrl?.data?.categoryByName[0]?.name]);

  useEffect(() => {
    if (sectionRef && sectionRef?.current && theme?.isMobile) {
      const y = sectionRef.current.offsetTop - 160;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [name]);

  if (!regNameMultiLng.test(name)) {
    return (
      <>
        <Redirect to={{ pathname: "/" }} />
      </>
    );
  }
  if (section) {
    const check = ["promocje", "sklepy"].filter((item) =>
      item.includes(section)
    ).length;
    if (check === 0) {
      return (
        <>
          <Redirect to={{ pathname: "/" }} />
        </>
      );
    }
  }

  if (categoryDataFromUrl?.error) {
    return (
      <ErrorFromData
        errorInfo={categoryDataFromUrl.error}
        queryName={"categoryDataFromUrl._CategoryPage"}
      />
    );
  }
  return (
    <>
      <div ref={sectionRef} />
      <SingleCategory
        setUrlChecked={setUrlChecked}
        categoryDataFromUrl={categoryDataFromUrl}
      />
      <BrandOrCategoryContainer section={section} />
      <MapContainer />
      <CloseToUserShops howMany={10} />
      <InfoSection categoryDataFromUrl={categoryDataFromUrl} />
      <Footer categoryDataFromUrl={categoryDataFromUrl} />
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
});
export const CategoryPage = connect(mapStateToProps, {})(_CategoryPage);
