import React from "react";
import { connect } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";
import {
  WrraperSection,
  MainWrapperLayer,
  MainContent,
  SectionHeader,
  More,
  MainPostWraper,
} from "./stylesForBlog";
import { ShowPost } from "./ShowPost";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";

const _MainPost = ({ theme }) => {
  // document.documentElement.scrollTop = 0
  const { title } = useParams();
  let history = useHistory();

  return (
    <>
      <WrraperSection isDark={theme.isDark}>
        <MainWrapperLayer isDark={theme.isDark}>
          <MainContent isDark={theme.isDark}>
            <SectionHeader
              isDark={theme.isDark}
              onClick={() => history.push("/blog")}
            >
              <h2>
                <span>blog</span>
              </h2>
            </SectionHeader>
            <ErrorBoundary>
              <MainPostWraper isDark={theme.isDark}>
                <ShowPost title={title} isDark={theme.isDark} />
              </MainPostWraper>
            </ErrorBoundary>
          </MainContent>
        </MainWrapperLayer>
      </WrraperSection>
    </>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
});
export const MainPost = connect(mapStateToProps, {})(_MainPost);
