import React, { useState, useRef, useEffect } from "react";
import { Spinner } from "../tools/spiner/Spinner";
import { Post } from "./Post";
import { ErrorFromData } from "../error/ErrorFromData";
import { BlogWrapper } from "./stylesForBlog";
import ReactPaginate from "react-paginate";
import { AnimatePresence } from "framer-motion";
import { BtnBack, BtnNext } from "../brandPostSection/BrandPostPosition.helper";

export const ShowBlog = ({ blogData, dataFromHomePage, isDark }) => {
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 12;
  const wrapper = useRef();
  let topDealsWraper;

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    topDealsWraper = document.getElementById("brandPostWrapper");
    const newOffset =
      (event.selected * itemsPerPage) % blogData.data.blog?.length;

    setItemOffset(newOffset);
    if (wrapper && wrapper.current) {
      document.documentElement.scrollTop =
        window.scrollY + topDealsWraper.getBoundingClientRect().top - 10;
    }
  };
  useEffect(() => {
    return () => {
      setItemOffset(0);
      topDealsWraper = null;
    };
  }, []);
  if (dataFromHomePage?.error && !dataFromHomePage?.error?.networkError) {
    return (
      <ErrorFromData
        errorInfo={dataFromHomePage.error}
        queryName={"dataFromHomePage.ShowBlog"}
      />
    );
  }
  if (blogData?.error) {
    return (
      <ErrorFromData
        errorInfo={blogData.error}
        queryName={"blogData.ShowBlog"}
      />
    );
  }
  if (blogData?.loading == true)
    return (
      <BlogWrapper>
        <Spinner />
      </BlogWrapper>
    );

  if (blogData?.data) {
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = blogData.data.blog?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(blogData.data.blog?.length / itemsPerPage);

    // const copyArr = [...blogData.data.blog];
    // const sortedDataFromQuery = copyArr.sort((a, b) => b.created - a.created);

    return (
      <BlogWrapper ref={wrapper} id="brandPostWrapper">
        {currentItems?.map((post) => (
          <Post
            img={post.mainImg}
            title={post.title}
            intro={post.intro}
            text={post.content}
            created={post.created}
            key={post.id}
            isDark={isDark}
          />
        ))}
        <ReactPaginate
          breakLabel="..."
          nextLabel={<BtnNext isDark={isDark} />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel={<BtnBack isDark={isDark} />}
          renderOnZeroPageCount={null}
          className="paginationLight"
        />
      </BlogWrapper>
    );
  }

  if (dataFromHomePage?.loading == true)
    return (
      <BlogWrapper>
        <Spinner />
      </BlogWrapper>
    );

  if (dataFromHomePage?.data?.forHomePage[0]?.name) {
    const copyArr = [...dataFromHomePage?.data?.forHomePage[0]?.blogLimited];
    const sortedDataFromQuery = copyArr.sort((a, b) => b.created - a.created);

    return (
      <BlogWrapper>
        {sortedDataFromQuery.map((post) => (
          <Post
            img={post.mainImg}
            title={post.title}
            intro={post.intro}
            text={post.content}
            key={post.id}
            isDark={isDark}
            created={post.created}
          />
        ))}
      </BlogWrapper>
    );
  }
};
