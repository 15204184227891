import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { getBrandsQuery } from "../../../../../../queries/queries";
import { motion, AnimatePresence } from "framer-motion";
import { Spinner } from "../../../../../tools/spiner/Spinner";
import { animationInputVariants } from "../style";
import { ErrorFromData } from "../../../../../error/ErrorFromData";
const loginOptionsVariants = {
  hidden: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
  visible: {
    opacity: 1,
    // scale:1,
    y: 0,
    // transformOrigin:"bottom",
    transition: { duration: 0.3 },
  },
  exit: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
};
const useBrandForTopDeals = () => {
  return useQuery(getBrandsQuery, { ssr: true });
};

const SelectContentForBrand = ({
  item,
  setBrandOptionsActive,
  setDays,
  days,
  activeItemIndexFromCarusel,
  activeIndexFromSlide,
}) => {
  const optionName = item;
  const [refToLi, setRefToLi] = useState(null);
  const litItem = useRef(null);

  if (refToLi) {
    refToLi.addEventListener("click", () => {
      setBrandOptionsActive(false);
      if (
        days?.time?.shoping[activeItemIndexFromCarusel]?.result?.item?.list[
          activeIndexFromSlide
        ]
      ) {
        days.time.shoping[activeItemIndexFromCarusel].result.item.list[
          activeIndexFromSlide
        ].brand = item.name;
        if (
          !days.time.shoping[activeItemIndexFromCarusel].result.item.list[
            activeIndexFromSlide
          ].brandData
        ) {
          days.time.shoping[activeItemIndexFromCarusel].result.item.list[
            activeIndexFromSlide
          ].brandData = [{}];
        }
        days.time.shoping[activeItemIndexFromCarusel].result.item.list[
          activeIndexFromSlide
        ].brandData[0].imgUrl = item.imgUrl;

        setDays({
          ...days,
          render: ++days.render,
        });
      }
    });
  }

  return (
    <motion.li
      variants={animationInputVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      ref={(current) => {
        litItem.current = current;
        setRefToLi(current);
      }}
    >
      {item.name}
    </motion.li>
  );
};

export const SelectBrand = ({
  setBrandOptionsActive,
  setDays,
  days,
  activeItemIndexFromCarusel,
  activeIndexFromSlide,
}) => {
  let pureData = useBrandForTopDeals();

  useEffect(() => {
    return () => {
      pureData = "";
    };
  });
  if (pureData.error) {
    return (
      <ErrorFromData
        errorInfo={pureData.error}
        queryName={"getBrandsQuery.SelectBrand"}
      />
    );
  }
  if (pureData.data) {
    const data = pureData.data;
    const sortedData = data.brands.sort((a, b) => {
      const nameA = a.name;
      const nameB = b.name;
      return nameA < nameB ? -1 : 1;
    });

    const createSelectOptions = sortedData.map((item) => (
      <SelectContentForBrand
        key={item.id}
        item={item}
        setBrandOptionsActive={setBrandOptionsActive}
        setDays={setDays}
        days={days}
        activeItemIndexFromCarusel={activeItemIndexFromCarusel}
        activeIndexFromSlide={activeIndexFromSlide}
      />
    ));
    return <ul>{createSelectOptions}</ul>;
  } else return <Spinner />;
};
