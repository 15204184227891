import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  userAccept,
  sendToken,
} from "../../store/shopingList/duck/actions/actions";
import {
  overflowHidden,
  overflowAuto,
} from "../../store/theme/duck/actions/actions";
import {
  MainWraperRodo,
  ContentRodoWraper,
  ContentRodo,
  Accept,
  Later,
  ActionBtn,
  modalWraperVariants,
  singleItemModalVariants,
} from "./styleForRodoConfirmation";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { AnimatePresence } from "framer-motion";
import { API_URL } from "../../constant/constant";
import { PageReloaded } from "../../utils/PageReloaded";
const _RodoConfirmation = ({
  theme,
  shopingList,
  userAccept,
  sendToken,
  overflowHidden,
  overflowAuto,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const reload = PageReloaded();

  useEffect(() => {
    if (
      !shopingList.userAcceptRodo.accepted &&
      localStorage &&
      localStorage.getItem("userRodo") &&
      localStorage.getItem("userToken")
    ) {
      sendToken(JSON.parse(localStorage.getItem("userToken")));
      setIsVisible(false);
      userAccept();
      overflowHidden();
    }
  }, [reload]);

  useEffect(() => {
    if (
      !shopingList.userAcceptRodo.accepted &&
      !localStorage.getItem("userRodo")
    ) {
      setTimeout(() => {
        fetch(`${API_URL}/api/rodoAccept`, {
          method: "POST",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            accepted: shopingList.userAcceptRodo.accepted,
            token: shopingList.userAcceptRodo.token,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              if (data.error === true) {
                setIsVisible(false);
                return;
              }
              if (data.token) {
                sendToken(data.token);
              }
              if (data.accepted === false) {
                setIsVisible(true);
                overflowAuto();
              }
              if (data.accepted === true) {
                userAccept();
                return;
              }
            }
          });
      }, 6000);
    }
  }, []);
  return (
    <>
      <ErrorBoundary>
        {isVisible && (
          <AnimatePresence>
            <MainWraperRodo
              isDark={theme.isDark}
              isVisible={isVisible}
              variants={modalWraperVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <ContentRodoWraper
                isDark={theme.isDark}
                variants={singleItemModalVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <ContentRodo isDark={theme.isDark}>
                  <h4>
                    <span>
                      Drogi użytkowniku serdecznie witamy Cię w serwisie
                      Cenniczek.com
                    </span>
                  </h4>

                  <div>
                    <p>
                      Naszym celem jest dostarczać niezbędne informacje,
                      najnowsze promocje, oraz gazetki promcyjne z Twoich
                      ulubionych sieci handlowych oraz sklepów w Twojej
                      najbliższej okolicy, aby zapewnić Ci wszystkie możliwe
                      funkcjonalności serwisu potrzebujemy zgodę na użycie
                      technologi cookies, czym ona się charakteryzuje i jak jest
                      przez nas wykorzystywana ? Niezbędne informacje znajdziesz
                      w naszej polityce prywatności. Zalecamy również akceptacje
                      geolokalizacji - pozwoli to dostarczyć Ci niezbędnych
                      informacji z sieci handlowych w Twojej okolicy.
                    </p>
                    <p>
                      Aby móc stale rozwijać nasz serwis bez żadnych dodatkowych
                      opłat z Twojej strony potrzebujemy Twojej zgody na
                      wyświetlanie treści marketingowych dopasowanych do Twoich
                      upodobań. Podstawowe informacje dotyczące przetwarzania
                      Twoich danych osobowych zgodnie z obowiązującymi
                      przepisami prawa oraz więcej szczegółów znajdziesz w
                      naszej 
                      <a target="_blank" href="https://cenniczek.com/polityka">
                        Polityce prywatności
                      </a>
                       <span>oraz</span>
                      <a target="_blank" href="https://cenniczek.com/regulamin">
                        Regulaminie
                      </a>
                       
                    </p>
                  </div>
                  <ActionBtn isDark={theme.isDark}>
                    <Later isDark={theme.isDark}>
                      <div
                        onClick={() => {
                          setIsVisible(false);
                          overflowHidden();
                        }}
                      >
                        później
                      </div>
                    </Later>
                    <Accept isDark={theme.isDark}>
                      <div
                        onClick={() => {
                          fetch(`${API_URL}/api/rodoAccept`, {
                            method: "POST",
                            cache: "no-cache",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                              accepted: true,
                              token: shopingList.userAcceptRodo.token,
                            }),
                          })
                            .then((response) => response.json())
                            .then((data) => {
                              // console.log(data);
                              if (data) {
                                if (data.error) console.log(data.error);
                                if (data.accepted === true) {
                                  setIsVisible(false);
                                  userAccept();
                                  overflowHidden();
                                }
                              }
                            });
                        }}
                      >
                        akceptuje
                      </div>
                    </Accept>
                  </ActionBtn>
                </ContentRodo>
              </ContentRodoWraper>
            </MainWraperRodo>
          </AnimatePresence>
        )}
      </ErrorBoundary>
    </>
  );
};

const mapDisaptchToProps = (dispatch) => ({
  userAccept: (item) => dispatch(userAccept(item)),
  sendToken: (item) => dispatch(sendToken(item)),
  overflowHidden: () => dispatch(overflowHidden()),
  overflowAuto: () => dispatch(overflowAuto()),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  shopingList: state.shopingList,
});
export const RodoConfirmation = connect(
  mapStateToProps,
  mapDisaptchToProps
)(_RodoConfirmation);
