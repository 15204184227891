import React from "react";
import { connect } from "react-redux";
import { CaruselSection } from "./caruselSection/CaruselSection";
import { AdvSection } from "./advSection/AdvSection";
import {
  WrraperSection,
  MainWrapperLayer,
  LeftSide,
  RigthSide,
} from "./PromoSection.style";
const _PromoSection = ({ theme, metrics }) => {
  return (
    <WrraperSection isDark={theme.isDark} metrics={metrics}>
      <MainWrapperLayer isDark={theme.isDark}>
        <LeftSide>
          <CaruselSection />
        </LeftSide>
        <RigthSide>
          <AdvSection isDark={theme.isDark} />
        </RigthSide>
      </MainWrapperLayer>
    </WrraperSection>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
});
export const PromoSection = connect(mapStateToProps, {})(_PromoSection);
