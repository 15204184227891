import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { SvgColorStyle } from "../../../utils/SvgColorStyle";

import {
  deleteItemFromList,
  updateItemOnList,
  pushUserItemToList,
  deleteUserItemFromList,
  updateUserItemOnList,
  userCanCrop,
  userCanNotCrop,
} from "../../../store/shopingList/duck/actions/actions";
import { SectionSelectBrandShopingList } from "./selectBrandForShopingList/SectionSelectBrandShopingList";
import {
  ItemContent,
  ItemWraper,
  ImgWraper,
  ActionsItem,
  InputItems,
  InfoWraper,
  UserItemContainer,
  styleForSvg,
  styleForActionsSvg,
  styleForMoreActionsSvg,
  BrandWraper,
  styleForActiveLigth,
  styleForActiveDark,
  styleForDisactiveDark,
  styleForDisactiveLight,
  animationInputVariants,
} from "../styleForShopingList";
// import alertify from "alertifyjs";
// import "alertifyjs/build/css/alertify.css";
// import "alertifyjs/build/css/themes/default.css";
import { Theme } from "../../../store/theme/theme";
import { API_URL } from "../../../constant/constant";
import { SvgDisplay } from "../../tools/spiner/svgDisplay/SvgDisplay";
import { plusPath } from "../../../media/svgPath/SvgPath";

const trashPath =
  "M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z";
const userPlusPath =
  "M624 208h-64v-64c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v64h-64c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-400 48c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z";
const startPath =
  "M436 160H12c-6.627 0-12-5.373-12-12v-36c0-26.51 21.49-48 48-48h48V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h128V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h48c26.51 0 48 21.49 48 48v36c0 6.627-5.373 12-12 12zM12 192h424c6.627 0 12 5.373 12 12v260c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V204c0-6.627 5.373-12 12-12zm333.296 95.947l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L194.12 364.665l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068l-28.397 28.17c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z";
const endTimePath =
  "M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm257.3 160l48.1-48.1c4.7-4.7 4.7-12.3 0-17l-28.3-28.3c-4.7-4.7-12.3-4.7-17 0L224 306.7l-48.1-48.1c-4.7-4.7-12.3-4.7-17 0l-28.3 28.3c-4.7 4.7-4.7 12.3 0 17l48.1 48.1-48.1 48.1c-4.7 4.7-4.7 12.3 0 17l28.3 28.3c4.7 4.7 12.3 4.7 17 0l48.1-48.1 48.1 48.1c4.7 4.7 12.3 4.7 17 0l28.3-28.3c4.7-4.7 4.7-12.3 0-17L269.3 352z";
const cutPath =
  "M278.06 256L444.48 89.57c4.69-4.69 4.69-12.29 0-16.97-32.8-32.8-85.99-32.8-118.79 0L210.18 188.12l-24.86-24.86c4.31-10.92 6.68-22.81 6.68-35.26 0-53.02-42.98-96-96-96S0 74.98 0 128s42.98 96 96 96c4.54 0 8.99-.32 13.36-.93L142.29 256l-32.93 32.93c-4.37-.61-8.83-.93-13.36-.93-53.02 0-96 42.98-96 96s42.98 96 96 96 96-42.98 96-96c0-12.45-2.37-24.34-6.68-35.26l24.86-24.86L325.69 439.4c32.8 32.8 85.99 32.8 118.79 0 4.69-4.68 4.69-12.28 0-16.97L278.06 256zM96 160c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32zm0 256c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32z";
const downPath =
  "M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z";
const animationVariants = {
  hidden: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
  visible: {
    opacity: 1,
    // scale:1,
    y: 0,
    // transformOrigin:"bottom",
    transition: { duration: 0.5 },
  },
  exit: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
};
const checkPrice = (additionalOptions) => {
  if (additionalOptions.howMany && additionalOptions.price) {
    return Number(additionalOptions.howMany * additionalOptions.price).toFixed(
      2
    );
  }
  return 0;
};

const regNameMultiLng =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžśŚÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9\s ,.'-]+$/u;
const forDecimals = /^\d*(\.)?(\,)?(\d{0,2})?$/;
// const forDecimals=/^[0-9]+([.,][0-9]{1,2})?$/

const SingleUserItem = ({
  deleteUserItemFromList,
  isDark,
  addUserItemIndex,
  updateUserItemOnList,
  userItems,
  userCanCrop,
  userCanNotCrop,
  shopingList,
  item,
}) => {
  const { location } = useHistory();
  const [selectBrand, setSelectBrand] = useState(null);
  const [moreOptionsVisible, setMoreOptionsVisible] = useState(true);

  const target = useRef(null);
  const [targetRef, setTargetRef] = useState(null);
  const [cropActive, setCropActive] = useState(false);
  const errAlfanumeric = "dostępne znaki alfanumeryczne";
  const errNumeric = "dostępne znaki numeryczne [0-9].[01-99]";
  const errToManySigns = "dostępne 50 znaków";
  const [alertify, setAlertify] = useState(null);
  useEffect(() => {
    return () => {
      setAlertify(null);
    };
  }, []);
  useEffect(() => {
    const loadAlertify = async () => {
      if (typeof window !== "undefined") {
        try {
          const alertifyInstance = (
            await import("../../../media/assets/alertify/alertify.js")
          ).default;
          await import("../../../media/assets/alertify/alertify.min.css");
          await import("../../../media/assets/alertify/default.min.css");
          setAlertify(alertifyInstance);
        } catch (error) {
          console.error("Error loading Alertify:", error);
        }
      }
    };

    loadAlertify();
  }, []);
  useEffect(() => {
    updateUserItemOnList({
      index: addUserItemIndex,
      value: {
        brand: userItems[addUserItemIndex]?.brand || "",
        imgUrl: userItems[addUserItemIndex]?.imgUrl || "",
        startDuringTime: userItems[addUserItemIndex]?.startDuringTime || "",
        endDuringTime: userItems[addUserItemIndex]?.endDuringTime || "",
        page: userItems[addUserItemIndex]?.page || "",
        howMany: userItems[addUserItemIndex]?.howMany || "",
        price: userItems[addUserItemIndex]?.price || "",
        typeSelect: userItems[addUserItemIndex]?.typeSelect || "kg",
        aditionalName: userItems[addUserItemIndex]?.aditionalName || "",
        err: userItems[addUserItemIndex]?.err || "",
        brandData: [
          {
            imgUrl:
              (userItems[addUserItemIndex].brandData &&
                userItems[addUserItemIndex].brandData[0]?.imgUrl) ||
              "",
          },
        ],
      },
    });
  }, [userItems[addUserItemIndex]?.brand, addUserItemIndex]);
  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.target.blur();
    }
  };

  const handleOnchange = (e) => {
    const name = e.target.name;
    if (name === "typeSelect") {
      if (
        e.target.options[e.target.selectedIndex].value === "kg" ||
        e.target.options[e.target.selectedIndex].value === "szt"
      ) {
        updateUserItemOnList({
          index: addUserItemIndex,
          value: {
            ...userItems[addUserItemIndex],
            [name]: e.target.options[e.target.selectedIndex].value,
          },
        });
      }
    } else {
      if (name === "aditionalName") {
        const { value } = e.target;
        if (value?.length > 2 && !regNameMultiLng.test(value)) {
          updateUserItemOnList({
            index: addUserItemIndex,
            value: {
              ...userItems[addUserItemIndex],
              [name]: e.target.value,
              err: errAlfanumeric,
            },
          });
          return;
        }
        if (value?.length <= 100) {
          updateUserItemOnList({
            index: addUserItemIndex,
            value: {
              ...userItems[addUserItemIndex],
              [name]: e.target.value,
              err: "",
            },
          });
          return;
        } else {
          updateUserItemOnList({
            index: addUserItemIndex,
            value: {
              ...userItems[addUserItemIndex],
              [name]: e.target.value,
              err: errToManySigns,
            },
          });
          return;
        }
      } else if (name === "howMany" || name === "price") {
        if (forDecimals.test(e.target.value)) {
          updateUserItemOnList({
            index: addUserItemIndex,
            value: {
              ...userItems[addUserItemIndex],
              [name]: e.target.value,
              err: "",
            },
          });
        } else {
          updateUserItemOnList({
            index: addUserItemIndex,
            value: { ...userItems[addUserItemIndex], err: errNumeric },
          });
        }
      }
    }
  };
  return (
    <ItemWraper
      isDark={isDark}
      variants={animationVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      moreOptionsVisible={moreOptionsVisible}
      isItem={true}
      ref={(current) => {
        target.current = current;
        setTargetRef(target.current);
      }}
    >
      <ItemContent isDark={isDark}>
        <ImgWraper isDark={isDark}>
          {userItems[addUserItemIndex]?.imgUrl && (
            <img
              loading="lazy"
              src={`${API_URL}${userItems[addUserItemIndex]?.imgUrl}`}
              alt={"oferta z gazetki promocyjnej wycięta przez użytkownika"}
              style={{ width: "auto" }}
            />
          )}
          {!userItems[addUserItemIndex]?.imgUrl && (
            <div
              onClick={() => {
                if (!shopingList.userLoged) {
                  alertify.set("notifier", "position", "bottom-left");
                  alertify.error(
                    `Aby skorzystać z funkcji
                     musisz być zalogowany`
                  );
                } else if (
                  shopingList.userLoged &&
                  !location.pathname.includes("gazetka-promocyjna")
                ) {
                  alertify.set("notifier", "position", "bottom-left");
                  alertify.error(
                    `Aby skorzystać z funkcji
                     musisz wybrać gazetke`
                  );
                } else if (
                  shopingList.userLoged &&
                  shopingList.userAcceptRodo.token &&
                  location.pathname.includes("gazetka-promocyjna")
                ) {
                  if (!shopingList.cropedFromNewspaper.active) {
                    userCanCrop(addUserItemIndex);
                    setCropActive(true);
                    alertify.set("notifier", "position", "bottom-left");
                    alertify.success(`Funkcja wycinania włączona,kliknij na
                                      strone z której będziesz wycinać`);
                  }
                  if (
                    shopingList.cropedFromNewspaper.active &&
                    shopingList.cropedFromNewspaper.index !== addUserItemIndex
                  ) {
                    alertify.set("notifier", "position", "bottom-left");
                    alertify.error(
                      `Funkcja została już włączona
                       pod inną pozycją listy`
                    );
                  }
                  if (
                    shopingList.cropedFromNewspaper.active &&
                    shopingList.cropedFromNewspaper.index === addUserItemIndex
                  ) {
                    userCanNotCrop();
                    setCropActive(false);
                    alertify.set("notifier", "position", "bottom-left");
                    alertify.error(`Funkcja wycinania wyłączona`);
                  }
                }
              }}
            >
              {" "}
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="cut"
                className="svg-inline--fa fa-cut fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                height={40}
                style={
                  (styleForSvg,
                  {
                    height: "40px",
                    color: `${
                      shopingList.cropedFromNewspaper.active && cropActive
                        ? Theme.light.secondaryColor.green
                        : ""
                    }`,
                  })
                }
              >
                <path
                  fill={
                    shopingList.cropedFromNewspaper.active && cropActive
                      ? Theme.light.secondaryColor.green
                      : SvgColorStyle(isDark)
                  }
                  d={cutPath}
                ></path>
              </svg>
              <p
                style={{
                  color: `${
                    shopingList.cropedFromNewspaper.active && cropActive
                      ? Theme.light.secondaryColor.green
                      : ""
                  }`,
                }}
              >
                wytnij z gazetki
              </p>
            </div>
          )}
        </ImgWraper>
        <InfoWraper isDark={isDark}>
          <p>
            {userItems[addUserItemIndex].aditionalName && (
              <span>{userItems[addUserItemIndex].aditionalName}</span>
            )}
          </p>
          <p>
            Ilość :
            <span>
              {userItems[addUserItemIndex].howMany
                ? userItems[addUserItemIndex].howMany
                : 0}{" "}
              {userItems[addUserItemIndex].typeSelect}
            </span>
          </p>
          <p>
            Cena za {userItems[addUserItemIndex].typeSelect}{" "}
            <span>
              {userItems[addUserItemIndex].price
                ? userItems[addUserItemIndex].price
                : 0}{" "}
              PLN
            </span>
          </p>
          <p>
            W sumie :<span>{checkPrice(userItems[addUserItemIndex])} PLN</span>
          </p>
        </InfoWraper>
        {userItems[addUserItemIndex]?.brand && (
          <BrandWraper isDark={isDark}>
            <img
              loading="lazy"
              src={`${
                userItems[addUserItemIndex]?.brandData &&
                userItems[addUserItemIndex]?.brandData[0]?.imgUrl
              }`}
              alt={userItems[addUserItemIndex]?.name}
            />
            <p>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="calendar-check"
                className="svg-inline--fa fa-calendar-check fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                height={16}
                style={styleForSvg}
              >
                <path fill={SvgColorStyle(isDark)} d={startPath}></path>
              </svg>

              {userItems[addUserItemIndex]?.startDuringTime &&
                userItems[addUserItemIndex]?.startDuringTime.slice(0, 5)}
            </p>
            <p>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="calendar-times"
                className="svg-inline--fa fa-calendar-times fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                height={16}
                style={styleForSvg}
              >
                <path fill={SvgColorStyle(isDark)} d={endTimePath}></path>
              </svg>
              {userItems[addUserItemIndex]?.endDuringTime &&
                userItems[addUserItemIndex]?.endDuringTime.slice(0, 5)}
            </p>
          </BrandWraper>
        )}
      </ItemContent>
      <ActionsItem isDark={isDark}>
        <SectionSelectBrandShopingList
          isDark={isDark}
          brandFromCroped={userItems[addUserItemIndex]?.brand}
          targetRef={targetRef}
          userItems={userItems}
          addUserItemIndex={addUserItemIndex}
        />

        <div
          onClick={() => {
            deleteUserItemFromList(addUserItemIndex);
          }}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="trash-alt"
            className="svg-inline--fa fa-trash-alt fa-w-14"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            width={20}
            height={20}
            style={styleForSvg}
          >
            <path fill={SvgColorStyle(isDark)} d={trashPath}></path>
          </svg>
          <p>usuń z listy</p>
        </div>
        <div onClick={() => setMoreOptionsVisible(!moreOptionsVisible)}>
          {
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="angle-down"
              className="svg-inline--fa fa-angle-down fa-w-10"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              height={30}
              style={
                moreOptionsVisible
                  ? isDark
                    ? styleForActiveDark
                    : styleForActiveLigth
                  : !isDark
                  ? styleForDisactiveLight
                  : styleForDisactiveDark
              }
            >
              <path fill={SvgColorStyle(isDark)} d={downPath}></path>
            </svg>
          }
        </div>
      </ActionsItem>
      {moreOptionsVisible && (
        <InputItems
          isDark={isDark}
          variants={animationInputVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <input
            type="number"
            name="howMany"
            inputMode="decimal"
            placeholder="ilość"
            value={userItems[addUserItemIndex].howMany}
            step={`${
              userItems[addUserItemIndex].typeSelect === "kg" ? 0.25 : 1
            }`}
            onChange={handleOnchange}
            onKeyDown={(e) => handleKeyDown(e)}
            min="0"
            max="1000"
          />
          <input
            type="number"
            name="price"
            placeholder="cena"
            inputMode="decimal"
            step="0.01"
            value={userItems[addUserItemIndex].price}
            onChange={handleOnchange}
            onKeyDown={(e) => handleKeyDown(e)}
            min="0"
            max="10000"
          />
          <select name="typeSelect" onChange={handleOnchange}>
            <option value="kg">kg</option>
            <option value="szt">szt</option>
          </select>
          <input
            type="text"
            name="aditionalName"
            placeholder="opis"
            value={userItems[addUserItemIndex].aditionalName}
            onChange={handleOnchange}
            onKeyDown={(e) => handleKeyDown(e)}
          />
          {userItems[addUserItemIndex].err && (
            <p>{userItems[addUserItemIndex].err}</p>
          )}
        </InputItems>
      )}
    </ItemWraper>
  );
};

const _UserItem = ({
  theme,
  shopingList,
  deleteUserItemFromList,
  updateUserItemOnList,
  pushUserItemToList,
  userCanCrop,
  userCanNotCrop,
}) => {
  return (
    <>
      {shopingList.userItems.length > 0 &&
        shopingList.userItems.map((item, index) => (
          <SingleUserItem
            key={index}
            addUserItemIndex={index}
            isDark={theme.isDark}
            deleteUserItemFromList={deleteUserItemFromList}
            updateUserItemOnList={updateUserItemOnList}
            userItems={shopingList.userItems}
            userCanNotCrop={userCanNotCrop}
            userCanCrop={userCanCrop}
            shopingList={shopingList}
            item={item}
            variants={animationVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          />
        ))}
      <ItemWraper isDark={theme.isDark}>
        <UserItemContainer
          isDark={theme.isDark}
          onClick={() =>
            pushUserItemToList({
              brand: "",
              howMany: "",
              price: "",
              typeSelect: "kg",
              aditionalName: "",
              startDuringTime: new Date().toLocaleDateString(),
              err: "",
            })
          }
        >
          <p>dodaj własną pozycję</p>
          <SvgDisplay
            viewBox={plusPath?.viewBox}
            d={plusPath?.d}
            style={styleForMoreActionsSvg}
            fill={SvgColorStyle(theme.isDark)}
          />
        </UserItemContainer>
      </ItemWraper>
    </>
  );
};

const mapDisaptchToProps = (dispatch) => ({
  deleteItemFromList: (item) => dispatch(deleteItemFromList(item)),
  updateItemOnList: (item) => dispatch(updateItemOnList(item)),
  pushUserItemToList: (item) => dispatch(pushUserItemToList(item)),
  deleteUserItemFromList: (item) => dispatch(deleteUserItemFromList(item)),
  updateUserItemOnList: (item) => dispatch(updateUserItemOnList(item)),
  userCanCrop: (item) => dispatch(userCanCrop(item)),
  userCanNotCrop: (item) => dispatch(userCanNotCrop(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  shopingList: state.shopingList,
});
export const UserItem = connect(mapStateToProps, mapDisaptchToProps)(_UserItem);
