import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  WrraperSection,
  MainWrapperLayer,
  MainContent,
  SectionHeader,
  SingleBrandOrCategoryLayout,
} from "./stylesForBrandOrCategoryContainer";
import { splitAndJoin } from "../../helpers/convert/splitAndJoin";
import { Info } from "./Info";
import { SelectedImg } from "./SelectedImg";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { GetCities } from "../getCities/GetCities";

const _BrandOrCategoryContainer = ({
  theme,
  select,
  localization,
  section,
}) => {
  const { name } = useParams();
  let title = name;
  if (name.includes("gazetki")) {
    title = name.slice(7);
  }

  return (
    <>
      <WrraperSection isDark={theme.isDark}>
        <MainWrapperLayer isDark={theme.isDark}>
          <MainContent isDark={theme.isDark}>
            <ErrorBoundary>
              <SectionHeader isDark={theme.isDark}>
                <h4>
                  <span>
                    {splitAndJoin(title)}
                    {localization.shopsByCoord.length > 0 &&
                      "-najbliżej ciebie"}
                  </span>
                </h4>
                <GetCities carusel={false} />
              </SectionHeader>
              <SingleBrandOrCategoryLayout isDark={theme.isDark}>
                <Info section={section} />
                <SelectedImg />
              </SingleBrandOrCategoryLayout>
            </ErrorBoundary>
          </MainContent>
        </MainWrapperLayer>
      </WrraperSection>
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
  localization: state.localization,
});
export const BrandOrCategoryContainer = connect(
  mapStateToProps,
  {}
)(_BrandOrCategoryContainer);
