import React from "react";
import { UserPasswordReset } from "../components/passwordReset/UserPasswordReset";
import { Footer } from "../components/footerSection/Footer";
import { Helmet } from "react-helmet";
export const PasswordResetPage = () => {
  // if (typeof document === "undefined" || typeof window === "undefined") {
  //   return null;
  // }

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://cenniczek.com/logowanie/reset-hasla`}
        />
        <meta name="description" content="Resetowanie hasła - Cenniczek.com" />
        <meta
          property="og:description"
          content="resetowanie hasła - Cenniczek.com"
        />
        <meta
          name="keywords"
          content="resetowanie hasła - Cenniczek.com"
        ></meta>
        <meta property="og:title" content="Cenniczek.com-resetowanie hasła" />
        <meta
          property="og:url"
          content={`https://cenniczek.com/logowanie/reset-hasla`}
        />
        <title>Cenniczek.com-resetowanie hasła</title>
      </Helmet>
      <UserPasswordReset />
      <Footer />
    </>
  );
};
