import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  newspaperId,
  selectPageFromDeal,
  setTargetCordinates,
  selectIdFromSearch,
  chose,
  clearSelectPageFromDeal,
  clearTargetCordinates,
} from "../../../store/select/duck/actions/actions";
import { pushItemToList } from "../../../store/shopingList/duck/actions/actions";
import { deleteShopsNearUser } from "../../../store/localization/duck/actions/actions";

import {
  ItemWraper,
  ItemContent,
  ImgWraper,
  InfoWraper,
  BrandWraper,
  ActionsItem,
  styleForSvg,
  styleForActionsSvg,
} from "./styleForSearchBar";
import { API_URL } from "../../../constant/constant";

const startPath =
  "M436 160H12c-6.627 0-12-5.373-12-12v-36c0-26.51 21.49-48 48-48h48V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h128V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h48c26.51 0 48 21.49 48 48v36c0 6.627-5.373 12-12 12zM12 192h424c6.627 0 12 5.373 12 12v260c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V204c0-6.627 5.373-12 12-12zm333.296 95.947l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L194.12 364.665l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068l-28.397 28.17c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z";
const endPath =
  "M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm257.3 160l48.1-48.1c4.7-4.7 4.7-12.3 0-17l-28.3-28.3c-4.7-4.7-12.3-4.7-17 0L224 306.7l-48.1-48.1c-4.7-4.7-12.3-4.7-17 0l-28.3 28.3c-4.7 4.7-4.7 12.3 0 17l48.1 48.1-48.1 48.1c-4.7 4.7-4.7 12.3 0 17l28.3 28.3c4.7 4.7 12.3 4.7 17 0l48.1-48.1 48.1 48.1c4.7 4.7 12.3 4.7 17 0l28.3-28.3c4.7-4.7 4.7-12.3 0-17L269.3 352z";
const targetPath =
  "M500 224h-30.364C455.724 130.325 381.675 56.276 288 42.364V12c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v30.364C130.325 56.276 56.276 130.325 42.364 224H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h30.364C56.276 381.675 130.325 455.724 224 469.636V500c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-30.364C381.675 455.724 455.724 381.675 469.636 288H500c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12zM288 404.634V364c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40.634C165.826 392.232 119.783 346.243 107.366 288H148c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-40.634C119.768 165.826 165.757 119.783 224 107.366V148c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-40.634C346.174 119.768 392.217 165.757 404.634 224H364c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40.634C392.232 346.174 346.243 392.217 288 404.634zM288 256c0 17.673-14.327 32-32 32s-32-14.327-32-32c0-17.673 14.327-32 32-32s32 14.327 32 32z";
const SingleSearchResult = ({
  item,
  isDark,
  newspaperId,
  selectPageFromDeal,
  setTargetCordinates,
  pushItemToList,
  selectIdFromSearch,
  deleteShopsNearUser,
  setIsActive,
  setSearchTxt,
  chose,
  clearSelectPageFromDeal,
  closeSearchInput,
  clearTargetCordinates,
  selectedPage,
  targetCordinatesX,
}) => {
  if (item.brandId) {
    return (
      <ItemWraper isDark={isDark}>
        <ItemContent isDark={isDark}>
          <InfoWraper isDark={isDark}>
            <ActionsItem isDark={isDark}>
              <Link
                to={`/${item.name.split(" ").join("-")}`}
                onClick={() => {
                  if (selectedPage && targetCordinatesX) {
                    clearSelectPageFromDeal();
                    clearTargetCordinates();
                  }
                  chose("sklepy");
                  selectIdFromSearch(item);
                  deleteShopsNearUser();
                  setIsActive(false);
                  setSearchTxt("");
                  closeSearchInput(false);
                }}
              >
                <div>{item.name} </div>
              </Link>
            </ActionsItem>
          </InfoWraper>
          <BrandWraper isDark={isDark}>
            <img loading="lazy" src={`${item.imgUrl}`} alt={item.name} />
          </BrandWraper>
        </ItemContent>
      </ItemWraper>
    );
  } else if (!item.brandId) {
    return (
      <ItemWraper isDark={isDark}>
        <ItemContent isDark={isDark}>
          <ImgWraper>
            <img
              loading="lazy"
              src={`${API_URL}${item.imgUrl}`}
              alt={item.name}
            />
          </ImgWraper>
          <InfoWraper isDark={isDark}>
            <p>{item.name} </p>
            <ActionsItem isDark={isDark}>
              <Link
                to={`/gazetka-promocyjna/${item.brand}-${item?.alternativeFinalName}/${item.startDuringTime}-${item.endDuringTime}/${item.page}`}
                onClick={() => {
                  if (selectedPage && targetCordinatesX) {
                    clearTargetCordinates();
                    clearSelectPageFromDeal();
                  }
                  selectPageFromDeal(item.page);
                  setTargetCordinates(item.cordinates);
                  newspaperId(item.bookId);
                  closeSearchInput(false);
                  setIsActive(false);
                  setSearchTxt("");
                }}
              >
                {/* <img src={Target} style={styleForActionsSvg} /> */}
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="crosshairs"
                  className="svg-inline--fa fa-crosshairs fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  style={styleForActionsSvg}
                  height={40}
                >
                  <path fill="currentColor" d={targetPath}></path>
                </svg>
                <p>pokaż w gazetce</p>
              </Link>
            </ActionsItem>
          </InfoWraper>
          <BrandWraper isDark={isDark}>
            <img
              loading="lazy"
              src={`/static/assets/media/img/brand/${item.brand
                .split(" ")
                .join("-")}.png`}
              alt={item.brand}
            />
            <p>
              {/* <img src={StartTime} style={styleForSvg} width={16} /> */}
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="calendar-check"
                className="svg-inline--fa fa-calendar-check fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                style={styleForSvg}
                width={16}
              >
                <path fill="currentColor" d={startPath}></path>
              </svg>
              {item.startDuringTime.slice(0, item.startDuringTime.length - 5)}
            </p>
            <p>
              {/* <img src={EndTime} style={styleForSvg} width={16} /> */}
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="calendar-times"
                className="svg-inline--fa fa-calendar-times fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                style={styleForSvg}
                width={16}
              >
                <path fill="currentColor" d={endPath}></path>
              </svg>
              {item.endDuringTime.slice(0, item.endDuringTime.length - 5)}
            </p>
          </BrandWraper>
        </ItemContent>
      </ItemWraper>
    );
  }
};

const _SearchResult = ({
  data,
  theme,
  shopingList,
  newspaperId,
  selectPageFromDeal,
  setTargetCordinates,
  pushItemToList,
  selectIdFromSearch,
  deleteShopsNearUser,
  setIsActive,
  setSearchTxt,
  chose,
  clearSelectPageFromDeal,
  closeSearchInput,
  clearTargetCordinates,
  select,
}) => {
  if (data?.length) {
    return data.map((item, index) => (
      <SingleSearchResult
        key={index}
        item={item}
        isDark={theme.isDark}
        newspaperId={newspaperId}
        selectPageFromDeal={selectPageFromDeal}
        setTargetCordinates={setTargetCordinates}
        pushItemToList={pushItemToList}
        selectIdFromSearch={selectIdFromSearch}
        deleteShopsNearUser={deleteShopsNearUser}
        setIsActive={setIsActive}
        setSearchTxt={setSearchTxt}
        chose={chose}
        clearSelectPageFromDeal={clearSelectPageFromDeal}
        closeSearchInput={closeSearchInput}
        clearTargetCordinates={clearTargetCordinates}
        selectedPage={select.selectedPage}
        targetCordinatesX={select.targetCordinates.xStart}
      />
    ));
  } else {
    return null;
  }
};

const mapDisaptchToProps = (dispatch) => ({
  newspaperId: (item) => dispatch(newspaperId(item)),
  selectPageFromDeal: (item) => dispatch(selectPageFromDeal(item)),
  setTargetCordinates: (item) => dispatch(setTargetCordinates(item)),
  pushItemToList: (item) => dispatch(pushItemToList(item)),
  selectIdFromSearch: (item) => dispatch(selectIdFromSearch(item)),
  deleteShopsNearUser: (item) => dispatch(deleteShopsNearUser(item)),
  chose: (item) => dispatch(chose(item)),
  clearSelectPageFromDeal: (item) => dispatch(clearSelectPageFromDeal(item)),
  clearTargetCordinates: (item) => dispatch(clearTargetCordinates(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  shopingList: state.shopingList,
  select: state.select,
});
export const SearchResult = connect(
  mapStateToProps,
  mapDisaptchToProps
)(_SearchResult);
