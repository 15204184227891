import React from "react";
import { connect } from "react-redux";
import {
  SwitcherWraper,
  styleForSvg,
  SwitcherContent,
  ShopingWraper,
  ChartWraper,
  SwitchLine,
  SwitchLineWraper,
} from "./style";

import { SvgColorStyle } from "../../../utils/SvgColorStyle";
const shopingPath =
  "M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z";
const chartPie =
  "M527.79 288H290.5l158.03 158.03c6.04 6.04 15.98 6.53 22.19.68 38.7-36.46 65.32-85.61 73.13-140.86 1.34-9.46-6.51-17.85-16.06-17.85zm-15.83-64.8C503.72 103.74 408.26 8.28 288.8.04 279.68-.59 272 7.1 272 16.24V240h223.77c9.14 0 16.82-7.68 16.19-16.8zM224 288V50.71c0-9.55-8.39-17.4-17.84-16.06C86.99 51.49-4.1 155.6.14 280.37 4.5 408.51 114.83 513.59 243.03 511.98c50.4-.63 96.97-16.87 135.26-44.03 7.9-5.6 8.42-17.23 1.57-24.08L224 288z";
const _ShopingChartSwitcher = ({ theme, shopingList, chart, setChart }) => {
  return (
    <SwitcherWraper>
      <SwitcherContent>
        <ShopingWraper onClick={() => setChart(false)} chart={chart}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="shopping-cart"
            className="svg-inline--fa fa-shopping-cart fa-w-18"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            width={30}
            height={30}
            style={styleForSvg}
          >
            <path fill={SvgColorStyle(theme.isDark)} d={shopingPath}></path>
          </svg>
        </ShopingWraper>
        <ChartWraper onClick={() => setChart(true)} chart={chart}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="chart-pie"
            className="svg-inline--fa fa-chart-pie fa-w-17"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 544 512"
            width={30}
            height={30}
            style={styleForSvg}
          >
            <path fill={SvgColorStyle(theme.isDark)} d={chartPie}></path>
          </svg>
        </ChartWraper>
        <SwitchLineWraper>
          <SwitchLine chart={chart} isDark={theme.isDark} />
        </SwitchLineWraper>
      </SwitcherContent>
    </SwitcherWraper>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
  shopingList: state.shopingList,
});
export const ShopingChartSwitcher = connect(
  mapStateToProps,
  {}
)(_ShopingChartSwitcher);
