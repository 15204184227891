import React from "react";
import { ThanksNewsletter } from "../components/thanksNewsletterSection/ThanksNewsletter";
import { InfoSection } from "../components/infoSection/InfoSection";
import { Footer } from "../components/footerSection/Footer";
import { Helmet } from "react-helmet";

export const NewsletterConfirmationPage = () => {
  // if (typeof document === "undefined" || typeof window === "undefined") {
  //   return null;
  // }
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://cenniczek.com/newsletter/succes`}
        />
        <meta
          name="description"
          content="Potwierdzenie zapisu do newslettera - Cenniczek.com"
        />
        <meta
          property="og:description"
          content="Potwierdzenie zapisu do newslettera - Cenniczek.com"
        />
        <meta name="keywords" content="newsletter - Cenniczek.com"></meta>
        <meta property="og:title" content="Cenniczek.com-newsletter" />
        <meta
          property="og:url"
          content={`https://cenniczek.com/newsletter/succes`}
        />
        <meta name="robots" content="noindex" />
        <title>Cenniczek.com-newsletter</title>
      </Helmet>
      <ThanksNewsletter />
      <InfoSection />
      <Footer />
    </>
  );
};
