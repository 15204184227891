import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
// import { ReactComponent as Kiss } from "../../media/svg/kiss.svg";
import MainBackground from "../../media/img/main-cenniczek-background5.jpg";
import MainBackgroundMobile from "../../media/img/main-cenniczek-background5_mobile_midQ.jpg";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { API_URL } from "../../constant/constant";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const kissPath =
  "M304 308.5c0-19.2-28.8-41.5-71.5-44-3.8-.4-7.4 2.4-8.2 6.2-.9 3.8 1.1 7.7 4.7 9.2l16.9 7.2c13 5.5 20.8 13.5 20.8 21.5s-7.8 16-20.7 21.5l-17 7.2c-5.7 2.4-6 12.2 0 14.8l16.9 7.2c13 5.5 20.8 13.5 20.8 21.5s-7.8 16-20.7 21.5l-17 7.2c-3.6 1.5-5.6 5.4-4.7 9.2.8 3.6 4.1 6.2 7.8 6.2h.5c42.8-2.5 71.5-24.8 71.5-44 0-13-13.4-27.3-35.2-36 21.7-9.1 35.1-23.4 35.1-36.4zm70.5-83.5l9.5 8.5c3.8 3.3 9.3 4 13.7 1.6 4.4-2.4 6.9-7.4 6.1-12.4-4-25.2-34.2-42.1-59.8-42.1s-55.9 16.9-59.8 42.1c-.8 5 1.7 10 6.1 12.4 5.8 3.1 11.2.7 13.7-1.6l9.5-8.5c14.8-13.2 46.2-13.2 61 0zM136 208.5c0 17.7 14.3 32 32 32s32-14.3 32-32-14.3-32-32-32-32 14.3-32 32zm365.1 194c-8-20.8-31.5-31.5-53.1-25.9l-8.4 2.2-2.3-8.4c-5.9-21.4-27-36.5-49-33-25.2 4-40.6 28.6-34 52.6l22.9 82.6c1.5 5.3 7 8.5 12.4 7.1l83-21.5c24.1-6.3 37.7-31.8 28.5-55.7zM334 436.3c-26.1 12.5-55.2 19.7-86 19.7-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200c0 22.1-3.7 43.3-10.4 63.2 9 6.4 17 14.2 22.6 23.9 6.4.1 12.6 1.4 18.6 2.9 10.9-27.9 17.1-58.2 17.1-90C496 119 385 8 248 8S0 119 0 256s111 248 248 248c35.4 0 68.9-7.5 99.4-20.9-2.5-7.3 4.3 17.2-13.4-46.8z";
const WrraperSection = styled.div`
  width: 100%;
  position: relative;
  user-select: none;
  height: 60vh;
  //   background: url(${MainBackground});
  background-image: url(${typeof window !== "undefined"
    ? MainBackground
    : `${API_URL}/server-media/img/main-cenniczek-background5.jpg`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media${Theme.device.mobileL} {
    height: 100vh;
    background-image: url(${typeof window !== "undefined"
      ? MainBackgroundMobile
      : `${API_URL}/server-media/img/main-cenniczek-background5_mobile_midQ.jpg`});
  }
`;
const MainWrapperLayer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  transition: background 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  @media${Theme.device.mobileL} {
    align-items: start;
  }
`;
const MainContent = styled.div`
  position: relative;
  width: 70%;
  height: 100%;
  padding: 20px 0 20px 0;
  max-width: ${Theme.breakPoint.desktop};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  font-family: montserrat;
  line-height: 2rem;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > h4 {
    font-size: ${Theme.size.h1};
    margin: 50px;
    text-transform: uppercase;
  }

  & > div > p > ul {
    margin-left: 30px;
  }
  @media${Theme.device.mobileL} {
    height: auto;
    width: 100%;
    & > div {
      margin: 50px 0;
    }
    & > h4 {
      font-size: ${Theme.size.h1};
      margin: 10px;
    }
  }
`;
const More = styled(motion.div)`
  // width: 100%;
  // height: 100px;
  border: none;
  margin: 10px 5px;
  & > a {
    display: block;
    margin: 0 auto;
    width: 300px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid
      ${({ isDark }) => (isDark ? "black" : Theme.dark.thirdColor.white)};
    border-radius: 30px;
    transform: scale(1);
    transition: 0.1s;
    background: ${Theme.light.secondaryColor.green};
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
  }
  & > a:hover {
    background: none;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
`;
const _ThanksNewsletter = ({ theme }) => {
  if (typeof window !== "undefined") document.documentElement.scrollTop = 0;
  return (
    <>
      <WrraperSection isDark={theme.isDark}>
        <MainWrapperLayer isDark={theme.isDark}>
          <MainContent isDark={theme.isDark}>
            <ErrorBoundary>
              <h4>
                Serdecznie dziękujemy za zapisanie się do naszego newslettera!!
              </h4>
              <div>
                <motion.svg
                  initial={{ scale: 1, rotate: 0 }}
                  animate={{ scale: 1.2, rotate: -5 }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse",
                  }}
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="kiss-wink-heart"
                  className="svg-inline--fa fa-kiss-wink-heart fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 504 512"
                  width={100}
                  style={{ width: "100%" }}
                >
                  <path fill="currentColor" d={kissPath}></path>
                </motion.svg>
                <More whileTap={{ scale: 0.95 }} isDark={theme.isDark}>
                  <Link to={`/`}>Strona główna</Link>
                </More>
              </div>
            </ErrorBoundary>
          </MainContent>
        </MainWrapperLayer>
      </WrraperSection>
    </>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
});
export const ThanksNewsletter = connect(mapStateToProps, {})(_ThanksNewsletter);
