import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import styled from "styled-components";
import { Theme } from "../../../../store/theme/theme";
import { Link, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  selectId,
  clearTopDeals,
} from "../../../../store/select/duck/actions/actions";
import { deleteShopsNearUser } from "../../../../store/localization/duck/actions/actions";

import { Spinner } from "../../../tools/spiner/Spinner";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
  DotGroup,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { motion } from "framer-motion";

import {
  sliderStyle,
  aStyle,
  aActiveStyle,
  slideStyle,
  slideStyleActive,
  buttonBackStyle,
  buttonNextStyle,
  btnStyle,
  mobileSlideStyleActive,
  mobileSlideStyle,
  mobileAActiveStyle,
  mobileAStyle,
  mobileAStyleNone,
} from "./style";

const backArrowPath =
  "M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z";
const nextArrowPath =
  "M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z";
const imageSuspensePath =
  "M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z";
const caruselWraperVariants = {
  hidden: {
    opacity: 0,
    x: "-25%",
  },
  visible: (index) => ({
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      delay: index * 0.1 + 0.1,
    },
  }),
  exit: {
    opacity: 0,
  },
};
const WraperMobile = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  height: 100%;
  transition: 2s background;
  &::-webkit-scrollbar {
    height: 5px;
    // border-radius: 0 5px 5px 0;
    background-color: ${Theme.dark.primaryColor.dark};
  }
  &::-webkit-scrollbar-thumb {
    height: 4px;
    border-radius: 2px;
    border-top: 1px solid ${Theme.dark.primaryColor.dark};
    border-bottom: 1px solid ${Theme.dark.primaryColor.dark};
    background-color: ${({ tap }) =>
      tap ? Theme.light.fiveColor.white : Theme.light.thirdColor.dark};
  }
`;
const CaruselWrapper = styled.div`
  max-width: ${Theme.breakPoint.desktop}px;
  // width: 880px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  align-items: center;
  position: relative;
  user-select: none;
  @media${Theme.device.tablet} {
    width: 100%;
  }
`;
const SlideHover = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  z-index: 2;
  text-align: center;
  &:hover {
    box-shadow: inset -1px -1px 3px white;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > img {
    display: block;
    margin: 0 auto;
    width: 110px;
    height: 100px;
  }
  & > h2 {
    font-size: 14px;
    padding-top: 18px;
    font-weight: normal;
    text-transform: uppercase;
  }
  @media${Theme.device.tablet} {
    & > img {
      width: 60px;
      height: 60px;
      border-radius: 5px;
    }
    & > h2 {
      // display: none;
      padding-top: 10px;
      font-size: 11px;
    }
  }
`;

const resizeScreen = (btnVisible, setBtnVisible, percent, width, maxWidth) => {
  const getNumberVisibleSLides = (percent, width, innerWidth, maxWidth) => {
    if (innerWidth > maxWidth) {
      return (maxWidth * percent) / width;
    }
    return (innerWidth * percent) / width;
  };
  window.addEventListener("resize", () => {
    const docWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    const nrSlides = getNumberVisibleSLides(percent, width, docWidth, maxWidth);
    if (window.innerWidth > Theme.breakPoint.desktop) {
      // console.log('wieksze niz desktop')
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    } else if (window.innerWidth > Theme.breakPoint.tablet) {
      // console.log('wieksze niz tablet')
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    } else if (window.innerWidth > Theme.breakPoint.mobileL) {
      // console.log('wieksze niz mobileL')
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    } else if (window.innerWidth > Theme.breakPoint.mobileM) {
      // console.log('wieksze niz mobileM')
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    } else {
      // console.log('wieksze niz')
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    }
  });
};
const onLoadScreen = (btnVisible, setBtnVisible, percent, width, maxWidth) => {
  const getNumberVisibleSLides = (percent, width, innerWidth, maxWidth) => {
    if (innerWidth > maxWidth) {
      return (maxWidth * percent) / width;
    }
    return (innerWidth * percent) / width;
  };

  const docWidth =
    document.documentElement.clientWidth || document.body.clientWidth;
  const nrSlides = getNumberVisibleSLides(percent, width, docWidth, maxWidth);
  if (window.innerWidth > Theme.breakPoint.desktop) {
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  } else if (window.innerWidth > Theme.breakPoint.tablet) {
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  } else if (window.innerWidth > Theme.breakPoint.mobileL) {
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  } else if (window.innerWidth > Theme.breakPoint.mobileM) {
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  } else {
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  }
};
const _CaruselCategory = ({
  select,
  selectId,
  deleteShopsNearUser,
  clearTopDeals,
  urlChecked,
  data,
}) => {
  const [btnVisible, setBtnVisible] = useState({ visible: false, slidesNr: 5 });
  const [memData, setMemData] = useState([]);
  let history = useHistory();
  const imgSuspenseStyle = {
    width: "55%",
    color: `${Theme.dark.fiveColor.white}`,
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [tap, setTap] = useState(false);
  const { name } = useParams();
  const mobileWrapRef = useRef();
  const memoizedData = useMemo(() => {
    if (data?.length) {
      const copyData = [...data];
      const sorted = copyData?.sort((a, b) => a.categoryId - b.categoryId);
      return sorted;
    }
  }, [data?.length]);

  useEffect(() => {
    if (memoizedData?.length) {
      setMemData(memoizedData);
    }
  }, [memoizedData?.length]);
  useEffect(() => {
    onLoadScreen(btnVisible, setBtnVisible, 1, 220, Theme.breakPoint.desktop);
    resizeScreen(btnVisible, setBtnVisible, 1, 220, Theme.breakPoint.desktop);
  }, []);

  useEffect(() => {
    if (isMobile && name && memoizedData) {
      const foundedBrand = memoizedData.findIndex(
        (item) => item.name === `gazetki ${name.split("-").join(" ")}`
      );

      if (foundedBrand && mobileWrapRef) {
        mobileWrapRef.current.scrollLeft = 110 * foundedBrand;
      }
    }
    // if (!isMobile && name && memoizedData) {
    //   const foundedBrand = memoizedData.findIndex(
    //     (item) => item.name === name.split("-").join(" ")
    //   );

    //   if (foundedBrand && desktopWrapRef?.current) {
    //     desktopWrapRef.current.scrollLeft = 160 * foundedBrand;
    //   }
    // }
  }, [name, memoizedData?.length, isMobile]);
  useEffect(() => {
    if (tap) {
      setTimeout(() => {
        if (tap) {
          setTap(false);
        }
      }, 1000);
    }
  }, [tap]);
  useEffect(() => {
    if (window.innerWidth < Theme.breakPoint.tablet) {
      setIsMobile(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < Theme.breakPoint.tablet) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
  }, []);
  const showCategoriesMobile = useCallback(
    (data, selectId, deleteShopsNearUser, clearTopDeals, history) => {
      if (data) {
        return data.map((item, index) => (
          <motion.div
            variants={caruselWraperVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            custom={index}
            key={index}
            whileTap={{ scale: 0.95 }}
          >
            <Link
              to={`/${item.name.split(" ").join("-")}`}
              style={
                select?.options.filter((option) => option.active)[0]
                  .valueName === item.name
                  ? mobileAActiveStyle
                  : !select?.options.filter((option) => option.active)[0]
                      ?.valueName
                  ? mobileAStyleNone
                  : mobileAStyle
              }
              onClick={() => {
                selectId(item);
                deleteShopsNearUser();
                clearTopDeals();
                history.push(`/${item.name.split(" ").join("-")}`);
              }}
            >
              <div
                index={index}
                style={
                  select?.options.filter((option) => option.active)[0]
                    .valueName === item.name
                    ? mobileSlideStyleActive
                    : mobileSlideStyle
                }
              >
                <SlideHover>
                  <h2>{item?.name?.slice(7)}</h2>
                  {isLoaded ? null : (
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="far"
                      data-icon="image"
                      className="svg-inline--fa fa-image fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      style={imgSuspenseStyle}
                      width={30}
                    >
                      <path fill="currentColor" d={imageSuspensePath}></path>
                    </svg>
                  )}
                  <img
                    loading="lazy"
                    onLoad={() => setIsLoaded(true)}
                    src={item.imgUrl}
                  />
                </SlideHover>
              </div>
            </Link>
          </motion.div>
        ));
      }
    },
    [isMobile, selectId, isLoaded]
  );

  const showCategories = useCallback(
    (data, selectId, deleteShopsNearUser, clearTopDeals, history) => {
      if (data) {
        return data.map((item, index) => (
          <motion.div
            variants={caruselWraperVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            custom={index}
            key={index}
            whileTap={{ scale: 0.95 }}
          >
            <Link
              to={`/${item.name.split(" ").join("-")}`}
              style={
                select?.options.filter((option) => option.active)[0]
                  .valueName === item.name
                  ? aActiveStyle
                  : aStyle
              }
              onClick={() => {
                selectId(item);
                deleteShopsNearUser();
                clearTopDeals();
                history.push(`/${item.name.split(" ").join("-")}`);
              }}
            >
              <Slide
                index={index}
                style={
                  select?.options.filter((option) => option.active)[0]
                    .valueName === item.name
                    ? slideStyleActive
                    : slideStyle
                }
              >
                <SlideHover>
                  <h2>{item.name}</h2>
                  {isLoaded ? null : (
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="far"
                      data-icon="image"
                      className="svg-inline--fa fa-image fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      style={imgSuspenseStyle}
                      width={30}
                    >
                      <path fill="currentColor" d={imageSuspensePath}></path>
                    </svg>
                  )}
                  <Image onLoad={() => setIsLoaded(true)} src={item.imgUrl} />
                </SlideHover>
              </Slide>
            </Link>
          </motion.div>
        ));
      }
    },
    [isMobile, selectId, isLoaded]
  );

  return (
    <CaruselWrapper>
      {!data && !urlChecked && <Spinner />}
      {isMobile === false && (
        <CarouselProvider
          naturalSlideWidth={220}
          naturalSlideHeight={140}
          totalSlides={data?.length}
          visibleSlides={btnVisible.slidesNr}
          orientation={"horizontal"}
          isIntrinsicHeight={true}
          step={btnVisible.slidesNr}
          dragStep={1}
        >
          <Slider style={sliderStyle}>
            {showCategories(
              memData,
              selectId,
              deleteShopsNearUser,
              clearTopDeals,
              history
            )}
          </Slider>
          <ButtonBack
            style={btnVisible.visible ? buttonBackStyle : { display: "none" }}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-left"
              className="svg-inline--fa fa-chevron-left fa-w-10"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              style={btnStyle}
              width={25}
            >
              <path fill="currentColor" d={backArrowPath}></path>
            </svg>
          </ButtonBack>
          <ButtonNext
            style={btnVisible.visible ? buttonNextStyle : { display: "none" }}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-right"
              className="svg-inline--fa fa-chevron-right fa-w-10"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              style={btnStyle}
              width={25}
            >
              <path fill="currentColor" d={nextArrowPath}></path>
            </svg>
          </ButtonNext>
          {typeof window !== "undefined" &&
          window.innerWidth < Theme.breakPoint.desktop ? (
            <DotGroup
              className="caruselDots"
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                padding: "10px",
              }}
            >
              <div style={{ width: "10px", margin: "2px" }}></div>
            </DotGroup>
          ) : null}
        </CarouselProvider>
      )}
      {isMobile === true && (
        <>
          <WraperMobile
            ref={mobileWrapRef}
            tap={tap}
            onScroll={() => {
              if (!tap) setTap(true);
              //
            }}
          >
            {showCategoriesMobile(
              memData,
              selectId,
              deleteShopsNearUser,
              clearTopDeals,
              history
            )}
          </WraperMobile>
        </>
      )}
    </CaruselWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  selectId: (item) => dispatch(selectId(item)),
  deleteShopsNearUser: (item) => dispatch(deleteShopsNearUser(item)),
  clearTopDeals: (item) => dispatch(clearTopDeals(item)),
});
const mapStateToProps = (state) => ({
  select: state.select,
});
export const CaruselCategory = connect(
  mapStateToProps,
  mapDispatchToProps
)(_CaruselCategory);
