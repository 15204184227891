import styled from "styled-components";
import { Theme } from "../../../store/theme/theme";
import { motion } from "framer-motion";

export const SectionWrapper = styled(motion.div).attrs((props) => ({
  style: {
    background: `${
      props.isDark
        ? Theme.dark.primaryColor.dark
        : Theme.light.primaryColor.white
    }`,
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,

    transform: `${
      props.shopsPage || props.topDealsPage
        ? "translateY(0)"
        : `translateY(${
            props.bookShopsInNeibourhood || props.bookTopDealsHeight
          }px)`
    }`,
    opacity: `${props.shopsPage || props.topDealsPage ? 1 : 0}`,
    zIndex: `${props.shopsPage || props.topDealsPage ? 90 : -1}`,
  },
}))`
  transition: opacity 0.3s, transform 0.6s;
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: auto;
  right: 0;
  top: 0;
  // z-index: 99;
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 0 5px 5px 0;
    background-color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border-left: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border-right: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    background-color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  @media${Theme.device.mobileL} {
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 0 2px 2px 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      border-right: none;
      border-left: none;
    }
  }
`;
export const CloseWraper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  color: ${({ isDark }) =>
    !isDark ? Theme.light.thirdColor.dark : Theme.dark.thirdColor.white};
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  transition: 0.3s;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }
`;
