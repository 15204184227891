import React, { useState } from "react";
import { connect } from "react-redux";
import { WrraperSection, MainWrapperLayer } from "./stylesForContact";
import { BeforeValidationeContact } from "./BeforeValidationeContact";
import { AfterValidationeContact } from "./AfterValidationeContact";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
const _Contact = ({ theme }) => {
  if (typeof window !== "undefined") document.documentElement.scrollTop = 0;
  const [mainValidation, setMainValidation] = useState(false);
  const [body, setBody] = useState({ first_name: "", email: "" });
  return (
    <>
      <WrraperSection isDark={theme.isDark}>
        <MainWrapperLayer isDark={theme.isDark}>
          <ErrorBoundary>
            {!mainValidation ? (
              <BeforeValidationeContact
                isDark={theme.isDark}
                validate={setMainValidation}
                setBodyEmail={setBody}
              />
            ) : (
              <AfterValidationeContact
                isDark={theme.isDark}
                mainValidation={mainValidation}
                body={body}
                changeAfterBadResponse={setMainValidation}
              />
            )}
          </ErrorBoundary>
        </MainWrapperLayer>
      </WrraperSection>
    </>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
});
export const Contact = connect(mapStateToProps, {})(_Contact);
