import React, { useState, useEffect } from "react";
import { MainContent, MailIconStyle } from "./styleForNewsLetterSection";
import { TermsAccept } from "./TermsAccept";
import { Theme } from "../../store/theme/theme";
import { motion, AnimatePresence } from "framer-motion";
// import ReCAPTCHA from "react-google-recaptcha";

const envePath =
  "M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z";
const pVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
export const BeforeValidatione = ({ isDark, validate, setBodyEmail }) => {
  const [ReCAPTCHA, setReCAPTCHA] = useState(null);
  const recaptchaSiteKey =
    process?.env?.NODE_ENV === "development"
      ? process.env.REACT_APP_RECAPTCHA_SITE_KEY
      : "6LeJQA4bAAAAAPNPnqEEFjPt4z0u7LTJAjFNHUt0";
  const recaptchaRef = React.createRef();
  const [human, setHuman] = useState(false);
  const [inputTextValue, setInputTextValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [validError, setValidError] = useState("");
  const [nameError, setNameError] = useState("");
  const [acceptTerm, setAcceptTerms] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validName, setValidName] = useState(false);
  const _validEmail = React.useRef(validEmail);
  const _validName = React.useRef(validName);
  const [emailError, setEmailError] = useState("");
  // const regName = /^[a-zA-Z '.-]*$/i
  const regNameMultiLng =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
  const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const _setValidEmail = (data) => {
    _validEmail.current = data;
    setValidEmail(data);
  };
  const _setValidName = (data) => {
    _validName.current = data;
    setValidName(data);
  };
  const onChange = () => {
    setHuman(true);
    console.log("recaptcha resolved");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!human && recaptchaRef.current) {
      recaptchaRef.current.reset();
      // recaptchaRef.current.execute()
    }
    const nameValidationHandle = async () => {
      if (nameValue.length >= 3 && nameValue.length < 33) {
        setNameError("");
      }
      if (!nameValue) {
        setNameError("Wpisz proszę swoje Imię");
      }
      if (nameValue && nameValue.length < 3) {
        setNameError("Wpisane imie jest zbyt krótkie");
      }
      if (nameValue.length > 33) {
        setNameError("Wpisane imie jest zbyt długie");
      }
      if (!regNameMultiLng.test(nameValue)) {
        setNameError("Wpisz poprawnie swoje imie");
      }
      if (
        regNameMultiLng.test(nameValue) &&
        nameValue.length >= 3 &&
        nameValue.length <= 33
      ) {
        setNameError("");
        _setValidName(true);

        return true;
      }
    };

    const emailValidationHandle = async () => {
      if (!inputTextValue) {
        setEmailError("Wpisz proszę swój e-mail");
      }
      if (inputTextValue && !regEmail.test(inputTextValue)) {
        setEmailError("Wpisany e-mail nie jest poprawny");
      }
      if (regEmail.test(inputTextValue)) {
        setEmailError("");
        _setValidEmail(true);

        return true;
      }
    };
    if (!acceptTerm) {
      setValidError("Musisz zaakceptować nasz regulamin");
    }

    const name = await nameValidationHandle();
    const email = await emailValidationHandle();
    if (name && email && acceptTerm) {
      setBodyEmail({ first_name: nameValue, email: inputTextValue });
      validate(true);
    }
  };
  useEffect(() => {
    const loadReCAPTCHA = async () => {
      if (typeof window !== "undefined") {
        try {
          const ReCAPTCHAInstance = (await import("react-google-recaptcha"))
            .default;

          setReCAPTCHA(ReCAPTCHAInstance);
        } catch (error) {
          console.error("Error loading ReCAPTCHAInstance:", error);
        }
      }
    };

    loadReCAPTCHA();
  }, []);
  useEffect(() => {
    return () => {
      setReCAPTCHA(null);
    };
  }, []);
  return (
    <MainContent isDark={isDark} layout>
      <h4>otrzymuj najnowsze promocje na swoją pocztę</h4>
      <div>
        {/* <MailIcon style={MailIconStyle} /> */}
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="envelope"
          className="svg-inline--fa fa-envelope fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          style={MailIconStyle}
          width={70}
        >
          <path fill="currentColor" d={envePath}></path>
        </svg>
        <p>zapisz sie do newsletter aby być na bieżąco</p>
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="twoje imie"
          name="name"
          value={nameValue}
          onChange={(e) => setNameValue(e.target.value)}
        />
        <AnimatePresence>
          {nameError && (
            <motion.p
              variants={pVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              style={{
                backgroundColor: `${Theme.light.fourthColor.red}`,
                padding: "5px",
              }}
            >
              {nameError}
            </motion.p>
          )}
        </AnimatePresence>
        <input
          type="text"
          placeholder="twój adres e-mail"
          name="email"
          value={inputTextValue}
          onChange={(e) => setInputTextValue(e.target.value)}
        />
        <AnimatePresence>
          {emailError && (
            <motion.p
              variants={pVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              style={{
                backgroundColor: `${Theme.light.fourthColor.red}`,
                padding: "5px",
              }}
            >
              {emailError}
            </motion.p>
          )}
        </AnimatePresence>
        <TermsAccept
          active={acceptTerm}
          click={() => {
            setAcceptTerms(!acceptTerm);
            setValidError("");
          }}
        />
        <AnimatePresence>
          {validError && (
            <motion.p
              variants={pVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              style={{
                backgroundColor: `${Theme.light.fourthColor.red}`,
                padding: "5px",
              }}
            >
              {validError}
            </motion.p>
          )}
        </AnimatePresence>
        {ReCAPTCHA && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptchaSiteKey}
            onChange={onChange}
            size={"normal"}
            hl={"pl"}
            theme={isDark ? "dark" : "light"}
          />
        )}
        <motion.button whileTap={{ scale: 0.95 }} type="submit">
          wyślij
        </motion.button>
      </form>
    </MainContent>
  );
};
