// import cutOption from "../../../media/img/cut_option_final.jpg";
// import cutOptionDark from "../../../media/img/cut_option_dark_final.jpg";
// import analise from "../../../media/img/analise_final.jpg";
// import analiseDark from "../../../media/img/analise_final_dark.jpg";
// import analiseAll from "../../../media/img/analise_all_final.jpg";
// import analiseAllDark from "../../../media/img/analise_dark_all_final.jpg";
// import periodAnaliseDark from "../../../media/img/period_analise_dark_final.jpg";
import periodAnaliseDesktopLight from "../../../media/img/period_analise_desktop_light_final.jpg";
import periodAnaliseDesktopDark from "../../../media/img/period_analise_desktop_dark_final.jpg";
import periodAnaliseMobileLight from "../../../media/img/period_analise_mobile_light_final.jpg";
import periodAnaliseMobileDark from "../../../media/img/period_analise_mobile_dark_final.jpg";
import mainMetricsLightBig from "../../../media/img/matrics_main2_light_big_final.jpg";
import mainMetricsDarkBig from "../../../media/img/matrics_main2_dark_big_final.jpg";
import mainMetricsLigthMobile from "../../../media/img/matrics_main2_light_mobile_final.jpg";
import mainMetricsDarkMobile from "../../../media/img/matrics_main2_dark_mobile_final.jpg";
import cutOptionLigthBig from "../../../media/img/cut_option_light_big_final.jpg";
import cutOptionDarkBig from "../../../media/img/cut_option_dark_big_final.jpg";
import cutOptionLightMobile from "../../../media/img/cut_option_light_mobile_final.jpg";
import cutOptionDarkMobile from "../../../media/img/cut_option_dark_mobile_final.jpg";
import { API_URL, SERVER_URL_IMG } from "../../../constant/constant";

export const data = [
  {
    img: {
      light: {
        mobile:
          typeof window !== "undefined"
            ? periodAnaliseMobileLight
            : `${SERVER_URL_IMG}/server-media/img/period_analise_mobile_light_final.jpg`,

        desktop:
          typeof window !== "undefined"
            ? periodAnaliseDesktopLight
            : `${SERVER_URL_IMG}/server-media/img/period_analise_desktop_light_final.jpg`,
      },
      dark: {
        mobile:
          typeof window !== "undefined"
            ? periodAnaliseMobileDark
            : `${SERVER_URL_IMG}/server-media/img/period_analise_mobile_dark_final.jpg`,
        desktop:
          typeof window !== "undefined"
            ? periodAnaliseDesktopDark
            : `${SERVER_URL_IMG}/server-media/img/period_analise_desktop_dark_final.jpg`,
      },
    },
    txt: "załóż konto",
    h3: "twórz listy zakupowe",
    h4: "oszczędzaj pieniądze",
    redirect: "/rejestracja",
    position: {
      top: "62%",
      right: "",
    },
  },
  {
    img: {
      light: {
        mobile:
          typeof window !== "undefined"
            ? mainMetricsLigthMobile
            : `${SERVER_URL_IMG}/server-media/img/matrics_main2_light_mobile_final.jpg`,

        desktop:
          typeof window !== "undefined"
            ? mainMetricsLightBig
            : `${SERVER_URL_IMG}/server-media/img/matrics_main2_light_big_final.jpg`,
      },
      dark: {
        mobile:
          typeof window !== "undefined"
            ? mainMetricsDarkMobile
            : `${SERVER_URL_IMG}/server-media/img/matrics_main2_dark_mobile_final.jpg`,
        desktop:
          typeof window !== "undefined"
            ? mainMetricsDarkBig
            : `${SERVER_URL_IMG}/server-media/img/matrics_main2_dark_big_final.jpg`,
      },
    },
    txt: "planuj zakupy",
    h3: "kontroluj domowy",
    h4: "budżet",
    redirect: "/rejestracja",
    position: {
      top: "",
      right: "",
    },
  },
  {
    img: {
      light: {
        mobile:
          typeof window !== "undefined"
            ? cutOptionLightMobile
            : `${SERVER_URL_IMG}/server-media/img/cut_option_light_mobile_final.jpg`,

        desktop:
          typeof window !== "undefined"
            ? cutOptionLigthBig
            : `${SERVER_URL_IMG}/server-media/img/cut_option_light_big_final.jpg`,
      },
      dark: {
        mobile:
          typeof window !== "undefined"
            ? cutOptionDarkMobile
            : `${SERVER_URL_IMG}/server-media/img/cut_option_dark_mobile_final.jpg`,
        desktop:
          typeof window !== "undefined"
            ? cutOptionDarkBig
            : `${SERVER_URL_IMG}/server-media/img/cut_option_dark_big_final.jpg`,
      },
    },
    txt: "kliknij 1 sekunde",
    h3: "sprawdź wycinanie",
    h4: "z gazetki",
    redirect: "/rejestracja",
    position: {
      top: "",
      right: "",
    },
  },
  //   {
  //     img: {
  //       light:
  //         typeof window !== "undefined"
  //           ? analise
  //           : `${SERVER_URL_IMG}/media/img/analise_final.jpg`,
  //       dark:
  //         typeof window !== "undefined"
  //           ? analiseDark
  //           : `${SERVER_URL_IMG}/media/img/analise_final_dark.jpg`,
  //     },
  //     txt: "analizuj",
  //     h3: "swoją",
  //     h4: "liste zakupową",
  //   },
];
