import React, { useState } from "react";
import { connect } from "react-redux";
import { MainWrapper, MainWrapperLayer } from "./styleForNewsLetterSection";
import { BeforeValidatione } from "./BeforeValidatione";
import { AfterValidatione } from "./AfterValidatione";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";

const _NewsLetterSection = ({ theme }) => {
  const [mainValidation, setMainValidation] = useState(false);
  const [body, setBody] = useState({ first_name: "", email: "" });

  return (
    <>
      <MainWrapper>
        <MainWrapperLayer isDark={theme.isDark}>
          <ErrorBoundary>
            {!mainValidation ? (
              <BeforeValidatione
                isDark={theme.isDark}
                validate={setMainValidation}
                setBodyEmail={setBody}
              />
            ) : (
              <AfterValidatione
                mainValidation={mainValidation}
                body={body}
                changeAfterBadResponse={setMainValidation}
              />
            )}
          </ErrorBoundary>
        </MainWrapperLayer>
      </MainWrapper>
    </>
  );
};
const mapDispatchToProps = (state) => ({
  theme: state.theme,
});

export const NewsLetterSection = connect(
  mapDispatchToProps,
  {}
)(_NewsLetterSection);
