import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { useQuery } from "@apollo/react-hooks";
// import { getCategoriesQuery, getBrandsQuery } from "../../queries/queries";
import { Theme } from "../../store/theme/theme";
import { LogoWrapper } from "../header/logoWrapper/LogoWrapper";
import { ItemsFromData } from "./ItemsFromData";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { ErrorFromData } from "../error/ErrorFromData";

const facebookPath =
  "M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z";
const twiterPath =
  "M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z";
const instagramPath =
  "M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z";
const styleForSvg = {
  height: "25px",
  margin: "0 10px",
};
const WrraperSectionFooter = styled.div`
  position: relative;
  width: 100%;
  user-select: none;
  // height:50vh;
  @media${Theme.device.mobileL} {
    z-index: 4;
  }
`;
const MainWrapperFooterLayer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ isDark }) =>
    isDark ? Theme.light.thirdColor.dark : Theme.light.thirdColor.dark};
  transition: background 0.3s;
  display: flex;
  justify-content: space-around;
  // align-items:flex-start;
`;
const MainContentFooter = styled.div`
  position: relative;
  width: 70%;
  height: 100%;
  padding: 20px 0 20px 0;
  max-width: ${Theme.breakPoint.desktop}px;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.fiveColor.white : Theme.dark.thirdColor.white};
  font-family: montserrat;
  & > footer {
    display: flex;
    height: 80%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  & > footer > div {
    display: flex;
    width: 100%;
    height: 60%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
  }
  & > footer > div > div {
    margin: 20px;
  }
  & > footer > div > div:first-of-type {
    border-right: 0px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.fiveColor.white : Theme.dark.thirdColor.white};
  }
  & > footer > div > div > ul {
    list-style-type: none;
  }
  & > footer > div > div > ul:last-of-type {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  & > footer > div > div > ul > li {
    list-style-type: none;
  }
  & > footer > div > div > ul > li > a {
    display: block;
    padding: 10px;
    text-decoration: none;
    text-transform: uppercase;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.fiveColor.white : Theme.dark.thirdColor.white};
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.3s, color 0.3s;
  }
  & > footer > div > div > ul > li > a:hover {
    transform: scale(1.1);
    color: ${Theme.light.fourthColor.red};
  }
  @media${Theme.device.mobileM} {
    width: 100%;
    & > footer > a {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > footer > a > img {
      width: 240px !important;
    }
    & > footer > a > svg {
      max-width: 225px;
      margin: 0 auto;
    }
    & > footer > div {
      justify-content: center;
      align-items: center;
      padding: 40px;
      flex-wrap: wrap;
    }
    & > footer > div > div {
      width: 100%;
    }
    & > footer > div > div > ul > li {
      width: 100%;
    }
    & > footer > div > div > ul > li > a {
      display: block;
      width: 100%;
      height: 50px;
      padding: 10px;
      text-align: center;
      // line-height:50px;
    }
  }
`;
const CopyWrap = styled.div`
  align-text: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  & > p {
    text-transform: uppercase;
    padding: 20px 5px;
  }
  & > p > a {
    display: block;
    text-decoration: none;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.3s, color 0.3s;
  }
  & > p > a:hover {
    transform: scale(1.1);
    color: ${Theme.light.fourthColor.red};
  }
  @media${Theme.device.mobileL} {
    margin-bottom: 20px;
    & > p {
      text-align: center;
    }
  }
`;

const _Footer = ({
  theme,
  select,
  brandDataFromUrl,
  categoryDataFromUrl,
  dataFromHomePage,
  dataFromAllNewspaperPage,
  blogData,
  dataFromTopDealsPage,
}) => {
  const [brandData, setBrandData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    //data for brands
    if (brandDataFromUrl?.data?.brandByName[0].name && !brandData.length) {
      setBrandData(brandDataFromUrl?.data?.brandByName[0]?.allBrands);
    }
    if (
      categoryDataFromUrl?.data?.categoryByName[0]?.name &&
      !brandData.length
    ) {
      setBrandData(categoryDataFromUrl?.data?.categoryByName[0]?.allBrands);
    }
    if (dataFromHomePage?.data?.forHomePage[0]?.name && !brandData.length) {
      setBrandData(dataFromHomePage?.data?.forHomePage[0]?.allBrands);
    }
    if (
      dataFromAllNewspaperPage?.data?.forAllNewspaperPage[0]?.name &&
      !brandData.length
    ) {
      setBrandData(
        dataFromAllNewspaperPage?.data?.forAllNewspaperPage[0]?.allBrands
      );
    }
    if (blogData?.data?.forBlog[0] && !brandData.length) {
      setBrandData(blogData?.data?.forBlog[0]?.allBrands);
    }

    if (
      dataFromTopDealsPage?.data?.forTopDealsPage[0]?.name &&
      !brandData.length
    ) {
      setBrandData(dataFromTopDealsPage?.data?.forTopDealsPage[0]?.allBrands);
    }

    //data for categories
    if (brandDataFromUrl?.data?.brandByName[0].name && !categoryData.length) {
      setCategoryData(brandDataFromUrl?.data?.brandByName[0]?.allCategories);
    }
    if (
      categoryDataFromUrl?.data?.categoryByName[0]?.name &&
      !categoryData.length
    ) {
      setCategoryData(
        categoryDataFromUrl?.data?.categoryByName[0]?.allCategories
      );
    }
    if (dataFromHomePage?.data?.forHomePage[0]?.name && !categoryData.length) {
      setCategoryData(dataFromHomePage?.data?.forHomePage[0]?.allCategories);
    }
    if (
      dataFromAllNewspaperPage?.data?.forAllNewspaperPage[0]?.name &&
      !categoryData.length
    ) {
      setCategoryData(
        dataFromAllNewspaperPage?.data?.forAllNewspaperPage[0]?.allCategories
      );
    }
    if (blogData?.data?.forBlog[0] && !categoryData.length) {
      setCategoryData(blogData?.data?.forBlog[0]?.allCategories);
    }

    if (
      dataFromTopDealsPage?.data?.forTopDealsPage[0]?.name &&
      !categoryData.length
    ) {
      setCategoryData(
        dataFromTopDealsPage?.data?.forTopDealsPage[0]?.allCategories
      );
    }
  }, [
    brandDataFromUrl?.loading,
    categoryDataFromUrl?.loading,
    dataFromHomePage?.loading,
    dataFromAllNewspaperPage?.loading,
    blogData?.loading,
    dataFromTopDealsPage?.loading,
  ]);

  if (brandDataFromUrl?.error) {
    return (
      <ErrorFromData
        errorInfo={brandDataFromUrl.error}
        queryName={"brandDataFromUrl._Footer"}
      />
    );
  }
  if (categoryDataFromUrl?.error) {
    return (
      <ErrorFromData
        errorInfo={categoryDataFromUrl.error}
        queryName={"categoryDataFromUrl._Footer"}
      />
    );
  }
  if (dataFromAllNewspaperPage?.error) {
    return (
      <ErrorFromData
        errorInfo={dataFromAllNewspaperPage.error}
        queryName={"dataFromAllNewspaperPage._Footer"}
      />
    );
  }
  if (blogData?.error) {
    return (
      <ErrorFromData
        errorInfo={blogData.error}
        queryName={"blogData._Footer"}
      />
    );
  }
  if (dataFromTopDealsPage?.error) {
    return (
      <ErrorFromData
        errorInfo={dataFromTopDealsPage.error}
        queryName={"dataFromTopDealsPage._Footer"}
      />
    );
  }

  return (
    <>
      <WrraperSectionFooter>
        <MainWrapperFooterLayer isDark={theme.isDark}>
          <MainContentFooter isDark={theme.isDark}>
            <footer>
              <ErrorBoundary>
                <LogoWrapper isDark={theme.isDark} styled={true} />

                <div>
                  <div>
                    <ul>
                      <ItemsFromData
                        categoryData={categoryData}
                        type="kategorie"
                      />
                    </ul>
                  </div>
                  <div>
                    <ul>
                      <ItemsFromData
                        brandData={brandData}
                        length={27}
                        type="sklepy"
                      />
                    </ul>
                  </div>
                </div>

                <div>
                  <div>
                    <ul>
                      <li>
                        <Link to="/kontakt">Kontakt</Link>
                      </li>
                      <li>
                        <Link to="/regulamin">Regulamin</Link>
                      </li>
                      <li>
                        <Link to="/polityka">Polityka</Link>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/cenniczek"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {/* <FacebookIco style={styleForSvg} /> */}
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="facebook-f"
                            className="svg-inline--fa fa-facebook-f fa-w-10"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                            style={styleForSvg}
                            height={25}
                          >
                            <path fill="currentColor" d={facebookPath}></path>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/cenniczek"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {/* <TwitterIco style={styleForSvg} /> */}
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="twitter"
                            className="svg-inline--fa fa-twitter fa-w-16"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            style={styleForSvg}
                            height={25}
                          >
                            <path fill="currentColor" d={twiterPath}></path>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/cenniczek/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {/* <InstagramIco style={styleForSvg} /> */}
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="instagram"
                            className="svg-inline--fa fa-instagram fa-w-14"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            style={styleForSvg}
                            height={25}
                          >
                            <path fill="currentColor" d={instagramPath}></path>
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </ErrorBoundary>
            </footer>
            <CopyWrap isDark={theme.isDark}>
              <p> &copy; copyrights - cenniczek.com - 2024</p>
              <p>
                <a
                  href="https://prudentcode.pl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Made with love - PrudentCode.pl
                </a>
              </p>
            </CopyWrap>
          </MainContentFooter>
        </MainWrapperFooterLayer>
      </WrraperSectionFooter>
    </>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
});
export const Footer = connect(mapStateToProps, {})(_Footer);
