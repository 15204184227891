import React from "react";
import { WithStore } from "pure-react-carousel";
import styled from "styled-components";
import { Theme } from "../../store/theme/theme";

const WrapperNumber = styled.div`
  width: 100px;
  height: 30px;
  margin: 0 10px 0 10px;
  border-radius: 15px;
  text-align: center;
  line-height: 30px;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  transition: background 0.3s;
  order: unset;
  @media${Theme.device.tablet} {
    order: -1;
    margin-top: 20px;
  }
`;

class _NumberOfPagesContainer extends React.Component {
  changeSlide(number) {
    if (number) {
      this.props.carouselStore.setStoreState({ currentSlide: number - 1 });
    }
  }
  componentDidMount() {
    // console.log(
    //   {
    //     pageNr: this.props.pageNr,
    //     currentSlide: this.props.currentSlide,
    //     pageFromDeals: this.props.pageFromDeals,
    //     newspaperId: this.props.newspaperId,
    //   },
    //   "componentDidMount"
    // );
    if (this.props.pageFromDeals) {
      this.changeSlide(this.props.pageFromDeals);
    } else {
      this.props.carouselStore.setStoreState({
        currentSlide: this.props.pageNr - 1,
      });
    }
  }

  componentDidUpdate(prevProps) {
    // console.log(
    //   {
    //     pageNr: this.props.pageNr,
    //     currentSlide: this.props.currentSlide,
    //     pageFromDeals: this.props.pageFromDeals,
    //     newspaperId: this.props.newspaperId,
    //     prevProps,
    //   },
    //   "componentDidUpdate"
    // );
    if (!this.props.pageFromDeals) {
      if (this.props.currentSlide !== prevProps.currentSlide) {
        // console.log("zmiana 1");
        this.props.setPageNrFromCarusel(this.props.currentSlide + 1);
        // this.props.setPageNrFromCarusel(prevProps.currentSlide);
      }
      if (this.props.newspaperId !== prevProps.newspaperId) {
        // console.log("zmiana 2");
        this.changeSlide(this.props.pageNr);
      }
    } else if (
      this.props.pageFromDeals &&
      this.props.pageFromDeals !== this.props.currentSlide
    ) {
      // console.log("zmiana 3");
      this.changeSlide(this.props.pageFromDeals);
    }
  }

  render() {
    return (
      <WrapperNumber isDark={this.props.isDark}>
        {!this.props.singlePage && (
          <div>{`${this.props.currentSlide + 1} -
                ${this.props.currentSlide + 2} / ${
            this.props.numberPages
          }`}</div>
        )}
        {this.props.singlePage && (
          <div>{`${this.props.currentSlide + 1} / ${
            this.props.numberPages
          }`}</div>
        )}
      </WrapperNumber>
    );
  }
}

export const NumberOfPagesContainer = WithStore(
  _NumberOfPagesContainer,
  (state) => ({
    currentSlide: state.currentSlide,
  })
);
