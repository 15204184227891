import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  newspaperId,
  clearSelectPageFromDeal,
  clearTopDeals,
} from "../../store/select/duck/actions/actions";
import { Theme } from "../../store/theme/theme";
import MainBackground from "../../media/img/main-cenniczek-background5.jpg";
import MainBackgroundMobile from "../../media/img/main-cenniczek-background5_mobile_midQ.jpg";
import { Spinner } from "../tools/spiner/Spinner";
import Cenniczek from "../../media/svg/cenniczek-icon-192.svg";
import { SingleItem } from "./SingleItem";
import { SectionSort } from "./sectionSort/SectionSort";
import { convertStrToDate } from "../../helpers/convert/convertStrToDate";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { ErrorFromData } from "../error/ErrorFromData";
import { API_URL, FRONT_URL } from "../../constant/constant";

const infoPath =
  "M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72 72-32.235 72-72S135.764 0 96 0z";
const mapPath =
  "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z";

const MainWrapper = styled.div`
  width: 100%;
  // ------------------------------------------------------------------------------------------------------
  // min-height: 70vh;
  // ------------------------------------------------------------------------------------------------------
  background-image: url(${typeof window !== "undefined"
    ? MainBackground
    : `${FRONT_URL}server-media/img/main-cenniczek-background5.jpg`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media${Theme.device.mobileL} {
    background-image: url(${typeof window !== "undefined"
      ? MainBackgroundMobile
      : `${FRONT_URL}/server-media/img/main-cenniczek-background5_mobile_midQ.jpg`});
  }
`;
const MainWrapperLayer = styled.div`
  width: 100%;
  height: 100%;
  // min-height:75vh;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  transition: background 0.3s;
`;
const MainContent = styled.div`
  position: relative;
  width: 100%;
  // ------------------------------------------------------------------------------------------------------
  // min-height: 70vh;
  // ------------------------------------------------------------------------------------------------------
  height: 100%;
  max-width: ${Theme.breakPoint.desktop}px;
  margin: 0 auto;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
`;
const SortStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 60px;
  flex-wrap: wrap;
  & > h1 {
    font-size: ${Theme.size.h1};
    font-family: "Montserrat";
    padding: 20px;
    text-transform: uppercase;
    user-select: none;
  }
  & > label {
    padding: 15px;
    text-transform: uppercase;
  }
  & > label > select {
    margin: 10px;
    padding: 5px;
    text-transform: uppercase;
    border: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border-radius: 5px;
  }
`;

const MainWrapperForBooks = styled.div`
  position: relative;
  width: 100%;
  min-height: 50vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  user-select: none;
`;
const OptionsWrapper = styled.div`
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  // postion: sticky;
  // top: 0;
  width: 100%;
  margin-bottom: 30px;
  // & > div {
  //   margin: 10px;
  // }
`;
const More = styled(motion.div)`
  // width: 100%;
  // height: 100px;
  border: none;
  margin: 10px 5px;
  & > a {
    display: block;
    margin: 0 auto;
    width: 300px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid
      ${({ isDark }) => (isDark ? "black" : Theme.dark.thirdColor.white)};
    border-radius: 30px;
    transform: scale(1);
    transition: 0.1s;
    background: ${Theme.light.secondaryColor.green};
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
  }
  & > a:hover {
    background: none;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
  @media${Theme.device.mobileS} {
    & > a {
      width: 240px;
    }
  }
`;
const afterSortData = (correctData, filterSortType) => {
  if (correctData?.length) {
    // console.log(typeSelect, 'insideSortMethod')
    switch (filterSortType.name) {
      case "recomended":
        return correctData.sort((a, b) => {
          return b.recomended - a.recomended;
        });
      case "newest":
        return correctData.sort((a, b) => {
          const aEnd = convertStrToDate(a.startDuringTime);
          const bEnd = convertStrToDate(b.startDuringTime);
          return bEnd - aEnd;
        });
      case "oldest":
        return correctData.sort((a, b) => {
          const aEnd = convertStrToDate(a.startDuringTime);
          const bEnd = convertStrToDate(b.startDuringTime);
          return bEnd < aEnd ? 1 : -1;
        });

      case "alphabet1":
        return correctData.sort((a, b) => {
          const nameA = a.name;
          const nameB = b.name;
          return nameA < nameB ? -1 : 1;
        });
      case "alphabet2":
        return correctData.sort((a, b) => {
          const nameA = a.name;
          const nameB = b.name;
          return nameB > nameA ? 1 : -1;
        });
      default:
        return correctData;
    }
  }
};

// const ShowBooks = ({
//   extended,
//   select,
//   dataFromHomePage,
//   dataFromAllNewspaperPage,
//   theme,
//   noLimit,
// }) => {
//   const [correctData, setCorrectData] = useState([]);
//   const [trueData, setTrueData] = useState([]);
//   let numberBooks = 0;
//   const filterSortOption = select?.sort.filter((item) => item.active === true);
//   const filterSortType = filterSortOption[0];

//   useEffect(() => {
//     if (
//       dataFromHomePage?.data?.forHomePage[0]?.allBooks.length > 0 &&
//       correctData?.length !==
//         dataFromHomePage?.data?.forHomePage[0]?.allBooks.length
//     ) {
//       setCorrectData(
//         dataFromHomePage?.data?.forHomePage[0]?.allBooks.filter(
//           (item) => item.status === "active" || item.status === "future"
//         )
//       );
//     }

//     if (
//       dataFromAllNewspaperPage?.data?.forAllNewspaperPage[0]?.allBooks.length >
//         0 &&
//       correctData?.length !==
//         dataFromAllNewspaperPage?.data?.forAllNewspaperPage[0]?.allBooks.length
//     ) {
//       setCorrectData(
//         dataFromAllNewspaperPage?.data?.forAllNewspaperPage[0]?.allBooks.filter(
//           (item) => item.status === "active" || item.status === "future"
//         )
//       );
//     }
//   }, [dataFromHomePage?.loading, dataFromAllNewspaperPage?.loading]);

//   useEffect(() => {
//     if (correctData?.length > 0 && correctData?.length !== trueData?.length) {
//       const sortedData = afterSortData(correctData, filterSortType);
//       console.log({ sortedData, correctData });
//       setTrueData(sortedData);
//     }
//   }, [dataFromHomePage?.loading, correctData?.length, filterSortType]);

//   // console.log(
//   //   trueData,
//   //   "trueData",
//   //   correctData,
//   //   "correctData",
//   //   dataFromHomePage,
//   //   "dataFromHomePage"
//   // );
//   if (dataFromHomePage?.loading || dataFromAllNewspaperPage?.loading) {
//     return <Spinner />;
//   }
//   if (
//     dataFromHomePage?.loading === false ||
//     dataFromAllNewspaperPage?.loading === false
//   ) {
//     // -------------------------------------------------------- spinner
//     if (trueData?.length === 0) {
//       return (
//         <>
//           <h3 style={{ padding: "100px", fontFamily: "montserrat" }}>
//             {`Niestety nie mamy obecnie wybranych gazetek :(`.toUpperCase()}
//           </h3>
//         </>
//       );
//     }

//     if (noLimit) {
//       return trueData.map((item, index) => {
//         return (
//           <SingleItem
//             key={index}
//             item={item}
//             clearSelectPageFromDeal={clearSelectPageFromDeal}
//             newspaperId={newspaperId}
//             isDark={theme.isDark}
//             index={index}
//           />
//         );
//       });
//     } else {
//       return trueData.map((item, index) => {
//         numberBooks++;
//         if (numberBooks <= 10 && extended === false) {
//           return (
//             <SingleItem
//               key={index}
//               item={item}
//               clearSelectPageFromDeal={clearSelectPageFromDeal}
//               newspaperId={newspaperId}
//               isDark={theme.isDark}
//               index={index}
//             />
//           );
//         }
//         return null;
//       });
//     }
//   }
// };

const _MainView = ({
  theme,
  select,
  newspaperId,
  clearSelectPageFromDeal,
  clearTopDeals,
  selected,
  noLimit,
  dataFromHomePage,
  dataFromAllNewspaperPage,
}) => {
  // console.log(dataFromHomePage, "data from mainView");
  let extended = false;
  const urlCheck = useLocation();
  const showtitleSelectedItem = useCallback(
    (select) => {
      let value = "wybrane dla ciebie";
      if (select) {
        select.options.map((option) => {
          if (option.active === true && option.selectedItemID) {
            value = option.valueName;
          }
          return null;
        });
      }
      return value;
    },
    [select]
  );
  const isSelected = useCallback(
    () => select.options.filter((option) => option.valueName).length > 0,
    [select]
  );

  // const checkHrefForMoreBtn = () => {
  //   const hrefMore = select.options.filter((option) => option.active)[0]
  //     .valueName;
  //   if (hrefMore) {
  //     const str = hrefMore.split(" ");
  //     return str.join("-");
  //   }
  //   return "/";
  // };
  const showBooks = useCallback(
    (extended, select) => {
      let numberBooks = 0;
      const filterSortOption = select?.sort.filter(
        (item) => item.active === true
      );
      const filterSortType = filterSortOption[0];

      if (dataFromHomePage?.loading || dataFromAllNewspaperPage?.loading) {
        return <Spinner zIndexController={3} />;
      }
      if (
        dataFromHomePage?.loading === false ||
        dataFromAllNewspaperPage?.loading === false
      ) {
        // -------------------------------------------------------- spinner
        if (
          dataFromHomePage?.data?.forHomePage[0]?.allBooks.length === 0 ||
          dataFromAllNewspaperPage?.data?.forAllNewspaperPage[0]?.allBooks
            .length === 0
        ) {
          return (
            <>
              <h3 style={{ padding: "100px", fontFamily: "montserrat" }}>
                {`Niestety nie mamy obecnie wybranych gazetek :(`.toUpperCase()}
              </h3>
            </>
          );
        }
        let correctData;
        if (dataFromHomePage?.data?.forHomePage[0]?.allBooks.length > 0) {
          correctData = dataFromHomePage?.data?.forHomePage[0]?.allBooks.filter(
            (item) => item.status === "active" || item.status === "future"
          );
        }

        if (
          dataFromAllNewspaperPage?.data?.forAllNewspaperPage[0]?.allBooks
            .length > 0
        ) {
          correctData =
            dataFromAllNewspaperPage?.data?.forAllNewspaperPage[0]?.allBooks.filter(
              (item) => item.status === "active" || item.status === "future"
            );
        }

        const trueData = afterSortData(correctData, filterSortType);
        if (noLimit) {
          if (trueData?.length) {
            return trueData.map((item, index) => {
              return (
                <SingleItem
                  key={index}
                  item={item}
                  clearSelectPageFromDeal={clearSelectPageFromDeal}
                  newspaperId={newspaperId}
                  isDark={theme.isDark}
                  index={index}
                  isMobile={theme?.isMobile}
                />
              );
            });
          }
        } else {
          if (trueData?.length) {
            return trueData.map((item, index) => {
              numberBooks++;
              if (numberBooks <= 10 && extended === false) {
                return (
                  <SingleItem
                    key={index}
                    item={item}
                    clearSelectPageFromDeal={clearSelectPageFromDeal}
                    newspaperId={newspaperId}
                    isDark={theme.isDark}
                    index={index}
                    isMobile={theme?.isMobile}
                  />
                );
              }
              return null;
            });
          } else {
            return null;
          }
        }
      }
    },
    [
      extended,
      select,
      dataFromHomePage?.loading,
      dataFromAllNewspaperPage?.loading,
    ]
  );

  if (dataFromHomePage?.error && !dataFromHomePage?.error?.networkError) {
    return (
      <ErrorFromData
        errorInfo={dataFromHomePage.error}
        queryName={"dataFromHomePage._MainView"}
      />
    );
  }
  if (dataFromAllNewspaperPage?.error) {
    return (
      <ErrorFromData
        errorInfo={dataFromAllNewspaperPage.error}
        queryName={"dataFromAllNewspaperPage._MainView"}
      />
    );
  }

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={
            noLimit
              ? "https://cenniczek.com/gazetki-promocyjne"
              : "https://cenniczek.com"
          }
        />
        <meta
          name="description"
          content={
            noLimit
              ? "Szukasz gazetki promocyjnej najwiekszych sieci handlowych w twojej okolicy? Może chcesz zaplanować zakupy,zapraszamy-Cenniczek.com"
              : "Szukasz aktualnej gazetki promocyjnej twojej ulubionej sieci handlowej lub sklepu w twojej okolicy, może szukasz ich lokalizacji, godzin otwarcia, aktualne promocji."
          }
        />
        <meta
          property="og:description"
          content={
            noLimit
              ? "Szukasz aktualnej gazetki promocyjnej twojej ulubionej sieci handlowej?"
              : "Może chcesz zaplanować zakupy,zapraszamy-Cenniczek.com"
          }
        />
        <meta
          name="keywords"
          content="gazetki promocyjne,aktualne gazetki,cenniczek,aktualne promocje,gazetka,promocje,tanie zakupy,przeceny,oferty promocyjne,okazje,rabaty"
        ></meta>
        <meta
          property="og:title"
          content={
            noLimit
              ? `Aktualne gazetki promocyjne,promocje-${new Date().toLocaleDateString()}-Cenniczek.com`
              : "Cenniczek.com-zakupy stają się łatwiejsze"
          }
        />
        <meta
          property="og:url"
          content={
            noLimit ? "cenniczek.com/gazetki-promocyjne" : "cenniczek.com"
          }
        />
        {noLimit ? (
          <title>
            Aktualne gazetki promocyjne,promocje-
            {new Date().toLocaleString("pl-PL", { timeZone: "Europe/Warsaw" })}
            -Cenniczek.com
          </title>
        ) : (
          <title>Cenniczek.com-zakupy stają się łatwiejsze</title>
        )}
      </Helmet>
      <MainWrapper>
        <MainWrapperLayer isDark={theme.isDark}>
          <MainContent isDark={theme.isDark}>
            <ErrorBoundary>
              <SortStyle isDark={theme.isDark}>
                <h1>{`Aktualne gazetki promocyjne -> ${
                  select.options.filter((item) => item.active)[0]?.name
                } -> ${showtitleSelectedItem(select)}`}</h1>
                <SectionSort
                  isDark={theme.isDark}
                  isBrandActive={select.options[1].active}
                  isBrandSelected={select.options[1].selectedItemID}
                  isCategoryActive={select.options[0].active}
                />
              </SortStyle>
              <MainWrapperForBooks isDark={theme.isDark}>
                {showBooks(extended, select)}
                {/* <ShowBooks
                  extended={extended}
                  select={select}
                  dataFromHomePage={dataFromHomePage}
                  dataFromAllNewspaperPage={dataFromAllNewspaperPage}
                  theme={theme}
                  noLimit={noLimit}
                /> */}
                {dataFromHomePage?.loading === false &&
                  !isSelected &&
                  urlCheck.pathname !== "/gazetki-promocyjne" && (
                    <OptionsWrapper>
                      <More
                        whileTap={{ scale: 0.95 }}
                        isDark={theme.isDark}
                        onClick={() => clearTopDeals()}
                      >
                        <Link to={`/gazetki-promocyjne`}>więcej gazetek</Link>
                      </More>
                    </OptionsWrapper>
                  )}
                {/* {isSelected && (
                  <OptionsWrapper>
                    {select.options[1].active || select.options[2].active ? (
                      <More
                        whileTap={{ scale: 0.95 }}
                        isDark={theme.isDark}
                        onClick={() => clearTopDeals()}
                      >
                        <Link to={`${checkHrefForMoreBtn()}/promocje`}>
                          <img
                            src={Cenniczek}
                            style={{ height: "16px", marginRight: "10px" }}
                          />

                          {`promocje ${
                            select.options[1].valueName ||
                            select.options[2].valueName
                          }`}
                        </Link>
                      </More>
                    ) : null}
                    <More
                      whileTap={{ scale: 0.95 }}
                      isDark={theme.isDark}
                      onClick={() => clearTopDeals()}
                    >
                      <Link to={`${checkHrefForMoreBtn()}/sklepy`}>
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="map-marker-alt"
                          className="svg-inline--fa fa-map-marker-alt fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          style={{ height: "16px", marginRight: "10px" }}
                          height={16}
                        >
                          <path fill="currentColor" d={mapPath}></path>
                        </svg>
                        {`sklepy ${
                          select.options[0].valueName.slice(7) ||
                          select.options[1].valueName ||
                          select.options[2].valueName
                        }`}
                      </Link>
                    </More>
                    <More
                      whileTap={{ scale: 0.95 }}
                      isDark={theme.isDark}
                      onClick={() => clearTopDeals()}
                    >
                      <Link to={checkHrefForMoreBtn()}>
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="info"
                          className="svg-inline--fa fa-info fa-w-6"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 192 512"
                          style={{ height: "16px", marginRight: "10px" }}
                          height={16}
                        >
                          <path fill="currentColor" d={infoPath}></path>
                        </svg>
                        {`więcej ${
                          select.options[0].valueName ||
                          select.options[1].valueName ||
                          select.options[2].valueName
                        }`}
                      </Link>
                    </More>
                  </OptionsWrapper>
                )} */}
              </MainWrapperForBooks>
            </ErrorBoundary>
          </MainContent>
        </MainWrapperLayer>
      </MainWrapper>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  newspaperId: (item) => dispatch(newspaperId(item)),
  clearSelectPageFromDeal: (item) => dispatch(clearSelectPageFromDeal(item)),
  clearTopDeals: (item) => dispatch(clearTopDeals(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
});
export const MainView = connect(mapStateToProps, mapDispatchToProps)(_MainView);
