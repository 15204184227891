import styled from "styled-components";
import { motion } from "framer-motion";
import { Theme } from "../../../store/theme/theme";
export const styleForMoreActionsSvg = {
  height: "30px",
};
export const WraperForCLose = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 10px;
  color: ${({ isDark }) =>
    !isDark ? Theme.light.thirdColor.dark : Theme.dark.thirdColor.white};
  cursor: pointer;
`;
