import styled from "styled-components";
import { Theme } from "../../../store/theme/theme";
import bgc from "../../../media/img/newsletter-bgc.jpg";
import bgcMobile from "../../../media/img/newsletter-bgc_mobile.jpg";
import { motion } from "framer-motion";
import { API_URL } from "../../../constant/constant";
export const MainWrapper = styled.div`
  width: 100%;
  min-height: 70vh;
  // background: url(${bgc});
  background-image: url(${typeof window !== "undefined"
    ? bgc
    : `${API_URL}/media/img/newsletter-bgc_mobile.jpg`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  @media${Theme.device.mobileL} {
    // background: url(${bgcMobile});
    background-image: url(${typeof window !== "undefined"
      ? bgcMobile
      : `${API_URL}/media/img/newsletter-bgc_mobile.jpg`});
  }
`;
export const MainWrapperLayer = styled.div`
  width: 100%;
  min-height: 70vh;
  height: 100%;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.dark.sixColor.dark};
  transition: background 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const MainContent = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 80%;
  max-width: ${Theme.breakPoint.desktop}px;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
  font-family: montserrat;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 40px;
  & > h1 {
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 500;
    user-select: none;
  }
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
  & > div > p {
    margin: 15px;
    font-size: 1.5rem;
    user-select: none;
  }
  & > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  & > form > input {
    width: 60%;
    height: 60px;
    margin: 20px;
    border: none;
    border-radius: 5px;
    text-align: center;
    transition: 0.1s;
    transform: scale(1);
    background: ${({ isDark }) =>
      isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  }
  & > form > input:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
  & > form > input::placeholder {
    text-align: center;
    text-transform: uppercase;
    opacity: 1;
    transition: 0.3s;
  }
  & > form > input:focus::placeholder {
    opacity: 0;
  }
  & > form > button {
    width: 200px;
    height: 60px;
    margin: 10px;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: ${Theme.light.fourthColor.red};
    border: 1px solid black;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
    transition: 0.3s;
    transform: scale(1);
    user-select: none;
    cursor: pointer;
  }
  & > form > button:hover {
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
    transform: scale(1.1);
  }
  @media${Theme.device.mobileL} {
    width: 95%;
    & > h1 {
      font-size: 30px;
    }
    & > form > input {
      width: 80%;
    }
  }
`;
export const ThumbsUpIconStyle = {
  width: "150px",
  margin: "40px",
};
export const MailIconStyle = {
  width: "70px",
  margin: "5px",
};
