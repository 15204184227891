import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { getProductKeysCategoriesQuery } from "../../../../../../queries/queries";
import { motion } from "framer-motion";
import { Spinner } from "../../../../../tools/spiner/Spinner";
import { animationInputVariants } from "../style";
import { ErrorFromData } from "../../../../../error/ErrorFromData";

const useCategoryForModal = () => {
  return useQuery(getProductKeysCategoriesQuery, { ssr: true });
};

const SelectContentForCategory = ({
  item,
  setCategoryOptionsActive,
  setDays,
  days,
  activeItemIndexFromCarusel,
  activeIndexFromSlide,
}) => {
  const optionName = item;
  const [refToLi, setRefToLi] = useState(null);
  const litItem = useRef(null);

  if (refToLi) {
    refToLi.addEventListener("click", () => {
      setCategoryOptionsActive(false);
      if (
        days?.time?.shoping[activeItemIndexFromCarusel]?.result?.item?.list[
          activeIndexFromSlide
        ]
      ) {
        days.time.shoping[activeItemIndexFromCarusel].result.item.list[
          activeIndexFromSlide
        ].categoryKeyProductName = item.name;

        setDays({
          ...days,
          render: ++days.render,
        });
      }
    });
  }

  return (
    <motion.li
      variants={animationInputVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      ref={(current) => {
        litItem.current = current;
        setRefToLi(current);
      }}
    >
      {item.name}
    </motion.li>
  );
};

export const SelectCategory = ({
  setCategoryOptionsActive,
  setDays,
  days,
  activeItemIndexFromCarusel,
  activeIndexFromSlide,
}) => {
  let pureData = useCategoryForModal();

  useEffect(() => {
    return () => {
      pureData = "";
    };
  });
  if (pureData.error) {
    return (
      <ErrorFromData
        errorInfo={pureData.error}
        queryName={"getProductKeysCategoriesQuery.SelectCategory"}
      />
    );
  }
  if (pureData.data) {
    const data = pureData.data;
    const sortedData = data.productKeysCategory.sort((a, b) =>
      a.name < b.name ? -1 : a.name > b.name ? 1 : 0
    );

    const createSelectOptions = sortedData.map((item) => (
      <SelectContentForCategory
        key={item.id}
        item={item}
        setCategoryOptionsActive={setCategoryOptionsActive}
        setDays={setDays}
        days={days}
        activeItemIndexFromCarusel={activeItemIndexFromCarusel}
        activeIndexFromSlide={activeIndexFromSlide}
      />
    ));
    return <ul>{createSelectOptions}</ul>;
  } else return <Spinner />;
};
