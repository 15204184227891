import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { MainImg } from "./stylesForBrandOrCategoryContainer";
import { CATEGORY_NAME } from "../../constant/constant";
// import { ReactComponent as SuspenseImg } from "../../media/svg/image-regular.svg";
const suspensePath =
  "M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z";
const _SelectedImg = ({ theme, select }) => {
  const { name } = useParams();

  let url = "";
  if (CATEGORY_NAME.includes(name.split("-").join(" "))) {
    if (name === "dom-i-ogród") {
      url = "/static/assets/media/img/category/gazetki-dom-i-ogrod.png";
    } else {
      url = `/static/assets/media/img/category/gazetki-${name
        .split(" ")
        .join("-")}.png`;
    }
  } else if (name === "sylwester") {
    url = "/static/assets/media/img/category/gazetki-sylwester.png";
  } else {
    url = `/static/assets/media/img/brand/${name.split(" ").join("-")}.png`;
  }
  const styleForImage = {
    height: "150px",
  };
  const [imgLoaded, setImgLoaded] = useState(false);

  return (
    <>
      <MainImg>
        {!imgLoaded && typeof window !== "undefined" && (
          // <SuspenseImg style={styleForImage} />
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="image"
            className="svg-inline--fa fa-image fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={styleForImage}
            height={150}
          >
            <path fill="currentColor" d={suspensePath}></path>
          </svg>
        )}
        <img
          loading="lazy"
          src={url}
          onLoad={() => setImgLoaded(true)}
          alt={`aktualne informacje o ${name}`}
        />
      </MainImg>
    </>
  );
};

const mapStateToProps = (state) => ({
  select: state.select,
  theme: state.theme,
});
export const SelectedImg = connect(mapStateToProps, {})(_SelectedImg);
