import React, { useState, useEffect } from "react";
import {
  MainContent,
  ThumbsUpIconStyle,
  HeadLine,
} from "./styleForUserRegisterSection";
import { Spinner } from "../../components/tools/spiner/Spinner";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { API_URL } from "../../constant/constant";
import { Theme } from "../../store/theme/theme";
import {
  encryptFrontData,
  decryptFrontData,
} from "../../utils/Crypto/cryptoFront.controller";
import { ErrorFromData } from "../error/ErrorFromData";

// const thumbPath =
//   "M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z";
const thumbPath =
  "M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z";
const sadFacePath =
  "M0 256C0 397.4 114.6 512 256 512s256-114.6 256-256S397.4 0 256 0S0 114.6 0 256zm240 80c0-8.8 7.2-16 16-16c45 0 85.6 20.5 115.7 53.1c6 6.5 5.6 16.6-.9 22.6s-16.6 5.6-22.6-.9c-25-27.1-57.4-42.9-92.3-42.9c-8.8 0-16-7.2-16-16zm-80 80c-26.5 0-48-21-48-47c0-20 28.6-60.4 41.6-77.7c3.2-4.4 9.6-4.4 12.8 0C179.6 308.6 208 349 208 369c0 26-21.5 47-48 47zM303.6 208c0-17.7 14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32s-32-14.3-32-32zm-128 32c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z";
const divVariants = {
  hidden: {
    opacity: 0,
    scale: 1.2,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};

const SvgWraper = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
  }
  & > div > p {
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%) !important;
    font-size: 22px;
  }
`;
const Response = ({ error }) => {
  const [seconds, setSeconds] = useState(20);
  useEffect(() => {
    setInterval(() => {
      setSeconds((state) => state - 1);
    }, 1000);
  }, []);
  const pathDefinition = "M25,50a25,25 0 1,0 50,0a25,25 0 1,0 -50,0";
  return (
    <>
      {!error ? (
        <>
          <h4>Dziękujemy za zaufanie</h4>
          <motion.div variants={divVariants} initial="hidden" animate="visible">
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              style={ThumbsUpIconStyle}
              width={150}
            >
              <path fill="currentColor" d={thumbPath}></path>
            </svg>
          </motion.div>
          <p>
            Postaramy się nie zawieść Twojego zaufania,jeszcze tylko jeden krok
          </p>
          <p>wysłaliśmy ci wiadomość potwierdzającą twojego e-maila, </p>
          <h5>prosimy otwórz e-maila i kliknij link w celu aktywacji konta</h5>
        </>
      ) : (
        <>
          <motion.div variants={divVariants} initial="hidden" animate="visible">
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              style={ThumbsUpIconStyle}
              width={150}
            >
              <path fill="currentColor" d={sadFacePath}></path>
            </svg>
          </motion.div>
          <h4>{error}</h4>
        </>
      )}
      <SvgWraper>
        <motion.svg
          aria-hidden="true"
          // className="svgItem"
          className="svgItemForModal"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="19 19 62 62"
        >
          <motion.path
            // fill="none"
            fill={Theme.light.secondaryColor.green}
            d={pathDefinition}
            stroke={Theme.light.primaryColor.white}
            stroke-width="20"
            initial={{ pathLength: 0, pathOffset: 1 }}
            animate={{ pathLength: 1, pathOffset: 0 }}
            transition={{ duration: 21 }}
          ></motion.path>
        </motion.svg>

        <div>
          <AnimatePresence>
            <motion.p
              variants={divVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              {seconds}
            </motion.p>
          </AnimatePresence>
        </div>
      </SvgWraper>
      <p>ta wiadomość zostanie zaraz wyczyszczona</p>
    </>
  );
};

export const AfterValidatione = ({
  isDark,
  mainValidation,
  body,
  changeAfterBadResponse,
}) => {
  const [responseError, setResponseError] = useState(false);
  const [getCorrectResponse, setCorrectResponse] = useState(false);
  const [errorFromCrypto, setErrorFromCrypto] = useState("");

  const sendConfirmationEmail = async ({ email, first_name, password }) => {
    // const data = {
    //   first_name,
    //   email,
    //   password,
    // };
    const frontDataToEncrypt = {
      first_name,
      email,
      password,
    };
    // encrypt data for fetch
    const ciphertext = encryptFrontData(
      frontDataToEncrypt,
      setErrorFromCrypto,
      null,
      "AfterValidatione.js-userRegister"
    );
    fetch(`${API_URL}/api/user/registration`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({
        data: ciphertext,
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((result) => {
        // decrypt data from response
        return decryptFrontData(
          result.data,
          setErrorFromCrypto,
          null,
          "AfterValidatione.js-userRegister"
        );
      })
      .then((data) => {
        if (data?.userRegister === true) {
          setCorrectResponse(true);
          setTimeout(() => changeAfterBadResponse(false), 20000);
        } else if (data?.userRegister === false && data?.error) {
          setResponseError(`${data?.error}`);
          setTimeout(() => changeAfterBadResponse(false), 20000);
        } else {
          setResponseError(
            "Przepraszamy coś poszło nie tak :( ,spróbuj ponownie."
          );
          setTimeout(() => changeAfterBadResponse(false), 20000);
        }
      });
  };
  useEffect(() => {
    sendConfirmationEmail(body);
  }, []);

  if (errorFromCrypto) {
    return (
      <ErrorFromData
        errorInfo={errorFromCrypto}
        queryName={"AfterValidatione.js-userRegister"}
      />
    );
  }

  return (
    <MainContent isDark={isDark}>
      {!getCorrectResponse && !responseError && <Spinner />}
      {getCorrectResponse && (
        <HeadLine isDark={isDark}>
          <Response />
        </HeadLine>
      )}
      {responseError && (
        <HeadLine isDark={isDark}>
          <Response error={responseError} />
        </HeadLine>
      )}
    </MainContent>
  );
};
