import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { TopDeals } from "../components/topDealsSection/TopDeals";
import { NewsLetterSection } from "../components/newsLetterSection/NewsLetterSection";
import { Footer } from "../components/footerSection/Footer";
import styled from "styled-components";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { ErrorFromData } from "../components/error/ErrorFromData";
// import { useQuery } from "@apollo/react-hooks";
import { useQuery } from "@apollo/client";
import {
  getDataForTopDealsQuery,
  getDealsForTopDealsQuery,
} from "../queries/queries";
import { selectId, chose } from "../store/select/duck/actions/actions";

import { checkOffset } from "../helpers/scrollObserver/scrollObserver";
import { PageReloaded } from "../utils/PageReloaded";
const DealWraper = styled.div`
  position: relative;
`;

const _TopDealsPage = ({ select, chose, theme }) => {
  const reload = PageReloaded();
  const wraperDealRef = useRef();
  let history = useHistory();
  const numberOfTopDeals = theme?.isMobile ? 15 : 35;
  const [numberOfDeals, setNumberOfDeals] = useState(0);
  const [dealsNumber, setDealsNumber] = useState({
    offset: 0,
    limit: numberOfTopDeals,
  });
  const [observableActive, setObservableActive] = useState(false);
  const obrervabelElementRef = useRef();

  const variableForSortDeals =
    select?.sortDeals.filter((item) => item.active)[0]?.name || "recomended";

  const dataFromTopDealsPage = useQuery(getDataForTopDealsQuery, {
    variables: {
      name: "gazetki markety",
    },
    ssr: true,
  });
  const dealsForTopDeals = useQuery(getDealsForTopDealsQuery, {
    fetchPolicy: "network-only",
    variables: {
      sortType: variableForSortDeals,
      offset: dealsNumber.offset,
      limit: dealsNumber.limit,
      category: select?.topDealsSelected?.name,
    },
    ssr: true,
  });
  // console.log(
  //   dataFromTopDealsPage,
  //   "dataFromTopDealsPage",
  //   dealsForTopDeals,
  //   "dealsForTopDeals"
  // );
  // console.log(observableActive, "observableActive", dealsNumber);

  const handleScroll = () => {
    let scroll = window && window.scrollY;
    const activeObservableElem = checkOffset(obrervabelElementRef, scroll, 600);
    if (activeObservableElem) {
      setObservableActive(true);
    } else {
      setObservableActive(false);
    }
  };

  useEffect(() => {
    if (dataFromTopDealsPage?.client) {
      dataFromTopDealsPage.client.resetStore();
    }
    if (dealsForTopDeals.client) {
      dealsForTopDeals.client.resetStore();
    }
  }, []);
  useEffect(() => {
    // if (!select?.topDealsSelected?.name) {
    // console.log("czyszcze");
    setNumberOfDeals(0);
    setDealsNumber({
      offset: 0,
      limit: numberOfTopDeals,
    });
    // }
  }, [select?.topDealsSelected?.name]);

  useEffect(() => {
    handleScroll();
    if (
      observableActive === true &&
      !select?.topDealsSelected?.name &&
      dealsForTopDeals.loading === false &&
      dealsNumber?.offset <= numberOfDeals
    ) {
      // console.log("refetchuje", observableActive);
      setDealsNumber({
        offset: numberOfDeals,
        limit: numberOfDeals + numberOfTopDeals,
      });
    }
  }, [
    observableActive,
    dealsForTopDeals?.data?.dealsTopDealsPage?.length,
    select?.topDealsSelected?.name,
    variableForSortDeals,
    dealsForTopDeals.loading,
    numberOfDeals,
  ]);

  useEffect(() => {
    chose({});
  }, []);
  useEffect(() => {
    if (wraperDealRef && wraperDealRef.current) {
      wraperDealRef.current.scrollIntoView();
    }
  }, []);
  useEffect(() => {
    history.listen((location, action) => {
      if (action === "POP") {
        history.push("/");
        chose("kategorie");
      }
    });
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  if (dataFromTopDealsPage?.error) {
    return (
      <ErrorFromData
        errorInfo={dataFromTopDealsPage.error}
        queryName={"dataFromTopDealsPage.TopDealsPage"}
      />
    );
  }
  if (dealsForTopDeals?.error) {
    return (
      <ErrorFromData
        errorInfo={dealsForTopDeals.error}
        queryName={"dealsForTopDeals.TopDealsPage"}
      />
    );
  }

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://cenniczek.com/promocje" />
        <meta
          name="description"
          content="Aktualne promocje z gazetek promocyjnych twoich ulubionych sieci handlowych w jednym miejscu &#9757; Cenniczek.com"
        />
        <meta
          property="og:description"
          content="Aktualne promocje z gazetek promocyjnych twoich ulubionych sieci handlowych w jednym miejscu &#9757; Cenniczek.com"
        />
        <meta
          name="keywords"
          content="aktualne promocje,promocja,cenniczek"
        ></meta>
        <meta
          property="og:title"
          content={`Aktualne promocje z gazetek promocyjnych sieci handlowych-Cenniczek.com ${new Date()
            .toLocaleDateString("pl-PL", {
              timeZone: "Europe/Warsaw",
            })
            .slice(0, 9)}`}
        />
        <meta property="og:url" content="https://cenniczek.com/promocje" />
        <meta property="robots" content="index, follow" />
        <title>
          {`Aktualne promocje z gazetek promocyjnych sieci
            handlowych-Cenniczek.com ${new Date()
              .toLocaleDateString("pl-PL", {
                timeZone: "Europe/Warsaw",
              })
              .slice(0, 9)}`}
        </title>
      </Helmet>
      <DealWraper ref={wraperDealRef}>
        <TopDeals
          dataFromTopDealsPage={dataFromTopDealsPage}
          dealsForTopDeals={dealsForTopDeals}
          setNumberOfDeals={setNumberOfDeals}
          setDealsNumber={setDealsNumber}
          dealsType="TOP_DEALS_PAGE"
        />
        <div ref={obrervabelElementRef}></div>
        <NewsLetterSection />
        {/* <Blog options={4} /> */}
        <Footer dataFromTopDealsPage={dataFromTopDealsPage} />
      </DealWraper>
    </>
  );
};
const mapStateToProps = (state) => ({
  select: state.select,
  theme: state.theme,
});
const mapDispatchToProps = (dispatch) => ({
  selectId: (item) => dispatch(selectId(item)),
  chose: (item) => dispatch(chose(item)),
});
export const TopDealsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(_TopDealsPage);
