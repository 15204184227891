import React from "react";
import { connect } from "react-redux";
import {
  WrraperUserSection,
  MainWrapperLayer,
  UserSectionHeader,
  UserMainContent,
  UserLoginItemWraper,
} from "./style";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { LoginOptions } from "../shopingList/contentList/loginOptions/LoginOptions";
import { Metrics } from "./metrics/Metrics";
import { UserEmail } from "./userEmail/UserEmail";
import { AnimatePresence } from "framer-motion";
import { PromoSection } from "../promoSection/PromoSection";
const pVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
const _UserSection = ({ theme, shopingList }) => {
  // useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  // }, []);

  return (
    <>
      <WrraperUserSection isDark={theme.isDark}>
        <MainWrapperLayer isDark={theme.isDark}>
          <ErrorBoundary>
            <UserMainContent isDark={theme.isDark}>
              {shopingList.userLoged ? (
                <>
                  {
                    <AnimatePresence>
                      {!shopingList.email && (
                        <UserEmail
                          isDark={theme.isDark}
                          variants={pVariants}
                          initial="hidden"
                          animate="visible"
                          exit="exit"
                        />
                      )}
                    </AnimatePresence>
                  }
                  <UserSectionHeader isDark={theme.isDark}>
                    <h1>
                      <span>Metryki</span>
                    </h1>
                  </UserSectionHeader>
                  <Metrics
                    isDark={theme.isDark}
                    // user={shopingList.userAcceptRodo.token}
                  />
                </>
              ) : (
                <>
                  <UserSectionHeader isDark={theme.isDark}>
                    <h1>
                      <span>
                        Lubimy się i niech tak pozostanie, ale to sekcja
                        wyłacznie dla zalogowanych użytkowników
                      </span>
                    </h1>
                  </UserSectionHeader>
                  <UserLoginItemWraper isDark={theme.isDark}>
                    <LoginOptions isDark={theme.isDark} />
                  </UserLoginItemWraper>
                </>
              )}
              {!shopingList?.userLoged && <PromoSection metrics={true} />}
            </UserMainContent>
          </ErrorBoundary>
        </MainWrapperLayer>
      </WrraperUserSection>
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  shopingList: state.shopingList,
});
export const UserSection = connect(mapStateToProps, {})(_UserSection);
