import { getUserLocalization } from "../actions/actions";

export const getLocation = () => async (dispatch) => {
  if (navigator) {
    navigator.geolocation.getCurrentPosition((position) => {
      if (position) {
        dispatch(getUserLocalization(position));
      }
    });
  }
};
