import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { ErrorFromData } from "../../error/ErrorFromData";
import { API_URL, FRONT_URL } from "../../../constant/constant";
import {
  encryptFrontData,
  decryptFrontData,
} from "../../../utils/Crypto/cryptoFront.controller";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { cenniczekPath } from "../cenniczekPath";
import { pushUserItemToList } from "../../../store/shopingList/duck/actions/actions";
import { trigerVisibleList } from "../../../store/shopingList/duck/actions/actions";
import {
  clearSelectPageFromDeal,
  clearTargetCordinates,
} from "../../../store/select/duck/actions/actions";
// import alertify from "alertifyjs";
// import "alertifyjs/build/css/alertify.css";
// import "alertifyjs/build/css/themes/default.css";
import { ErrorBoundary } from "../../errorBoundary/ErrorBoundary";
import { ErrorHandler } from "../../error/ErrorHandler";
import { motion } from "framer-motion";
import { Image } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Theme } from "../../../store/theme/theme";
import { Spinner } from "../../tools/spiner/Spinner";
import { ImageWrapper } from "./ImageWrap.style";
import { Item } from "../item/Item";

const calculateDelta = (
  { touchCordinates, setTouchCordinates },
  {
    longPress,
    setLongPress,
    longPressDetected,
    setLongPressDetected,
    select,
    clearSelectPageFromDeal,
    clearTargetCordinates,
  },
  { eventStart, eventEnd },
  msLongPress
) => {
  const acceptedDelta = 15;
  const endEventTimer = +new Date();
  const touchXEnd =
    eventEnd?.changedTouches && eventEnd?.changedTouches[0]?.pageX;
  const touchYEnd =
    eventEnd?.changedTouches && eventEnd?.changedTouches[0]?.pageY;
  const mouseXEnd = eventEnd?.pageX;
  const mouseYEnd = eventEnd?.pageY;

  const touchXStart = eventStart?.touches && eventStart?.touches[0]?.pageX;
  const touchYStart = eventStart?.touches && eventStart?.touches[0]?.pageY;
  const mouseXStart = eventStart?.pageX;
  const mouseYStart = eventStart?.pageY;
  const clearTouchCordinatesState = () =>
    setTouchCordinates({
      touchStartTime: null,
      touchXStart: null,
      touchYStart: null,
      mouseStartTime: null,
      mouseXStart: null,
      mouseYStart: null,
    });

  if (touchXStart && touchYStart && !touchXEnd && !touchYEnd) {
    console.log("finger touchLong occurs during user press");
    if (!longPressDetected) {
      setLongPressDetected(true);
      clearTouchCordinatesState();
    }
    return;
  }
  if (mouseXStart && mouseYStart && !mouseXEnd && !mouseYEnd) {
    console.log("mouse longPress occurs during user press");
    if (!longPressDetected) {
      setLongPressDetected(true);
      clearTouchCordinatesState();
    }
    return;
  }

  if (
    touchCordinates?.touchXStart &&
    touchCordinates?.touchYStart &&
    touchXEnd &&
    touchYEnd
  ) {
    if (
      !longPressDetected &&
      endEventTimer - touchCordinates?.touchStartTime >= msLongPress
    ) {
      const deltaX = Math.abs(touchCordinates?.touchXStart - touchXEnd);
      const deltaY = Math.abs(touchCordinates?.touchYStart - touchYEnd);
      if (deltaX <= acceptedDelta && deltaY <= acceptedDelta) {
        console.log("finger touchLong occurs after finger out");
        setLongPressDetected(true);
        clearTouchCordinatesState();
      } else {
        console.log("touch event swapp detected");
        clearTouchCordinatesState();
        if (select?.targetCordinates?.xStart && select?.selectedPage) {
          clearSelectPageFromDeal();
          clearTargetCordinates();
        }
      }
    } else if (
      !longPressDetected &&
      endEventTimer - touchCordinates?.touchStartTime < msLongPress
    ) {
      console.log("finger event to short ");
      clearTouchCordinatesState();
    }
    return;
  }
  if (
    touchCordinates?.mouseXStart &&
    touchCordinates?.mouseYStart &&
    mouseXEnd &&
    mouseYEnd
  ) {
    if (
      !longPressDetected &&
      endEventTimer - touchCordinates?.mouseStartTime >= msLongPress
    ) {
      const deltaX = Math.abs(touchCordinates?.mouseXStart - mouseXEnd);
      const deltaY = Math.abs(touchCordinates?.mouseYStart - mouseYEnd);
      if (deltaX <= acceptedDelta && deltaY <= acceptedDelta) {
        console.log("mouse longPress occurs after key mouse out");
        setLongPressDetected(true);
        clearTouchCordinatesState();
      } else {
        console.log("mouse event swapp detected");
        clearTouchCordinatesState();
        if (select?.targetCordinates?.xStart && select?.selectedPage) {
          clearSelectPageFromDeal();
          clearTargetCordinates();
        }
      }
    } else if (
      !longPressDetected &&
      endEventTimer - touchCordinates?.mouseStartTime < msLongPress
    ) {
      console.log("mouse press to short");
      clearTouchCordinatesState();
    }
    return;
  }
};
const _ImageWrap = ({
  itemUrl,
  name,
  duringTime,
  targetCord,
  targetPage,
  index,
  productsItems,
  pushItemToList,
  cropedActive,
  userCanCrop,
  userCanNotCrop,
  token,
  updateUserItemOnList,
  indexOfUserList,
  userItems,
  isMobileL,
  pageNrFromCarusel,
  setImgDim,
  shopingList,
  theme,
  pushUserItemToList,
  trigerVisibleList,
  select,
  clearSelectPageFromDeal,
  clearTargetCordinates,
}) => {
  const msLongPress = 800;

  const [isLoaded, setIsLoaded] = useState(false);
  const [imgDimensions, setImgDimensions] = useState({});
  const [canICrop, setCanICrop] = useState(false);
  const [imgInVisible, setImgInVisible] = useState("");
  const [longPress, setLongPress] = useState(false);
  const [longPressDetected, setLongPressDetected] = useState(false);
  const pureImgRef = useRef();
  const imgRef = useRef();
  // const imageRef = useRef(null);
  const [upImg, setUpImg] = useState();
  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  const [cutProcessing, setCutProcessing] = useState(false);
  const [alertify, setAlertify] = useState(null);

  const pageNr = useParams();
  const previewCanvasRef = useRef(null);
  const [cropTarget, setCropTarget] = useState({ x: 0, y: 0 });
  const [crop, setCrop] = useState({
    unit: "px",
    width: theme.isMobile ? 80 : 100,
    height: theme.isMobile ? 80 : 100,
    x: cropTarget.x,
    y: cropTarget.y,
    // aspect: 9 / 9,
  });
  const [touchCordinates, setTouchCordinates] = useState({
    touchStartTime: null,
    touchXStart: null,
    touchYStart: null,
    mouseStartTime: null,
    mouseXStart: null,
    mouseYStart: null,
  });

  const [completedCrop, setCompletedCrop] = useState(null);

  const getDimensions = (ref) => {
    if (
      ref.current?.instance?.image?.height &&
      ref?.current?.instance?.image?.width
    ) {
      setImgDimensions({
        height: ref.current.instance.image.height,
        width: ref.current.instance.image.width,
      });
      setImgDim({
        height: ref.current.instance.image.height,
        width: ref.current.instance.image.width,
      });
    }
  };
  // useEffect(() => {
  //   if (shopingList.cropedFromNewspaper.active) {
  //     userCanNotCrop();
  //   }
  // }, [pageNr]);
  useEffect(() => {
    setCrop({ ...crop, x: cropTarget.x, y: cropTarget.y });
  }, [cropTarget.x, cropTarget.y]);
  useEffect(() => {
    return () => {
      setAlertify(null);
      setTouchCordinates({
        touchStartTime: null,
        touchXStart: null,
        touchYStart: null,
        mouseStartTime: null,
        mouseXStart: null,
        mouseYStart: null,
      });
      setLongPressDetected(false);
      setLongPress(false);
    };
  }, []);
  useEffect(() => {
    const loadAlertify = async () => {
      if (typeof window !== "undefined") {
        try {
          const alertifyInstance = (
            await import("../../../media/assets/alertify/alertify.js")
          ).default;
          await import("../../../media/assets/alertify/alertify.min.css");
          await import("../../../media/assets/alertify/default.min.css");
          setAlertify(alertifyInstance);
        } catch (error) {
          console.error("Error loading Alertify:", error);
        }
      }
    };

    loadAlertify();
  }, []);
  useEffect(() => {
    if (longPressDetected && !shopingList.userLoged) {
      alertify.set("notifier", "position", "bottom-left");
      alertify.error(
        `Aby skorzystać z funkcji wycinania z gazetki
           musisz być zalogowany`
      );
      return;
    }
    if (
      longPressDetected &&
      shopingList?.cropedFromNewspaper?.active === true &&
      shopingList?.userLoged
    ) {
      return;
    }
    if (
      longPressDetected &&
      shopingList?.cropedFromNewspaper?.active === false &&
      shopingList?.userLoged
    ) {
      userCanCrop(shopingList?.userItems?.length);
      setCanICrop(true);
    }
  }, [longPressDetected, shopingList?.cropedFromNewspaper?.active]);

  useEffect(() => {
    if (
      parseInt(pageNrFromCarusel) - parseInt(index) <= 2 &&
      parseInt(pageNrFromCarusel) - parseInt(index) >= -2
    ) {
      setImgInVisible(`${API_URL}${itemUrl}`);
    }
  }, [
    pageNrFromCarusel,
    index,
    targetPage,
    name,
    duringTime,
    indexOfUserList,
    itemUrl,
  ]);
  useEffect(() => {
    setUpImg(`${API_URL}${itemUrl}`);

    return () => {
      setUpImg(null);
    };
  }, [canICrop]);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);
  function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }

    const cropJpg = canvas.toDataURL("image/jpg", 0.8);

    const frontDataToEncrypt = {
      name: name,
      time: duringTime,
      page: index + 1,
      img: cropJpg,
      token,
      crop,
    };

    // encrypt data for fetch
    const ciphertext = encryptFrontData(
      frontDataToEncrypt,
      setErrorFromCrypto,
      null,
      "NewsPaper.js"
    );

    fetch(`${API_URL}/api/cropFromNewspaper`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: ciphertext,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        // decrypt data from response
        return decryptFrontData(
          result.data,
          setErrorFromCrypto,
          null,
          "NewsPaper.js"
        );
      })
      .then((data) => {
        if (data) {
          setCutProcessing(false);
          if (data.error) {
            alertify.set("notifier", "position", "bottom-left");
            alertify.error(`Przepraszamy coś poszło nie po naszej
                              myśli,spróbuj przeładować stronę`);
            throw new Error(data.data);
          } else {
            alertify.set("notifier", "position", "bottom-left");
            alertify.success(`Oferta wycięta pomyślnie, uzupełnij liste`);

            if (!indexOfUserList) {
              pushUserItemToList({
                brand: "",
                howMany: "",
                price: "",
                typeSelect: "kg",
                aditionalName: "",
                startDuringTime: new Date().toLocaleDateString(),
                err: "",
              });
            }
            updateUserItemOnList({
              index: indexOfUserList || shopingList?.userItems?.length,
              value: {
                ...userItems[indexOfUserList || shopingList?.userItems?.length],
                brand: data.data.brand,
                imgUrl: data.data.imgUrl,
                startDuringTime: data.data.duringTime.slice(0, 10),
                endDuringTime: data.data.duringTime.slice(11),
                page: data.data.page,
                brandData: [{ imgUrl: data.data.brandImg }],
              },
            });
            userCanNotCrop();
            if (!shopingList?.isListVisible) {
              trigerVisibleList();
            }
          }
        }
      });
  }
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    image.setAttribute("crossorigin", "anonymous");
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);
  const mapingProductsPage =
    productsItems.length > 0
      ? productsItems.map((item) => {
          if (
            targetCord.xStart === item.cordinates.xStart &&
            targetCord.yStart === item.cordinates.yStart
          ) {
            return (
              <Item
                item={item}
                key={item.id}
                target={true}
                imgDimensions={imgDimensions}
                targetCord={targetCord}
                pushItemToList={pushItemToList}
              />
            );
          }
          return (
            <Item
              item={item}
              key={item.id}
              target={false}
              imgDimensions={imgDimensions}
              targetCord={targetCord}
              pushItemToList={pushItemToList}
            />
          );
        })
      : null;

  return (
    <>
      <ErrorBoundary>
        {errorFromCrypto && <ErrorHandler errorMsg={errorFromCrypto} />}
        <ImageWrapper
          onTouchStart={(e) => {
            setLongPress(true);

            const top = window.scrollY + e.target.getBoundingClientRect().top;
            const left = window.scrollX + e.target.getBoundingClientRect().left;
            const xcoord = e.touches ? e.touches[0].pageX : e.pageX;
            const ycoord = e.touches ? e.touches[0].pageY : e.pageY;
            const x = xcoord - left - crop.width / 2;
            const y = ycoord - top - crop.height / 2;
            if (!canICrop) {
              setCropTarget({ x, y });
            }
            setTouchCordinates({
              mouseXStart: null,
              mouseYStart: null,
              mouseStartTime: null,
              touchStartTime: +new Date(),
              touchXStart: e?.touches[0]?.pageX,
              touchYStart: e?.touches[0]?.pageY,
            });
          }}
          onMouseDown={(e) => {
            setLongPress(true);
            const top = window.scrollY + e.target.getBoundingClientRect().top;
            const left = window.scrollX + e.target.getBoundingClientRect().left;
            const xcoord = e.touches ? e.touches[0].pageX : e.pageX;
            const ycoord = e.touches ? e.touches[0].pageY : e.pageY;
            const x = xcoord - left - crop.width / 2;
            const y = ycoord - top - crop.height / 2;
            if (!canICrop) {
              setCropTarget({ x, y });
            }
            setTouchCordinates({
              touchXStart: null,
              touchYStart: null,
              touchStartTime: null,
              mouseStartTime: +new Date(),
              mouseXStart: e?.pageX,
              mouseYStart: e?.pageY,
            });
          }}
          onMouseUp={(e) => {
            calculateDelta(
              { touchCordinates, setTouchCordinates },
              {
                longPress,
                setLongPress,
                longPressDetected,
                setLongPressDetected,
                select,
                clearSelectPageFromDeal,
                clearTargetCordinates,
              },
              {
                eventEnd: e,
              },
              msLongPress
            );
          }}
          onTouchEnd={(e) => {
            calculateDelta(
              { touchCordinates, setTouchCordinates },
              {
                longPress,
                setLongPress,
                longPressDetected,
                setLongPressDetected,
                select,
                clearSelectPageFromDeal,
                clearTargetCordinates,
              },
              {
                eventEnd: e,
              },
              msLongPress
            );
          }}
          onClick={(e) => {
            if (
              !canICrop &&
              cropedActive &&
              !shopingList?.cropedFromNewspaper?.active
            ) {
              setCanICrop(true);
            }
            if (
              !canICrop &&
              cropedActive &&
              shopingList?.cropedFromNewspaper?.active
            ) {
              setCanICrop(true);
            }
          }}
        >
          {!isLoaded && <Spinner />}
          {isLoaded && productsItems.length > 0 && mapingProductsPage}
          {canICrop && cropedActive && imgInVisible ? (
            <ReactCrop
              src={imgInVisible}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
              maxWidth={isMobileL ? 110 : 200}
              maxHeight={isMobileL ? 110 : 200}
              minWidth={theme.isMobile ? 80 : 100}
              minHeight={theme.isMobile ? 80 : 100}
            >
              <Image
                src={imgInVisible}
                alt={`aktualna gazetka promocyjna ${name} ,obowiązuje ${duringTime} `}
                ref={pureImgRef}
                style={{ display: "none" }}
                onLoad={() => {
                  getDimensions(pureImgRef);
                  setIsLoaded(true);
                }}
              />
              <motion.button
                type="button"
                whileTap={{ scale: 0.8 }}
                whileHover={{ scale: 1.2 }}
                style={{
                  position: "absolute",
                  top: "10px",
                  left: `calc(35% - ${"50px"})`,
                  width: "100px",
                  cursor: "pointer",
                  padding: "10px",
                  zIndex: "99",
                  background: `${Theme.light.secondaryColor.green}`,
                  borderRadius: "5px",
                  border: "none",
                  color: `${Theme.light.primaryColor.white}`,
                  textTransform: "uppercase",
                }}
                disabled={!completedCrop?.width || !completedCrop?.height}
                onClick={() => {
                  generateDownload(previewCanvasRef.current, completedCrop);
                  setCanICrop(false);
                  setCutProcessing(true);
                  setTouchCordinates({
                    touchStartTime: null,
                    touchXStart: null,
                    touchYStart: null,
                    mouseStartTime: null,
                    mouseXStart: null,
                    mouseYStart: null,
                  });
                  if (longPressDetected) {
                    setLongPressDetected(false);
                  }
                }}
              >
                Wytnij
              </motion.button>
              <motion.button
                type="button"
                whileTap={{ scale: 0.8 }}
                whileHover={{ scale: 1.2, zIndex: 100 }}
                style={{
                  position: "absolute",
                  top: "10px",
                  left: `calc(65% - ${"50px"})`,
                  width: "100px",
                  cursor: "pointer",
                  padding: "10px",
                  zIndex: "98",
                  background: `${Theme.light.fourthColor.red}`,
                  borderRadius: "5px",
                  border: "none",
                  color: `${Theme.light.primaryColor.white}`,
                  textTransform: "uppercase",
                }}
                disabled={!completedCrop?.width || !completedCrop?.height}
                onClick={() => {
                  setCanICrop(false);
                  userCanNotCrop();
                  alertify.set("notifier", "position", "bottom-left");
                  alertify.error(`Funkcja wycinania wyłączona`);
                  setTouchCordinates({
                    touchStartTime: null,
                    touchXStart: null,
                    touchYStart: null,
                    mouseStartTime: null,
                    mouseXStart: null,
                    mouseYStart: null,
                  });
                  if (longPressDetected) {
                    setLongPressDetected(false);
                  }
                }}
              >
                Anuluj
              </motion.button>
            </ReactCrop>
          ) : (
            imgInVisible && (
              <>
                {cutProcessing && <Spinner cut={true} isDark={theme.isDark} />}
                <Image
                  src={imgInVisible}
                  alt={`aktualna gazetka promocyjna ${name} ,obowiązuje ${duringTime} `}
                  ref={(image) => {
                    pureImgRef.current = image;
                    if (image?.instance?.image?.naturalWidth) {
                      setIsLoaded(true);
                    }
                  }}
                  onLoad={() => {
                    getDimensions(pureImgRef);
                  }}
                />
              </>
            )
          )}

          <div style={{ display: "none" }}>
            <div>
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0),
                }}
              />
            </div>
          </div>
        </ImageWrapper>
      </ErrorBoundary>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  pushUserItemToList: (item) => dispatch(pushUserItemToList(item)),
  trigerVisibleList: (item) => dispatch(trigerVisibleList(item)),
  clearSelectPageFromDeal: (item) => dispatch(clearSelectPageFromDeal(item)),
  clearTargetCordinates: (item) => dispatch(clearTargetCordinates(item)),
});

const mapStateToProps = (state) => ({
  shopingList: state.shopingList,
  select: state.select,
  theme: state.theme,
});

export const ImageWrap = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ImageWrap);
