import React, { useState, useEffect } from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  newspaperId,
  selectId,
  chose,
  clearSelectPageFromDeal,
} from "../../store/select/duck/actions/actions";
import { Theme } from "../../store/theme/theme";
import MainBackground from "../../media/img/main-cenniczek-background5.jpg";
import MainBackgroundMobile from "../../media/img/main-cenniczek-background5_mobile_midQ.jpg";
import { Spinner } from "../tools/spiner/Spinner";
import { SectionSort } from "../main/sectionSort/SectionSort";
import { convertStrToDate } from "../../helpers/convert/convertStrToDate";
import { SingleItem } from "../main/SingleItem";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { ErrorFromData } from "../error/ErrorFromData";
import { API_URL } from "../../constant/constant";

const MainWrapper = styled.div`
  width: 100%;
  //   min-height:50vh;
  // background: url(${MainBackground});
  background-image: url(${typeof window !== "undefined"
    ? MainBackground
    : `${API_URL}/server-media/img/main-cenniczek-background5.jpg`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media${Theme.device.mobileL} {
    // background: url(${MainBackgroundMobile});
    background-image: url(${typeof window !== "undefined"
      ? MainBackgroundMobile
      : `${API_URL}/server-media/img/main-cenniczek-background5_mobile_midQ.jpg`});
  }
`;
const MainWrapperLayer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  transition: background 0.3s;
`;
const MainContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: ${Theme.breakPoint.desktop}px;
  margin: 0 auto;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
`;
const SortStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   height: 60px;
  flex-wrap: wrap;
  & > h1 {
    font-size: ${Theme.size.h1};
    font-family: "Montserrat";
    padding: 20px;
    text-transform: uppercase;
    user-select: none;
  }
  & > label {
    padding: 15px;
    text-transform: uppercase;
  }
  & > label > select {
    margin: 10px;
    padding: 5px;
    text-transform: uppercase;
    border: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border-radius: 5px;
  }
`;
const MainWrapperForBooks = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 50px;
  user-select: none;
  & > a {
    margin: 20px;
    border: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border-radius: 5px;
    background: ${({ isDark }) =>
      isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    transition: 0.1s;
    text-align: center;
    text-transform: uppercase;
    transform: scale(1);
    cursor: pointer;
    overflow: hidden;
  }
  & > a:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
  & > a > div {
    width: 100%;
    height: 70%;
    position: relative;
    overflow: hidden;
  }
  & > a > div > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
`;

const afterSortData = (correctData, filterSortType) => {
  switch (filterSortType.name) {
    case "recomended":
      return correctData.sort((a, b) => {
        return b.recomended - a.recomended;
      });
    case "newest":
      return correctData.sort((a, b) => {
        const aEnd = convertStrToDate(a.startDuringTime);
        const bEnd = convertStrToDate(b.startDuringTime);
        return bEnd - aEnd;
      });
    case "oldest":
      return correctData.sort((a, b) => {
        const aEnd = convertStrToDate(a.startDuringTime);
        const bEnd = convertStrToDate(b.startDuringTime);
        return bEnd < aEnd ? 1 : -1;
      });

    case "alphabet1":
      return correctData.sort((a, b) => {
        const nameA = a.name;
        const nameB = b.name;
        return nameA < nameB ? -1 : 1;
      });
    case "alphabet2":
      return correctData.sort((a, b) => {
        const nameA = a.name;
        const nameB = b.name;
        return nameB > nameA ? 1 : -1;
      });
    default:
      return correctData;
  }
};
const _SingleCategory = ({
  theme,
  select,
  newspaperId,
  selectId,
  chose,
  clearSelectPageFromDeal,
  setUrlChecked,
  categoryDataFromUrl,
}) => {
  const [nameUrl, setNameUrl] = useState("");

  const [brandsUrl, setBrandsUrl] = useState(false);
  const [brandUrlFlag, setBrandUrlFlag] = useState(false);

  const { name } = useParams();
  let history = useHistory();

  const filterSortOption = select.sort.filter((item) => item.active === true);
  const filterSortType = filterSortOption[0];

  let nameChoosen = select.options.filter((item) => item.active);
  nameChoosen = nameChoosen[0].valueName;

  useEffect(() => {
    setNameUrl(name);
  }, [nameUrl]);
  useEffect(() => {
    history.listen((location, action) => {
      if (action === "POP") {
        history.push("/");
        chose("kategorie");
      }
    });
  }, []);
  if (categoryDataFromUrl?.error) {
    return (
      <ErrorFromData
        errorInfo={categoryDataFromUrl.error}
        queryName={"categoryDataFromUrl._SingleCategory"}
      />
    );
  }

  if (
    categoryDataFromUrl.loading === false &&
    categoryDataFromUrl.data &&
    !brandsUrl &&
    !brandUrlFlag
  ) {
    setBrandUrlFlag(true);
    setBrandsUrl(true);
    if (
      !select.options[0].selectedItemID &&
      !select.options[1].selectedItemID
    ) {
      chose("kategorie");
      selectId(categoryDataFromUrl?.data?.categoryByName[0]);
      setUrlChecked(true);
    }
  }

  const showBooks = () => {
    let numberBooks = 0;

    if (categoryDataFromUrl.loading) {
      return <Spinner zIndexController={3} />;
    }
    if (
      categoryDataFromUrl.loading === false &&
      categoryDataFromUrl?.data?.categoryByName[0]
    ) {
      if (
        categoryDataFromUrl?.data?.categoryByName[0]?.books?.length +
          categoryDataFromUrl?.data?.categoryByName[0]?.altCategoryBooks
            ?.length ===
        0
      ) {
        return (
          <h3 style={{ padding: "100px", fontFamily: "montserrat" }}>
            {`Niestety nie mamy obecnie wybranych gazetek :(`.toUpperCase()}
          </h3>
        );
      }
      if (
        categoryDataFromUrl?.data?.categoryByName[0]?.books?.length ||
        categoryDataFromUrl?.data?.categoryByName[0]?.altCategoryBooks?.length
      ) {
        let trueData, finalData;
        const correctData =
          categoryDataFromUrl?.data?.categoryByName[0]?.books.filter(
            (item) => item.status === "active" || item.status === "future"
          );
        if (categoryDataFromUrl?.data?.categoryByName[0]?.altCategoryBooks) {
          const correctAltData =
            categoryDataFromUrl?.data?.categoryByName[0]?.altCategoryBooks.filter(
              (item) => item.status === "active" || item.status === "future"
            );
          finalData = [...correctData, ...correctAltData].filter(
            (item, index, self) => {
              return (
                self.findIndex((insideItem) => insideItem?.id === item?.id) ===
                index
              );
            }
          );

          trueData = afterSortData(finalData, filterSortType);
        } else {
          finalData = correctData;
          trueData = afterSortData(finalData, filterSortType);
        }

        return trueData.map((item, index) => {
          numberBooks++;
          if (numberBooks <= trueData.length) {
            return (
              <SingleItem
                key={index}
                item={item}
                clearSelectPageFromDeal={clearSelectPageFromDeal}
                newspaperId={newspaperId}
                isDark={theme.isDark}
                index={index}
              />
            );
          }
          return null;
        });
      }
    }
  };

  return (
    <>
      <MainWrapper>
        <MainWrapperLayer isDark={theme.isDark}>
          <MainContent isDark={theme.isDark}>
            <ErrorBoundary>
              <SortStyle isDark={theme.isDark}>
                <h1>{`Aktualne gazetki promocyjne -> ${
                  select.options.filter((item) => item.active)[0].name
                } -> ${nameChoosen}`}</h1>
                <SectionSort
                  isDark={theme.isDark}
                  isBrandActive={select.options[1].active}
                  isBrandSelected={select.options[1].selectedItemID}
                  isCategoryActive={select.options[0].active}
                />
              </SortStyle>
              <MainWrapperForBooks isDark={theme.isDark}>
                {showBooks()}
              </MainWrapperForBooks>
            </ErrorBoundary>
          </MainContent>
        </MainWrapperLayer>
      </MainWrapper>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  newspaperId: (item) => dispatch(newspaperId(item)),
  selectId: (item) => dispatch(selectId(item)),
  chose: (item) => dispatch(chose(item)),
  clearSelectPageFromDeal: (item) => dispatch(clearSelectPageFromDeal(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
});
export const SingleCategory = connect(
  mapStateToProps,
  mapDispatchToProps
)(_SingleCategory);
