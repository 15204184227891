import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { Spinner } from "../tools/spiner/Spinner";
import {
  WrraperSection,
  MainWrapperLayer,
  MainContent,
  SectionHeader,
  MainPostWraper,
  BlogWrapper,
} from "../blogSection/stylesForBlog";
import { Post } from "../blogSection/Post";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { BtnBack, BtnNext } from "./BrandPostPosition.helper";

const _BrandPostSection = ({ brandDataFromUrl, itemsPerPage, theme }) => {
  const [itemOffset, setItemOffset] = useState(0);
  const wrapper = useRef();
  let topDealsWraper;

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = brandDataFromUrl?.data?.brandByName[0]?.promoBlog?.slice(
    itemOffset,
    endOffset
  );
  const pageCount =
    brandDataFromUrl?.loading === false &&
    brandDataFromUrl?.data?.brandByName[0]?.promoBlog?.length
      ? Math.ceil(
          brandDataFromUrl?.data?.brandByName[0]?.promoBlog?.length /
            itemsPerPage
        )
      : 0;

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    topDealsWraper = document.getElementById("brandPostWrapper");
    const newOffset =
      (event.selected * itemsPerPage) %
      brandDataFromUrl?.data?.brandByName[0].promoBlog?.length;

    setItemOffset(newOffset);
    if (wrapper && wrapper.current) {
      document.documentElement.scrollTop =
        window.scrollY + topDealsWraper.getBoundingClientRect().top - 10;
    }
  };
  useEffect(() => {
    return () => {
      setItemOffset(0);
      topDealsWraper = null;
    };
  }, []);

  return (
    <>
      <WrraperSection isDark={theme.isDark} ref={wrapper} id="brandPostWrapper">
        <MainWrapperLayer isDark={theme.isDark}>
          <MainContent isDark={theme.isDark}>
            <SectionHeader isDark={theme.isDark}>
              <h3>
                <span>
                  z gazetki promocyjnej{" "}
                  {brandDataFromUrl?.data?.brandByName[0]?.name}
                </span>
              </h3>
            </SectionHeader>
            <ErrorBoundary>
              <MainPostWraper isDark={theme.isDark}>
                {brandDataFromUrl?.loading == true && <Spinner />}
                <BlogWrapper>
                  {brandDataFromUrl?.loading === false &&
                    !brandDataFromUrl?.data?.brandByName[0]?.promoBlog
                      ?.length && <p>brak postów</p>}
                  {currentItems?.map((post) => (
                    <Post
                      img={post.mainImg}
                      title={post.title}
                      intro={post.intro}
                      text={post.content}
                      created={post.created}
                      key={post.id}
                      isDark={theme.isDark}
                    />
                  ))}
                </BlogWrapper>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<BtnNext isDark={theme?.isDark} />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  pageCount={pageCount}
                  previousLabel={<BtnBack isDark={theme?.isDark} />}
                  renderOnZeroPageCount={null}
                  className="paginationLight"
                />
              </MainPostWraper>
            </ErrorBoundary>
          </MainContent>
        </MainWrapperLayer>
      </WrraperSection>
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
});
export const BrandPostSection = connect(mapStateToProps, {})(_BrandPostSection);
