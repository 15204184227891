import React from "react";
import { connect } from "react-redux";
import { StyledWraperForNumber } from "./style/style";
const _NumbersOfItems = ({ theme, item }) => {
  return (
    <>
      {item && (
        <StyledWraperForNumber isDark={theme.isDark}>
          {Math.round(item) > 1000
            ? `${(Math.round(item) / 1000).toFixed(2)} km`
            : `${Math.round(item)} m`}
        </StyledWraperForNumber>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
});
export const NumbersOfItems = connect(mapStateToProps, {})(_NumbersOfItems);
