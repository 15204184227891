import React, { useState, useEffect, useRef } from "react";
import { PaginationSelect } from "./paginationSelectDeals/PaginationSelectDeals";
import {
  CarouselProvider,
  Slider,
  DotGroup,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  CaruselWrapper,
  caruselStyle,
  BtnHover,
  sliderStyle,
  buttonBackStyle,
  buttonNextStyle,
  btnStyle,
  DotWrapper,
} from "./PaginationDeals.style";
import { ErrorBoundary } from "../../errorBoundary/ErrorBoundary";
import { PagesForSlider } from "./pagesForSlider/PagesForSlider";
import { backArrow, nextArrow } from "../../../media/svgPath/SvgPath";
import { SvgDisplay } from "../../tools/spiner/svgDisplay/SvgDisplay";

export const PaginationDeals = ({
  deals,
  isDark,
  howMany,
  setTopDealsShoppageFromNewspaper,
  topDealsPageFromNewsPaper,
  brandDataFromUrl,
  isMobile,
}) => {
  const [pagePagination, setPagePagination] = useState(1);
  const wrapper = useRef();

  let slides = Math.ceil((deals.length - 1) / howMany);

  useEffect(() => {
    const topDealsWraper = document.getElementById("topDealsWrapper");

    if (isMobile && wrapper && wrapper.current && pagePagination > 1) {
      document.documentElement.scrollTop =
        window.scrollY + topDealsWraper.getBoundingClientRect().top - 10;
    }
  }, [isMobile, pagePagination]);
  return (
    <ErrorBoundary>
      <CaruselWrapper ref={wrapper} id="topDealsWrapper">
        <CarouselProvider
          // naturalSlideWidth={isMobile ? 360 : 800}
          // naturalSlideHeight={800}
          totalSlides={slides}
          visibleSlides={1}
          orientation={"horizontal"}
          isIntrinsicHeight={true}
          step={1}
          dragStep={1}
          style={caruselStyle}
          touchEnabled={false}
          dragEnabled={false}
        >
          <Slider style={sliderStyle}>
            <PagesForSlider
              deals={deals}
              howMany={howMany}
              isDark={isDark}
              slides={slides}
              pagePagination={pagePagination}
              setTopDealsShoppageFromNewspaper={
                setTopDealsShoppageFromNewspaper
              }
              topDealsPageFromNewsPaper={topDealsPageFromNewsPaper}
              brandDataFromUrl={brandDataFromUrl}
            />
          </Slider>
          <DotWrapper>
            <ButtonBack style={buttonBackStyle}>
              <BtnHover isDark={isDark} whileTap={{ scale: 0.85 }}>
                <SvgDisplay
                  viewBox={backArrow.viewBox}
                  d={backArrow.d}
                  style={btnStyle}
                />
              </BtnHover>
            </ButtonBack>
            <DotGroup
              className="caruselDotsShops"
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                padding: "25px",
              }}
            >
              <div style={{ width: "10px", margin: "2px" }}></div>
            </DotGroup>
            <ButtonNext style={buttonNextStyle}>
              <BtnHover isDark={isDark} whileTap={{ scale: 0.85 }}>
                <SvgDisplay
                  viewBox={nextArrow.viewBox}
                  d={nextArrow.d}
                  style={btnStyle}
                />
              </BtnHover>
            </ButtonNext>
          </DotWrapper>

          <PaginationSelect
            pagePagination={pagePagination}
            setPagePagination={setPagePagination}
          />
        </CarouselProvider>
      </CaruselWrapper>
    </ErrorBoundary>
  );
};
