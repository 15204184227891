import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
import { motion } from "framer-motion";
export const MobileMenuWrapper = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  box-shadow: 2px 2px 10px black;
  transition: 0.3s all;
  //   z-index: 90;
  z-index: ${({ isMainMenuOpen }) => (isMainMenuOpen ? 1 : 102)};
`;
export const ShopingListWrapper = styled(motion.div)`
  position: absolute;
  bottom: 45%;
  left: 80%;
  width: ${({ isActive }) => (isActive ? 25 : 20)}px;
  height: ${({ isActive }) => (isActive ? 25 : 20)}px;
  //   transform: translateY(50%);
  opacity: ${({ isActive }) => (isActive ? 1 : 0.6)};
  transition: 0.3s all;
  text-allign: center;
  font-size: 12px;
  diplay: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HomeWrapper = styled(motion.div)`
  position: absolute;
  bottom: 45%;
  left: 47%;
  width: ${({ isActive }) => (isActive ? 25 : 20)}px;
  height: ${({ isActive }) => (isActive ? 25 : 20)}px;
  //   transform: translate(-50%, 50%);
  opacity: ${({ isActive }) => (isActive ? 1 : 0.6)};
  transition: 0.3s all;
  text-allign: center;
  font-size: 12px;
  diplay: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > p {
    margin-left: -4px;
  }
`;

export const MetricsWrapper = styled(motion.div)`
  position: absolute;
  bottom: 45%;
  left: 15%;
  width: ${({ isActive }) => (isActive ? 25 : 20)}px;
  height: ${({ isActive }) => (isActive ? 25 : 20)}px;
  //   transform: translateY(50%);
  opacity: ${({ isActive }) => (isActive ? 1 : 0.6)};
  transition: 0.3s all;
  text-allign: center;
  font-size: 12px;
  diplay: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > p {
    margin-left: -8px;
  }
`;

export const svgStyle = {
  width: "25px",
  height: "25px",
};
