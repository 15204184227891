import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
// import AdSense from "react-adsense";

const imagePath =
  "M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z";
const AdWrapper = styled.div`
  position: ${({ allSection }) => (allSection ? "sticky" : "relative")};
  top: 20px;
  width: 100%;
  height: ${({ mainAd, allSection }) =>
    mainAd ? "400px" : allSection ? "85vh" : "35%"};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  & > p {
    text-transform: uppercase;
    font-family: montserrat;
    font-size: 0.7rem;
    margin: 5px 0 0 5px;
  }
  & > div {
    width: 100%;
    // height: 300px;
    // height: ${({ mainAd }) => (mainAd ? "365px" : "300px")};
    height: ${({ mainAd, allSection }) =>
      mainAd ? "365px" : allSection ? "85vh" : "300px"};
    border: 1px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  @media${Theme.device.desktop} {
    // height: 20%;
    height: ${({ mainAd }) => (mainAd ? "400px" : "20%")};
  }
  @media${Theme.device.tablet} and (orientation:landscape) {
    // height: 100vh;
    height: ${({ mainAd }) => (mainAd ? "400px" : "100vh")};
    position: sticky;
    top: 0;
  }
  @media${Theme.device.mobileL} {
    // display: none;
    display: ${({ mainAd }) => (mainAd ? "" : "none")};
  }
`;
export const AdvContainer = ({ isDark, mainAd, allSection }) => {
  const [adv, setAdv] = useState(null);
  const [AdSense, setAdSense] = useState(null);
  useEffect(() => {
    const loadAdsense = async () => {
      if (typeof window !== "undefined") {
        try {
          const adsenseInstance = (await import("react-adsense")).default;

          setAdSense(adsenseInstance);
        } catch (error) {
          console.error("Error loading AdSense:", error);
        }
      }
    };

    loadAdsense();
  }, []);
  useEffect(() => {
    return () => {
      setAdSense(null);
    };
  }, []);
  useEffect(() => {
    // if (process.env.NODE_ENV === "production") {
    setAdv(true);
    // }
  }, []);
  return (
    <AdWrapper isDark={isDark} mainAd={mainAd} allSection={allSection}>
      <p>reklama</p>
      {!adv && (
        <div>
          {/* <ImgSuspense style={{ width: "50%" }} /> */}
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="image"
            className="svg-inline--fa fa-image fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{ width: "50%" }}
          >
            <path fill="currentColor" d={imagePath}></path>
          </svg>
        </div>
      )}
      {adv && AdSense && (
        <div>
          <AdSense.Google
            client="ca-pub-9031422111285471"
            slot="6417759377"
            // style={{ display: 'block' }}
            format=""
            style={{
              display: "block",
              width: "90%",
              height: `${allSection ? "85vh" : "300px"}`,
            }}
            responsive="true"
          />
        </div>
      )}
    </AdWrapper>
  );
};
