import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { MainPost } from "../components/blogSection/MainPost";
import { Footer } from "../components/footerSection/Footer";
import { Helmet } from "react-helmet";
import { firstLetterCapitalisation } from "../helpers/convert/firstLetter";
import { useQuery } from "@apollo/client";
import { getForBlogData } from "../queries/queries";

export const PostPage = () => {
  const { title } = useParams();

  let forBlogData = useQuery(getForBlogData, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    ssr: true,
  });

  useEffect(() => {
    if (typeof window !== "undefined") document.documentElement.scrollTop = 0;
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://cenniczek.com/blog/${title}`} />
        {!title && (
          <meta
            property="og:description"
            content={`Cenniczek.com-blog.Szukasz informacji o ${title} &#127978; Zapraszamy`}
          />
        )}
        <meta
          name="keywords"
          content="gazetki promocyjne,aktualne gazetki,sprytne zakupy,blog cenniczek"
        ></meta>
        <meta
          property="description"
          content={`Cenniczek.com-blog.Szukasz informacji o ${title}. Może interesującie cie programy partnerskie sieci handlowych - zapraszamy &#127978;`}
        />
        <meta property="og:title" content={`${title},Blog-Cenniczek.com`} />
        <meta
          property="og:url"
          content={`https://cenniczek.com/blog/${title}`}
        />
        <title>
          {`${firstLetterCapitalisation(
            title.split("-").join(" ")
          )} blog-Cenniczek.com`}
        </title>
      </Helmet>
      <MainPost />
      <Footer blogData={forBlogData} />
    </>
  );
};
