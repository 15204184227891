import styled from "styled-components";
import { motion } from "framer-motion";
import { Theme } from "../../../store/theme/theme";

export const SwitcherWraper = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SwitcherContent = styled.div`
  width: 200px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
`;
export const SwitchLineWraper = styled.div`
  width: 200px;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
`;
export const SwitchLine = styled.div`
  width: 100px;
  height: 4px;
  background: ${Theme.light.secondaryColor.green};
  position: absolute;
  bottom: 0;
  left: ${({ chart }) => (chart ? "100px" : 0)};
  transition: 0.3s;
  border-radius: 2px;
`;
export const ShopingWraper = styled.div`
  width: 100px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${({ chart }) => (chart ? "scale(1)" : "scale(1.2)")};
  transition: 0.3s;
  color: ${({ chart }) => (!chart ? Theme.light.secondaryColor.green : "")};
  &:hover {
    transform: ${({ chart }) => (chart ? "scale(1.2)" : "")};
  }
`;
export const ChartWraper = styled.div`
  width: 100px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${({ chart }) => (chart ? "scale(1.2)" : "scale(1)")};
  transition: 0.3s;
  color: ${({ chart }) => (chart ? Theme.light.secondaryColor.green : "")};
  &:hover {
    transform: ${({ chart }) => (chart ? "" : "scale(1.2)")};
  }
`;

export const styleForSvg = {
  height: "30px",
  paddingRight: "2px",
};
