import styled from "styled-components";
import { motion } from "framer-motion";
import { Theme } from "../../../store/theme/theme";
export const WraperForChartsAnalis = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  overflow: auto;
  // z-index: 1001;
  position: relative;
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 0 5px 5px 0;
    background-color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border-left: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border-right: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    background-color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }

  & > h2 {
    padding: 20px;
    font-weight: 400;
    font-family: Montserrat;
    text-align: center;
  }
  & > p {
    font-family: Montserrat;
    padding-bottom: 10px;
  }
  @media${Theme.device.mobileL} {
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 0 2px 2px 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      box-shadow: inset 0 0 2px
        ${({ isDark }) =>
          !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
      border-right: none;
      border-left: none;
    }
  }
`;
export const Analise = styled.div`
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & > h2 {
    padding: 20px;
    font-weight: 400;
    font-family: Montserrat;
    text-align: center;
  }
`;
export const NoAnalise = styled.div`
  width: 100%;
  height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > h2 {
    padding: 20px;
    font-weight: 400;
    font-family: Montserrat;
    text-align: center;
  }
`;
