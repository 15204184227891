import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { storeForClient } from "./store/storeForClient";
import ReactGA from "react-ga4";
import { reportWebVitals } from "./utils/WebVitals/reportWebVitals";
import { sendToGoogleAnalytics } from "./helpers/sendToGoogleAnalytics/sendToGoogleAnalytics";

const rootElement = document.getElementById("root");

ReactGA.initialize(process.env.REACT_APP_GA);

ReactDOM.hydrate(
  // ReactDOM.render(
  <React.StrictMode>
    <App store={storeForClient} />
  </React.StrictMode>,
  rootElement
);

// to check vitals please paste console.log as parameter to function - reportWebVitals(console.log)
reportWebVitals(sendToGoogleAnalytics);
