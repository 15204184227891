import React from "react";
import { connect } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";
import {
  WrraperSection,
  MainWrapperLayer,
  MainContent,
  SectionHeader,
  More,
  MainPostWraper,
} from "./stylesForBlog";
import { ShowBlog } from "./ShowBlog";
import { ShowPost } from "./ShowPost";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { RightBar } from "../rightBar/RightBar";
const _MainBlog = ({ theme, options, blogData, dataFromHomePage }) => {
  // document.documentElement.scrollTop = 0
  const { title } = useParams();
  let history = useHistory();

  return (
    <>
      <WrraperSection isDark={theme.isDark}>
        <MainWrapperLayer isDark={theme.isDark}>
          <MainContent isDark={theme.isDark}>
            <SectionHeader
              isDark={theme.isDark}
              onClick={() => history.push("/blog")}
            >
              <h2>
                <span>blog</span>
              </h2>
            </SectionHeader>
            <ErrorBoundary>
              <MainPostWraper isDark={theme.isDark}>
                {title ? (
                  <ShowPost title={title} isDark={theme.isDark} />
                ) : (
                  <>
                    <ShowBlog
                      options={options}
                      blogData={blogData}
                      dataFromHomePage={dataFromHomePage}
                      isDark={theme.isDark}
                    />
                    <RightBar isDark={theme.isDark} allSection={true} />
                  </>
                )}
              </MainPostWraper>
              {dataFromHomePage?.loading === false && !title && (
                <More whileTap={{ scale: 0.95 }} isDark={theme.isDark}>
                  <Link to={"/blog"}>więcej</Link>
                </More>
              )}
            </ErrorBoundary>
          </MainContent>
        </MainWrapperLayer>
      </WrraperSection>
    </>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
});
export const MainBlog = connect(mapStateToProps, {})(_MainBlog);
