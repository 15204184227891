import { Theme } from "../../store/theme/theme";
import styled from "styled-components";

export const HeaderSearchWrapper = styled.div`
  width: 500px;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & > p {
    padding-right: 10px;
  }
`;
