import React, { useState, useEffect } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Spinner } from "../../tools/spiner/Spinner";
import { ResponseWraper, MainMetricsWrapper } from "../style";
import { MainBar } from "./mainBar/MainBar";
import { UserList } from "./userList/UserList";
import { Theme } from "../../../store/theme/theme";
import { MainBrandBar } from "./mainBrandBar/MainBrandBar";
import { MainCategoryBar } from "./mainCategoryBar/MainCategoryBar";
import { MainTitle } from "./mainTitle/MainTitle";
import { API_URL } from "../../../constant/constant";
import { ErrorBoundary } from "../../errorBoundary/ErrorBoundary";
import { ErrorHandler } from "../../error/ErrorHandler";
import {
  encryptFrontData,
  decryptFrontData,
} from "../../../utils/Crypto/cryptoFront.controller";
const resizeScreen = (isMobileL, setIsMobileL, breakPoint) => {
  window.addEventListener("resize", () => {
    const docWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    if (docWidth > breakPoint) {
      setIsMobileL(false);
    } else {
      setIsMobileL(true);
    }
  });
};

const onLoadScreen2 = (isMobileL, setIsMobileL, breakPoint) => {
  const docWidth =
    document.documentElement.clientWidth || document.body.clientWidth;

  if (docWidth > breakPoint) {
    setIsMobileL(false);
  } else {
    setIsMobileL(true);
  }
};
const checkMetrics = async (
  start,
  stop,
  setData,
  setProcess,
  setErrorFromCrypto
) => {
  const frontDataToEncrypt = {
    start: start,
    stop: stop,
  };

  // encrypt data for fetch
  const ciphertext = encryptFrontData(
    frontDataToEncrypt,
    setErrorFromCrypto,
    setProcess,
    "Metrics.js"
  );

  fetch(`${API_URL}/api/metrics`, {
    method: "POST",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: ciphertext }),
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      // decrypt data from response
      return decryptFrontData(
        result.data,
        setErrorFromCrypto,
        setProcess,
        "Metrics.js"
      );
    })
    .then((data) => {
      setProcess(false);
      setData(data);
    })
    .catch((err) => console.log(err));
};
export const Metrics = ({ isDark, user }) => {
  const [process, setProcess] = useState(false);
  const [isMobileL, setIsMobileL] = useState(false);
  const [value, onChange] = useState([new Date(), new Date()]);
  const [data, setData] = useState(null);
  const [days, setDays] = useState({
    number: null,
    time: null,
    render: 0,
  });
  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  // console.log(data);
  // console.log(days);
  useEffect(() => {
    onLoadScreen2(isMobileL, setIsMobileL, Theme.breakPoint.mobileM);
    resizeScreen(isMobileL, setIsMobileL, Theme.breakPoint.mobileM);
  }, []);
  useEffect(() => {
    if (data && data.ok && data.start[0]) {
      const number = Math.round(
        (data.stop[0] - data.start[0]) / (1000 * 60 * 60 * 24)
      );
      const checkDate = (miliseconds) => {
        const daysFrom = [];
        for (let i = 0; i < number; i++) {
          daysFrom.push(
            new Date(
              miliseconds + 1000 * 60 * 60 + i * 1000 * 60 * 60 * 24
            ).toLocaleDateString()
          );
        }
        return daysFrom;
      };

      setDays({
        render: 0,
        number: number,
        time: {
          day: checkDate(data?.start[0]),
          shoping: checkDate(data?.start[0]).map((item) => {
            const result = data?.shopingList.filter((listItem) => {
              if (typeof listItem.item.created === "string") {
                if (listItem.item.created.indexOf(",") !== -1) {
                  return (
                    listItem.item.created.slice(
                      0,
                      listItem.item.created.indexOf(",")
                    ) === item
                  );
                } else if (listItem.item.created.indexOf("o") !== -1) {
                  return (
                    listItem.item.created.slice(
                      0,
                      listItem.item.created.indexOf("o") - 1
                    ) === item
                  );
                }
              } else if (typeof listItem.item.created === "number") {
                return (
                  new Date(listItem.item.created).toLocaleDateString() === item
                );
              }
            });
            if (result.length > 0) {
              result[0].item.total = result
                .map((item) => parseFloat(item.item.total))
                .reduce((total, item) => total + item, 0)
                .toString();
              const arrayList = [];
              result.map((res) => arrayList.push(...res.item.list));
              result[0].item.list = arrayList;
            }

            return {
              result: result[0],
              day: item,
            };
          }),
        },
      });
    }
  }, [data?.start[0], data?.stop[0]]);
  useEffect(() => {
    if (value && value.length) {
      checkMetrics(value[0], value[1], setData, setProcess, setErrorFromCrypto);
    }
    if (!value) {
      setData(null);
      setDays({
        number: null,
        time: null,
      });
    }
  }, [value && value[0], value && value[1]]);
  return (
    <>
      <section>
        <DateRangePicker
          isOpen={true}
          onChange={onChange}
          value={value}
          locale={"pl-PL"}
          format={"dd-MM-y"}
          dayPlaceholder={"dd"}
          monthPlaceholder={"mm"}
          yearPlaceholder={"rrrr"}
          calendarClassName={isDark ? "darkCalendar" : "ligthCalendar"}
        />
      </section>
      <ResponseWraper>
        <ErrorBoundary>
          {process && <Spinner />}
          {errorFromCrypto && <ErrorHandler errorMsg={errorFromCrypto} />}
          {/* {data?.ok && data?.name && !process && (
            <h1>
              Witaj {data.name[0].toUpperCase()}
              {data.name.slice(1)}
            </h1>
          )} */}
          {data && data.error ? (
            <h1>{data.error}</h1>
          ) : (
            data?.ok &&
            !data?.shopingList.length &&
            !process && <h1>Wybierz zakres dat</h1>
          )}

          {data?.ok && days?.number > 0 && !process && (
            <>
              <MainMetricsWrapper>
                <MainTitle
                  days={days}
                  isDark={isDark}
                  data={data}
                  isMobileL={isMobileL}
                />
                <MainBar days={days} isDark={isDark} isMobileL={isMobileL} />
                <MainBrandBar
                  days={days}
                  isDark={isDark}
                  isMobileL={isMobileL}
                />
                <MainCategoryBar
                  days={days}
                  isDark={isDark}
                  isMobileL={isMobileL}
                />
              </MainMetricsWrapper>
              <UserList days={days} isDark={isDark} setDays={setDays} />
            </>
          )}
        </ErrorBoundary>
      </ResponseWraper>
    </>
  );
};
