import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { updateUserItemOnList } from "../../../../store/shopingList/duck/actions/actions";
const _SelectContentForBrand = ({
  item,
  updateUserItemOnList,
  addUserItemIndex,
  shopingList,
}) => {
  const optionName = item;
  const [refToLi, setRefToLi] = useState(null);
  const litItem = useRef(null);

  if (refToLi) {
    refToLi.addEventListener("click", () => {
      updateUserItemOnList({
        index: addUserItemIndex,
        value: {
          ...shopingList.userItems[addUserItemIndex],
          brand: optionName?.name,
          brandData: [{ imgUrl: optionName?.imgUrl }],
        },
      });
    });
  }

  return (
    <li
      ref={(current) => {
        litItem.current = current;
        setRefToLi(current);
      }}
    >
      {item.name}
    </li>
  );
};
const mapStateToProps = (state) => ({
  shopingList: state.shopingList,
});
const mapDispatchToProps = (dispatch) => ({
  updateUserItemOnList: (item) => dispatch(updateUserItemOnList(item)),
});
export const SelectContentForBrand = connect(
  mapStateToProps,
  mapDispatchToProps
)(_SelectContentForBrand);
