import React from "react";
import { Slide } from "pure-react-carousel";
import { SlideHover, slideStyle, pageVariants } from "../PaginationDeals.style";
import { FromSecondToHowMany } from "../fromSecondToHowMany/FromSecondToHowMany";

export const PagesForSlider = ({
  deals,
  howMany,
  isDark,
  slides,
  pagePagination,
  setTopDealsShoppageFromNewspaper,
  topDealsPageFromNewsPaper,
  brandDataFromUrl,
}) => {
  const nrPages = slides;
  let arrayForPages = [];
  for (let i = 1; i <= nrPages; i++) {
    arrayForPages = [...arrayForPages, { page: i }];
  }

  return arrayForPages.map((item, index) => {
    return (
      <Slide index={pagePagination - 1} style={slideStyle} key={index}>
        <SlideHover
          variants={pageVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          isDark={isDark}
        >
          <FromSecondToHowMany
            howMany={howMany}
            deals={deals}
            pagePagination={item.page}
            setTopDealsShoppageFromNewspaper={setTopDealsShoppageFromNewspaper}
            topDealsPageFromNewsPaper={topDealsPageFromNewsPaper}
            brandDataFromUrl={brandDataFromUrl}
          />
        </SlideHover>
      </Slide>
    );
  });
};
