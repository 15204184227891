import React, { useEffect, useRef, useParams } from "react";
import { connect } from "react-redux";
import { setShopsByCoords } from "../../store/localization/duck/actions/actions";
import {
  SinglePostLayout,
  Localization,
} from "./stylesForBrandOrCategoryContainer";
// import { ReactComponent as LocalIcon } from "../../media/svg/marker.svg";
import { ArroundSection } from "../mapSection/arroundSection/ArroundSection";
import { FindClosestShopInfo } from "./FindClosestShopInfo";

const localPath =
  "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z";
const pageVariants = {
  hidden: {
    opacity: 0,
    // x:'-25%',
  },
  visible: (index) => ({
    opacity: 1,
    // x:0,
    transition: {
      duration: 0.3,
      // delay:index*0.1+0.5,
      delay: 0.3,
    },
  }),
  exit: {
    opacity: 0,
  },
};
const styleForIcon = {
  width: "30px",
  margin: "10px",
  maxHeight: "30px",
};

const _Info = ({ theme, select, localization, setShopsByCoords, section }) => {
  const closestShop = localization.shopsByCoord[0];
  const sectionRef = useRef();
  useEffect(() => {
    if (localization.partnersArroundUser.length) {
      setShopsByCoords(filterClosestShop(localization.partnersArroundUser));
    }
  }, [localization.partnersArroundUser, select.options[1].valueName]);
  const filterClosestShop = (shops) => {
    const sortedData = shops.sort(
      (a, b) => a.cordinatesDiff - b.cordinatesDiff
    );
    return sortedData;
  };

  const checkShopNumber = () => {
    if (localization.partnersArroundUser.length) {
      if (localization.partnersArroundUser.length % 2 === 0) {
        return (
          <div>
            znaleźliśmy <span>{localization.partnersArroundUser.length} </span>{" "}
            sklepy
          </div>
        );
      }
      if (localization.partnersArroundUser.length % 2 !== 0) {
        return (
          <div>
            znaleźliśmy <span>{localization.partnersArroundUser.length} </span>{" "}
            sklepów
          </div>
        );
      }
    }
    return null;
  };

  useEffect(() => {
    if (section === "sklepy") {
      if (sectionRef && sectionRef.current) {
        sectionRef.current.scrollIntoView();
      }
    }
  }, [section]);
  return (
    <>
      <SinglePostLayout isDark={theme.isDark} ref={sectionRef}>
        <Localization isDark={theme.isDark}>
          <div>
            <div>
              {" "}
              {/* <LocalIcon style={styleForIcon} /> */}
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="map-marker-alt"
                className="svg-inline--fa fa-map-marker-alt fa-w-12"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                style={styleForIcon}
                width={30}
              >
                <path fill="currentColor" d={localPath}></path>
              </svg>
              w okolicy <ArroundSection isDark={theme.isDark} />
            </div>{" "}
            {checkShopNumber()}
          </div>
          <FindClosestShopInfo
            isDark={theme.isDark}
            localizationItem={localization.shopsByCoord[0] || closestShop}
            option={false}
          />
        </Localization>
      </SinglePostLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  select: state.select,
  theme: state.theme,
  localization: state.localization,
});
const mapDispatToProps = (dispatch) => ({
  setShopsByCoords: (item) => dispatch(setShopsByCoords(item)),
});
export const Info = connect(mapStateToProps, mapDispatToProps)(_Info);
