import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Theme } from "../../../../store/theme/theme";
import styled from "styled-components";
import { motion } from "framer-motion";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const WraperForMainBar = styled(motion.div)`
  width: 70%;
  // min-height: 660px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  position: relative;
  @media${Theme.device.tablet} {
    min-height: 400px;
    margin: 0;
  }
  @media${Theme.device.mobileL} {
    width: 100%;
    min-height: 185px;
    margin: 5px 0;
  }
`;
export const MainBar = ({ days, isDark }) => {
  const options = {
    responsive: true,
    radius: 5,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItem, data) {
            return tooltipItem[0].label;
          },
          label: function (tooltipItem, data) {
            const index = tooltipItem.dataIndex;
            const dataFromAll = tooltipItem.dataset.data.map((item) =>
              item ? parseFloat(item) : 0
            );
            const total = dataFromAll.reduce((all, item) => all + item, 0);

            const percentage = parseFloat(
              ((tooltipItem.dataset.data[index] / total) * 100).toFixed(1)
            );

            return `${tooltipItem.dataset.data[index].toFixed(
              2
            )} PLN - ${percentage}% `;
          },
        },
      },
      filler: {
        propagate: true,
      },
    },
    datasetFill: false,
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return value + " PLN";
          },
        },
      },
    },
  };
  const labels = days?.time?.shoping.map((item) =>
    item.day.slice(0, item.day.length - 5)
  );
  const dataChart = {
    labels,
    datasets: [
      {
        label: `Wartość zakupów z ${days.number} dni`,
        data: days?.time?.shoping.map((item) => {
          if (item?.result?.item?.total) {
            const resPrice = item.result.item.list.map((item) =>
              parseFloat(
                (
                  (parseFloat(item.price) || 0) *
                  (parseFloat(item.howMany) || 0)
                ).toFixed(2)
              )
            );
            const total = resPrice.reduce((total, item) => total + item, 0);
            // return item.result.item.total;
            return total;
          } else {
            return 0;
          }
        }),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgb(53, 162, 235)",
      },
    ],
    options: {
      plugins: {
        filler: {
          propagate: false,
        },
      },
    },
  };
  defaults.fontFamily = ["Montserrat", "Helvetica Neue"];
  defaults.color = isDark
    ? Theme.dark.thirdColor.white
    : Theme.light.thirdColor.dark;
  defaults.borderColor = isDark
    ? Theme.light.fiveColor.white
    : Theme.dark.sixColor.dark;
  defaults.font.size = 16;
  defaults.font.family = "Arial";
  defaults.font.lineHeight = 1.6;

  return (
    <WraperForMainBar>
      <Bar
        options={options}
        data={dataChart}
        style={{
          background: `${
            isDark
              ? Theme.dark.primaryColor.dark
              : Theme.light.primaryColor.white
          }`,
          padding: "15px",
          borderRadius: "5px",
        }}
      />
    </WraperForMainBar>
  );
};
