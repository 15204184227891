import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { Theme } from "../../store/theme/theme";
import Promotions from "../../media/img/gazetki-promocyjne.jpg";
import { AdvForInfo } from "./AdvForInfo";
// import { useQuery } from "@apollo/react-hooks";
// import { getBrandsQuery, getBrandByNameQuery } from "../../queries/queries";
import { Helmet } from "react-helmet";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { firstLetterCapitalisation } from "../../helpers/convert/firstLetter";
import { API_URL, BRAND_NAME } from "../../constant/constant";
import { ErrorFromData } from "../error/ErrorFromData";
// import { Spinner } from "../tools/spiner/Spinner";
import {
  newspaperId,
  selectPageFromDeal,
  setTargetCordinates,
  clearSelectPageFromDeal,
  clearTargetCordinates,
} from "../../store/select/duck/actions/actions";
import { pushItemToList } from "../../store/shopingList/duck/actions/actions";
import { overflowHidden } from "../../store/theme/duck/actions/actions";
const WrraperSection = styled.div`
  width: 100%;
  position: relative;
  user-select: none;
  // height:100vh;
  @media${Theme.device.mobileL} {
    z-index: 5;
  }
`;
const MainWrapperLayer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ isDark }) =>
    isDark ? Theme.light.thirdColor.dark : Theme.light.primaryColor.white};
  transition: background 0.3s;
  display: flex;
  justify-content: space-around;
  // align-items:flex-start;
`;
const MainContent = styled.div`
  position: relative;
  width: 70%;
  height: 100%;
  max-width: ${Theme.breakPoint.desktop}px;
  color: ${({ isDark }) =>
    isDark ? Theme.light.fiveColor.white : Theme.light.thirdColor.dark};
  font-family: montserrat;
  line-height: 2rem;
  @media${Theme.device.mobileL} {
    width: 90%;
  }
`;
const SectionHeader = styled.div`
  padding: 20px;
  & > h5 {
    margin: 10px 0;
  }
  & > h5 > span {
    padding-bottom: 20px;
    display: block;
    // width: 300px;
    font-size: 30px;
    text-transform: uppercase;
    border-bottom: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.light.fiveColor.white : Theme.light.thirdColor.dark};
  }
  @media${Theme.device.mobileL} {
    width: 95%;
    padding: 0;
    & > h5 > span {
      width: 100%;
      font-size: ${Theme.size.mobileL};
    }
  }
  @media${Theme.device.mobileM} {
    & > h5 {
      font-size: ${Theme.size.fontSizeMobileL};
    }
    & > h5 > span {
      width: 100%;
      font-size: 20px;
    }
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  & > h6 {
    text-transform: uppercase;
    padding-top: 40px;
    // float:right;
  }
  & > img {
    // display:block;
    float: left;
    padding-right: 20px;
    padding-bottom: 20px;
    max-width: 100%;
    max-height: 100%;
  }
  & > p {
    margin: 10px;
  }
  & > section {
    display: flex;
    flex-wrap: wrap;
  }
  & > section > a {
    width: 25%;
    text-align: center;
    text-decoration: none;
    margin: 30px;
    text-transform: uppercase;
    padding: 20px;
    border-radius: 14px;
    cursor: pointer;
    background: ${({ isDark }) =>
      isDark ? Theme.dark.sixColor.dark : Theme.light.eightColor.ligthGray};
    color: ${({ isDark }) =>
      isDark ? Theme.light.fiveColor.white : Theme.dark.primaryColor.dark};
    border: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.thirdColor.white : Theme.light.primaryColor.white};
    transition: 0.3s all;
  }
  & > section > a:hover {
    color: ${({ isDark }) =>
      isDark ? Theme.light.primaryColor.white : Theme.dark.sixColor.dark};
  }
  // &>p:after{
  //     display:block;
  //     content:'';
  //     clear:both;
  // }
  & > p:last-of-type {
    padding-bottom: 20px;
  }
  & > p:last-of-type:after {
    display: block;
    content: "";
    clear: both;
  }
  @media${Theme.device.mobileL} {
    & > h6 {
      font-size: ${Theme.size.fontSizeMobileL};
    }
    & > p {
      font-size: ${Theme.size.fontSizeMobileM};
      margin: 0;
    }
    & > section > a {
      width: 90%;
      margin: 15px;
    }
  }
  @media${Theme.device.mobileM} {
    & > h6 {
      font-size: ${Theme.size.fontSizeMobileL};
    }
    & > p {
      font-size: ${Theme.size.fontSizeMobileM};
    }
    & > section > a {
      width: 90%;
      margin: 15px;
    }
  }
`;
const TextContent = () => {
  return (
    <>
      <h6>
        Przemyślane zakupy: odkryj moc gazetek promocyjnych i ulepszonej
        komunikacji z klientem
      </h6>
      <p>
        W dzisiejszym pędzącym świecie, napędzanym przez konsumentów, zakupy
        stały się czymś więcej niż tylko doświadczeniem transakcyjnym. To okazja
        do podejmowania świadomych decyzji, wspierania zrównoważonych praktyk i
        współpracy z firmami, które naprawdę troszczą się o swoich klientów.
        Przemyślane zakupy to rosnący trend, a nasz serwis, który publikuje
        gazetki promocyjne, może być Twoją bramą do tego przełomowego
        doświadczenia w handlu detalicznym. Opowiadając się zarówno za
        środowiskiem, jak i skuteczną komunikacją, staramy się wzmacniać pozycję
        zarówno konsumentów, jak i firm. Przyjrzymy się korzyściom płynącym z
        przemyślanych zakupów i kluczowej roli, jaką odgrywa utrzymywanie kanału
        komunikacji z klientami w tworzeniu obopólnie korzystnych doświadczeń
        zakupowych.
      </p>
      <h6>Wzmacnianie pozycji konsumentów:</h6>
      <p>
        Gazetki promocyjne odgrywają kluczową rolę we wzmacnianiu pozycji
        konsumentów w podejmowaniu świadomych decyzji zakupowych. Serwis nasz
        przeznaczony do publikowania tych gazetek zapewnia kompleksowy zbiór
        zniżek, ofert i okazji z różnych sklepów w jednym miejscu. Przeglądając
        te cyfrowe gazetki, konsumenci zyskują szerszą perspektywę na rynek,
        upewniając się, że są świadomi najnowszych dostępnych ofert i promocji.
        Dzięki temu kupujący mogą wybierać produkty i usługi, które są zgodne z
        ich wartościami, jednocześnie oszczędzając pieniądze.
      </p>
      <h6>Zrównoważone praktyki zakupowe:</h6>
      <p>
        Przemyślane zakupy to wspieranie firm, dla których zrównoważony rozwój
        jest priorytetem. Uzyskując dostęp do gazetek promocyjnych online,
        konsumenci mogą odkryć świadome ekologicznie marki oferujące produkty i
        usługi przyjazne dla środowiska. Witryna nasza, które przygotowuje takie
        ulotki, traktuje priorytetowo współpracę z firmami promującymi
        zrównoważone praktyki, czy to poprzez etyczne pozyskiwanie,
        odpowiedzialną produkcję czy zmniejszenie ilości odpadów opakowaniowych.
        Wybór tych przyjaznych dla środowiska opcji za pośrednictwem gazetek
        zachęca do zmiany w kierunku bardziej ekologicznej i zrównoważonej
        przyszłości.
      </p>
      <h6>Korzyści ekonomiczne dla firm:</h6>
      <p>
        Dla firm utrzymywanie kanału komunikacji z klientami jest niezbędne do
        budowania lojalności i zwiększania sprzedaży. Gazetki promocyjne
        stanowią opłacalne narzędzie marketingowe, które pozwala firmom
        bezpośrednio dotrzeć do szerokiej bazy klientów. Współpracując z nami
        poprzez te gazetki, firmy mogą skutecznie przekazywać potencjalnym
        klientom swoje najnowsze oferty, premiery produktów i ekskluzywne
        promocje. Taka ukierunkowana komunikacja buduje świadomość marki,
        zachęca do ponownych zakupów i wzmacnia relacje klient-biznes, co
        przyczynia się do zrównoważonego wzrostu i rentowności.
      </p>
      <h6>Dostosowane doświadczenia zakupowe:</h6>
      <p>
        Skuteczne kanały komunikacji między firmami a klientami umożliwiają
        bardziej spersonalizowane i dostosowane do potrzeb zakupy. Witryny nasza
        umożliwiaja klientom subskrypcję biuletynów lub aktualizacji
        e-mailowych. Dzięki temu firmy mogą wysyłać spersonalizowane oferty i
        rekomendacje w oparciu o indywidualne preferencje i historię zakupów.
        Utrzymując ten ciągły dialog, firmy mogą lepiej zrozumieć potrzeby
        klientów, zwiększając swoją zdolność do zapewniania odpowiednich i
        terminowych promocji, które rezonują z ich docelowymi odbiorcami.
      </p>
      <h6>Zwiększona satysfakcja klienta:</h6>
      <p>
        Otwarte linie komunikacji między firmami a klientami prowadzą do
        zwiększenia zadowolenia klientów. Kiedy klienci czują się wysłuchani i
        docenieni, jest bardziej prawdopodobne, że nawiążą długoterminową
        relację z marką. Serwis nasz publikujący ulotki promocyjne ułatwia to
        połączenie, działając jako pomost między przedsiębiorstwami a
        konsumentami. Dzięki temu klienci mogą przekazywać opinie, zadawać
        pytania lub zgłaszać wątpliwości, podczas gdy firmy mogą szybko
        reagować, wzmacniając zaufanie i lojalność. Rezultatem jest scenariusz
        korzystny dla wszystkich, w którym firmy rozwijają się, spełniając
        oczekiwania klientów, a klienci otrzymują wyjątkową obsługę dostosowaną
        do ich potrzeb.
      </p>

      <p>
        Przemyślane zakupy to potężny wybór, który przynosi korzyści zarówno
        konsumentom, jak i firmom. Korzystając z serwisu cenniczek.com, który
        publikuje gazetki promocyjne, osoby fizyczne mogą podejmować świadome
        decyzje zakupowe, wspierać zrównoważone praktyki i cieszyć się
        oszczędnościami. W przypadku firm utrzymywanie skutecznych kanałów
        komunikacji z klientami pomaga budować lojalność, personalizować
        doświadczenia zakupowe i zwiększać zadowolenie klientów. Przyjmijmy ideę
        przemyślanych zakupów i czerpmy korzyści z bardziej zrównoważonego i
        satysfakcjonującego doświadczenia w handlu detalicznym dla wszystkich
        zaangażowanych stron.
      </p>
      <h6>Zapraszamy serdecznie - Cenniczek.com</h6>
    </>
  );
};
const _InfoSection = ({
  theme,
  select,
  newspaperId,
  selectPageFromDeal,
  setTargetCordinates,
  setTopDealsShoppageFromNewspaper,
  topDealsPageFromNewsPaper,
  overflowHidden,
  clearTargetCordinates,
  clearSelectPageFromDeal,
  brandDataFromUrl,
  categoryDataFromUrl,
}) => {
  const { name } = useParams();

  const titleParams = name && name.split("-").join(" ");

  if (brandDataFromUrl?.error) {
    return (
      <ErrorFromData
        errorInfo={brandDataFromUrl.error}
        queryName={"brandDataFromUrl._InfoSection"}
      />
    );
  }
  if (categoryDataFromUrl?.error) {
    return (
      <ErrorFromData
        errorInfo={categoryDataFromUrl?.error}
        queryName={"categoryDataFromUrl._InfoSection"}
      />
    );
  }

  if (brandDataFromUrl?.loading || categoryDataFromUrl?.loading) {
    return null;
  }
  if (
    brandDataFromUrl &&
    brandDataFromUrl?.data?.brandByName[0]?.name &&
    brandDataFromUrl?.data?.brandByName[0]?.name === titleParams
  ) {
    // const copyArr = [...brandDataFromUrl?.data?.brandByName[0]?.itemProducts];
    const copyArr = [];
    return (
      <>
        <Helmet>
          <link rel="canonical" href={`https://cenniczek.com/${name}`} />
          <meta
            name="description"
            content={`${firstLetterCapitalisation(
              brandDataFromUrl?.data?.brandByName[0]?.describe[0]?.text
            )} 👍 Cenniczek.com. Zaplanuj swoje zakupy w ${name},stwórz liste,kontroluj wydatki 😊`}
          />
          <meta
            property="og:description"
            content={`${firstLetterCapitalisation(
              brandDataFromUrl?.data?.brandByName[0]?.describe[0]?.text
            )} 👍  Zaplanuj swoje zakupy w ${name},stwórz liste, kontroluj wydatki 😊`}
          />
          <meta
            name="keywords"
            content={`${name} gazetka,${name},${name} gazetki promocyjne,${name} aktualne gazetki,cenniczek,${name} aktualne promocje,${name} promocja`}
          ></meta>
          <meta
            property="og:title"
            content={`${firstLetterCapitalisation(
              titleParams
            )} gazetka promocyjna,aktualne promocje ${new Date().toLocaleDateString(
              "pl-PL",
              {
                timeZone: "Europe/Warsaw",
              }
            )} Cenniczek.com`}
          />
          <meta property="og:url" content={`https://cenniczek.com/${name}`} />
          <title>
            {`${firstLetterCapitalisation(
              titleParams
            )} gazetka promocyjna,aktualne promocje ${new Date().toLocaleDateString(
              "pl-PL",
              {
                timeZone: "Europe/Warsaw",
              }
            )} Cenniczek.com`}{" "}
          </title>
        </Helmet>
        <WrraperSection>
          <MainWrapperLayer isDark={theme.isDark}>
            <MainContent isDark={theme.isDark}>
              <SectionHeader isDark={theme.isDark}>
                <h5>
                  <span>{`info ${titleParams}`}</span>
                </h5>
              </SectionHeader>
              <ErrorBoundary>
                <ContentWrapper>
                  <img
                    loading="lazy"
                    src={
                      brandDataFromUrl?.data?.brandByName[0] &&
                      brandDataFromUrl?.data?.brandByName[0]?.imgUrl
                    }
                    alt="cenniczek.com - aktualne gazetki promocyjne"
                  />
                  {brandDataFromUrl?.data?.brandByName[0] &&
                    brandDataFromUrl?.data?.brandByName[0]?.describe.map(
                      (txt, index) => <p key={index}>{txt.text}</p>
                    )}
                </ContentWrapper>
                <SectionHeader isDark={theme.isDark}>
                  <h5>
                    <span>{`tagi znalezione w ${titleParams}`}</span>
                  </h5>
                </SectionHeader>
                <ContentWrapper isDark={theme.isDark}>
                  <section>
                    {brandDataFromUrl?.data?.brandByName[0] &&
                      brandDataFromUrl?.data?.brandByName[0]?.books.map(
                        (item, index) => (
                          <Link
                            key={index}
                            to={`/gazetka-promocyjna/${item.name}-${item.alternativeFinalName}/${item.startDuringTime}-${item.endDuringTime}/1`}
                            onClick={() => {
                              clearSelectPageFromDeal();
                              newspaperId(item.id);
                            }}
                            style={{}}
                          >
                            <h6>{`gazetka promocyjna ${item.name}-${
                              item.alternativeFinalName
                            } od ${item.startDuringTime.slice(
                              0,
                              5
                            )} do ${item.endDuringTime.slice(0, 5)}`}</h6>
                          </Link>
                        )
                      )}
                    {brandDataFromUrl?.data?.brandByName[0] &&
                      copyArr
                        .sort((a, b) => {
                          const nameA = a.categoryKeyProductName;
                          const nameB = b.categoryKeyProductName;
                          return nameA < nameB ? -1 : 1;
                        })
                        .map((item, index) => (
                          <Link
                            key={index}
                            to={`/gazetka-promocyjna/${item.brand}-${item?.bookData[0]?.alternativeFinalName}/${item.startDuringTime}-${item.endDuringTime}/${item.page}`}
                            onClick={() => {
                              // console.log(item);
                              clearSelectPageFromDeal();
                              clearTargetCordinates();
                              newspaperId(item.bookId);
                              selectPageFromDeal(item.page);
                              setTargetCordinates(item.cordinates);
                            }}
                          >
                            <h6>{`promocja ${item.categoryKeyProductName}-${
                              item.name
                            } od ${item.startDuringTime.slice(
                              0,
                              5
                            )} do ${item.endDuringTime.slice(0, 5)}`}</h6>
                          </Link>
                        ))}
                  </section>
                </ContentWrapper>
                <AdvForInfo isDark={theme?.isDark} />
              </ErrorBoundary>
            </MainContent>
          </MainWrapperLayer>
        </WrraperSection>
      </>
    );
  }

  if (
    categoryDataFromUrl &&
    categoryDataFromUrl?.data?.categoryByName[0]?.name &&
    categoryDataFromUrl?.data?.categoryByName[0]?.name === titleParams
  ) {
    const nameFromUrl = categoryDataFromUrl?.data?.categoryByName[0]?.name;
    return (
      <>
        <Helmet>
          <link rel="canonical" href={`https://cenniczek.com/${nameFromUrl}`} />
          <meta
            name="description"
            content="Aktualne gazetki promocyjne twoich ulubionych sieci handlowych 😊 w jednym miejscu,aktualne promocje 👍  Cenniczek.com"
          />
          <meta
            property="og:description"
            content="Cenniczek.com 😊 aktualne gazetki promocyjne 👍 "
          />
          <meta
            name="keywords"
            content={`${nameFromUrl} gazetka,${nameFromUrl} gazetki promocyjne,${nameFromUrl} aktualne gazetki,${nameFromUrl} cenniczek,${nameFromUrl} aktualne promocje,${nameFromUrl} promocja`}
          ></meta>
          <meta
            property="og:title"
            content={`Cenniczek.com - ${titleParams}`}
          />
          <meta
            property="og:url"
            content={`https://cenniczek.com/${nameFromUrl}`}
          />
          <title>{`${titleParams} - cenniczek.com`}</title>
        </Helmet>
        <WrraperSection>
          <MainWrapperLayer isDark={theme.isDark}>
            <MainContent isDark={theme.isDark}>
              <SectionHeader isDark={theme.isDark}>
                <h5>
                  <span>{`info ${titleParams}`}</span>
                </h5>
              </SectionHeader>
              <ContentWrapper>
                <img
                  loading="lazy"
                  src={
                    typeof window !== "undefined"
                      ? Promotions
                      : `${API_URL}/media/img/gazetki-promocyjne.jpg`
                  }
                  alt="cenniczek.com - aktualne gazetki promocyjne"
                />

                <TextContent />
              </ContentWrapper>
              <AdvForInfo isDark={theme.isDark} />
            </MainContent>
          </MainWrapperLayer>
        </WrraperSection>
      </>
    );
  } else {
    return (
      <>
        <WrraperSection>
          <MainWrapperLayer isDark={theme.isDark}>
            <MainContent isDark={theme.isDark}>
              <SectionHeader isDark={theme.isDark}>
                <h5>
                  <span>info</span>
                </h5>
              </SectionHeader>
              <ContentWrapper>
                <img
                  loading="lazy"
                  src={
                    typeof window !== "undefined"
                      ? Promotions
                      : `${API_URL}/server-media/img/gazetki-promocyjne.jpg`
                  }
                  alt="cenniczek.com - aktualne gazetki promocyjne"
                />

                <TextContent />
              </ContentWrapper>
              <AdvForInfo isDark={theme.isDark} />
            </MainContent>
          </MainWrapperLayer>
        </WrraperSection>
      </>
    );
  }
};
const mapDispatchToProps = (dispatch) => ({
  newspaperId: (item) => dispatch(newspaperId(item)),
  selectPageFromDeal: (item) => dispatch(selectPageFromDeal(item)),
  setTargetCordinates: (item) => dispatch(setTargetCordinates(item)),
  pushItemToList: (item) => dispatch(pushItemToList(item)),
  overflowHidden: () => dispatch(overflowHidden()),
  clearSelectPageFromDeal: (item) => dispatch(clearSelectPageFromDeal(item)),
  clearTargetCordinates: (item) => dispatch(clearTargetCordinates(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
});
export const InfoSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(_InfoSection);
