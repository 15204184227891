import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  defaults,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { Theme } from "../../../../store/theme/theme";
import { ItemsAnalise } from "./style";
ChartJS.register(ArcElement, Tooltip, Legend);
export const ItemsChart = ({ dataAnalise, isDark }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItem, data) {
            return tooltipItem[0].label;
          },
          label: function (tooltipItem, data) {
            const dataFromAll = tooltipItem.dataset.data.map((item) =>
              item ? parseFloat(item) : 0
            );
            const total = dataFromAll.reduce((all, item) => all + item, 0);

            const percentage = parseFloat(
              ((tooltipItem.parsed / total) * 100).toFixed(1)
            );

            return `${tooltipItem.parsed} PLN - ${percentage}% `;
          },
        },
      },
      legend: { display: true },
    },
    tooltips: {
      enabled: false,
    },
  };

  const labels = dataAnalise.shopingList.map(
    (item) =>
      `${item.name ? item.name : ""} ${
        item.aditionalName ? item.aditionalName : ""
      }`
  );
  const dataChart = {
    labels,
    datasets: [
      {
        label: "Artykuły",
        data: dataAnalise.shopingList.map((item) =>
          (
            (parseFloat(item.price) || 0) * (parseFloat(item.howMany) || 0)
          ).toFixed(2)
        ),
        backgroundColor: [
          "#0bb4ff",
          "#50e991",
          "#e6d800",
          "#9b19f5",
          "#ffa300",
          "#dc0ab4",
          "#b3d4ff",
          "#00bfa0",
          "#e60049",
          "rgba(255,0,0, 1)",
          "rgba(0,0,255, 1)",
          "rgba(0,255,255, 1)",
          "rgba(255,0,255, 1)",
          "rgba(128,0,128, 1)",
          "rgba(255,255,0, 1)",
          "rgba(0,128,0, 1)",
          "rgba(0,255,0, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 12, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(235, 129, 34, 1)",
          "rgba(205, 199, 32, 1)",
          "rgba(154, 102, 25, 1)",
          "rgba(255, 226, 186, 1)",
          "rgba(75, 142, 122, 1)",
          "rgba(153, 12, 235, 1)",
          "rgba(255, 129, 164, 1)",
        ],
        borderColor: [
          "#0bb4ff",
          "#50e991",
          "#e6d800",
          "#9b19f5",
          "#ffa300",
          "#dc0ab4",
          "#b3d4ff",
          "#00bfa0",
          "#e60049",
          "rgba(255,0,0, 1)",
          "rgba(0,0,255, 1)",
          "rgba(0,255,255, 1)",
          "rgba(255,0,255, 1)",
          "rgba(128,0,128, 1)",
          "rgba(255,255,0, 1)",
          "rgba(0,128,0, 1)",
          "rgba(0,255,0, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 12, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(235, 129, 34, 1)",
          "rgba(205, 199, 32, 1)",
          "rgba(154, 102, 25, 1)",
          "rgba(255, 226, 186, 1)",
          "rgba(75, 142, 122, 1)",
          "rgba(153, 12, 235, 1)",
          "rgba(255, 129, 164, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  //   console.log(labels, "LABELS");
  defaults.fontFamily = ["Montserrat", "Helvetica Neue"];
  defaults.color = isDark
    ? Theme.dark.thirdColor.white
    : Theme.light.thirdColor.dark;
  defaults.borderColor = isDark
    ? Theme.light.fiveColor.white
    : Theme.dark.sixColor.dark;

  return (
    <ItemsAnalise>
      <Pie
        data={dataChart}
        options={options}
        height={300}
        style={{
          background: `${
            isDark
              ? Theme.dark.primaryColor.dark
              : Theme.light.primaryColor.white
          }`,
          padding: "15px",
          borderRadius: "5px",
        }}
      />
    </ItemsAnalise>
  );
};
