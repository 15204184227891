import React from "react";
import { UserLogin } from "../components/login/UserLogin";
import { Footer } from "../components/footerSection/Footer";
import { Helmet } from "react-helmet";
export const LoginPage = () => {
  // if (typeof document === "undefined" || typeof window === "undefined") {
  //   return null;
  // }

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://cenniczek.com/logowanie`} />
        <meta name="description" content="Logowanie - Cenniczek.com" />
        <meta property="og:description" content="Logowanie - Cenniczek.com" />
        <meta name="keywords" content="Logowanie - Cenniczek.com"></meta>
        <meta property="og:title" content="Cenniczek.com-logowanie" />
        <meta property="og:url" content={`https://cenniczek.com/logowanie`} />
        <title>Cenniczek.com-logowanie</title>
      </Helmet>
      <UserLogin />
      <Footer />
    </>
  );
};
