import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "pure-react-carousel/dist/react-carousel.es.css";
import { API_URL } from "../../constant/constant";
import { SvgColorStyle } from "../../utils/SvgColorStyle";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";
import { getDataForRightSideCaruselQuery } from "../../queries/queries";
import {
  addToSugestedNewspapers,
  removeOneFromSugestedNewspapers,
  clearSugestedNewspapers,
} from "../../store/select/duck/actions/actions";
const startPath =
  "M436 160H12c-6.627 0-12-5.373-12-12v-36c0-26.51 21.49-48 48-48h48V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h128V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h48c26.51 0 48 21.49 48 48v36c0 6.627-5.373 12-12 12zM12 192h424c6.627 0 12 5.373 12 12v260c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V204c0-6.627 5.373-12 12-12zm333.296 95.947l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L194.12 364.665l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068l-28.397 28.17c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z";
const endPath =
  "M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm257.3 160l48.1-48.1c4.7-4.7 4.7-12.3 0-17l-28.3-28.3c-4.7-4.7-12.3-4.7-17 0L224 306.7l-48.1-48.1c-4.7-4.7-12.3-4.7-17 0l-28.3 28.3c-4.7 4.7-4.7 12.3 0 17l48.1 48.1-48.1 48.1c-4.7 4.7-4.7 12.3 0 17l28.3 28.3c4.7 4.7 12.3 4.7 17 0l48.1-48.1 48.1 48.1c4.7 4.7 12.3 4.7 17 0l28.3-28.3c4.7-4.7 4.7-12.3 0-17L269.3 352z";
export const _ShowCarusel = ({
  brandBooks,
  brandName,
  categoryName,
  categoryBooks,
  newspaperId,
  clearSelectPageFromDeal,
  clearTargetCordinates,
  isDark,
  selectId,
  booksLength,
  select,
  addToSugestedNewspapers,
  removeOneFromSugestedNewspapers,
  bookDataLoading,
  slideNr,
  postDataLoading,
}) => {
  const [sugestedBooks, setSugestedBooks] = useState([]);
  const [allBooks, setAllBooks] = useState([]);
  const variableForSort =
    select?.sort.filter((item) => item.active)[0]?.name || "recomended";
  const dataFromAllNewspaperPage = useQuery(getDataForRightSideCaruselQuery, {
    variables: {
      name: "gazetki markety",
      sortType: variableForSort,
    },
    ssr: true,
  });
  // console.log(sugestedBooks, "data");
  // console.log(allBooks, "allBooks");
  // console.log(bookDataLoading, "bookData");
  // console.log(dataFromAllNewspaperPage?.loading, "dataFromAllNewspaperPage");
  // console.log({ brandBooks, categoryBooks, allBooks, sugestedBooks });
  useEffect(() => {
    if (
      dataFromAllNewspaperPage?.data?.forAllBooksInRightSideCarusel[0]?.allBooks
        ?.length
    ) {
      setAllBooks(
        dataFromAllNewspaperPage?.data?.forAllBooksInRightSideCarusel[0]
          ?.allBooks
      );
    }
  }, [dataFromAllNewspaperPage.loading]);
  useEffect(() => {
    let recomendetBooks = [];
    if (
      !select?.sugestedNewspapers?.length &&
      (bookDataLoading === false || postDataLoading === false) &&
      brandBooks?.length > 0
    ) {
      recomendetBooks = [...brandBooks];
    }
    if (
      !select?.sugestedNewspapers?.length &&
      (bookDataLoading === false || postDataLoading === false) &&
      categoryBooks?.length
    ) {
      recomendetBooks = [...recomendetBooks, ...categoryBooks];
    }
    if (
      !select?.sugestedNewspapers?.length &&
      (bookDataLoading === false || postDataLoading === false) &&
      allBooks?.length
    ) {
      recomendetBooks = [...recomendetBooks, ...allBooks];
    }
    const booksFiltered = recomendetBooks.map((item) => {
      const selectedId = selectId;
      if (selectedId !== item.id) {
        return item;
      }
      return null;
    });
    const booksAfterNull = booksFiltered.filter((item) => item !== null);
    const removeDuplicate = booksAfterNull.filter(
      (item, index, self) =>
        self.findIndex((object) => object.id === item.id) === index
    );

    // ---------------------------------------------------------------------------------------------------
    setSugestedBooks(removeDuplicate);
    booksLength(removeDuplicate.length);

    return () => {
      sugestedBooks.length = 0;
    };
  }, [
    brandBooks,
    categoryBooks,
    selectId,
    sugestedBooks?.length,
    allBooks?.length,
    bookDataLoading,
    postDataLoading,
  ]);
  useEffect(() => {
    sugestedBooks?.length && addToSugestedNewspapers(sugestedBooks);
  }, [sugestedBooks?.length]);

  if (select?.sugestedNewspapers?.length) {
    return select.sugestedNewspapers.map((item, index) => {
      let imgSrc;
      if (index >= slideNr - 3 && index <= slideNr + 6) {
        imgSrc =
          (item.thumbnail && `${API_URL}${item.thumbnail}`) ||
          (item.imgUrl && `${API_URL}${item.imgUrl}`);
      } else {
        imgSrc = "";
      }

      return (
        <li key={index}>
          <div>
            <Link
              to={`/gazetka-promocyjna/${item.name}-${item.alternativeFinalName}/${item.startDuringTime}-${item.endDuringTime}/1`}
              onClick={() => {
                // console.log(item);
                clearSelectPageFromDeal();
                clearTargetCordinates();
                newspaperId(item.id);
              }}
              style={{
                width: "100%",
                height: "230px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "5px",
                position: "relative",
                textDecoration: "none",
                margin: "5px 0 10px 0",
                border: "none",
              }}
              key={index}
            >
              <div
                style={{
                  width: "130px",
                  height: "230px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <div>
                  <img
                    loading="lazy"
                    // src={item.thumbnail || item.imgUrl}
                    src={imgSrc}
                    style={{
                      width: "130px",
                      display: "block",
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                    alt={`gazetka ${item.name} czas obowiązywania od ${item.startDuringTime} do ${item.endDuringTime}`}
                  />
                </div>
              </div>
              <div
                isDark={isDark}
                style={{
                  height: "20px",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  margin: "5px",
                  zIndex: "2",
                }}
              >
                <div>
                  <span>
                    {/* <StartTime
                    style={{
                      width: "11px",
                      marginRight: "5px",
                      marginLeft: "5px",
                      textAlign: "right",
                    }}
                  /> */}
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="calendar-check"
                      className="svg-inline--fa fa-calendar-check fa-w-14"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      style={{
                        width: "11px",
                        marginRight: "5px",
                        marginLeft: "5px",
                        textAlign: "right",
                      }}
                      width={11}
                    >
                      <path fill="currentColor" d={startPath}></path>
                    </svg>
                    {item.startDuringTime.slice(
                      0,
                      item.startDuringTime.length - 5
                    )}
                  </span>
                  <span>
                    {/* <EndTime
                    style={{
                      width: "11px",
                      marginRight: "5px",
                      marginLeft: "5px",
                    }}
                  /> */}
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="calendar-times"
                      className="svg-inline--fa fa-calendar-times fa-w-14"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      style={{
                        width: "11px",
                        marginRight: "5px",
                        marginLeft: "5px",
                      }}
                      width={11}
                    >
                      <path fill="currentColor" d={endPath}></path>
                    </svg>
                    {item.endDuringTime.slice(0, item.endDuringTime.length - 5)}
                  </span>
                </div>
              </div>
            </Link>
          </div>
        </li>
      );
    });
  }

  return null;
};

const mapDispatchToProps = (dispatch) => ({
  addToSugestedNewspapers: (item) => dispatch(addToSugestedNewspapers(item)),
  removeOneFromSugestedNewspapers: (item) =>
    dispatch(removeOneFromSugestedNewspapers(item)),
});
const mapStateToProps = (state) => ({
  select: state.select,
});

export const ShowCarusel = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ShowCarusel);
