import React, { useEffect, useState } from "react";
import { SingleSlideMobile } from "../singleSlideMobile/SingleSlideMobile";

export const ShowDealsMobile = ({
  brandDataFromUrl,
  brandItemProductsDataFromUrl,
  select,
  topDealsSelect,
  slideStyleActive,
  slideStyle,
  caruselWraperVariants,
  topDealsSelectedName,
  dataFromHomePage,
  dataFromTopDealsPage,
  bookData,
}) => {
  // console.log(bookData);
  if (
    brandDataFromUrl?.loading === false ||
    brandItemProductsDataFromUrl?.loading === false ||
    dataFromHomePage?.loading === false ||
    dataFromTopDealsPage?.loading === false ||
    bookData?.loading === false
  ) {
    let activeItemProduct = [];
    if (
      brandDataFromUrl?.data?.brandByName[0]?.productKeysCategory?.length > 0
    ) {
      const copyArr = [
        ...brandDataFromUrl?.data?.brandByName[0]?.productKeysCategory,
      ];
      activeItemProduct = copyArr
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((item) => {
          const numberOfItemsProductFromCategoryKeys =
            brandItemProductsDataFromUrl?.data?.brandItemProducts.filter(
              (product) => product.categoryKeyProductName === item.name
            ).length;
          return numberOfItemsProductFromCategoryKeys > 0
            ? { ...item, numberOfItemsProductFromCategoryKeys }
            : null;
        })
        .filter((item) => item);
    } else if (
      dataFromHomePage?.data?.forHomePage[0]?.productKeysCategory.length > 0
    ) {
      const copyArr = [
        ...dataFromHomePage?.data?.forHomePage[0]?.productKeysCategory,
      ];
      activeItemProduct = copyArr.sort((a, b) =>
        a.name < b.name ? -1 : a.name > b.name ? 1 : 0
      );
    } else if (
      dataFromTopDealsPage?.data?.forTopDealsPage[0]?.productKeysCategory
        .length > 0
    ) {
      const copyArr = [
        ...dataFromTopDealsPage?.data?.forTopDealsPage[0]?.productKeysCategory,
      ];
      activeItemProduct = copyArr
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .filter((item) => item.categoryItems > 0);
    } else if (bookData?.data?.bookNotId[0]?.productKeysCategory.length > 0) {
      const copyArr = [...bookData?.data?.bookNotId[0]?.productKeysCategory];
      activeItemProduct = copyArr
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((item) => {
          const numberOfItemsProductFromCategoryKeys =
            bookData?.data?.bookNotId[0]?.productsItems.filter(
              (product) => product.categoryKeyProductName === item.name
            ).length;
          return numberOfItemsProductFromCategoryKeys > 0
            ? { ...item, numberOfItemsProductFromCategoryKeys }
            : null;
        })
        .filter((item) => item);
    }

    return (
      activeItemProduct &&
      activeItemProduct.map((item, index) => (
        <SingleSlideMobile
          key={index}
          item={item}
          index={index}
          select={select}
          slideStyleActive={slideStyleActive}
          slideStyle={slideStyle}
          caruselWraperVariants={caruselWraperVariants}
          topDealsSelect={topDealsSelect}
          topDealsSelectedName={topDealsSelectedName}
          dataFromTopDealsPage={dataFromTopDealsPage}
        />
      ))
    );
  } else return null;
};
