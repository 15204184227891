import styled, { keyframes } from "styled-components";
import { Theme } from "../../../store/theme/theme";
import { motion } from "framer-motion";

export const modalWraperVariants = {
  hidden: {
    opacity: 0,
  },
  visible: (index) => ({
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
    },
  }),
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      delay: 0.3,
    },
  },
};
export const DescribeFutureContainer = styled(motion.div)`
  position: absolute;
  top: 70px;
  left: -70px;
  width: 130px;
  border-radius: 5px;
  background: ${Theme.light.secondaryColor.green};
  color: ${Theme.dark.thirdColor.white};
  // border: 1px solid black;
  z-index: 93;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  line-height: 16px;
  & > h3 {
    text-transform: uppercase;
    margin: 10px;
    font-size: 16px;
    text-align: center;
  }
  & > p {
    text-align: center;
  }
  &:after {
    display: block;
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: -10px;
    left: 65px;
    border: 15px solid ${Theme.light.secondaryColor.green};
    border-bottom-color: transparent;
    border-left-color: transparent;
    transform: rotate(-45deg);
  }
`;
export const AcceptButton = styled(motion.div)`
  width: 80px;
  height: 40px;
  border-radius: 5px;
  line-height: 40px;
  font-size: 1em;
  background: ${Theme.light.fourthColor.red};
  color: ${Theme.light.primaryColor.white};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  margin: 10px;
  &:hover {
    box-shadow: 2px 2px 7px black;
  }
  & > p {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  & > svg {
    padding-right: 16px;
  }
  @media${Theme.device.mobileL} {
    margin-bottom: 30px;
    & > svg {
      padding: 0;
    }
  }
`;
export const styleForActionsSvg = {
  height: "20px",
  paddingRight: "10px",
};
