import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { connect } from "react-redux";
import { getLocation } from "../../store/localization/duck/operations/getLocation";
import { Theme } from "../../store/theme/theme";

import { FetchingData } from "./FetchingData";
import { BurgerNav } from "./burgerNav/BurgerNav";
import { LogoWrapper } from "./logoWrapper/LogoWrapper";
import { ThemeSwitcher } from "./themeSwitcher/ThemeSwitcher";
import { SearchBar } from "./searchBar/SearchBar";
import { Login } from "./login/Login";
import { User } from "./user/User";
import { PageReloaded } from "../../utils/PageReloaded";

const svgPath =
  "M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z";
const closePath =
  "M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z";

const Icon = styled.div`
  display: none;
  width: 20px;
  z-index: 2;
  cursor: pointer;
  @media${Theme.device.tablet} {
    display: block;
  }
`;
const forLight = {
  color: Theme.light.thirdColor.dark,
  width: "20px",
  height: "20px",
};
const forDark = {
  color: Theme.dark.thirdColor.white,
  width: "20px",
  height: "20px",
};

const MainHeaderWrapper = styled.header`
  width: 100%;
  height: 70px;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  box-shadow: 2px 2px 10px black;
  transition: background 0.3s;
  z-index: 91;
`;
const HeaderWrapper = styled.div`
  position: relative;
  max-width: ${Theme.breakPoint.desktop}px;
  margin: 0 auto;
  @media${Theme.device.tablet} {
    max-width: ${Theme.breakPoint.tablet}px;
  }
`;
const StyledHeader = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  min-height: 70px;

  color: black;
  position: absolute;
  top: 0;
  left: 0;
  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > a > img {
    max-width: 350px;
    height: 40px;
    margin-left: 5px;
  }
  @media${Theme.device.mobileM} {
    & > a > img {
      max-width: 175px;
      margin-left: 15px;
      height: 25px;
    }
  }
  @media${Theme.device.mobileS} {
    & > a > img {
      max-width: 125px;
      margin-left: 10px;
    }
  }
  @media${Theme.device.mobileXS} {
    & > a > img {
      max-width: 80px;
      margin-left: 2px;
    }
  }
`;
const resizeScreen = (mainSearchBarVisible, setMainSearchBarVisible) => {
  window.addEventListener("resize", () => {
    if (window.innerWidth > Theme.breakPoint.tablet && mainSearchBarVisible) {
      console.log(window.innerWidth);
      setMainSearchBarVisible(false);
    }
  });
};

const _Header = ({ theme, localization, getLocation }) => {
  const [cord, setCord] = useState({});
  const [pageUpdated, setPageUpdated] = useState(false);
  const [mainSearchBarVisible, setMainSearchBarVisible] = useState(false);
  const [flagCord, setFlagCoord] = useState(localization?.setLocalization);
  const arround = localization?.arround.filter((item) => item.active)[0].value;
  const reload = PageReloaded();
  useEffect(() => {
    resizeScreen(mainSearchBarVisible, setMainSearchBarVisible);
  }, []);
  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    const active = localization.setLocalization;

    if (active && !flagCord) {
      setFlagCoord(active);
    }
    if (!active) {
      setFlagCoord(active);
    }
    if (!flagCord) {
      setFlagCoord(active);
    }
    if (flagCord) {
      const userCoord = localization.coordinates;
      setCord(userCoord);
    }
  }, [localization?.setLocalization, flagCord, localization?.coordinates.lat]);

  return (
    <MainHeaderWrapper isDark={theme.isDark}>
      <FetchingData
        coordLat={cord?.lat}
        coordLng={cord?.lng}
        arround={arround}
      />
      <HeaderWrapper>
        <StyledHeader>
          {!mainSearchBarVisible && <LogoWrapper isDark={theme.isDark} />}
          <SearchBar
            isDark={theme.isDark}
            mobile={mainSearchBarVisible}
            closeSearchInput={setMainSearchBarVisible}
          />
          {!mainSearchBarVisible && <ThemeSwitcher />}
          {!mainSearchBarVisible && <Login />}
          {!mainSearchBarVisible && (
            <Icon onClick={() => setMainSearchBarVisible(true)}>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="search"
                className="svg-inline--fa fa-search fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                style={theme.isDark ? forDark : forLight}
              >
                <path
                  fill={
                    theme.isDark
                      ? Theme.dark.thirdColor.white
                      : Theme.light.thirdColor.dark
                  }
                  d={svgPath}
                ></path>
              </svg>
            </Icon>
          )}
          {mainSearchBarVisible && (
            <Icon onClick={() => setMainSearchBarVisible(false)}>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                className="svg-inline--fa fa-times fa-w-11"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
                style={theme.isDark ? forDark : forLight}
              >
                <path
                  fill={
                    theme.isDark
                      ? Theme.dark.thirdColor.white
                      : Theme.light.thirdColor.dark
                  }
                  d={closePath}
                ></path>
              </svg>
            </Icon>
          )}
          {!mainSearchBarVisible && !theme?.isMobile && (
            <User>
              <p>Wydatki</p>
            </User>
          )}
          <BurgerNav isDark={theme.isDark} />
        </StyledHeader>
      </HeaderWrapper>
    </MainHeaderWrapper>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
  localization: state.localization,
});
const mapDispatchToProps = (dispatch) => ({
  getLocation: (item) => dispatch(getLocation(item)),
});
export const Header = connect(mapStateToProps, mapDispatchToProps)(_Header);
