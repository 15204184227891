export const TOGGLE_THEME = "TOGGLE_THEME";
export const MENU_OPEN = "MENU_OPEN";
export const MENU_CLOSE = "MENU_CLOSE";
export const OVER_CLOSE = "OVER_CLOSE";
export const OVER_OPEN = "OVER_OPEN";
export const CHECK_IS_MOBILE = "CHECK_IS_MOBILE";

// export default {
//   TOGGLE_THEME,
//   MENU_OPEN,
//   MENU_CLOSE,
//   OVER_CLOSE,
//   OVER_OPEN,
// };
