import React, { useEffect, useMemo, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import { connect } from "react-redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { StaticRouter } from "react-router";
import { ApolloProvider } from "@apollo/client";
// import { clientSSR } from "./apollo/ApolloSSR";
import { clientSSR } from "./apollo/ApolloSSRWithoutCrypto";

import "./App.css";
import { Header } from "./components/header/Header";
import { SelectViev } from "./components/main/selectViev/SelectViev";

// --------- pages
import { HomePage } from "./pages/HomePage";
import { AllNewspaperPage } from "./pages/AllNewspaperPage";
import { MagazinePage } from "./pages/MagazinePage";
import { ContactPage } from "./pages/ContactPage";
import { TermsPage } from "./pages/TermsPage";
import { PolicyPage } from "./pages/PolicyPage";
import { NewsletterConfirmationPage } from "./pages/NewsletterConfirmationPage";
import { BlogPage } from "./pages/BlogPage";
import { UserPage } from "./pages/UserPage";
import { BrandPage } from "./pages/BrandPage";
import { CategoryPage } from "./pages/CategoryPage";
import { TopDealsPage } from "./pages/TopDealsPage";
import { LoginPage } from "./pages/LoginPage";
import { RegisterPage } from "./pages/RegisterPage";
import { RegisterConfirmationPage } from "./pages/RegisterConfirmationPage";
import { ShopingList } from "./components/shopingList/ShopingList";
import { RodoConfirmation } from "./components/rodoConfirmation/RodoConfirmation";
// import { init, RouteTracker } from "./utils/GoogleAnalytics";
import { ErrorBoundary } from "./components/errorBoundary/ErrorBoundary";
import { Theme } from "./store/theme/theme";
import "./index.css";
import { PageReloaded } from "./utils/PageReloaded";
import { PromoSection } from "./components/promoSection/PromoSection";
import { PasswordResetPage } from "./pages/PasswordResetPage";
import { MobileMenu } from "./components/mobileMenu/MobileMenu";
import { ScrollToTop } from "./helpers/scrollToTop/SrollToTop";
import { PostPage } from "./pages/PostPage";

const GlobalStyle = createGlobalStyle`
*,*::after,*::before{
  box-sizing:border-box;
  margin:0;
  padding:0;
  scroll-behavior: smooth;
}
body{
  overflow-y:${({ overflow }) => (overflow ? `hidden !important` : "")};
  overflow-y:${({ overflowY }) => (overflowY ? `hidden !important` : "")};
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 0 5px 5px 0;
    background-color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border-left: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border-right: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
   
    background-color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  @media${Theme.device.mobileL} {
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 0 2px 2px 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
    
      border-right: none;
      border-left: none;
    }
  }
}
`;

const AppRoutes = ({ theme }) => {
  const [urlChecked, setUrlChecked] = useState(true);
  const [selectViewData, setSelectViewData] = useState([]);
  const forCaruselsData = useMemo(
    () => selectViewData,
    [selectViewData?.allBrands?.length]
  );
  return (
    <>
      <ErrorBoundary>
        {/* {init() && <RouteTracker />} */}
        <GlobalStyle
          overflow={theme?.isMenuOpen}
          overflowY={theme?.canOverflow}
          isDark={theme?.isDark}
        />
        <RodoConfirmation />
        <MobileMenu />
        <ShopingList />
        <ScrollToTop />
        <Header />
        <Switch>
          <Route path="/" exact>
            <PromoSection />
            <SelectViev
              setUrlChecked={setUrlChecked}
              urlChecked={urlChecked}
              // selectViewData={selectViewData}
              selectViewData={forCaruselsData}
            />
            <HomePage
              setSelectViewData={setSelectViewData}
              selectViewData={selectViewData}
            />
          </Route>
          <Route path="/gazetki-promocyjne" exact>
            <PromoSection />
            <SelectViev
              setUrlChecked={setUrlChecked}
              urlChecked={urlChecked}
              selectViewData={selectViewData}
            />
            <AllNewspaperPage
              setSelectViewData={setSelectViewData}
              selectViewData={selectViewData}
            />
          </Route>
          <Route path="/gazetka-promocyjna/:name-:alternativeFinalName/:duringTime/:pageNr">
            <MagazinePage />
          </Route>
          <Route path="/kontakt" exact>
            <ContactPage />
          </Route>
          <Route path="/regulamin" exact>
            <TermsPage />
          </Route>
          <Route path="/polityka" exact>
            <PolicyPage />
          </Route>
          <Route path="/newsletter/succes" exact>
            <NewsletterConfirmationPage />
          </Route>
          <Route path="/promocje" exact>
            <TopDealsPage />
          </Route>
          <Route path="/blog" exact>
            <BlogPage />
          </Route>
          <Route path="/blog/:title">
            <PostPage />
          </Route>
          <Route path="/twoje-zakupy" exact>
            <UserPage />
          </Route>
          <Route path="/logowanie" exact>
            <LoginPage />
          </Route>
          <Route path="/logowanie/reset-hasla" exact>
            <PasswordResetPage />
          </Route>
          <Route path="/rejestracja" exact>
            <RegisterPage />
          </Route>
          <Route path="/rejestracja/potwierdzona" exact>
            <RegisterConfirmationPage confirmed={true} />
          </Route>
          <Route path="/rejestracja/przekroczenie-czasu-na-potwierdzenie" exact>
            <RegisterConfirmationPage timeExpired={true} />
          </Route>
          <Route path="/rejestracja/konto-potwierdzone-wczesniej" exact>
            <RegisterConfirmationPage alreadyConfirmed={true} />
          </Route>
          <Route path="/gazetki-:name/:section?">
            <PromoSection />
            <SelectViev
              urlChecked={urlChecked}
              selectViewData={selectViewData}
            />
            <CategoryPage
              setUrlChecked={setUrlChecked}
              setSelectViewData={setSelectViewData}
              selectViewData={selectViewData}
            />
          </Route>
          <Route path="/:name/:section?">
            <PromoSection />
            <SelectViev
              urlChecked={urlChecked}
              selectViewData={selectViewData}
            />
            <BrandPage
              setUrlChecked={setUrlChecked}
              setSelectViewData={setSelectViewData}
              selectViewData={selectViewData}
            />
          </Route>
        </Switch>
      </ErrorBoundary>
    </>
  );
};

const _App = ({ theme, store, location }) => {
  const reloaded = PageReloaded();
  useEffect(() => {
    window.__INITIAL_STATE__ = {};
  }, [reloaded]);
  return (
    <Provider store={store}>
      <ApolloProvider client={clientSSR}>
        {!location ? (
          <BrowserRouter>
            <AppRoutes theme={theme} />
          </BrowserRouter>
        ) : (
          <StaticRouter context={{}} location={location}>
            <AppRoutes theme={theme} />
          </StaticRouter>
        )}
      </ApolloProvider>
    </Provider>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
});
export const App = connect(mapStateToProps, {})(_App);
