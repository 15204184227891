import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Theme } from "../../../../../store/theme/theme";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const BrandBar = ({ day, isDark }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    radius: 5,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: function (tooltipItem, data) {
            return tooltipItem[0].label;
          },
          label: function (tooltipItem, data) {
            const index = tooltipItem.dataIndex;
            const dataFromAll = tooltipItem.dataset.data.map((item) =>
              item ? parseFloat(item) : 0
            );
            const total = dataFromAll.reduce((all, item) => all + item, 0);

            const percentage = parseFloat(
              ((tooltipItem.dataset.data[index] / total) * 100).toFixed(1)
            );

            return `${tooltipItem.dataset.data[index].toFixed(
              2
            )} PLN - ${percentage}% `;
          },
        },
      },
      legend: { display: true },
    },
    datasetFill: false,
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return value + " PLN";
          },
        },
      },
    },
    tooltips: {
      enabled: false,
    },
  };

  const brands = day.result.item.list
    .map((item, index, self) => {
      if (!item.brand) return "brak";
      return self.findIndex((selfItem) => selfItem.brand === item.brand) !==
        index
        ? null
        : item.brand;
    })
    .filter((item) => item);
  const labels = brands.map((item, index, self) => {
    return self.findIndex((selfItem) => selfItem === item) !== index
      ? null
      : item;
  });

  const dataChart = {
    labels,
    datasets: [
      {
        label: "Sklepy",
        data: labels.map((label) => {
          const res = day.result.item.list.filter((item) => {
            if (label === "brak") {
              return item.brand === "";
            } else {
              return item.brand === label;
            }
          });

          const resPrice = res.map((item) =>
            parseFloat(
              (
                (parseFloat(item.price) || 0) * (parseFloat(item.howMany) || 0)
              ).toFixed(2)
            )
          );

          return resPrice.reduce((total, item) => total + item, 0);
        }),
        backgroundColor: [
          "#ea5545",
          "#f46a9b",
          "#ef9b20",
          "#edbf33",
          "#ede15b",
          "#bdcf32",
          "#87bc45",
          "#27aeef",
          "#b33dc6",
          "rgba(0,255,0, 1)",
          "rgba(0,0,255, 1)",
          "rgba(255,0,0, 1)",
          "rgba(255,255,0, 1)",
          "rgba(0,255,255, 1)",
          "rgba(255,0,255, 1)",
          "rgba(128,0,128, 1)",
          "rgba(0,128,0, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 12, 214, 1)",
          "rgba(54, 62, 135, 1)",
          "rgba(25, 16, 86, 1)",
          "rgba(215, 59, 124, 1)",
        ],
        borderColor: [
          "#ea5545",
          "#f46a9b",
          "#ef9b20",
          "#edbf33",
          "#ede15b",
          "#bdcf32",
          "#87bc45",
          "#27aeef",
          "#b33dc6",

          "rgba(0,255,0, 1)",
          "rgba(0,0,255, 1)",
          "rgba(255,0,0, 1)",
          "rgba(255,255,0, 1)",
          "rgba(0,255,255, 1)",
          "rgba(255,0,255, 1)",
          "rgba(128,0,128, 1)",
          "rgba(0,128,0, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 12, 214, 1)",
          "rgba(54, 62, 135, 1)",
          "rgba(25, 16, 86, 1)",
          "rgba(215, 59, 124, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  defaults.fontFamily = ["Montserrat", "Helvetica Neue"];
  defaults.color = isDark
    ? Theme.dark.thirdColor.white
    : Theme.light.thirdColor.dark;
  defaults.borderColor = isDark
    ? Theme.light.fiveColor.white
    : Theme.dark.sixColor.dark;
  defaults.animation.delay = 300;

  return (
    <Bar
      data={dataChart}
      options={options}
      style={{
        background: `${
          isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white
        }`,
        padding: "15px",
        borderRadius: "5px",
      }}
    />
  );
};
