import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { GRAPHQL_URL } from "../constant/constant";
import axios from "axios";
import { Response } from "node-fetch";
let numberGraphQLWrongresponse = 0;

function createResponseFromAxiosResp(axiosResp, data) {
  data = data || axiosResp?.data;
  let ret = new Response(JSON.stringify(data), {
    ...axiosResp,
  });
  return ret;
}
const myFetch = (url, option) => {
  const { variables, query, operationName } = JSON.parse(option.body);
  const frontData = {
    variables,
    query,
    operationName,
  };

  // if (typeof window?.axios?.defaults?.headers?.common === 'undefined') {
  //   window?.axios?.defaults?.headers?.common = {};
  //   window?.axios?.defaults?.headers?.common['X-Requested-With'] = 'XMLHttpRequest';
  // }
  // axios?.defaults?.headers = {
  //   Cache-Control: "no-cache",
  //   Pragma: "no-cache",
  //   Expires: "0",
  // };
  axios.defaults.headers.common["header1"] = "application/json";
  return axios({
    method: "post",
    url: `${url}`,
    data: { data: frontData },
    headers: {
      ["Content-Type"]: "application/json",
      ["Access-Control-Allow-Origin"]: "*",
    },
  })
    .then((result) => {
      if (result?.data) {
        return Promise.resolve(
          createResponseFromAxiosResp(result, result?.data)
        );
      }
    })
    .catch((err) => {
      if (numberGraphQLWrongresponse <= 3) {
        numberGraphQLWrongresponse++;
        myFetch(url, option);
        console.log(
          "[myFetch-graphQL]-Wrong graphQLResposne",
          err?.message ? err.message : err
        );
      } else {
        console.log(
          "[myFetch-graphQL]-exceeds alowed nr of wrongResponse",
          err
        );
      }
    });
};
const dataToResore =
  typeof window !== "undefined" && window?.__APOLLO_STATE__
    ? window.__APOLLO_STATE__
    : {};

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "ignore",
  },
};
export const clientSSR = new ApolloClient({
  ssrMode: typeof window === "undefined",
  ssrForceFetchDelay: 100,
  link: createHttpLink({
    uri: `${GRAPHQL_URL}/graphql`,
    fetch: async (url, option) => await myFetch(url, option),
  }),
  defaultOptions: defaultOptions,
  // cache: new InMemoryCache(),
  cache: new InMemoryCache().restore(dataToResore),
});
