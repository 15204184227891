import styled from "styled-components";
import { Theme } from "../../../store/theme/theme";

export const ItemWraper = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
    borderBottom: `1px solid ${
      props.isDark ? "black" : Theme.light.thirdColor.dark
    }`,
  },
}))`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  z-index: 91;
`;

export const ItemContent = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 5px;
`;

export const ImgWraper = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  width: 30%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  position: relative;
  & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  }
`;
export const InfoWraper = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  max-width: 50%;
  // height:60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  & > p {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    line-height: 18px;
    padding: 10px;
    text-transform: uppercase;
  }
  & > a:hover {
  }
`;
export const BrandWraper = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  & > img,
  & > p {
    width: 100%;
  }
  & > p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
  }
  & > p > svg {
    vertical-align: -2px;
  }
  @media${Theme.device.mobileL} {
    width: 25%;
  }
`;
export const ActionsItem = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 10px;
  & > a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transform: scale(1);
    transition: 0.3s;
    padding-left: 10px;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > a > div {
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    padding: 20px;
  }
  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transform: scale(1);
    transition: 0.3s;
    padding-left: 10px;
    color: ${Theme.light.fourthColor.red};
    cursor: pointer;
  }
  & > div:last-child {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > a:hover,
  & > div:hover {
    transform: scale(1.1);
    color: ${Theme.light.fourthColor.red};
  }
`;

export const AdditionalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  transition: 0.2s color;
  & > a {
    text-decoration: none;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  &:hover,
  &:hover a {
    color: ${Theme.light.fourthColor.red};
    cursor: pointer;
  }
`;
export const styleForSvg = {
  height: "16px",
  paddingRight: "2px",
};
export const styleForActionsSvg = {
  height: "40px",
  paddingRight: "10px",
};
export const styleForMoreActionsSvg = {
  height: "30px",
};
export const svgAdditionalStyle = {
  width: "35px",
  margin: "10px",
};
