import React, { useEffect } from "react";
import { MainView } from "../components/main/MainView";
import { InfoSection } from "../components/infoSection/InfoSection";
import { Footer } from "../components/footerSection/Footer";
import { TopDeals } from "../components/topDealsSection/TopDeals";
import { connect } from "react-redux";
import { getDataForAllNewspaperQuery } from "../queries/queries";
import { ErrorFromData } from "../components/error/ErrorFromData";
import { useQuery } from "@apollo/client";
const _AllNewspaperPage = ({ select, setSelectViewData }) => {
  const selected = select.options.filter((item) => item.active);

  const variableForSort =
    select?.sort.filter((item) => item.active)[0]?.name || "recomended";

  const dataFromAllNewspaperPage = useQuery(getDataForAllNewspaperQuery, {
    fetchPolicy: "network-only",
    variables: {
      name: "gazetki markety",
      sortType: variableForSort,
    },
    ssr: true,
  });
  // console.log(dataFromAllNewspaperPage, "dataFromAllNewspaperPage");
  useEffect(() => {
    setSelectViewData(dataFromAllNewspaperPage?.data?.forAllNewspaperPage[0]);
  }, [dataFromAllNewspaperPage?.data?.forAllNewspaperPage[0]?.name]);

  useEffect(() => {
    if (dataFromAllNewspaperPage?.client) {
      dataFromAllNewspaperPage.client.resetStore();
    }
  }, []);

  if (dataFromAllNewspaperPage?.error) {
    return (
      <ErrorFromData
        errorInfo={dataFromAllNewspaperPage.error}
        queryName={"dataFromAllNewspaperPage._AllNewspaperPage"}
      />
    );
  }

  return (
    <>
      <MainView
        noLimit={true}
        dataFromAllNewspaperPage={dataFromAllNewspaperPage}
      />
      {/* <TopDeals options={15} />*/}
      <InfoSection />
      <Footer dataFromAllNewspaperPage={dataFromAllNewspaperPage} />
    </>
  );
};
const mapStateToProps = (state) => ({
  select: state.select,
});
export const AllNewspaperPage = connect(mapStateToProps, {})(_AllNewspaperPage);
