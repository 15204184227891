import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  newspaperId,
  selectPageFromDeal,
  setTargetCordinates,
  clearSelectPageFromDeal,
  clearTargetCordinates,
} from "../../../store/select/duck/actions/actions";
import { pushItemToList } from "../../../store/shopingList/duck/actions/actions";
import { overflowHidden } from "../../../store/theme/duck/actions/actions";
import styled from "styled-components";
import { Theme } from "../../../store/theme/theme";
// import alertify from "alertifyjs";
// import "alertifyjs/build/css/alertify.css";
// import "alertifyjs/build/css/themes/default.css";
// import { useMutation } from "@apollo/react-hooks";
import { useMutation } from "@apollo/client";
import { popularItemProductMutation } from "../../../queries/queries";
import { motion } from "framer-motion";
import { API_URL } from "../../../constant/constant";

const imagePath =
  "M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z";
const startPath =
  "M436 160H12c-6.627 0-12-5.373-12-12v-36c0-26.51 21.49-48 48-48h48V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h128V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h48c26.51 0 48 21.49 48 48v36c0 6.627-5.373 12-12 12zM12 192h424c6.627 0 12 5.373 12 12v260c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V204c0-6.627 5.373-12 12-12zm333.296 95.947l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L194.12 364.665l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068l-28.397 28.17c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z";
const endPath =
  "M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm257.3 160l48.1-48.1c4.7-4.7 4.7-12.3 0-17l-28.3-28.3c-4.7-4.7-12.3-4.7-17 0L224 306.7l-48.1-48.1c-4.7-4.7-12.3-4.7-17 0l-28.3 28.3c-4.7 4.7-4.7 12.3 0 17l48.1 48.1-48.1 48.1c-4.7 4.7-4.7 12.3 0 17l28.3 28.3c4.7 4.7 12.3 4.7 17 0l48.1-48.1 48.1 48.1c4.7 4.7 12.3 4.7 17 0l28.3-28.3c4.7-4.7 4.7-12.3 0-17L269.3 352z";
const targetPath =
  "M500 224h-30.364C455.724 130.325 381.675 56.276 288 42.364V12c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v30.364C130.325 56.276 56.276 130.325 42.364 224H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h30.364C56.276 381.675 130.325 455.724 224 469.636V500c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-30.364C381.675 455.724 455.724 381.675 469.636 288H500c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12zM288 404.634V364c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40.634C165.826 392.232 119.783 346.243 107.366 288H148c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-40.634C119.768 165.826 165.757 119.783 224 107.366V148c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-40.634C346.174 119.768 392.217 165.757 404.634 224H364c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40.634C392.232 346.174 346.243 392.217 288 404.634zM288 256c0 17.673-14.327 32-32 32s-32-14.327-32-32c0-17.673 14.327-32 32-32s32 14.327 32 32z";
const listPath =
  "M464 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zM128 120c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm288-136v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12z";
const divVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
const ImgForSuspenseStyle = styled.div`
  width: 90%;
  height: 100%;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
`;
const imgSuspenseStyle = {
  height: "100px",
};

const svgAdditionalStyle = {
  width: "35px",
  margin: "10px",
};

const MainWrapper = styled(motion.div)`
  width: 200px;
  height: 250px;
  padding: 5px;
  position: relative;
  margin: 20px;
  border: 1px solid
    ${({ isDark }) => (isDark ? "black" : Theme.dark.thirdColor.white)};
  border-radius: 12px;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  box-shadow: 0px 7px 16px 0px
    ${({ isDark }) =>
      isDark ? Theme.dark.eightColor.dark : Theme.light.eightColor.ligthGray};
  transition: 0.1s;
  text-align: center;
  text-transform: uppercase;
  transform: scale(1);
  overflow: hidden;
  position: relative;

  &:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
  @media${Theme.device.mobileL} {
    width: 165px;
    margin: 12px;
  }
  @media${Theme.device.mobileM} {
    width: 160px;
    margin: 7px;
  }
  @media${Theme.device.mobileXS} {
    width: 120px;
    margin: 5px;
  }
`;

const FirstDiv = styled.div`
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.3s;
    opacity: ${({ optActive }) => (optActive ? "0" : "1")};
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SecondDiv = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: ${({ optActive }) => (optActive ? "0" : "50%")};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  padding: 5px;
  transition: 0.3s;
`;
const SecondDivFirstWraper = styled.div`
  width: 100%;
  height: 20%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > h4 {
    font-size: 10px;
  }
  & > h5 {
    font-size: 16px;
    font-weight: normal;
  }
  @media${Theme.device.mobileL} {
    flex-warp: wrap;
  }
`;

const SecondDivSecondWraper = styled.div`
  width: 100%;
  height: 80%;
  position: absolute;
  top: 20%;
  left: 0;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.fiveColor.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & > div > div {
    display: flex;
  }
  & > div > div > h6 {
    font-weight: normal;
    font-size: 16px;
  }
  @media${Theme.device.tablet} {
    h3 {
      font-size: 12px !important;
    }
  }
  @media${Theme.device.mobileM} {
    & > p > img {
      height: 55px !important;
      left: 4% !important;
      top: 7% !important;
    }
  }
  @media${Theme.device.mobileXS} {
    & > p > img {
      height: 42px !important;
      left: 2% !important;
      top: 10% !important;
    }
  }
`;
const AdditionalOptionsWraper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0 !important;
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media${Theme.device.tablet} {
    font-size: 12px;
  }
`;
const AdditionalContent = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  transition: 0.2s color;
  & > a {
    text-decoration: none;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  &:hover,
  &:hover a {
    color: ${Theme.light.secondaryColor.green};
    cursor: pointer;
  }
`;

const calculateDelta = (
  { touchCordinates, setTouchCordinates },
  { optActive, setOptActive },
  { eventStart, eventEnd },
  msLongPress
) => {
  const acceptedDelta = 5;
  const endEventTimer = +new Date();
  const touchXEnd =
    eventEnd?.changedTouches && eventEnd?.changedTouches[0]?.pageX;
  const touchYEnd =
    eventEnd?.changedTouches && eventEnd?.changedTouches[0]?.pageY;
  const mouseXEnd = eventEnd?.pageX;
  const mouseYEnd = eventEnd?.pageY;

  const touchXStart = eventStart?.touches && eventStart?.touches[0]?.pageX;
  const touchYStart = eventStart?.touches && eventStart?.touches[0]?.pageY;
  const mouseXStart = eventStart?.pageX;
  const mouseYStart = eventStart?.pageY;
  const clearTouchCordinatesState = () =>
    setTouchCordinates({
      touchStartTime: null,
      touchXStart: null,
      touchYStart: null,
      mouseStartTime: null,
      mouseXStart: null,
      mouseYStart: null,
    });

  if (touchXStart && touchYStart && !touchXEnd && !touchYEnd) {
    console.log("finger touchLong occurs during user press");
    if (!optActive) {
      setOptActive(true);
      clearTouchCordinatesState();
    }
    return;
  }
  if (mouseXStart && mouseYStart && !mouseXEnd && !mouseYEnd) {
    console.log("mouse longPress occurs during user press");
    if (!optActive) {
      setOptActive(true);
      clearTouchCordinatesState();
    }
    return;
  }

  if (
    touchCordinates?.touchXStart &&
    touchCordinates?.touchYStart &&
    touchXEnd &&
    touchYEnd
  ) {
    if (
      !optActive &&
      endEventTimer - touchCordinates?.touchStartTime >= msLongPress
    ) {
      const deltaX = Math.abs(touchCordinates?.touchXStart - touchXEnd);
      const deltaY = Math.abs(touchCordinates?.touchYStart - touchYEnd);
      if (deltaX <= acceptedDelta && deltaY <= acceptedDelta) {
        console.log("finger touchLong occurs after finger out");
        setOptActive(true);
        clearTouchCordinatesState();
      } else {
        console.log("touch event swapp detected");
        clearTouchCordinatesState();
      }
    } else if (
      !optActive &&
      endEventTimer - touchCordinates?.touchStartTime < msLongPress
    ) {
      console.log("finger event to short ");
      clearTouchCordinatesState();
    }
    return;
  }
  if (
    touchCordinates?.mouseXStart &&
    touchCordinates?.mouseYStart &&
    mouseXEnd &&
    mouseYEnd
  ) {
    if (
      !optActive &&
      endEventTimer - touchCordinates?.mouseStartTime >= msLongPress
    ) {
      const deltaX = Math.abs(touchCordinates?.mouseXStart - mouseXEnd);
      const deltaY = Math.abs(touchCordinates?.mouseYStart - mouseYEnd);
      if (deltaX <= acceptedDelta && deltaY <= acceptedDelta) {
        console.log("mouse longPress occurs after key mouse out");
        setOptActive(true);
        clearTouchCordinatesState();
      } else {
        console.log("mouse event swapp detected");
        clearTouchCordinatesState();
      }
    } else if (
      !optActive &&
      endEventTimer - touchCordinates?.mouseStartTime < msLongPress
    ) {
      console.log("mouse press to short");
      clearTouchCordinatesState();
    }
    return;
  }
};

const _SingleDeal = ({
  item,
  newspaperId,
  theme,
  selectPageFromDeal,
  setTargetCordinates,
  pushItemToList,
  setTopDealsShoppageFromNewspaper,
  topDealsPageFromNewsPaper,
  overflowHidden,
  clearTargetCordinates,
  clearSelectPageFromDeal,
  brandDataFromUrl,
}) => {
  const msLongPress = 200;
  const [isLoaded, setIsLoaded] = useState(false);
  const [optActive, setOptActive] = useState(false);
  const [incPopular, { data }] = useMutation(popularItemProductMutation);
  const [alertify, setAlertify] = useState(null);
  const [touchCordinates, setTouchCordinates] = useState({
    touchStartTime: null,
    touchXStart: null,
    touchYStart: null,
    mouseStartTime: null,
    mouseXStart: null,
    mouseYStart: null,
  });
  useEffect(() => {
    return () => {
      setAlertify(null);
      setTouchCordinates({
        touchStartTime: null,
        touchXStart: null,
        touchYStart: null,
        mouseStartTime: null,
        mouseXStart: null,
        mouseYStart: null,
      });
    };
  }, []);
  useEffect(() => {
    const loadAlertify = async () => {
      if (typeof window !== "undefined") {
        try {
          const alertifyInstance = (
            await import("../../../media/assets/alertify/alertify.js")
          ).default;
          await import("../../../media/assets/alertify/alertify.min.css");
          await import("../../../media/assets/alertify/default.min.css");
          setAlertify(alertifyInstance);
        } catch (error) {
          console.error("Error loading Alertify:", error);
        }
      }
    };

    loadAlertify();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (optActive) {
        setOptActive(false);
      }
    }, 3000);
  }, [optActive]);
  return (
    <MainWrapper
      variants={divVariants}
      key={item.bookId}
      initial="hidden"
      animate="visible"
      exit="exit"
      isDark={theme.isDark}
      onMouseOver={() => {
        if (!optActive) {
          setOptActive(true);
        }
      }}
      onMouseLeave={() => {
        if (optActive) {
          setOptActive(false);
        }
      }}
      onTouchStart={(e) => {
        setTouchCordinates({
          mouseXStart: null,
          mouseYStart: null,
          mouseStartTime: null,
          touchStartTime: +new Date(),
          touchXStart: e?.touches[0]?.pageX,
          touchYStart: e?.touches[0]?.pageY,
        });
        // if (!optActive) {
        //   setOptActive(true);
        // }
      }}
      onTouchEnd={(e) => {
        calculateDelta(
          { touchCordinates, setTouchCordinates },
          {
            optActive,
            setOptActive,
          },
          {
            eventEnd: e,
          },
          msLongPress
        );
      }}
    >
      {!isLoaded && (
        <ImgForSuspenseStyle isDark={theme.isDark}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="image"
            className="svg-inline--fa fa-image fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width={70}
            style={imgSuspenseStyle}
          >
            <path fill="currentColor" d={imagePath}></path>
          </svg>
        </ImgForSuspenseStyle>
      )}
      <FirstDiv isDark={theme.isDark} optActive={optActive}>
        <img
          loading="lazy"
          src={item?.imgUrl && `${API_URL}${item?.imgUrl}`}
          onLoad={() => setIsLoaded(true)}
          alt={`gazetka ${item?.name} czas obowiązywania od ${item?.startDuringTime} do ${item?.endDuringTime}`}
        />
      </FirstDiv>
      <SecondDiv isDark={theme?.isDark} optActive={optActive}>
        <SecondDivFirstWraper isDark={theme?.isDark}>
          <h4>promocja</h4>
          <h5>{item?.name}</h5>
        </SecondDivFirstWraper>
        <SecondDivSecondWraper isDark={theme?.isDark}>
          <p>
            <img
              loading="lazy"
              src={
                brandDataFromUrl?.data?.brandByName[0]?.imgUrl ||
                item?.brandData[0]?.imgUrl
              }
              alt={`gazetka ${item?.name} obowiązująca od ${item?.startDuringTime} do ${item?.endDuringTime}`}
              style={{
                height: "60px",
                position: "absolute",
                left: "5px",
                top: "5px",
              }}
            />
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              left: `${theme?.isMobile ? "42%" : "80px"}`,
              top: "10px",
            }}
          >
            {item?.statusBook === "active" && (
              <div
                style={{
                  color: "green",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                <h3
                  style={{
                    color: "green",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  aktualna
                </h3>
              </div>
            )}
            {item?.statusBook === "future" && (
              <div
                style={{
                  color: "blue",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                <h3
                  style={{
                    color: "blue",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  następna
                </h3>
              </div>
            )}

            <div style={{ textAlign: "left" }}>
              <span>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="calendar-check"
                  className="svg-inline--fa fa-calendar-check fa-w-14"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  style={{
                    width: "13px",
                    marginRight: "10px",
                    textAlign: "right",
                  }}
                  width={13}
                >
                  <path fill="currentColor" d={startPath}></path>
                </svg>
              </span>
              <h6>
                {item?.startDuringTime.slice(
                  0,
                  item?.startDuringTime.length - 5
                )}
              </h6>
            </div>
            <div style={{ textAlign: "left" }}>
              <span>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="calendar-times"
                  className="svg-inline--fa fa-calendar-times fa-w-14"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  style={{ width: "13px", marginRight: "10px" }}
                  width={13}
                >
                  <path fill="currentColor" d={endPath}></path>
                </svg>
              </span>
              <h6>
                {item?.endDuringTime.slice(0, item?.endDuringTime.length - 5)}
              </h6>
            </div>
          </div>
          <AdditionalOptionsWraper>
            <AdditionalContent whileTap={{ scale: 0.95 }} isDark={theme.isDark}>
              <Link
                to={`/gazetka-promocyjna/${item.brand}-${item?.bookData[0]?.alternativeFinalName}/${item?.startDuringTime}-${item?.endDuringTime}/${item?.page}`}
                onClick={() => {
                  // console.log(item);
                  clearSelectPageFromDeal();
                  clearTargetCordinates();
                  newspaperId(item.bookId);
                  selectPageFromDeal(item.page);
                  setTargetCordinates(item.cordinates);
                  theme.canOverflow && overflowHidden();
                  topDealsPageFromNewsPaper &&
                    setTopDealsShoppageFromNewspaper(false);
                  setTouchCordinates({
                    touchStartTime: null,
                    touchXStart: null,
                    touchYStart: null,
                    mouseStartTime: null,
                    mouseXStart: null,
                    mouseYStart: null,
                  });
                  optActive && setOptActive(false);
                }}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="crosshairs"
                  className="svg-inline--fa fa-crosshairs fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  style={svgAdditionalStyle}
                  width={35}
                >
                  <path fill="currentColor" d={targetPath}></path>
                </svg>
                <p>pokaż w gazetce</p>
              </Link>
            </AdditionalContent>
            <AdditionalContent
              whileTap={{ scale: 0.95 }}
              onClick={() => {
                pushItemToList(item);
                incPopular({
                  variables: {
                    id: item.id,
                  },
                });
                alertify.set("notifier", "position", "bottom-left");
                alertify.success(`Dodano ${item.name}`);
                setTouchCordinates({
                  touchStartTime: null,
                  touchXStart: null,
                  touchYStart: null,
                  mouseStartTime: null,
                  mouseXStart: null,
                  mouseYStart: null,
                });
                optActive && setOptActive(false);
              }}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="list-alt"
                className="svg-inline--fa fa-list-alt fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                style={svgAdditionalStyle}
                width={35}
              >
                <path fill="currentColor" d={listPath}></path>
              </svg>
              <p>dodaj do listy</p>
            </AdditionalContent>
          </AdditionalOptionsWraper>
        </SecondDivSecondWraper>
      </SecondDiv>
    </MainWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  newspaperId: (item) => dispatch(newspaperId(item)),
  selectPageFromDeal: (item) => dispatch(selectPageFromDeal(item)),
  setTargetCordinates: (item) => dispatch(setTargetCordinates(item)),
  pushItemToList: (item) => dispatch(pushItemToList(item)),
  overflowHidden: () => dispatch(overflowHidden()),
  clearSelectPageFromDeal: (item) => dispatch(clearSelectPageFromDeal(item)),
  clearTargetCordinates: (item) => dispatch(clearTargetCordinates(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
});
export const SingleDeal = connect(
  mapStateToProps,
  mapDispatchToProps
)(_SingleDeal);
