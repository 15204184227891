import React, { useState, useEffect } from "react";
import { ReactComponent as MailIcon } from "../../../media/svg/envelope-solid.svg";
import { MainContent, MailIconStyle } from "./style";
import { TermsAccept } from "./TermsAccept";
import { Theme } from "../../../store/theme/theme";
import { motion, AnimatePresence } from "framer-motion";
// import ReCAPTCHA from "react-google-recaptcha";
// import alertify from "alertifyjs";
// import "alertifyjs/build/css/alertify.css";
// import "alertifyjs/build/css/themes/default.css";
import { connect } from "react-redux";
import { usserLogged } from "../../../store/shopingList/duck";
import { API_URL } from "../../../constant/constant";
import { ErrorBoundary } from "../../errorBoundary/ErrorBoundary";
import { ErrorHandler } from "../../error/ErrorHandler";
import {
  encryptFrontData,
  decryptFrontData,
} from "../../../utils/Crypto/cryptoFront.controller";

const pVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const _UserEmail = ({ isDark, usserLogged }) => {
  const [ReCAPTCHA, setReCAPTCHA] = useState(null);
  const recaptchaSiteKey =
    process?.env?.NODE_ENV === "development"
      ? process.env.REACT_APP_RECAPTCHA_SITE_KEY
      : process.env.REACT_APP_RECAPTCHA_SITE_KEY_PROD;
  const recaptchaRef = React.createRef();
  const [human, setHuman] = useState(false);
  const [inputTextValue, setInputTextValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [validError, setValidError] = useState("");
  const [nameError, setNameError] = useState("");
  const [acceptTerm, setAcceptTerms] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validName, setValidName] = useState(false);
  const _validEmail = React.useRef(validEmail);
  const _validName = React.useRef(validName);
  const [emailError, setEmailError] = useState("");
  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  // const regName = /^[a-zA-Z '.-]*$/i
  const regNameMultiLng =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
  const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const [alertify, setAlertify] = useState(null);

  const _setValidEmail = (data) => {
    _validEmail.current = data;
    setValidEmail(data);
  };
  const _setValidName = (data) => {
    _validName.current = data;
    setValidName(data);
  };
  const onChange = () => {
    setHuman(true);
    console.log("recaptcha resolved");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!human && recaptchaRef.current) {
      recaptchaRef.current.reset();
      // recaptchaRef.current.execute()
    }
    const nameValidationHandle = async () => {
      if (nameValue.length >= 3 && nameValue.length < 33) {
        setNameError("");
      }
      if (!nameValue) {
        setNameError("Wpisz proszę swoje Imię");
      }
      if (nameValue && nameValue.length < 3) {
        setNameError("Wpisane imie jest zbyt krótkie");
      }
      if (nameValue.length > 33) {
        setNameError("Wpisane imie jest zbyt długie");
      }
      if (!regNameMultiLng.test(nameValue)) {
        setNameError("Wpisz poprawnie swoje imie");
      }
      if (
        regNameMultiLng.test(nameValue) &&
        nameValue.length >= 3 &&
        nameValue.length <= 33
      ) {
        setNameError("");
        _setValidName(true);

        return true;
      }
    };

    const emailValidationHandle = async () => {
      if (!inputTextValue) {
        setEmailError("Wpisz proszę swój e-mail");
      }
      if (inputTextValue && !regEmail.test(inputTextValue)) {
        setEmailError("Wpisany e-mail nie jest poprawny");
      }
      if (regEmail.test(inputTextValue)) {
        setEmailError("");
        _setValidEmail(true);

        return true;
      }
    };
    if (!acceptTerm) {
      setValidError("Musisz zaakceptować nasz regulamin");
    }

    const name = await nameValidationHandle();
    const email = await emailValidationHandle();
    if (name && email && acceptTerm) {
      const frontDataToEncrypt = {
        firstName: nameValue,
        email: inputTextValue,
      };

      // encrypt data for fetch
      const ciphertext = encryptFrontData(
        frontDataToEncrypt,
        setErrorFromCrypto,
        null,
        "UserEmail.js"
      );
      fetch(`${API_URL}/api/user-update`, {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: ciphertext }),
      })
        .then((response) => response.json())
        .then((result) => {
          // decrypt data from response
          return decryptFrontData(
            result.data,
            setErrorFromCrypto,
            null,
            "UserEmail.js"
          );
        })
        .then((data) => {
          if (data) {
            if (data && data.error) {
              alertify.set("notifier", "position", "top-center");
              alertify.alert(
                `${data.error}`,
                "Przykro nam ale chyba musisz przeładować stronę i spróbować jeszcze raz"
              );
            }
            if (data.isLoged && !data.email) {
              alertify.set("notifier", "position", "top-center");
              alertify.alert(
                "Niby wszystko ok, ale jednak nie do końca",
                "Przykro nam ale chyba musisz przeładować stronę i spróbować jeszcze raz"
              );
            } else if (data.isLoged && data.email) {
              usserLogged(data);
              alertify.set("notifier", "position", "bottom-left");
              alertify.success(`Zaktualizowałeś swój profil pomyślnie`);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const loadAlertify = async () => {
      if (typeof window !== "undefined") {
        try {
          const alertifyInstance = (
            await import("../../../media/assets/alertify/alertify.js")
          ).default;
          await import("../../../media/assets/alertify/alertify.min.css");
          await import("../../../media/assets/alertify/default.min.css");
          setAlertify(alertifyInstance);
        } catch (error) {
          console.error("Error loading Alertify:", error);
        }
      }
    };

    loadAlertify();
  }, []);

  useEffect(() => {
    const loadReCAPTCHA = async () => {
      if (typeof window !== "undefined") {
        try {
          const ReCAPTCHAInstance = (await import("react-google-recaptcha"))
            .default;

          setReCAPTCHA(ReCAPTCHAInstance);
        } catch (error) {
          console.error("Error loading ReCAPTCHAInstance:", error);
        }
      }
    };

    loadReCAPTCHA();
  }, []);
  useEffect(() => {
    return () => {
      setReCAPTCHA(null);
    };
  }, []);
  return (
    <MainContent isDark={isDark} layout>
      <ErrorBoundary>
        {errorFromCrypto && <ErrorHandler errorMsg={errorFromCrypto} />}

        {/* <h1>Nie znamy twojego emaila - zapisz go u nas</h1> */}
        <div>
          <MailIcon style={MailIconStyle} />
          {/* <p>Nie znamy twojego emaila - zapisz go u nas</p> */}
          <p>
            Nie znamy twojego emaila - zapisz go u nas, wysyłaj listy zakupowe,
            skorzystaj ze statystyk, planuj swoje codzienne wydatki
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="twoje imie"
            name="name"
            value={nameValue}
            onChange={(e) => setNameValue(e.target.value)}
          />
          <AnimatePresence>
            {nameError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {nameError}
              </motion.p>
            )}
          </AnimatePresence>
          <input
            type="text"
            placeholder="twój adres e-mail"
            name="email"
            value={inputTextValue}
            onChange={(e) => setInputTextValue(e.target.value)}
          />
          <AnimatePresence>
            {emailError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {emailError}
              </motion.p>
            )}
          </AnimatePresence>
          <TermsAccept
            active={acceptTerm}
            click={() => {
              setAcceptTerms(!acceptTerm);
              setValidError("");
            }}
          />
          <AnimatePresence>
            {validError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {validError}
              </motion.p>
            )}
          </AnimatePresence>
          {ReCAPTCHA && (
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={recaptchaSiteKey}
              onChange={onChange}
              size={"normal"}
              hl={"pl"}
              theme={isDark ? "dark" : "light"}
            />
          )}
          <motion.button whileTap={{ scale: 0.95 }} type="submit">
            zapisz
          </motion.button>
        </form>
      </ErrorBoundary>
    </MainContent>
  );
};
const mapDispatchToProps = (dispatch) => ({
  usserLogged: (item) => dispatch(usserLogged(item)),
});
const mapStateToProps = (state) => ({
  shopingList: state.shopingList,
});
export const UserEmail = connect(
  mapStateToProps,
  mapDispatchToProps
)(_UserEmail);
