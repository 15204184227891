import React from "react";
import { Contact } from "../components/contactSection/Contact";
import { Footer } from "../components/footerSection/Footer";
import { Helmet } from "react-helmet";
export const ContactPage = () => {
  // if (typeof document === "undefined" || typeof window === "undefined") {
  //   return null;
  // }

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://cenniczek.com/kontakt`} />
        <meta name="description" content="Kontakt - Cenniczek.com" />
        <meta property="og:description" content="Kontakt - Cenniczek.com" />
        <meta name="keywords" content="kontakt - Cenniczek.com"></meta>
        <meta property="og:title" content="Cenniczek.com-kontakt" />
        <meta property="og:url" content={`https://cenniczek.com/kontakt`} />
        <title>Cenniczek.com-kontakt</title>
      </Helmet>
      <Contact />
      <Footer />
    </>
  );
};
