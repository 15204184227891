import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import {
  WrapperForSingleOffer,
  ForCloseIcon,
  SingleItemContent,
  modalWraperVariants,
  singleItemModalVariants,
  ImageForSingle,
  BrandForSingle,
  TimeForSingle,
  CategoryForSingle,
  DataForSingle,
  WraperFor2Items,
  imgSuspenseStyleDark,
  imgSuspenseStyleForBrandDark,
  imgSuspenseStyleForBrandLight,
  imgSuspenseStyleLight,
  ChangeButton,
  styleForActiveLigth,
  styleForActiveDark,
  styleForDisactiveDark,
  styleForDisactiveLight,
  InputModalItems,
  animationInputVariants,
  styleForActionsSvg,
  SvgWraper,
} from "./style";

import Cenniczek from "../../../../../media/svg/cenniczek-icon-192.svg";
import { AnimatePresence } from "framer-motion";
import { useQuery } from "@apollo/client";
import { ErrorFromData } from "../../../../error/ErrorFromData";
import { ErrorBoundary } from "../../../../errorBoundary/ErrorBoundary";
import { ErrorHandler } from "../../../../error/ErrorHandler";
import { getProductFromKeysCategoriesQuery } from "../../../../../queries/queries";
import { connect } from "react-redux";
import { overflowHidden } from "../../../../../store/theme/duck/actions/actions";
import StarRatings from "react-star-ratings";
import { Theme } from "../../../../../store/theme/theme";
import { SelectBrand } from "./selectBrand/SelectBrand";
import { SelectCategory } from "./selectCategory/SelectCategory";
// import alertify from "alertifyjs";
// import "alertifyjs/build/css/alertify.css";
// import "alertifyjs/build/css/themes/default.css";
import { motion } from "framer-motion";
import { API_URL } from "../../../../../constant/constant";

import {
  encryptFrontData,
  decryptFrontData,
} from "../../../../../utils/Crypto/cryptoFront.controller";

export const styleForMoreActionsSvg = {
  height: "30px",
};
const divVariants = {
  hidden: {
    opacity: 0,
    scale: 1.2,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};
const closePath =
  "M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z";
const startPath =
  "M436 160H12c-6.627 0-12-5.373-12-12v-36c0-26.51 21.49-48 48-48h48V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h128V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h48c26.51 0 48 21.49 48 48v36c0 6.627-5.373 12-12 12zM12 192h424c6.627 0 12 5.373 12 12v260c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V204c0-6.627 5.373-12 12-12zm333.296 95.947l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L194.12 364.665l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068l-28.397 28.17c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z";
const endPath =
  "M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm257.3 160l48.1-48.1c4.7-4.7 4.7-12.3 0-17l-28.3-28.3c-4.7-4.7-12.3-4.7-17 0L224 306.7l-48.1-48.1c-4.7-4.7-12.3-4.7-17 0l-28.3 28.3c-4.7 4.7-4.7 12.3 0 17l48.1 48.1-48.1 48.1c-4.7 4.7-4.7 12.3 0 17l28.3 28.3c4.7 4.7 12.3 4.7 17 0l48.1-48.1 48.1 48.1c4.7 4.7 12.3 4.7 17 0l28.3-28.3c4.7-4.7 4.7-12.3 0-17L269.3 352z";
const imagePath =
  "M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z";
const downPath =
  "M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z";
const trashPath =
  "M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z";
const useGetProductKeysCategory = ({ nameToFind }) => {
  return useQuery(getProductFromKeysCategoriesQuery, {
    variables: {
      name: nameToFind,
    },
    ssr: true,
  });
};
const errAlfanumeric = "dostępne znaki alfanumeryczne";
const errNumeric = "dostępne znaki numeryczne [0-9].[01-99]";
const errToManySigns = "dostępne 50 znaków";
const regNameMultiLng =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšśŚžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9\s ,.'-]+$/u;

const forDecimals = /^\d*(\.)?(\,)?(\d{0,2})?$/;

const fetchForChangeListItem = (
  days,
  activeIndexFromSlide,
  activeItemIndexFromCarusel,
  setErrorFromCrypto,
  alertify
) => {
  const frontDataToEncrypt = {
    days: days,
    activeIndexFromSlide: activeIndexFromSlide,
    activeItemIndexFromCarusel: activeItemIndexFromCarusel,
  };
  // encrypt data for fetch
  const ciphertext = encryptFrontData(
    frontDataToEncrypt,
    setErrorFromCrypto,
    null,
    "Modal.js"
  );
  fetch(`${API_URL}/api/updateListItem`, {
    method: "POST",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data: ciphertext,
    }),
  })
    .then((response) => response.json())
    .then((result) => {
      // decrypt data from response
      return decryptFrontData(
        result.data,
        setErrorFromCrypto,
        null,
        "Modal.js"
      );
    })
    .then((data) => {
      // console.log(data);
      if (!data.ok) {
        if (typeof window !== "undefined") {
          alertify.set("notifier", "position", "bottom-left");
          alertify.error(`Przepraszamy coś poszło nie po naszej
          myśli,${data.error}`);
        }
      }
    })
    .catch((err) => console.log(err));
};

const _Modal = ({
  isDark,
  setActiveIndexFromSlide,
  setActiveItemIndexFromCarusel,
  modal,
  days,
  activeIndexFromSlide,
  activeItemIndexFromCarusel,
  overflowHidden,
  isActive,
  setDays,
}) => {
  const [isMainImg, setIsMainImg] = useState(false);
  const [isBrandImg, setIsBarandImg] = useState(false);
  const [isCategoryImg, setIsCategoryImg] = useState(false);
  const [optionsActive, setOptionsActive] = useState(false);
  const [optionsBrandActive, setBrandOptionsActive] = useState(false);
  const [optionsCategoryActive, setCategoryOptionsActive] = useState(false);
  const [wasChanged, setWasChanged] = useState(false);
  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  const pathDefinition = "M25,50a25,25 0 1,0 50,0a25,25 0 1,0 -50,0";
  // console.log(days);
  const [err, setErr] = useState("");
  const [alertify, setAlertify] = useState(null);

  const portalContainer = document.getElementById("root");

  const chosenItem =
    days?.time?.shoping[activeItemIndexFromCarusel]?.result?.item?.list[
      activeIndexFromSlide
    ];

  const total = days?.time?.shoping[
    activeItemIndexFromCarusel
  ]?.result?.item?.list
    .map(
      (item) => (parseFloat(item.price) || 0) * (parseFloat(item.howMany) || 0)
    )
    .reduce((all, item) => all + item, 0);

  const circlePathLength =
    chosenItem?.price &&
    chosenItem.howMany &&
    total &&
    (
      parseFloat(chosenItem?.howMany * chosenItem?.price).toFixed(2) / total
    ).toFixed(2);

  const nameToFind = chosenItem.categoryKeyProductName;
  let pureData = useGetProductKeysCategory({ nameToFind });

  let refs = useRef();
  let refsHref = useRef();
  // console.log(chosenItem, pureData);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.target.blur();
    }
  };
  const handleOnchange = (e) => {
    const name = e.target.name;

    if (name === "typeSelect") {
      if (
        e.target.options[e.target.selectedIndex].value === "kg" ||
        e.target.options[e.target.selectedIndex].value === "szt"
      ) {
        const { value } = e.target.options[e.target.selectedIndex];
        if (
          days?.time?.shoping[activeItemIndexFromCarusel]?.result?.item?.list[
            activeIndexFromSlide
          ]
        ) {
          days.time.shoping[activeItemIndexFromCarusel].result.item.list[
            activeIndexFromSlide
          ][name] = value;
          setDays({
            ...days,
            render: ++days.render,
          });
          setWasChanged(true);
        }
      }
    } else {
      if (name === "aditionalName") {
        const { value } = e.target;
        if (regNameMultiLng.test(value)) {
          if (e.target.value.length > 50) {
            if (
              days?.time?.shoping[activeItemIndexFromCarusel]?.result?.item
                ?.list[activeIndexFromSlide]
            ) {
              days.time.shoping[activeItemIndexFromCarusel].result.item.list[
                activeIndexFromSlide
              ][name] = value;
              setDays({
                ...days,
                render: ++days.render,
              });
              setErr({
                err: errToManySigns,
              });
            }
          } else {
            if (
              days?.time?.shoping[activeItemIndexFromCarusel]?.result?.item
                ?.list[activeIndexFromSlide]
            ) {
              days.time.shoping[activeItemIndexFromCarusel].result.item.list[
                activeIndexFromSlide
              ][name] = value;
              setDays({
                ...days,
                render: ++days.render,
              });
              setErr({
                err: "",
              });
              setWasChanged(true);
            }
          }
        } else {
          if (
            days?.time?.shoping[activeItemIndexFromCarusel]?.result?.item?.list[
              activeIndexFromSlide
            ]
          ) {
            days.time.shoping[activeItemIndexFromCarusel].result.item.list[
              activeIndexFromSlide
            ][name] = value;
            setDays({
              ...days,
              render: ++days.render,
            });
            setErr({
              err: errAlfanumeric,
            });
          }
        }
      } else if (name === "howMany" || name === "price") {
        const { value } = e.target;

        if (forDecimals.test(value)) {
          if (
            days?.time?.shoping[activeItemIndexFromCarusel]?.result?.item?.list[
              activeIndexFromSlide
            ]
          ) {
            days.time.shoping[activeItemIndexFromCarusel].result.item.list[
              activeIndexFromSlide
            ][name] = value;
            setDays({
              ...days,
              render: ++days.render,
            });
            setErr({
              err: "",
            });
            setWasChanged(true);
          }
        } else {
          if (
            days?.time?.shoping[activeItemIndexFromCarusel]?.result?.item?.list[
              activeIndexFromSlide
            ]
          ) {
            days.time.shoping[activeItemIndexFromCarusel].result.item.list[
              activeIndexFromSlide
            ][name] = value;
            setDays({
              ...days,
              render: ++days.render,
            });
            setErr({
              err: errNumeric,
            });
          }
        }
      }
    }
  };

  const handleClickOutside = (e) => {
    if (
      refs.current &&
      refs.current.contains(e.target) &&
      !refsHref.current.contains(e.target)
    ) {
      setActiveIndexFromSlide(false);
      setActiveItemIndexFromCarusel(false);
      overflowHidden();
      fetchForChangeListItem(
        days,
        activeIndexFromSlide,
        activeItemIndexFromCarusel,
        setErrorFromCrypto,
        alertify
      );
      if (
        wasChanged &&
        Object.keys(
          days.time.shoping[activeItemIndexFromCarusel].result.item.list[
            days.time.shoping[activeItemIndexFromCarusel].result.item.list
              .length - 1
          ]
        ).length
      ) {
        days.time.shoping[activeItemIndexFromCarusel].result.item.list.push({});
        setDays({
          ...days,
          render: ++days.render,
        });
      }

      return document.removeEventListener("click", handleClickOutside);
    }
  };
  useEffect(() => {
    return () => {
      setAlertify(null);
    };
  }, []);

  useEffect(() => {
    const loadAlertify = async () => {
      if (typeof window !== "undefined") {
        try {
          const alertifyInstance = (
            await import("../../../../../media/assets/alertify/alertify.js")
          ).default;
          await import("../../../../../media/assets/alertify/alertify.min.css");
          await import("../../../../../media/assets/alertify/default.min.css");
          setAlertify(alertifyInstance);
        } catch (error) {
          console.error("Error loading Alertify:", error);
        }
      }
    };

    loadAlertify();
  }, []);
  useEffect(() => {
    document && document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  });
  // console.log(
  //   (
  //     parseFloat(chosenItem?.howMany * chosenItem?.price).toFixed(2) / total
  //   ).toFixed(2)
  // );
  if (pureData.error) {
    return (
      <ErrorFromData
        errorInfo={pureData.error}
        queryName={"getProductFromKeysCategoriesQuery._Modal"}
      />
    );
  }

  return (
    <>
      {portalContainer?.children?.length > 0 &&
        ReactDOM.createPortal(
          <AnimatePresence exitBeforeEnter>
            <ErrorBoundary>
              {errorFromCrypto && <ErrorHandler errorMsg={errorFromCrypto} />}
              {isActive && (
                <WrapperForSingleOffer
                  isDark={isDark}
                  variants={modalWraperVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  ref={refs}
                >
                  <ForCloseIcon
                    isDark={isDark}
                    onClick={() => {
                      setActiveIndexFromSlide(false);
                      setActiveItemIndexFromCarusel(false);
                      overflowHidden();
                      fetchForChangeListItem(
                        days,
                        activeIndexFromSlide,
                        activeItemIndexFromCarusel
                      );
                      if (
                        wasChanged &&
                        Object.keys(
                          days.time.shoping[activeItemIndexFromCarusel].result
                            .item.list[
                            days.time.shoping[activeItemIndexFromCarusel].result
                              .item.list.length - 1
                          ]
                        ).length
                      ) {
                        days.time.shoping[
                          activeItemIndexFromCarusel
                        ].result.item.list.push({});
                        setDays({
                          ...days,
                          render: ++days.render,
                        });
                      }
                    }}
                  >
                    {/* <Close style={styleForMoreActionsSvg} /> */}
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="times"
                      className="svg-inline--fa fa-times fa-w-11"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 352 512"
                      style={styleForMoreActionsSvg}
                    >
                      <path fill="currentColor" d={closePath}></path>
                    </svg>
                  </ForCloseIcon>
                  <SingleItemContent
                    isDark={isDark}
                    variants={singleItemModalVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    ref={refsHref}
                  >
                    <h2>{`${chosenItem?.name ? chosenItem.name : ""} ${
                      chosenItem?.aditionalName ? chosenItem.aditionalName : ""
                    }`}</h2>
                    <ImageForSingle>
                      {isMainImg ? null : (
                        // <ImgSuspense
                        //   style={
                        //     isDark ? imgSuspenseStyleDark : imgSuspenseStyleLight
                        //   }
                        //   isDark={isDark}
                        // />
                        // <svg
                        //   aria-hidden="true"
                        //   focusable="false"
                        //   data-prefix="far"
                        //   data-icon="image"
                        //   class="svg-inline--fa fa-image fa-w-16"
                        //   role="img"
                        //   xmlns="http://www.w3.org/2000/svg"
                        //   viewBox="0 0 512 512"
                        //   style={
                        //     isDark ? imgSuspenseStyleDark : imgSuspenseStyleLight
                        //   }
                        // >
                        //   <path fill="currentColor" d={imagePath}></path>
                        // </svg>
                        <img
                          loading="lazy"
                          src={Cenniczek}
                          style={
                            isDark
                              ? imgSuspenseStyleDark
                              : imgSuspenseStyleLight
                          }
                        />
                      )}
                      {chosenItem?.imgUrl && (
                        <img
                          loading="lazy"
                          onLoad={() => setIsMainImg(true)}
                          src={chosenItem?.imgUrl || ""}
                          alt={`${chosenItem?.name ? chosenItem.name : ""} ${
                            chosenItem?.aditionalName
                              ? chosenItem.aditionalName
                              : ""
                          }`}
                        />
                      )}
                    </ImageForSingle>
                    <DataForSingle
                      isDark={isDark}
                      optionsActive={optionsActive}
                    >
                      <div>
                        <section>
                          <div>
                            <p>Ile:</p>
                            <p>
                              {chosenItem?.howMany || "brak danych"}{" "}
                              {chosenItem?.typeSelect}
                            </p>
                          </div>
                          <div>
                            <p>Cena:</p>
                            <p>
                              {chosenItem?.price || "brak danych"}{" "}
                              {chosenItem?.price && "PLN"}
                            </p>
                          </div>
                        </section>
                        <section>
                          <div>
                            <SvgWraper>
                              <motion.svg
                                aria-hidden="true"
                                className="svgItemForModal"
                                focusable="false"
                                xmlns="http://www.w3.org/2000/svg"
                                // viewBox="0 0 100 100"
                                // preserveAspectRatio="xMidYMid meet"
                                viewBox="19 19 62 62"
                              >
                                {" "}
                                <motion.path
                                  fill="none"
                                  d={pathDefinition}
                                  initial={{ pathLength: 0, pathOffset: 1 }}
                                  animate={{ pathLength: 1, pathOffset: 0 }}
                                  transition={{ duration: 1 }}
                                ></motion.path>
                                <motion.path
                                  isDark={isDark}
                                  fill={Theme.light.secondaryColor.green}
                                  d={pathDefinition}
                                  stroke={Theme.dark.primaryColor.dark}
                                  stroke-width="20"
                                  initial={{
                                    pathLength: 0,
                                    pathOffset: 1,
                                  }}
                                  animate={{
                                    pathLength: Number(circlePathLength) || 1,
                                    pathOffset: 0,
                                  }}
                                  transition={{ duration: 5 }}
                                ></motion.path>
                              </motion.svg>
                              <div>
                                <AnimatePresence>
                                  <motion.p
                                    variants={divVariants}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                  >
                                    {chosenItem.howMany && chosenItem.price ? (
                                      <>
                                        <p>W sumie:</p>
                                        <p>
                                          {`${(
                                            chosenItem?.howMany *
                                            chosenItem?.price
                                          ).toFixed(2)}`}
                                          <span>PLN</span>
                                        </p>
                                        <p>W tym dniu:</p>
                                        <p>
                                          {`${(
                                            ((
                                              chosenItem?.howMany *
                                              chosenItem?.price
                                            ).toFixed(2) /
                                              total) *
                                            100
                                          ).toFixed(2)}`}
                                          <span>%</span>
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <p>uzupełnij</p>
                                        <p>dane</p>
                                      </>
                                    )}
                                  </motion.p>
                                </AnimatePresence>
                              </div>
                            </SvgWraper>
                            {/* <p>Łącznie:</p>
                        <p>
                          {chosenItem.howMany && chosenItem.price
                            ? `${(
                                chosenItem?.howMany * chosenItem?.price
                              ).toFixed(2)} PLN - ${(
                                ((
                                  chosenItem?.howMany * chosenItem?.price
                                ).toFixed(2) /
                                  total) *
                                100
                              ).toFixed(2)}% `
                            : "brak danych"}
                        </p> */}
                          </div>
                        </section>
                      </div>
                      <p
                        onClick={() => {
                          // console.log("zmiana", optionsActive);
                          setOptionsActive(!optionsActive);
                        }}
                      >
                        {/* <Down
                      isDark={isDark}
                      style={
                        optionsActive
                          ? isDark
                            ? styleForActiveDark
                            : styleForActiveLigth
                          : !isDark
                          ? styleForDisactiveLight
                          : styleForDisactiveDark
                      }
                    /> */}
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="angle-down"
                          className="svg-inline--fa fa-angle-down fa-w-10"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          style={
                            optionsActive
                              ? isDark
                                ? styleForActiveDark
                                : styleForActiveLigth
                              : !isDark
                              ? styleForDisactiveLight
                              : styleForDisactiveDark
                          }
                        >
                          <path fill="currentColor" d={downPath}></path>
                        </svg>
                      </p>

                      {optionsActive && (
                        <div>
                          <InputModalItems
                            isDark={isDark}
                            variants={animationInputVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                          >
                            <input
                              type="number"
                              name="howMany"
                              placeholder="ilość"
                              inputMode="decimal"
                              value={chosenItem?.howMany || ""}
                              step={`${
                                chosenItem?.typeSelect === ""
                                  ? 0.25
                                  : chosenItem?.typeSelect === "kg"
                                  ? 0.25
                                  : 1
                              }`}
                              onChange={handleOnchange}
                              onKeyDown={(e) => handleKeyDown(e)}
                              min="0"
                              max="1000"
                            />
                            <input
                              type="number"
                              name="price"
                              placeholder="cena"
                              inputMode="decimal"
                              value={chosenItem?.price || ""}
                              step="0.01"
                              onChange={handleOnchange}
                              onKeyDown={(e) => handleKeyDown(e)}
                              min="0"
                              max="10000"
                            />
                            <select
                              name="typeSelect"
                              onChange={handleOnchange}
                              value={chosenItem?.typeSelect}
                            >
                              <option value="kg">kg</option>
                              <option value="szt">szt</option>
                            </select>
                            <input
                              type="text"
                              name="aditionalName"
                              placeholder="dodatkowy opis"
                              value={chosenItem?.aditionalName}
                              onChange={handleOnchange}
                              onKeyDown={(e) => handleKeyDown(e)}
                            />
                            {err?.err && <p>{err?.err}</p>}
                          </InputModalItems>
                        </div>
                      )}
                    </DataForSingle>
                    <WraperFor2Items isDark={isDark}>
                      <BrandForSingle
                        isDark={isDark}
                        optionsBrandActive={optionsBrandActive}
                      >
                        <section>
                          {isBrandImg ? null : (
                            // <ImgSuspense
                            //   isDark={isDark}
                            //   style={
                            //     isDark
                            //       ? imgSuspenseStyleForBrandDark
                            //       : imgSuspenseStyleForBrandLight
                            //   }
                            // />
                            // <svg
                            //   aria-hidden="true"
                            //   focusable="false"
                            //   data-prefix="far"
                            //   data-icon="image"
                            //   class="svg-inline--fa fa-image fa-w-16"
                            //   role="img"
                            //   xmlns="http://www.w3.org/2000/svg"
                            //   viewBox="0 0 512 512"
                            //   style={
                            //     isDark
                            //       ? imgSuspenseStyleForBrandDark
                            //       : imgSuspenseStyleForBrandLight
                            //   }
                            // >
                            //   <path fill="currentColor" d={imagePath}></path>
                            // </svg>
                            <img
                              loading="lazy"
                              src={Cenniczek}
                              style={
                                isDark
                                  ? imgSuspenseStyleForBrandDark
                                  : imgSuspenseStyleForBrandLight
                              }
                            />
                          )}
                          {chosenItem?.brandData &&
                            chosenItem?.brandData[0] &&
                            chosenItem?.brandData[0]?.imgUrl && (
                              <img
                                loading="lazy"
                                onLoad={() => setIsBarandImg(true)}
                                src={
                                  chosenItem?.brandData
                                    ? chosenItem?.brandData[0].imgUrl
                                    : "brak danych"
                                }
                                alt={chosenItem?.brand}
                              />
                            )}
                          <p>{chosenItem?.brand || "brak danych"}</p>
                          <div
                            onClick={() => {
                              // console.log("zmiana", optionsBrandActive);
                              setBrandOptionsActive(!optionsBrandActive);
                            }}
                          >
                            {/* <Down
                          isDark={isDark}
                          style={
                            optionsBrandActive
                              ? isDark
                                ? styleForActiveDark
                                : styleForActiveLigth
                              : !isDark
                              ? styleForDisactiveLight
                              : styleForDisactiveDark
                          }
                        /> */}
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="angle-down"
                              className="svg-inline--fa fa-angle-down fa-w-10"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              style={
                                optionsBrandActive
                                  ? isDark
                                    ? styleForActiveDark
                                    : styleForActiveLigth
                                  : !isDark
                                  ? styleForDisactiveLight
                                  : styleForDisactiveDark
                              }
                            >
                              <path fill="currentColor" d={downPath}></path>
                            </svg>
                          </div>
                        </section>
                        {optionsBrandActive && (
                          <section>
                            <SelectBrand
                              isDark={isDark}
                              setBrandOptionsActive={setBrandOptionsActive}
                              setDays={setDays}
                              days={days}
                              activeItemIndexFromCarusel={
                                activeItemIndexFromCarusel
                              }
                              activeIndexFromSlide={activeIndexFromSlide}
                            />
                          </section>
                        )}
                      </BrandForSingle>
                      <TimeForSingle isDark={isDark}>
                        <div>
                          {/* <StartTime style={styleForMoreActionsSvg} /> */}
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="calendar-check"
                            className="svg-inline--fa fa-calendar-check fa-w-14"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            style={styleForMoreActionsSvg}
                          >
                            <path fill="currentColor" d={startPath}></path>
                          </svg>
                          <p>
                            {chosenItem?.startDuringTime
                              ? chosenItem?.startDuringTime.slice(
                                  0,
                                  chosenItem.startDuringTime.slice.length - 7
                                )
                              : "brak danych"}
                          </p>
                        </div>
                        <div>
                          {/* <EndTime style={styleForMoreActionsSvg} /> */}
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="calendar-times"
                            class="svg-inline--fa fa-calendar-times fa-w-14"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            style={styleForMoreActionsSvg}
                          >
                            <path fill="currentColor" d={endPath}></path>
                          </svg>
                          <p>
                            {chosenItem?.endDuringTime
                              ? chosenItem?.endDuringTime.slice(
                                  0,
                                  chosenItem.endDuringTime.slice.length - 7
                                )
                              : "brak danych"}
                          </p>
                        </div>
                      </TimeForSingle>
                    </WraperFor2Items>
                    <CategoryForSingle
                      isDark={isDark}
                      optionsCategoryActive={optionsCategoryActive}
                    >
                      <section>
                        {isCategoryImg ? null : (
                          // <ImgSuspense
                          //   isDark={isDark}
                          //   style={
                          //     isDark
                          //       ? imgSuspenseStyleForBrandDark
                          //       : imgSuspenseStyleForBrandLight
                          //   }
                          // />
                          // <svg
                          //   aria-hidden="true"
                          //   focusable="false"
                          //   data-prefix="far"
                          //   data-icon="image"
                          //   class="svg-inline--fa fa-image fa-w-16"
                          //   role="img"
                          //   xmlns="http://www.w3.org/2000/svg"
                          //   viewBox="0 0 512 512"
                          //   style={
                          //     isDark
                          //       ? imgSuspenseStyleForBrandDark
                          //       : imgSuspenseStyleForBrandLight
                          //   }
                          // >
                          //   <path fill="currentColor" d={imagePath}></path>
                          // </svg>
                          <img
                            loading="lazy"
                            src={Cenniczek}
                            style={
                              isDark
                                ? imgSuspenseStyleForBrandDark
                                : imgSuspenseStyleForBrandLight
                            }
                          />
                        )}
                        {pureData?.data?.productKeysFromCategory &&
                          pureData?.data?.productKeysFromCategory[0] &&
                          pureData?.data?.productKeysFromCategory[0]
                            ?.imgUrl && (
                            <img
                              loading="lazy"
                              src={
                                pureData?.data?.productKeysFromCategory &&
                                pureData?.data?.productKeysFromCategory[0]
                                  ? pureData?.data?.productKeysFromCategory[0]
                                      .imgUrl
                                  : ""
                              }
                              alt={
                                pureData?.data?.productKeysFromCategory &&
                                pureData?.data?.productKeysFromCategory[0]
                                  ? pureData?.data?.productKeysFromCategory[0]
                                      .name
                                  : "brak danych"
                              }
                              onLoad={() => setIsCategoryImg(true)}
                            />
                          )}
                        <div>
                          <p>
                            {pureData?.data?.productKeysFromCategory &&
                            pureData?.data?.productKeysFromCategory[0]
                              ? pureData?.data?.productKeysFromCategory[0].name
                              : "brak danych"}
                          </p>
                          <p style={{ fontSize: "10px" }}>
                            rozpoznawalność kategori
                          </p>
                          <StarRatings
                            rating={chosenItem?.similarityRating * 10 || 0}
                            starRatedColor={`${Theme.light.secondaryColor.green}`}
                            numberOfStars={5}
                            starDimension="20px"
                            starSpacing="3px"
                            name="rating"
                          />
                        </div>
                        <p
                          onClick={() => {
                            // console.log("zmiana", optionsBrandActive);
                            setCategoryOptionsActive(!optionsCategoryActive);
                          }}
                        >
                          {/* <Down
                        isDark={isDark}
                        style={
                          optionsCategoryActive
                            ? isDark
                              ? styleForActiveDark
                              : styleForActiveLigth
                            : !isDark
                            ? styleForDisactiveLight
                            : styleForDisactiveDark
                        }
                      /> */}
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="angle-down"
                            className="svg-inline--fa fa-angle-down fa-w-10"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                            style={
                              optionsCategoryActive
                                ? isDark
                                  ? styleForActiveDark
                                  : styleForActiveLigth
                                : !isDark
                                ? styleForDisactiveLight
                                : styleForDisactiveDark
                            }
                          >
                            <path fill="currentColor" d={downPath}></path>
                          </svg>
                        </p>
                      </section>
                      {optionsCategoryActive && (
                        <section>
                          <SelectCategory
                            setCategoryOptionsActive={setCategoryOptionsActive}
                            isDark={isDark}
                            setDays={setDays}
                            days={days}
                            activeItemIndexFromCarusel={
                              activeItemIndexFromCarusel
                            }
                            activeIndexFromSlide={activeIndexFromSlide}
                          />
                        </section>
                      )}
                    </CategoryForSingle>

                    <ChangeButton
                      whileTap={{ scale: 0.95 }}
                      whileHover={{ scale: 1.05 }}
                      onClick={() => {
                        setActiveIndexFromSlide(false);
                        setActiveItemIndexFromCarusel(false);
                        overflowHidden();

                        days.time.shoping[
                          activeItemIndexFromCarusel
                        ].result.item.list.splice(activeIndexFromSlide, 1);

                        setDays({
                          ...days,
                          render: ++days.render,
                        });

                        fetchForChangeListItem(
                          days,
                          activeIndexFromSlide,
                          activeItemIndexFromCarusel
                        );
                      }}
                    >
                      <p>
                        {/* <Trash style={styleForActionsSvg} /> */}
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="trash-alt"
                          className="svg-inline--fa fa-trash-alt fa-w-14"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          style={styleForActionsSvg}
                        >
                          <path fill="currentColor" d={trashPath}></path>
                        </svg>
                        usuń
                      </p>
                    </ChangeButton>
                  </SingleItemContent>
                </WrapperForSingleOffer>
              )}
            </ErrorBoundary>
          </AnimatePresence>,
          portalContainer
        )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  overflowHidden: () => dispatch(overflowHidden()),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
});
export const Modal = connect(mapStateToProps, mapDispatchToProps)(_Modal);
