import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { popularItemProductMutation } from "../../../queries/queries";

import {
  StyledForProductsPage,
  AnimationForProductsPage,
  StyledForTarget,
  AnimationForTarget,
  TargetOptions,
  AddToShopingListOption,
  HrefShopingListOption,
  singleTargetVariants,
  svgAdditionalStyle,
} from "./Item.style";

import { connect } from "react-redux";
import { trigerVisibleList } from "../../../store/shopingList/duck/actions/actions";

// import alertify from "alertifyjs";
// import "alertifyjs/build/css/alertify.css";
// import "alertifyjs/build/css/themes/default.css";

import { calculatePercentegeOfImage } from "./Item.helpers";

const targetPath =
  "M500 224h-30.364C455.724 130.325 381.675 56.276 288 42.364V12c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v30.364C130.325 56.276 56.276 130.325 42.364 224H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h30.364C56.276 381.675 130.325 455.724 224 469.636V500c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-30.364C381.675 455.724 455.724 381.675 469.636 288H500c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12zM288 404.634V364c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40.634C165.826 392.232 119.783 346.243 107.366 288H148c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-40.634C119.768 165.826 165.757 119.783 224 107.366V148c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-40.634C346.174 119.768 392.217 165.757 404.634 224H364c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40.634C392.232 346.174 346.243 392.217 288 404.634zM288 256c0 17.673-14.327 32-32 32s-32-14.327-32-32c0-17.673 14.327-32 32-32s32 14.327 32 32z";
const listPath =
  "M464 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zM128 120c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm288-136v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12z";
const globePath =
  "M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z";

export const _Item = ({
  item,
  target,
  imgDimensions,
  targetCord,
  pushItemToList,
  trigerVisibleList,
  shopingList,
}) => {
  const [targetClicked, setTargetClicked] = useState(false);
  const refs = React.createRef();
  const refsHref = React.createRef();
  const [incPopular, { data }] = useMutation(popularItemProductMutation);
  const [alertify, setAlertify] = useState(null);

  const handleClickOutside = (e) => {
    if (targetClicked === true) {
      if (refs.current && refs.current.contains(e.target)) {
        return document.removeEventListener("click", handleClickOutside);
      } else {
        setTargetClicked(false);
      }
      if (refsHref.current && refsHref.current.contains(e.target)) {
        return document.removeEventListener("click", handleClickOutside);
      } else {
        setTargetClicked(false);
      }
    }
  };
  useEffect(() => {
    return () => {
      setAlertify(null);
    };
  }, []);

  useEffect(() => {
    const loadAlertify = async () => {
      if (typeof window !== "undefined") {
        try {
          const alertifyInstance = (
            await import("../../../media/assets/alertify/alertify.js")
          ).default;
          await import("../../../media/assets/alertify/alertify.min.css");
          await import("../../../media/assets/alertify/default.min.css");
          setAlertify(alertifyInstance);
        } catch (error) {
          console.error("Error loading Alertify:", error);
        }
      }
    };

    loadAlertify();
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  });
  const topForProductInPercent = calculatePercentegeOfImage(
    imgDimensions,
    item.cordinates
  ).top;
  const leftForProductInPercent = calculatePercentegeOfImage(
    imgDimensions,
    item.cordinates
  ).left;

  if (target) {
    const topInPercent = calculatePercentegeOfImage(
      imgDimensions,
      targetCord
    ).top;
    const leftInPercent = calculatePercentegeOfImage(
      imgDimensions,
      targetCord
    ).left;

    return (
      <StyledForTarget
        top={topInPercent}
        left={leftInPercent}
        onClick={() => {
          setTargetClicked(!targetClicked);
        }}
      >
        {targetClicked && (
          <TargetOptions
            variants={singleTargetVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            isDark={true}
            top={topInPercent}
            left={leftInPercent}
          >
            <AddToShopingListOption
              ref={refs}
              onClick={() => {
                // console.log('item',item)
                pushItemToList(item);
                incPopular({
                  variables: {
                    id: item.id,
                  },
                });
                alertify.set("notifier", "position", "bottom-left");
                alertify.success(`Dodano ${item.name}`);
                if (!shopingList?.isListVisible) {
                  trigerVisibleList();
                }
              }}
            >
              {/* <List style={svgAdditionalStyle} /> */}
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="list-alt"
                className="svg-inline--fa fa-list-alt fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                style={svgAdditionalStyle}
                width={35}
              >
                <path fill="currentColor" d={listPath}></path>
              </svg>
              <p>dodaj</p>
              <p>{item.name}</p>
              <p>do listy</p>
            </AddToShopingListOption>
            {item.linkHref && (
              <HrefShopingListOption ref={refsHref} onClick={() => {}}>
                <a
                  href={item.linkHref}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <Globe style={svgAdditionalStyle} /> */}
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="globe"
                    className="svg-inline--fa fa-globe fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 496 512"
                    style={svgAdditionalStyle}
                    width={35}
                  >
                    <path fill="currentColor" d={globePath}></path>
                  </svg>
                  <p>przekieruj do</p>
                  <p>sklepu</p>
                </a>
              </HrefShopingListOption>
            )}
          </TargetOptions>
        )}
        <AnimationForTarget>
          {/* <Target style={{ width: "30px" }} /> */}
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="crosshairs"
            className="svg-inline--fa fa-crosshairs fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{ width: "20px" }}
            width={20}
          >
            <path fill="currentColor" d={targetPath}></path>
          </svg>
        </AnimationForTarget>
      </StyledForTarget>
    );
  }
  return (
    <StyledForProductsPage
      onClick={() => {
        setTargetClicked(!targetClicked);
      }}
      top={topForProductInPercent}
      left={leftForProductInPercent}
    >
      {targetClicked && (
        <TargetOptions
          isDark={true}
          top={topForProductInPercent}
          left={leftForProductInPercent}
          variants={singleTargetVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <AddToShopingListOption
            ref={refs}
            onClick={() => {
              pushItemToList(item);
              incPopular({
                variables: {
                  id: item.id,
                },
              });
              alertify.set("notifier", "position", "bottom-left");
              alertify.success(`Dodano ${item.name}`);
              if (!shopingList?.isListVisible) {
                trigerVisibleList();
              }
            }}
          >
            {/* <List style={svgAdditionalStyle} /> */}
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="list-alt"
              className="svg-inline--fa fa-list-alt fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              style={svgAdditionalStyle}
              width={35}
            >
              <path fill="currentColor" d={listPath}></path>
            </svg>
            <p>dodaj</p>
            <p>{item.name}</p>
            <p>do listy</p>
          </AddToShopingListOption>
          {item.linkHref && (
            <HrefShopingListOption ref={refsHref} onClick={() => {}}>
              <a href={item.linkHref} target="_blank" rel="noopener noreferrer">
                {/* <Globe style={svgAdditionalStyle} /> */}
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="globe"
                  className="svg-inline--fa fa-globe fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 496 512"
                  style={svgAdditionalStyle}
                  width={35}
                >
                  <path fill="currentColor" d={globePath}></path>
                </svg>
                <p>przekieruj do</p>
                <p>sklepu</p>
              </a>
            </HrefShopingListOption>
          )}
        </TargetOptions>
      )}
      <AnimationForProductsPage>
        {/* <Target style={{ width: "20px" }} /> */}
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="crosshairs"
          className="svg-inline--fa fa-crosshairs fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          style={{ width: "20px" }}
          width={20}
        >
          <path fill="currentColor" d={targetPath}></path>
        </svg>
      </AnimationForProductsPage>
    </StyledForProductsPage>
  );
};
const mapDispatchToProps = (dispatch) => ({
  trigerVisibleList: (item) => dispatch(trigerVisibleList(item)),
});

const mapStateToProps = (state) => ({
  shopingList: state.shopingList,
  theme: state.theme,
});

export const Item = connect(mapStateToProps, mapDispatchToProps)(_Item);
