import { Theme } from "../../../store/theme/theme";
import { motion } from "framer-motion";
import styled from "styled-components";
export const SingleSlide = styled.div`
  width: ${({ caruselWidth }) => `${caruselWidth}px`};
  height: 365px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  position: relative;
  @media${Theme.device.mobileL} {
    height: 430px;
    align-items: flex-start;
  }
`;
export const ImgWrapper = styled.div`
  width: ${({ caruselWidth }) => `${caruselWidth}px`};
  height: 300px;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  & > img {
    position: absolute;
    max-width: 100%;
    max-height: 275px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  @media${Theme.device.mobileL} {
    width: 95vw;
    margin-top: 10px;
    & > img {
      max-width: none;
      max-height: 100%;
    }
  }
`;
export const TxtWrapper = styled.div`
  position: absolute;
  top: 0;
  // right: 40px;
  left: 5px;
  width: 300px;
  height: 100%;
  & > p,
  & > h3,
  & > h4 {
    position: absolute;
    box-shadow: 1px 1px 15px ${Theme.dark.primaryColor.dark};
    padding: 10px 20px;
    background: ${Theme.light.secondaryColor.green};
  }
  & > p {
    top: 80px;
    left: 0;
    font-size: 34px;
  }
  & > h3 {
    top: 135px;
    left: 30px;
    font-size: 45px;
  }
  & > h4 {
    top: 250px;
    left: 15px;
    font-size: 24px;
  }
  @media${Theme.device.mobileL} {
    top: 185px;
    & > p,
    & > h3,
    & > h4 {
      padding: 10px;
    }
    & > p {
      font-size: 26px;
    }
    & > h3 {
      font-size: 26px;
      top: 125px;
    }
    & > h4 {
      top: 175px;
    }
  }
`;
