import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  ConentListWraper,
  ItemWraper,
  SendList,
  TotalPrice,
  ItemContent,
  ActionsItem,
  InputItems,
  ImgWraper,
  InfoWraper,
  BrandWraper,
  styleForSvg,
  styleForActionsSvg,
  styleForMoreActionsSvg,
  styleForActiveLigth,
  styleForActiveDark,
  styleForDisactiveDark,
  styleForDisactiveLight,
  animationInputVariants,
} from "../styleForShopingList";

import { Theme } from "../../../store/theme/theme";
// import alertify from "alertifyjs";
// import "alertifyjs/build/css/alertify.css";
// import "alertifyjs/build/css/themes/default.css";
import { UserItem } from "./UserItem";
import { LoginItem } from "./LoginItem";

import {
  deleteItemFromList,
  updateItemOnList,
  clearAllShopingList,
  setErr,
  closeVisibleList,
} from "../../../store/shopingList/duck/actions/actions";
import {
  newspaperId,
  selectPageFromDeal,
  setTargetCordinates,
  clearSelectPageFromDeal,
  clearTargetCordinates,
} from "../../../store/select/duck/actions/actions";
import { API_URL } from "../../../constant/constant";
import { SvgColorStyle } from "../../../utils/SvgColorStyle";
import { PageReloaded } from "../../../utils/PageReloaded";
import { ErrorBoundary } from "../../errorBoundary/ErrorBoundary";
import { ErrorHandler } from "../../error/ErrorHandler";
import {
  encryptFrontData,
  decryptFrontData,
} from "../../../utils/Crypto/cryptoFront.controller";
const trashPath =
  "M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z";

const startPath =
  "M436 160H12c-6.627 0-12-5.373-12-12v-36c0-26.51 21.49-48 48-48h48V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h128V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h48c26.51 0 48 21.49 48 48v36c0 6.627-5.373 12-12 12zM12 192h424c6.627 0 12 5.373 12 12v260c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V204c0-6.627 5.373-12 12-12zm333.296 95.947l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L194.12 364.665l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068l-28.397 28.17c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z";
const endTimePath =
  "M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm257.3 160l48.1-48.1c4.7-4.7 4.7-12.3 0-17l-28.3-28.3c-4.7-4.7-12.3-4.7-17 0L224 306.7l-48.1-48.1c-4.7-4.7-12.3-4.7-17 0l-28.3 28.3c-4.7 4.7-4.7 12.3 0 17l48.1 48.1-48.1 48.1c-4.7 4.7-4.7 12.3 0 17l28.3 28.3c4.7 4.7 12.3 4.7 17 0l48.1-48.1 48.1 48.1c4.7 4.7 12.3 4.7 17 0l28.3-28.3c4.7-4.7 4.7-12.3 0-17L269.3 352z";

const downPath =
  "M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z";
const targetPath =
  "M500 224h-30.364C455.724 130.325 381.675 56.276 288 42.364V12c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v30.364C130.325 56.276 56.276 130.325 42.364 224H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h30.364C56.276 381.675 130.325 455.724 224 469.636V500c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-30.364C381.675 455.724 455.724 381.675 469.636 288H500c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12zM288 404.634V364c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40.634C165.826 392.232 119.783 346.243 107.366 288H148c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12h-40.634C119.768 165.826 165.757 119.783 224 107.366V148c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12v-40.634C346.174 119.768 392.217 165.757 404.634 224H364c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40.634C392.232 346.174 346.243 392.217 288 404.634zM288 256c0 17.673-14.327 32-32 32s-32-14.327-32-32c0-17.673 14.327-32 32-32s32 14.327 32 32z";
const walletPath =
  "M461.2 128H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h384c8.84 0 16-7.16 16-16 0-26.51-21.49-48-48-48H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h397.2c28.02 0 50.8-21.53 50.8-48V176c0-26.47-22.78-48-50.8-48zM416 336c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z";
const planePath =
  "M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z";
const animationVariants = {
  hidden: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
  visible: {
    opacity: 1,
    // scale:1,
    y: 0,
    // transformOrigin:"bottom",
    transition: { duration: 0.5 },
  },
  exit: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
};

const countTotalPrice = (listItems, userItems) => {
  const concatList = [...listItems, ...userItems];
  const total = concatList.reduce((total, item) => {
    return total + item.price * item.howMany;
  }, 0);
  return total.toFixed(2);
};

const checkPrice = (additionalOptions) => {
  if (additionalOptions.howMany && additionalOptions.price) {
    return Number(additionalOptions.howMany * additionalOptions.price).toFixed(
      2
    );
  }
  return 0;
};

const SingleItem = ({
  item,
  isDark,
  newspaperId,
  selectPageFromDeal,
  setTargetCordinates,
  deleteItemFromList,
  updateItemOnList,
  clearSelectPageFromDeal,
  clearTargetCordinates,
  selectedPage,
  targetCordinatesX,
  isMobileL,
  closeVisibleList,
}) => {
  const reload = PageReloaded();
  const [moreOptionsVisible, setMoreOptionsVisible] = useState(true);
  useEffect(() => {
    setMoreOptionsVisible(true);
  }, [reload]);
  const errAlfanumeric = "dostępne znaki alfanumeryczne";
  const errNumeric = "dostępne znaki numeryczne [0-9].[01-99]";
  const errToManySigns = "dostępne 50 znaków";
  const regNameMultiLng =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšśŚžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9\s ,.'-]+$/u;

  const forDecimals = /^\d*(\.)?(\,)?(\d{0,2})?$/;

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.target.blur();
    }
  };
  const handleOnchange = (e) => {
    const name = e.target.name;

    if (name === "typeSelect") {
      if (
        e.target.options[e.target.selectedIndex].value === "kg" ||
        e.target.options[e.target.selectedIndex].value === "szt"
      ) {
        updateItemOnList({
          ...item,
          [name]: e.target.options[e.target.selectedIndex].value,
        });
      }
    } else {
      if (name === "aditionalName") {
        const { value } = e.target;
        if (value?.length > 2 && !regNameMultiLng.test(value)) {
          updateItemOnList({
            ...item,
            [name]: e.target.value,
            err: errAlfanumeric,
          });
          return;
        }
        if (value?.length <= 100) {
          updateItemOnList({ ...item, [name]: e.target.value, err: "" });
          return;
        } else {
          updateItemOnList({
            ...item,
            [name]: e.target.value,
            err: errToManySigns,
          });
          return;
        }
      } else if (name === "howMany" || name === "price") {
        if (forDecimals.test(e.target.value)) {
          updateItemOnList({ ...item, [name]: e.target.value, err: "" });
        } else {
          updateItemOnList({
            ...item,
            [name]: e.target.value,
            err: errNumeric,
          });
        }
      }
    }
  };

  return (
    <>
      {item && (
        <ItemWraper
          isDark={isDark}
          variants={animationVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          isItem={true}
          moreOptionsVisible={moreOptionsVisible}
        >
          <ItemContent isDark={isDark}>
            <ImgWraper isDark={isDark}>
              <img
                loading="lazy"
                src={`${API_URL}${item.imgUrl}`}
                alt={item.name}
              />
            </ImgWraper>
            <InfoWraper isDark={isDark}>
              <p>
                {item.name}{" "}
                {item.aditionalName && <span>{item.aditionalName}</span>}
              </p>
              <p>
                Ilość :
                <span>
                  {item.howMany ? item.howMany : 0} {item.typeSelect}
                </span>
              </p>
              <p>
                Cena za {item.typeSelect}{" "}
                <span>{item.price ? item.price : 0} PLN</span>
              </p>
              <p>
                W sumie :<span>{checkPrice(item)} PLN</span>
              </p>
            </InfoWraper>
            <BrandWraper isDark={isDark}>
              <img
                loading="lazy"
                src={`${
                  item && item?.brandData?.length && item?.brandData[0]?.imgUrl
                }`}
                alt={item?.name}
              />
              <p>
                {/* <img src={StartTime} style={styleForSvg} /> */}
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="calendar-check"
                  className="svg-inline--fa fa-calendar-check fa-w-14"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  height={16}
                  style={styleForSvg}
                >
                  <path fill={SvgColorStyle(isDark)} d={startPath}></path>
                </svg>
                {item.startDuringTime.slice(0, item.startDuringTime.length - 5)}
              </p>
              <p>
                {/* <img src={EndTime} style={styleForSvg} /> */}
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="calendar-times"
                  className="svg-inline--fa fa-calendar-times fa-w-14"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  height={16}
                  style={styleForSvg}
                >
                  <path fill={SvgColorStyle(isDark)} d={endTimePath}></path>
                </svg>
                {item.endDuringTime.slice(0, item.endDuringTime.length - 5)}
              </p>
            </BrandWraper>
          </ItemContent>
          <ActionsItem isDark={isDark}>
            <Link
              to={`/gazetka-promocyjna/${item.brand}-${item?.bookData[0]?.alternativeFinalName}/${item.startDuringTime}-${item.endDuringTime}/${item.page}`}
              onClick={() => {
                if (selectedPage && targetCordinatesX) {
                  clearTargetCordinates();
                  clearSelectPageFromDeal();
                }

                selectPageFromDeal(item.page);
                setTargetCordinates(item.cordinates);
                newspaperId(item.bookId);
                if (isMobileL) {
                  closeVisibleList();
                }
              }}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="crosshairs"
                className="svg-inline--fa fa-crosshairs fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                height={20}
                style={styleForActionsSvg}
              >
                <path fill={SvgColorStyle(isDark)} d={targetPath}></path>
              </svg>
              <p>pokaż w gazetce</p>
            </Link>
            <div onClick={() => deleteItemFromList(item)}>
              {/* <img src={Trash} style={styleForActionsSvg} /> */}
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="trash-alt"
                className="svg-inline--fa fa-trash-alt fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                width={20}
                height={20}
                style={styleForSvg}
              >
                <path fill={SvgColorStyle(isDark)} d={trashPath}></path>
              </svg>
              <p>usuń z listy</p>
            </div>
            <div onClick={() => setMoreOptionsVisible(!moreOptionsVisible)}>
              {
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="angle-down"
                  className="svg-inline--fa fa-angle-down fa-w-10"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  height={30}
                  style={
                    moreOptionsVisible
                      ? isDark
                        ? styleForActiveDark
                        : styleForActiveLigth
                      : !isDark
                      ? styleForDisactiveLight
                      : styleForDisactiveDark
                  }
                >
                  <path fill={SvgColorStyle(isDark)} d={downPath}></path>
                </svg>
              }
            </div>
          </ActionsItem>
          {moreOptionsVisible && (
            <InputItems
              isDark={isDark}
              variants={animationInputVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <input
                type="number"
                name="howMany"
                placeholder="ilość"
                inputMode="decimal"
                value={item.howMany || ""}
                step={`${
                  item.typeSelect === ""
                    ? 0.25
                    : item.typeSelect === "kg"
                    ? 0.25
                    : 1
                }`}
                onChange={handleOnchange}
                onKeyDown={(e) => handleKeyDown(e)}
                min="0"
                max="1000"
              />
              <input
                type="number"
                name="price"
                placeholder="cena"
                inputMode="decimal"
                value={item.price || ""}
                step="0.01"
                onChange={handleOnchange}
                onKeyDown={(e) => handleKeyDown(e)}
                min="0"
                max="10000"
              />
              <select name="typeSelect" onChange={handleOnchange}>
                <option value="kg">kg</option>
                <option value="szt">szt</option>
              </select>
              <input
                type="text"
                name="aditionalName"
                placeholder="dodatkowy opis"
                value={item.aditionalName}
                onChange={handleOnchange}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              {item.err && <p>{item.err}</p>}
            </InputItems>
          )}
        </ItemWraper>
      )}
    </>
  );
};

const _ContentList = ({
  theme,
  shopingList,
  newspaperId,
  selectPageFromDeal,
  setTargetCordinates,
  deleteItemFromList,
  updateItemOnList,
  clearAllShopingList,
  setErr,
  localization,
  clearSelectPageFromDeal,
  clearTargetCordinates,
  select,
  closeVisibleList,
  isMobileL,
}) => {
  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  const sortedList =
    shopingList && shopingList.listItems.length > 0 && shopingList.listItems;
  const wraperRef = useRef();
  const [alertify, setAlertify] = useState(null);
  useEffect(() => {
    return () => {
      setAlertify(null);
    };
  }, []);
  useEffect(() => {
    const loadAlertify = async () => {
      if (typeof window !== "undefined") {
        try {
          const alertifyInstance = (
            await import("../../../media/assets/alertify/alertify.js")
          ).default;
          await import("../../../media/assets/alertify/alertify.min.css");
          await import("../../../media/assets/alertify/default.min.css");
          setAlertify(alertifyInstance);
        } catch (error) {
          console.error("Error loading Alertify:", error);
        }
      }
    };

    loadAlertify();
  }, []);
  useEffect(() => {
    // wraperRef?.current.scrollTop=wraperRef?.current?.offsetHeight;
    if (wraperRef && wraperRef.current) {
      wraperRef.current.scrollTop =
        100 +
        ((shopingList?.listItems?.length || 0) +
          (shopingList?.userItems?.length || 0)) *
          (theme?.isMobile ? 275 : 245);
    }
  }, [shopingList?.userItems?.length]);
  useEffect(() => {
    // wraperRef?.current.scrollTop=wraperRef?.current?.offsetHeight;
    if (wraperRef && wraperRef.current) {
      wraperRef.current.scrollTop =
        100 +
        (shopingList?.listItems?.length - 1 || 0) *
          (theme?.isMobile ? 275 : 245);
    }
  }, [shopingList?.listItems?.length]);
  return (
    <ErrorBoundary>
      {errorFromCrypto && <ErrorHandler errorMsg={errorFromCrypto} />}
      <ConentListWraper isDark={theme.isDark} ref={wraperRef}>
        <h2>lista zakupów</h2>
        <p>z dnia : {new Date().toLocaleDateString()}</p>
        {shopingList.listItems.length > 0 &&
          sortedList &&
          sortedList.map((item) => (
            <SingleItem
              key={item.id}
              item={item}
              isDark={theme.isDark}
              newspaperId={newspaperId}
              selectPageFromDeal={selectPageFromDeal}
              setTargetCordinates={setTargetCordinates}
              deleteItemFromList={deleteItemFromList}
              updateItemOnList={updateItemOnList}
              clearSelectPageFromDeal={clearSelectPageFromDeal}
              clearTargetCordinates={clearTargetCordinates}
              selectedPage={select.selectedPage}
              targetCordinatesX={select.targetCordinates.xStart}
              isMobileL={isMobileL}
              closeVisibleList={closeVisibleList}
            />
          ))}
        <UserItem />
        {shopingList.listItems &&
          shopingList.userItems &&
          (shopingList.listItems.length > 0 ||
            shopingList.userItems.length > 0) && (
            <ItemWraper isDark={theme.isDark}>
              <TotalPrice
                isDark={theme.isDark}
                onClick={() => console.log("zaloguj się")}
              >
                <p>
                  Suma :
                  <span>
                    {` ${countTotalPrice(
                      shopingList.listItems,
                      shopingList.userItems
                    )} `}
                    PLN
                  </span>{" "}
                </p>

                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="wallet"
                  className="svg-inline--fa fa-wallet fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  height={30}
                  style={styleForMoreActionsSvg}
                >
                  <path
                    fill={SvgColorStyle(theme.isDark)}
                    d={walletPath}
                  ></path>
                </svg>
              </TotalPrice>
            </ItemWraper>
          )}
        {shopingList.listItems &&
          shopingList.userItems &&
          (shopingList.listItems.length > 0 ||
            shopingList.userItems.length > 0) &&
          !shopingList.userLoged && (
            <ItemWraper isDark={theme.isDark}>
              <LoginItem isDark={theme.isDark}></LoginItem>
            </ItemWraper>
          )}
        {shopingList.err && (
          <ItemWraper isDark={theme.isDark}>
            <p
              style={{
                color: `${Theme.light.fourthColor.red}`,
                fontSize: "14px",
              }}
            >
              {shopingList.err}
            </p>
          </ItemWraper>
        )}
        {shopingList.listItems &&
          shopingList.userItems &&
          (shopingList.listItems.length > 0 ||
            shopingList.userItems.length > 0) && (
            <ItemWraper isDark={theme.isDark} sendList={true}>
              <SendList
                isDark={theme.isDark}
                onClick={() => {
                  if (shopingList.email) {
                    const frontDataToEncrypt = {
                      list: [
                        ...shopingList.listItems,
                        ...shopingList.userItems,
                      ],
                      total: countTotalPrice(
                        shopingList.listItems,
                        shopingList.userItems
                      ),
                      email: shopingList.email,
                      coordinates: localization.coordinates,
                      created: new Date().getTime(),
                    };

                    // encrypt data for fetch
                    const ciphertext = encryptFrontData(
                      frontDataToEncrypt,
                      setErrorFromCrypto,
                      null,
                      "ContentList.js"
                    );
                    fetch(`${API_URL}/api/sendList`, {
                      method: "POST",
                      cache: "no-cache",
                      credentials: "include",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        data: ciphertext,
                      }),
                    })
                      .then((response) => response.json())
                      .then((result) => {
                        // decrypt data from response
                        return decryptFrontData(
                          result.data,
                          setErrorFromCrypto,
                          null,
                          "ContentList.js"
                        );
                      })
                      .then((data) => {
                        if (data.send === true) {
                          clearAllShopingList();
                          const index = shopingList.email.indexOf("@");
                          const incognitoEmail = shopingList.email
                            .split("")
                            .map((letter, ind) => {
                              if (ind === 0 || ind >= index - 1) {
                                return letter;
                              } else {
                                return "*";
                              }
                            })
                            .join("");
                          alertify.set("notifier", "position", "bottom-left");
                          alertify.success(
                            `Lista zakupów wysłana na ${incognitoEmail}`
                          );
                        } else {
                          alertify.set("notifier", "position", "bottom-left");
                          alertify.error(`Błąd wysyłania - ${data}`);
                          setErr(data);
                        }
                      })
                      .catch((err) => {
                        alertify.set("notifier", "position", "bottom-left");
                        alertify.error(`Błąd wysyłania`);
                        console.log(err);
                      });
                  } else if (!shopingList.email && shopingList.userLoged) {
                    setErr(
                      "Jesteś zalogowany przez paltformę w której nie podałeś swojego adresu e-mail, uzupełnij to lub skorzystaj z innej platformy autoryzującej."
                    );
                  } else {
                    setErr("Musisz się zalogować");
                  }
                }}
              >
                <p>Wyślij liste mailem</p>

                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="paper-plane"
                  className="svg-inline--fa fa-paper-plane fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  height={30}
                  style={styleForMoreActionsSvg}
                >
                  <path fill={SvgColorStyle(theme.isDark)} d={planePath}></path>
                </svg>
              </SendList>
            </ItemWraper>
          )}
      </ConentListWraper>
    </ErrorBoundary>
  );
};

const mapDisaptchToProps = (dispatch) => ({
  newspaperId: (item) => dispatch(newspaperId(item)),
  selectPageFromDeal: (item) => dispatch(selectPageFromDeal(item)),
  setTargetCordinates: (item) => dispatch(setTargetCordinates(item)),
  deleteItemFromList: (item) => dispatch(deleteItemFromList(item)),
  updateItemOnList: (item) => dispatch(updateItemOnList(item)),
  clearAllShopingList: (item) => dispatch(clearAllShopingList(item)),
  setErr: (item) => dispatch(setErr(item)),
  clearSelectPageFromDeal: (item) => dispatch(clearSelectPageFromDeal(item)),
  clearTargetCordinates: (item) => dispatch(clearTargetCordinates(item)),
  closeVisibleList: (item) => dispatch(closeVisibleList(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  shopingList: state.shopingList,
  localization: state.localization,
  select: state.select,
});
export const ContentList = connect(
  mapStateToProps,
  mapDisaptchToProps
)(_ContentList);
