import React from "react";
import { SingleSlide, ImgWrapper, TxtWrapper } from "./ShowCarusel.style";
import { API_URL, SERVER_URL_IMG } from "../../../constant/constant";
import { data } from "./data";
import { RedirectBtn } from "./RedirectBtn";

export const ShowCarusel = ({ isDark, caruselWidth, isMobile }) => {
  return data.map((item) => (
    <SingleSlide key={item?.txt} isDark={isDark} caruselWidth={caruselWidth}>
      <ImgWrapper isDark={isDark} caruselWidth={caruselWidth}>
        <img
          loading="lazy"
          src={
            isDark
              ? isMobile
                ? item.img.dark.mobile
                : item.img.dark.desktop
              : !isMobile
              ? item.img.light.desktop
              : item.img.light.mobile
          }
          alt={item.txt}
        />
      </ImgWrapper>
      <TxtWrapper isDark={isDark}>
        <p>{item.txt}</p>
        <h3>{item.h3}</h3>
        <h4>{item.h4}</h4>
      </TxtWrapper>
      <RedirectBtn
        path={item?.redirect}
        isDark={isDark}
        isMobile={isMobile}
        position={item?.position}
      />
    </SingleSlide>
  ));
};
