import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { chose } from "../../../store/select/duck/actions/actions";
import { clearTopDeals } from "../../../store/select/duck/actions/actions";

import LogoForLight from "../../../media/svgLogo/cenniczek-logo.svg";
import LogoForDark from "../../../media/svgLogo/cenniczek-logo-for-dark.svg";
import { FRONT_URL, SERVER_URL_IMG } from "../../../constant/constant";

const _LogoWrapper = ({ isDark, chose, styled, clearTopDeals }) => {
  const style = {
    height: "40px",
    width: "350px",
    cursor: "pointer",
  };
  return (
    <Link
      to="/"
      onClick={() => {
        chose("kategorie");
        clearTopDeals();
      }}
    >
      {isDark ? (
        <img
          loading="lazy"
          // src={`${FRONT_URL}${LogoForDark}`}
          src={
            typeof window !== "undefined"
              ? // ? `${FRONT_URL}${LogoForDark}`
                `${LogoForDark}`
              : `${SERVER_URL_IMG}/server-media/img/cenniczek-logo-for-dark.svg`
          }
          style={styled ? style : null}
          height={40}
          width={350}
          alt="logo cenniczek.com-zakupy stają się łatwiejsze"
        />
      ) : (
        <img
          loading="lazy"
          // src={`${FRONT_URL}${LogoForLight}`}
          src={
            typeof window !== "undefined"
              ? // ? `${FRONT_URL}${LogoForLight}`
                `${LogoForLight}`
              : `${SERVER_URL_IMG}/server-media/img/cenniczek-logo.svg`
          }
          style={styled ? style : null}
          height={40}
          width={350}
          alt="logo cenniczek.com-zakupy stają się łatwiejsze"
        />
      )}
    </Link>
  );
};
const mapDisaptchToProps = (dispatch) => ({
  chose: (item) => dispatch(chose(item)),
  clearTopDeals: (item) => dispatch(clearTopDeals(item)),
});
export const LogoWrapper = connect(null, mapDisaptchToProps)(_LogoWrapper);
