import { Theme } from "../../../../store/theme/theme";
export const sliderStyle = {
  height: "140px",
  textAlign: "center",
  color: `${Theme.light.primaryColor.white}`,
};
export const aStyle = {
  display: "block",
  height: "120px",
  width: "160px",
  margin: "10px 30px",
  // border: "1px solid white",
  borderRadius: "5px",
  overflow: "hidden",
  cursor: "pointer",
  transition: ".3s",
  position: "relative",
  zIndex: 10,
};
export const aActiveStyle = {
  display: "block",
  height: "120px",
  width: "160px",
  margin: "10px 30px",
  // border: "3px solid white",
  borderRadius: "5px",
  overflow: "hidden",
  cursor: "pointer",
  transition: ".3s",
  position: "relative",
  zIndex: 10,
};
export const mobileAStyle = {
  display: "block",
  height: "80px",
  width: "80px",
  margin: "5px 15px",
  // border: "1px solid white",
  borderRadius: "5px",
  overflow: "hidden",
  cursor: "pointer",
  transition: ".3s",
  position: "relative",
  zIndex: 10,
  opacity: 0.5,
};
export const mobileAStyleNone = {
  display: "block",
  height: "80px",
  width: "80px",
  margin: "5px 15px",
  // border: "1px solid white",
  borderRadius: "5px",
  overflow: "hidden",
  cursor: "pointer",
  transition: ".3s",
  position: "relative",
  zIndex: 10,
  opacity: 1,
};
export const mobileAActiveStyle = {
  display: "block",
  height: "80px",
  width: "80px",
  margin: "5px 15px",
  // border: "3px solid white",
  borderRadius: "5px",
  overflow: "hidden",
  cursor: "pointer",
  transition: ".3s",
  position: "relative",
  zIndex: 10,
  opacity: 1,
};
export const mobileSlideStyle = {
  display: "block",
  height: "80px",
  width: "80px",
  // margin: "10px 30px",
  border: `1px solid ${Theme.light.fiveColor.white}`,
  borderRadius: "5px",
  overflow: "hidden",
  cursor: "pointer",
  transition: ".3s",
  position: "relative",
  // opacity: 0.5,
};
export const mobileSlideStyleActive = {
  display: "block",
  height: "80px",
  width: "80px",
  // margin: "10px 30px",
  border: "3px solid white",
  borderRadius: "5px",
  overflow: "hidden",
  cursor: "pointer",
  transition: ".3s",
  position: "relative",
  // opacity: 1,
};
export const slideStyle = {
  display: "block",
  height: "120px",
  width: "160px",
  // margin: "10px 30px",
  border: "1px solid white",
  borderRadius: "5px",
  overflow: "hidden",
  cursor: "pointer",
  transition: ".3s",
  position: "relative",
  // opacity: 0.5,
};
export const slideStyleActive = {
  display: "block",
  height: "120px",
  width: "160px",
  // margin: "10px 30px",
  border: "3px solid white",
  borderRadius: "5px",
  overflow: "hidden",
  cursor: "pointer",
  transition: ".3s",
  position: "relative",
  // opacity: 1,
};
export const buttonBackStyle = {
  position: "absolute",
  top: "50%",
  left: "0",
  transform: "translate(-45px,-50%)",
  width: "30px",
  height: "40px",
  backgroundColor: "transparent",
  border: "none",
};
export const buttonNextStyle = {
  position: "absolute",
  top: "50%",
  right: "0",
  transform: "translate(45px,-50%)",
  width: "30px",
  height: "40px",
  backgroundColor: "transparent",
  border: "none",
};
export const btnStyle = {
  width: "25px",
  // color: `${Theme.light.primaryColor.white}`,
};
