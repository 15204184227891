import React, { useState } from "react";
import styled from "styled-components";
import { Theme } from "../../../store/theme/theme";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  clearTopDeals,
  chose,
} from "../../../store/select/duck/actions/actions";
import { menuOpen, menuClose } from "../../../store/theme/duck/actions/actions";
import "../../../styles/burgerNav/Burger.css";

const Wrap = styled.nav`
  position: relative;
  z-index: ${({ fromNewspaperHidding }) => (fromNewspaperHidding ? -1 : 100)};
`;
const FirstSpan = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: ${({ active }) => (active ? "28.25px" : "25px")};
  height: 4px;
  border-radius: 3px;
  background-color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  transform-origin: right;
  transform: ${({ active }) => (active ? "rotate(-45deg)" : "rotate(0)")};
  transition: 0.3s;
`;
const SecondSpan = styled.div`
  position: absolute;
  top: 30px;
  right: 15px;
  width: ${({ active }) => (active ? "0" : "25px")};
  height: 4px;
  border-radius: 3px;
  background-color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  transform-origin: right;
  transform: rotate(0);
  transition: 0.3s;
`;
const ThirdSpan = styled.div`
  position: absolute;
  top: 40px;
  right: 20px;
  width: ${({ active }) => (active ? "28.25px" : "25px")};
  height: 4px;
  border-radius: 3px;
  background-color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  transform-origin: right;
  transform: ${({ active }) => (active ? "rotate(45deg)" : "rotate(0)")};
  transition: 0.3s;
`;

const Burger = styled.div`
  width: 70px;
  height: 70px;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  z-index: 99;
  :hover ${FirstSpan} {
    width: ${({ active }) => (active ? "25px" : "28.25px")};
  }
  :hover ${SecondSpan} {
    width: $ ${({ active }) => (active ? "0" : "25px")};
    right: 20px;
  }
  :hover ${ThirdSpan} {
    width: ${({ active }) => (active ? "25px" : "28.25px")};
  }
  @media${Theme.device.mobileL} {
    width: 40px;
  }
`;

const _BurgerNav = ({
  isDark,
  clearTopDeals,
  menuOpen,
  menuClose,
  theme,
  chose,
  select,
}) => {
  const [activeBurger, setActiveBurger] = useState(false);
  const activeStyle = {
    fontWeight: "bold",
    color: `${Theme.light.fourthColor.red}`,
  };
  return (
    <Wrap fromNewspaperHidding={theme?.canOverflow}>
      <Burger
        onClick={() => {
          setActiveBurger(!activeBurger);
          theme?.isMenuOpen ? menuClose() : menuOpen();
        }}
      >
        <FirstSpan active={theme?.isMenuOpen} isDark={isDark} />
        <SecondSpan active={theme?.isMenuOpen} isDark={isDark} />
        <ThirdSpan active={theme?.isMenuOpen} isDark={isDark} />
      </Burger>

      <nav
        className={`burger-nav ${theme?.isMenuOpen ? "active" : ""} ${
          theme?.canOverflow ? "canOverflow" : ""
        }`}
      >
        <ul>
          <li>
            <NavLink
              to="/"
              onClick={() => {
                // setActiveBurger(false)
                menuClose();
              }}
              exact
              activeStyle={activeStyle}
            >
              strona główna
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/gazetki-promocyjne"
              onClick={() => {
                // setActiveBurger(false)
                clearTopDeals();
                menuClose();
                chose(select?.options[0].name);
              }}
              exact
              activeStyle={activeStyle}
            >
              gazetki promocyjne
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/promocje"
              onClick={() => {
                // setActiveBurger(false)
                clearTopDeals();
                menuClose();
              }}
              exact
              activeStyle={activeStyle}
            >
              promocje
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/blog"
              onClick={() => {
                // setActiveBurger(false)
                menuClose();
              }}
              exact
              activeStyle={activeStyle}
            >
              blog
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/kontakt"
              onClick={() => {
                //  setActiveBurger(false)
                menuClose();
              }}
              exact
              activeStyle={activeStyle}
            >
              kontakt
            </NavLink>
          </li>
        </ul>
      </nav>
    </Wrap>
  );
};
const mapDispatchToProps = (dispatch) => ({
  clearTopDeals: (item) => dispatch(clearTopDeals(item)),
  menuOpen: () => dispatch(menuOpen()),
  menuClose: () => dispatch(menuClose()),
  chose: (item) => dispatch(chose(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
});
export const BurgerNav = connect(
  mapStateToProps,
  mapDispatchToProps
)(_BurgerNav);
