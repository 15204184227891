import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
// import { ReactComponent as Checked } from '../../../../media/svg/check-solid.svg'
import { motion } from "framer-motion";
import { Theme } from "../../../../store/theme/theme";
const timer = (index) => index * 1 - index * 0.2 - 0.8;
const animateVariants = {
  hidden: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
  visible: {
    opacity: 1,
    // scale:1,
    y: 0,
    // transformOrigin:"bottom",
    transition: { duration: 0.3 },
  },
  exit: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
};
const animationForSelect = keyframes`
    from {
        transform:translateX(-100%);
    }
    to {
        transform:translateX(200%);
    }

`;
const AnimmationDiv = styled.div.attrs((props) => ({
  style: {
    display: props.isOverflow ? "none" : "block",
  },
}))`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 70%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: ${animationForSelect} 2s ${({ index }) => timer(index)}s 3 both;
`;

const ItemWraper = styled(motion.div)`
  min-width: 150px;
  height: 40px;
  margin: 0 20px 0 20px;
  border: 1px solid ${Theme.light.primaryColor.white};
  border-radius: 20px;
  // background-image: linear-gradient(
  //   180deg,
  //   #ff512f 35%,
  //   ${Theme.light.fourthColor.red} 100%
  // );
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 40px;
  cursor: pointer;
  transition: 0.3s;
  user-select: none;
  position: relative;
  overflow: ${({ isOverflow }) => (!isOverflow ? "hidden" : "")};
  & > h3 {
    font-weight: normal;
    font-size: 14px;
    line-height: 40px;
  }
  &:hover {
    box-shadow: 1px 1px 7px ${Theme.light.primaryColor.white};
  }
  @media${Theme.device.tablet} {
    margin: 0 15px 0 15px;
  }
  @media${Theme.device.mobileL} {
    min-width: 120px;
    height: 40px;
    margin: 0 5px 0 5px;
    font-size: ${Theme.size.mobileM};
    line-height: 35px;
    padding: 0 7px 0 7px;
  }
  @media${Theme.device.mobileM} {
    min-width: 100px;
    height: 30px;
    margin: 0 5px 0 5px;
    font-size: ${Theme.size.fontSizeMobileS};
    line-height: 30px;
  }
  @media${Theme.device.mobileS} {
    // min-width: 50px;
    height: 30px;
    margin: 0 5px 0 5px;
    font-size: ${Theme.size.fontSizeMobileS};
    line-height: 30px;
  }
  @media${Theme.device.mobileXS} {
    // min-width: 40px;
    height: 40px;
    margin: 0 5px 10px 0;
    font-size: ${Theme.size.fontSizeMobileS};
    line-height: 20px;
  }
`;
const CheckBox = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 15px;
  border-radius: 5px;
  border: 1px solid $black;
  background: ${Theme.light.thirdColor.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  box-shadow: inset 1px 1px 1px black;
  @media${Theme.device.mobileL} {
    margin-left: 5px;
  }
`;
const DescribeContainer = styled(motion.div)`
  position: absolute;
  top: 40px;
  left: 20px;
  width: 130px;
  border-radius: 5px;
  background: ${Theme.dark.primaryColor.dark};
  color: ${Theme.dark.thirdColor.white};
  border: 1px solid black;
  z-index: 5;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 16px;
`;

const checkContent = (content) => {
  switch (content.toLowerCase()) {
    case "kategorie":
      return <p>gazetki podzielone na kategorie</p>;
    case "sklepy":
      return <p>wybierz sklep którego szukasz</p>;
    case "okolica":
      return <p>sklepy w odległości najbliższej od Ciebie</p>;
    default:
      return null;
  }
};
export const ItemSelect = ({ active, content, click, isDark, index }) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const [isVisible, setVisible] = useState({
    show: false,
    count: 0,
  });
  const pathDefinition = "M22.9 3.7l-15.2 16.6-6.6-7.1";
  useEffect(() => {
    setTimeout(() => {
      setIsOverflow(true);
    }, (timer(index) + 2) * 1000);
  }, []);
  useEffect(() => {
    if (isVisible.show) {
      setTimeout(() => {
        setVisible({ ...isVisible, show: false });
      }, 2000);
    }
  }, [isVisible]);
  return (
    <>
      <ItemWraper
        isOverflow={isOverflow}
        onClick={click}
        whileTap={{ scale: 0.95 }}
        onMouseOver={() => {
          if (isVisible.count < 1) {
            setVisible({ show: true, count: isVisible.count + 1 });
          }
        }}
        onMouseLeave={() => {
          setTimeout(() => {
            setVisible({ ...isVisible, show: false });
          }, 2000);
        }}
      >
        {isVisible.show && (
          <DescribeContainer
            variants={animateVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {checkContent(content)}
          </DescribeContainer>
        )}
        <AnimmationDiv index={index} isOverflow={isOverflow} />
        <h3>{content}</h3>
        <CheckBox>
          {active ? (
            <motion.svg
              aria-hidden="true"
              className="svgItem"
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 24 24"
            >
              <motion.path
                fill="none"
                d={pathDefinition}
                strokeDashoffset="0px"
                strokeDasharray="32.2016px,32.2016px"
                initial={{ pathLength: 0, pathOffset: 1 }}
                animate={{ pathLength: 1, pathOffset: 0 }}
                transition={{ duration: 0.3 }}
              ></motion.path>
            </motion.svg>
          ) : null}
        </CheckBox>
      </ItemWraper>
    </>
  );
};
