import React, { useState, useEffect } from "react";
import { Theme } from "../../../store/theme/theme";
import { connect } from "react-redux";

import { CarouselProvider, Slider, DotGroup } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { sliderStyle, CaruselWrapper } from "./CaruselSection.style";

import { ShowCarusel } from "./ShowCarusel";
import { PageReloaded } from "../../../utils/PageReloaded";

const _CaruselSection = ({ theme }) => {
  const [btnVisible, setBtnVisible] = useState({ visible: true });
  const [caruselWidth, setCaruselWidth] = useState(350);
  const reload = PageReloaded();
  useEffect(() => {
    if (window.innerWidth > Theme.breakPoint.mobileL) {
      setCaruselWidth(window.innerWidth - window.innerWidth * 0.4);
    } else {
      setCaruselWidth(window.innerWidth - 30);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth > Theme.breakPoint.mobileL) {
        setCaruselWidth(window.innerWidth - window.innerWidth * 0.4);
      } else {
        setCaruselWidth(window.innerWidth - 30);
      }
    });
  }, [reload]);
  // console.log(caruselWidth, "carusel");
  return (
    <CaruselWrapper>
      <CarouselProvider
        // naturalSlideWidth={caruselWidth || 350}
        // naturalSlideHeight={300}
        totalSlides={3}
        visibleSlides={1}
        orientation={"horizontal"}
        isIntrinsicHeight={true}
        step={1}
        dragStep={1}
        isPlaying={true}
        interval={5000}
      >
        <Slider style={{ ...sliderStyle, width: `${caruselWidth}px` }}>
          <ShowCarusel
            isDark={theme.isDark}
            caruselWidth={caruselWidth}
            isMobile={theme.isMobile}
          />
        </Slider>
        <DotGroup
          className="caruselTopDealsDots"
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            padding: "10px",
          }}
        >
          <div style={{ width: "10px", margin: "2px" }}></div>
        </DotGroup>
        {/* ) : null} */}
      </CarouselProvider>
    </CaruselWrapper>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
});

export const CaruselSection = connect(mapStateToProps, {})(_CaruselSection);
