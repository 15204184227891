import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Theme } from "../../store/theme/theme";

const StyledWraperForNumber = styled.div.attrs((props) => ({
  style: {
    background: `${
      props.isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white
    }`,
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
    border: `2px solid ${props.isDark ? "black" : Theme.light.thirdColor.dark}`,
  },
}))`
  width: ${({ isMobile }) => (isMobile ? 20 : 30)}px;
  height: ${({ isMobile }) => (isMobile ? 20 : 30)}px;
  border-radius: 15px;
  position: absolute;
  top: ${({ isMobile }) => (isMobile ? -12 : -15)}px;
  left: ${({ isMobile }) => (isMobile ? 25 : 40)}px;
  // left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media${Theme.device.mobileL} {
    font-size: ${Theme.size.fontSizeMobileS};
  }
`;
const _NumberOfShopingItems = ({ theme, shopingList }) => {
  return (
    <>
      {shopingList &&
        shopingList.listItems &&
        (shopingList.listItems.length > 0 ||
          shopingList.userItems.length > 0) && (
          <StyledWraperForNumber
            isDark={theme.isDark}
            isMobile={theme.isMobile}
          >
            {shopingList.listItems.length + shopingList.userItems.length}
          </StyledWraperForNumber>
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  shopingList: state.shopingList,
});
export const NumberOfShopingItems = connect(
  mapStateToProps,
  {}
)(_NumberOfShopingItems);
