import React, { useEffect } from "react";
import { MainView } from "../components/main/MainView";
import { MainBlog } from "../components/blogSection/MainBlog";
import { NewsLetterSection } from "../components/newsLetterSection/NewsLetterSection";
import { InfoSection } from "../components/infoSection/InfoSection";
import { Footer } from "../components/footerSection/Footer";
import { TopDeals } from "../components/topDealsSection/TopDeals";
import { connect } from "react-redux";
import {
  getDataForHomePageQuery,
  getFirstOtionedProductItemsQuery,
} from "../queries/queries";
import { ErrorFromData } from "../components/error/ErrorFromData";
import { useQuery } from "@apollo/client";
import { chose } from "../store/select/duck/actions/actions";
import { clearTopDeals } from "../store/select/duck/actions/actions";

const useDealsDataOptioned = ({ select, options }) => {
  const sortTypeVariables =
    select?.sortDeals.filter((item) => item.active === true)[0]?.name ||
    "recomended";
  const topDealsSelected = select?.topDealsSelected?.name || "";

  return useQuery(getFirstOtionedProductItemsQuery, {
    variables: {
      sortType: sortTypeVariables,
      selectType: topDealsSelected,
      options,
    },
    ssr: true,
  });
};
const _HomePage = ({
  select,
  selectViewData,
  setSelectViewData,
  theme,
  clearTopDeals,
  chose,
}) => {
  const options = theme?.isMobile ? 16 : 15;
  const selected = select.options.filter((item) => item.active);

  const variableForSort =
    select?.sort.filter((item) => item.active)[0]?.name || "recomended";
  const variableForSortDeals =
    select?.sortDeals.filter((item) => item.active)[0]?.name || "recomended";

  const dataFromHomePage = useQuery(getDataForHomePageQuery, {
    fetchPolicy: "network-only",
    variables: {
      name: "gazetki markety",
      options: 10,
      sortType: variableForSort,
    },
    ssr: true,
  });
  const dealsDataOptioned = useDealsDataOptioned({ select, options });

  useEffect(() => {
    if (dataFromHomePage?.client) {
      dataFromHomePage.client.resetStore();
    }
    if (dealsDataOptioned?.client) {
      dealsDataOptioned.client.resetStore();
    }
  }, []);
  useEffect(() => {
    // chose("kategorie");
    clearTopDeals();
  }, []);
  useEffect(() => {
    dealsDataOptioned.refetch();
  }, [variableForSortDeals]);

  useEffect(() => {
    setSelectViewData(dataFromHomePage?.data?.forHomePage[0]);
  }, [dataFromHomePage?.data?.forHomePage[0]?.name]);

  if (dataFromHomePage?.error) {
    return (
      <ErrorFromData
        errorInfo={dataFromHomePage.error}
        queryName={"dataFromHomePage._HomePage"}
      />
    );
  }

  return (
    <>
      <MainView
        selected={selected[0]?.valueName}
        dataFromHomePage={dataFromHomePage}
      />
      <TopDeals
        options={options}
        dataFromHomePage={dataFromHomePage}
        dealsDataOptioned={dealsDataOptioned}
        dealsType="HOME_PAGE"
      />
      <NewsLetterSection />
      <MainBlog dataFromHomePage={dataFromHomePage} />
      <InfoSection />
      <Footer dataFromHomePage={dataFromHomePage} />
    </>
  );
};
const mapDisaptchToProps = (dispatch) => ({
  chose: (item) => dispatch(chose(item)),
  clearTopDeals: (item) => dispatch(clearTopDeals(item)),
});
const mapStateToProps = (state) => ({
  select: state.select,
  theme: state.theme,
});
export const HomePage = connect(mapStateToProps, mapDisaptchToProps)(_HomePage);
