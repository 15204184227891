import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Theme } from "../../store/theme/theme";

const ItemWraper = styled.div`
  width: 70%;
  height: 40px;
  margin: 10px;
  // border:1px solid ${Theme.light.primaryColor.white};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 40px;
  cursor: pointer;
  transition: 0.3s;
  user-select: none;
  transform: scale(1);
  // transform-origin:left;
  &:hover {
    // box-shadow:1px 1px 7px ${Theme.light.primaryColor.white};
    transform: scale(1.1);
  }
  & > a {
    margin-left: 5px;
    color: ${Theme.light.primaryColor.white};
    transform: scale(1);
    transform-origin: left;
    transition: 0.3s;
  }
  & > a:hover {
    transform: scale(1.1);
  }
  @media${Theme.device.mobileL} {
    width: 90%;
  }
`;
const CheckBox = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 15px;
  border-radius: 5px;
  border: 1px solid $black;
  background: ${Theme.light.thirdColor.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  box-shadow: inset 1px 1px 1px black;
`;

export const TermsAcceptContact = ({ active, click }) => {
  const pathDefinition = "M22.9 3.7l-15.2 16.6-6.6-7.1";

  return (
    <>
      <ItemWraper onClick={click}>
        <CheckBox>
          {active ? (
            <motion.svg
              aria-hidden="true"
              className="svgItem"
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 24 24"
            >
              <motion.path
                fill="none"
                d={pathDefinition}
                strokeDashoffset="0px"
                strokeDasharray="32.2016px,32.2016px"
                initial={{ pathLength: 0, pathOffset: 1 }}
                animate={{ pathLength: 1, pathOffset: 0 }}
                transition={{ duration: 0.3 }}
              ></motion.path>
            </motion.svg>
          ) : null}
        </CheckBox>
        Akceptacja <Link to="/regulamin">regulaminu</Link>
      </ItemWraper>
    </>
  );
};
