export const buttonBackStyle = {
  width: "30px",
  height: "50px",
  backgroundColor: "transparent",
  border: "none",
  margin: "5px",
};
export const buttonNextStyle = {
  width: "30px",
  height: "50px",
  backgroundColor: "transparent",
  border: "none",
  margin: "5px",
  marginLeft: "15px",
};
export const btnStyle = {
  width: "25px",
};
