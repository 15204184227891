import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  SlideHover,
  imgSuspenseStyle,
  mobileSlideStyle,
  mobileSlideStyleActive,
} from "../style/style";
import { NumbersOfItems } from "../singleSlide/numbersOfItems/NumbersOfItems";

const imagePath =
  "M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z";
const ItemBrandImageMobile = ({ imgUrl, altName }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <>
      {isLoaded ? null : (
        // <ImgSuspense style={imgSuspenseStyle} />
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="image"
          className="svg-inline--fa fa-image fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width={30}
          style={imgSuspenseStyle}
        >
          <path fill="currentColor" d={imagePath}></path>
        </svg>
      )}
      <img
        loading="lazy"
        onLoad={() => setIsLoaded(true)}
        src={`${imgUrl}`}
        alt={`promocje ${altName}`}
      />
    </>
  );
};

export const SingleSlideMobile = ({
  item,
  index,
  select,
  slideStyleActive,
  slideStyle,
  caruselWraperVariants,
  topDealsSelect,
  topDealsSelectedName,
  dataFromTopDealsPage,
}) => {
  const [active, setActive] = useState(false);
  // console.log(topDealsSelectedName, "topDealsSelectedName SingleSide");
  useEffect(() => {
    topDealsSelectedName === item.name ? setActive(true) : setActive(false);
  }, [topDealsSelectedName]);
  return (
    <motion.div
      key={index}
      variants={caruselWraperVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      custom={index}
      whileTap={{ scale: 0.95 }}
      style={{ position: "relative" }}
    >
      <NumbersOfItems item={item} />
      <div
        index={index}
        style={active ? mobileSlideStyleActive : mobileSlideStyle}
        onClick={() => {
          topDealsSelect(item);
        }}
      >
        <SlideHover>
          <h3>{item.name}</h3>
          <ItemBrandImageMobile imgUrl={item.imgUrl} altName={item.name} />
        </SlideHover>
      </div>
    </motion.div>
  );
};
