import {
  TOGGLE_THEME,
  MENU_OPEN,
  MENU_CLOSE,
  OVER_CLOSE,
  OVER_OPEN,
  CHECK_IS_MOBILE,
} from "../types/types";
let myStorage;
if (process?.env?.NODE_ENV === "development" || "production") {
  if (typeof window === "undefined") {
    myStorage = null;
  } else {
    myStorage = localStorage;
  }
}

const INITIAL_STATE = {
  isDark:
    // myStorage && myStorage.getItem("isDark")
    //   ? JSON.parse(myStorage.getItem("isDark"))
    //   :
    false,
  isMenuOpen: false,
  canOverflow: false,
  isMobile: false,
};
export const themeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      myStorage && myStorage.setItem("isDark", action.item);
      return {
        ...state,
        isDark: action.item,
      };
    case MENU_OPEN:
      return {
        ...state,
        isMenuOpen: true,
      };
    case MENU_CLOSE:
      return {
        ...state,
        isMenuOpen: false,
      };
    case OVER_CLOSE:
      return {
        ...state,
        canOverflow: false,
      };
    case OVER_OPEN:
      return {
        ...state,
        canOverflow: true,
      };
    case CHECK_IS_MOBILE:
      return {
        ...state,
        isMobile: action.item,
      };

    default:
      return { ...state };
  }
};
// export default themeReducer;
