import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  addShopsNearUser,
  errorPartnersNearUser,
  disalowToUserGeolocation,
  getUserLocalization,
} from "../../store/localization/duck/actions/actions";
import { getLocation } from "../../store/localization/duck/operations/getLocation";
import { API_URL } from "../../constant/constant";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { ErrorHandler } from "../error/ErrorHandler";
import {
  encryptFrontData,
  decryptFrontData,
} from "../../utils/Crypto/cryptoFront.controller";

const _FetchingData = ({
  coordLat,
  coordLng,
  localization,
  addShopsNearUser,
  arround,
  errorPartnersNearUser,
  getLocation,
  disalowToUserGeolocation,
  getUserLocalization,
}) => {
  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((res) => {
        res.onchange = (e) => {
          const newState = e.target.state;
          if (newState === "denied") {
            console.log("why did you decide to block us?");
            return disalowToUserGeolocation();
          } else if (newState === "granted") {
            console.log("We will be together forever!");
            return getLocation();
          } else {
            console.log("Thanks for reverting things back to normal");
            return getLocation();
          }
        };
      });
    } else {
      navigator.geolocation.getCurrentPosition(
        (positition) => {
          console.log("We will be together forever!");
          return getLocation();
        },
        (error) => {
          console.log("why did you decide to block us?");
          return disalowToUserGeolocation();
        }
      );
    }
  }, []);
  useEffect(() => {
    if (localStorage && localStorage.getItem("userLocalization")) {
      const localizationData = JSON.parse(
        localStorage.getItem("userLocalization")
      );
      if (localizationData?.coords) {
        getUserLocalization(localizationData);
      }
    }
  }, []);

  useEffect(() => {
    errorPartnersNearUser("");
    if (coordLat && coordLng && coordLat !== 52.237049) {
      const active = localization.setLocalization;
      if (active) {
        const frontDataToEncrypt = {
          arroundOption: arround,
          userLat: localization.coordinates.lat,
          userLng: localization.coordinates.lng,
        };
        // encrypt data for fetch
        const ciphertext = encryptFrontData(
          frontDataToEncrypt,
          setErrorFromCrypto,
          null,
          "FetchingData.js"
        );
        fetch(`${API_URL}/api/shopsNearUser`, {
          method: "POST",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
          // body: JSON.stringify({
          //   arroundOption: arround,
          //   userLat: localization.coordinates.lat,
          //   userLng: localization.coordinates.lng,
          // }),
          body: JSON.stringify({
            data: ciphertext,
          }),
        })
          .then((response) => response.json())
          .then((result) => {
            // decrypt data from response
            return decryptFrontData(
              result.data,
              setErrorFromCrypto,
              null,
              "FetchingData.js"
            );
          })
          .then((data) => {
            if (data) {
              if (data.error) errorPartnersNearUser(data.error);
              const shopsFromApi = data.data.map((shop) => shop.item);
              if (!shopsFromApi.length) {
                errorPartnersNearUser(
                  "Brak wyników-spróbuj zwiększyć zakres poszukiwań "
                );
                addShopsNearUser("");
              } else {
                errorPartnersNearUser("");
                addShopsNearUser(data.data);
              }
            }
          });
      }
    }
  }, [coordLat, coordLng, arround, localization?.setLocalization]);

  return (
    <>
      {/* {errorFromCrypto && <ErrorHandler errorMsg={errorFromCrypto} />} */}
      {!errorFromCrypto && null}
    </>
  );
};
const mapStateToProps = (state) => ({
  localization: state.localization,
});
const mapDispatchToProps = (dispatch) => ({
  addShopsNearUser: (item) => dispatch(addShopsNearUser(item)),
  errorPartnersNearUser: (item) => dispatch(errorPartnersNearUser(item)),
  disalowToUserGeolocation: () => dispatch(disalowToUserGeolocation()),
  getLocation: (item) => dispatch(getLocation(item)),
  getUserLocalization: (item) => dispatch(getUserLocalization(item)),
});
export const FetchingData = connect(
  mapStateToProps,
  mapDispatchToProps
)(_FetchingData);
