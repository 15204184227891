import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
import MainBackground from "../../media/img/main-cenniczek-background5.jpg";
import MainBackgroundMobile from "../../media/img/main-cenniczek-background5_mobile_midQ.jpg";
import { motion } from "framer-motion";
import { API_URL } from "../../constant/constant";
export const WrraperSection = styled.div`
  width: 100%;
  // margin-top: 70px;
  // height:70vh;
  // background: url(${MainBackground});
  background-image: url(${typeof window !== "undefined"
    ? MainBackground
    : `${API_URL}/server-media/img/main-cenniczek-background5.jpg`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  user-select: none;
  // height:50vh;
  @media${Theme.device.mobileL} {
    background-image: url(${typeof window !== "undefined"
      ? MainBackgroundMobile
      : `${API_URL}/server-media/img/main-cenniczek-background5_mobile_midQ.jpg`});
  }
`;
export const MainWrapperLayer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  transition: background 0.3s;
  display: flex;
  justify-content: space-around;
  // align-items:flex-start;
`;
export const MainContent = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 70vh;
  max-width: ${Theme.breakPoint.desktop}px;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  font-family: montserrat;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  & > h1 {
    text-transform: uppercase;
    font-size: ${Theme.size.h1};
    font-weight: 500;
    user-select: none;
  }
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
  & > div > p {
    margin: 15px;
    font-size: 1.5rem;
    user-select: none;
  }
  & > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  & > form > input,
  & > form > textarea {
    width: 70%;
    height: 60px;
    margin: 20px;
    border: none;
    border-radius: 5px;
    text-align: center;
    transition: 0.1s;
    transform: scale(1);
    background: ${({ isDark }) =>
      isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  }
  & > form > textarea {
    height: 120px;
  }
  & > form > input:hover,
  & > form > textarea:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
  & > form > input::placeholder,
  & > form > textarea::placeholder {
    text-align: center;
    text-transform: uppercase;
    opacity: 1;
    transition: 0.3s;
  }
  & > form > textarea::placeholder {
    padding-top: 10px;
  }
  & > form > input:focus::placeholder,
  & > form > textarea:focus::placeholder {
    opacity: 0;
  }
  & > form > button {
    width: 200px;
    height: 60px;
    margin: 10px;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: ${Theme.light.fourthColor.red};
    border: 1px solid black;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
    transition: 0.3s;
    transform: scale(1);
    user-select: none;
    cursor: pointer;
  }
  & > form > button:hover {
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
    transform: scale(1.1);
  }
  @media${Theme.device.mobileL} {
    & > div > p {
      margin: 0;
      font-size: 1.2rem;
      text-align: left;
    }
    & > form > button {
      margin-bottom: 20px;
    }
  }
`;
export const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-left: 100px;
  & > h1 {
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
  }

  & > h1 > span {
    text-align: left;
    width: 300px;
    padding-bottom: 20px;
    display: block;
    text-transform: uppercase;
    border-bottom: 1px solid
      ${({ isDark }) =>
        !isDark
          ? Theme.dark.primaryColor.dark
          : Theme.light.primaryColor.white};
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  }
  @media${Theme.device.mobileL} {
    width: 95%;
    margin-left: 0;
    & > h1 > span {
      font-size: 20px;
      width: 240px;
    }
    & > h1 {
      font-size: 20px;
    }
  }
`;
export const ThumbsUpIconStyle = {
  width: "150px",
  margin: "40px",
};
export const MailIconStyle = {
  width: "70px",
  margin: "5px",
};
