import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";
import {
  WrraperSection,
  MainWrapperLayer,
  MainContent,
  SectionHeader,
  More,
  styleForActionsSvg,
} from "./stylesForTopDeals";
import { SortStyle } from "./sortSection/SortStyle";
import { SectionSortTopDeals } from "./sortSection/SectionSortTopDeals";
import { TopDealsCarusel } from "./topDealsCarusel/TopDealsCarusel";
import { MainTopDealsContent } from "./mainTopDealsContent/MainTopDealsContent";
import { clearTopDeals, chose } from "../../store/select/duck/actions/actions";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { ErrorFromData } from "../error/ErrorFromData";
import { PageReloaded } from "../../utils/PageReloaded";

const trashPath =
  "M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z";
const _TopDeals = ({
  theme,
  options,
  select,
  clearTopDeals,
  brand,
  setTopDealsShoppageFromNewspaper,
  topDealsPageFromNewsPaper,
  fromBookTopDeals,
  brandDataFromUrl,
  brandItemProductsDataFromUrl,
  dataFromHomePage,
  dataFromTopDealsPage,
  bookData,
  dealsDataOptioned,
  dealsForTopDeals,
  dealsType,
  setNumberOfDeals,
  setDealsNumber,
}) => {
  const { name, section, alternativeFinalName } = useParams();
  const sectionRef = useRef();
  // console.log(brandDataFromUrl, "data");
  let brandExist;
  let brandName;

  const titleParams = name && name.split("-").join(" ");
  brandName = brandDataFromUrl?.data?.brandByName[0]?.name;
  brandExist = brandDataFromUrl?.data?.brandByName[0]?.name ? true : false;

  if (
    (brand && select.options[1].valueName && select.options[1].active) ||
    (brand && select.options[2].valueName && select.options[2].active)
  ) {
    brandExist = true;
  }
  useEffect(() => {
    if (dataFromHomePage?.client) {
      dataFromHomePage.client.resetStore();
    }
    if (dealsDataOptioned?.client) {
      dealsDataOptioned.client.resetStore();
    }
  }, []);
  useEffect(() => {
    if (section === "promocje") {
      if (sectionRef && sectionRef.current) {
        sectionRef.current.scrollIntoView();
      }
    }
  }, [brand, name]);
  useEffect(() => {
    if (name && !alternativeFinalName) {
      brandDataFromUrl.refetch();
    }
  }, [name]);
  if (
    name === "gazetki-markety" ||
    name === "gazetki-media-i-agd" ||
    name === "gazetki-dom-i-ogród" ||
    name === "gazetki-zdrowie-i-uroda"
  ) {
    return null;
  }
  if (brandDataFromUrl?.error) {
    return (
      <ErrorFromData
        errorInfo={brandDataFromUrl.error}
        queryName={"brandDataFromUrl.TopDeals"}
      />
    );
  }
  if (brandItemProductsDataFromUrl?.error) {
    return (
      <ErrorFromData
        errorInfo={brandItemProductsDataFromUrl.error}
        queryName={"brandItemProductsDataFromUrl.TopDeals"}
      />
    );
  }
  if (dataFromHomePage?.error) {
    return (
      <ErrorFromData
        errorInfo={dataFromHomePage.error}
        queryName={"dataFromHomePage.TopDeals"}
      />
    );
  }
  if (dataFromTopDealsPage?.error) {
    return (
      <ErrorFromData
        errorInfo={dataFromTopDealsPage.error}
        queryName={"dataFromTopDealsPage.TopDeals"}
      />
    );
  }
  if (dealsForTopDeals?.error) {
    return (
      <ErrorFromData
        errorInfo={dealsForTopDeals.error}
        queryName={"dealsForTopDeals.TopDeals"}
      />
    );
  }
  if (bookData?.error) {
    return (
      <ErrorFromData
        errorInfo={bookData.error}
        queryName={"bookData.TopDeals"}
      />
    );
  }

  return (
    <>
      <WrraperSection isDark={theme.isDark} ref={sectionRef}>
        <MainWrapperLayer isDark={theme.isDark}>
          <MainContent isDark={theme.isDark}>
            <SectionHeader isDark={theme.isDark}>
              <h2>
                <span>
                  promocje{" "}
                  {(!dataFromTopDealsPage?.data?.forTopDealsPage[0]
                    ?.selectedProductItems ||
                    dealsForTopDeals?.data?.dealsTopDealsPage) &&
                    (select?.options[1].active === true ||
                    select?.options[2].active === true
                      ? select?.options.filter(
                          (item) => item.active === true
                        )[0].valueName
                      : "")}
                </span>
              </h2>
            </SectionHeader>
            <ErrorBoundary>
              <TopDealsCarusel
                update={
                  select.options[1].valueName || select.options[2].valueName
                }
                topDealsSelectedName={select?.topDealsSelected?.name}
                brandDataFromUrl={brandDataFromUrl}
                brandItemProductsDataFromUrl={brandItemProductsDataFromUrl}
                dataFromHomePage={dataFromHomePage}
                dataFromTopDealsPage={dataFromTopDealsPage}
                bookData={bookData}
              />
              <SortStyle isDark={theme.isDark}>
                <h1>
                  {`${select.topDealsSelected.name}` || "wszystkie"}
                  {`${select.topDealsSelected.name}` ? (
                    <span
                      onClick={() => clearTopDeals()}
                      style={{
                        fontSize: "14px",
                        padding: "5px",
                        borderRadius: "5px",
                        border: `1px solid black`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "35px",
                        marginLeft: "15px",
                      }}
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="trash-alt"
                        className="svg-inline--fa fa-trash-alt fa-w-14"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        style={styleForActionsSvg}
                        height={22}
                      >
                        <path fill="currentColor" d={trashPath}></path>
                      </svg>
                    </span>
                  ) : (
                    ""
                  )}
                </h1>

                <SectionSortTopDeals isDark={theme.isDark} />
              </SortStyle>
              <MainTopDealsContent
                brandName={brandName}
                options={options}
                brandExist={brandExist}
                setTopDealsShoppageFromNewspaper={
                  setTopDealsShoppageFromNewspaper
                }
                topDealsPageFromNewsPaper={topDealsPageFromNewsPaper}
                fromBookTopDeals={fromBookTopDeals}
                brandDataFromUrl={brandDataFromUrl}
                brandItemProductsDataFromUrl={brandItemProductsDataFromUrl}
                dataFromHomePage={dataFromHomePage}
                dataFromTopDealsPage={dataFromTopDealsPage}
                bookData={bookData}
                dealsDataOptioned={dealsDataOptioned}
                dealsForTopDeals={dealsForTopDeals}
                dealsType={dealsType}
                setNumberOfDeals={setNumberOfDeals}
                setDealsNumber={setDealsNumber}
              />
              {options &&
                dataFromHomePage?.loading == false &&
                dealsType == "HOME_PAGE" && (
                  <More whileTap={{ scale: 0.95 }} isDark={theme.isDark}>
                    <Link to={"/promocje"}>
                      więcej promocji{" "}
                      {select.options[1].valueName ||
                        select.options[2].valueName}
                    </Link>
                  </More>
                )}
            </ErrorBoundary>
          </MainContent>
        </MainWrapperLayer>
      </WrraperSection>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  clearTopDeals: (item) => dispatch(clearTopDeals(item)),
  chose: (item) => dispatch(chose(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
});
export const TopDeals = connect(mapStateToProps, mapDispatchToProps)(_TopDeals);
