import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { MainBlog } from "../components/blogSection/MainBlog";
import { Footer } from "../components/footerSection/Footer";
import { Helmet } from "react-helmet";
import { firstLetterCapitalisation } from "../helpers/convert/firstLetter";
import { useQuery } from "@apollo/client";
import { getBlogQuery, getForBlogData } from "../queries/queries";

export const BlogPage = () => {
  const { title } = useParams();
  let blogData = useQuery(getBlogQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    ssr: true,
  });
  let forBlogData = useQuery(getForBlogData, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    ssr: true,
  });

  useEffect(() => {
    if (typeof window !== "undefined") document.documentElement.scrollTop = 0;
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={"https://cenniczek.com/blog"} />

        <meta
          property="og:description"
          content={`Cenniczek.com-blog.Szukasz informacji o obowiązujących trendach zakupowych &#127978;`}
        />

        <meta
          name="keywords"
          content="gazetki promocyjne,aktualne gazetki,sprytne zakupy,blog cenniczek"
        ></meta>
        <meta
          property="description"
          content={`Cenniczek.com-blog.Szukasz informacji o obowiązujących trendach zakupowych. Może interesującie cie programy partnerskie sieci handlowych - zapraszamy &#127978; `}
        />
        <meta
          property="og:title"
          content={"Blog-Cenniczek.com.Trendy zakupowe.Poradniki"}
        />
        <meta property="og:url" content={"https://cenniczek.com/blog"} />
        <title>{"Blog-Cenniczek.com.Trendy zakupowe.Poradniki"}</title>
      </Helmet>
      <MainBlog blogData={blogData} />
      <Footer blogData={forBlogData} />
    </>
  );
};
