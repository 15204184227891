import React from "react";
import { RegisterConfirmation } from "../components/confirmation/RegisterConfirmation";
import { Footer } from "../components/footerSection/Footer";
import { Helmet } from "react-helmet";
export const RegisterConfirmationPage = ({
  confirmed,
  timeExpired,
  alreadyConfirmed,
}) => {
  // if (typeof document === "undefined" || typeof window === "undefined") {
  //   return null;
  // }

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://cenniczek.com/rejestracja`} />
        <meta name="description" content="Rejestracja - Cenniczek.com" />
        <meta property="og:description" content="rejestracja - Cenniczek.com" />
        <meta name="keywords" content="rejestracja - Cenniczek.com"></meta>
        <meta property="og:title" content="Cenniczek.com-rejestracja" />
        <meta property="og:url" content={`https://cenniczek.com/rejestracja`} />
        <title>Cenniczek.com-rejestracja potwierdzona</title>
      </Helmet>
      {/* {confirmed && <p>rejestracja potwierdzona</p>}
      {timeExpired && <p>przekroczenie czasu</p>}
      {alreadyConfirmed && <p>potwierdzona wczesniej</p>} */}
      <RegisterConfirmation
        confirmed={confirmed}
        timeExpired={timeExpired}
        alreadyConfirmed={alreadyConfirmed}
      />
      <Footer />
    </>
  );
};
