import React, { useEffect, useState } from "react";
import { Spinner } from "../../tools/spiner/Spinner";
import { PaginationDeals } from "../paginationDeals/PaginationDeals";
import { SingleDeal } from "../singleDeal/SingleDeal";
import { sortBySelected } from "./loadDeals.helper";

export const LoadDealsBrandPage = ({
  brandDataFromUrl,
  brandItemProductsDataFromUrl,
  topDealsSelected,
  activeSortType,
  theme,
}) => {
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);
  if (
    !result &&
    brandDataFromUrl?.loading === false &&
    brandItemProductsDataFromUrl?.loading === false &&
    brandItemProductsDataFromUrl?.data?.brandItemProducts
  ) {
    setResult(brandItemProductsDataFromUrl?.data?.brandItemProducts);
  }
  useEffect(() => {
    const arr = [
      { name: "brandDataFromUrl", loading: brandDataFromUrl?.loading },
      {
        name: "brandItemProductsDataFromUrl",
        loading: brandItemProductsDataFromUrl?.loading,
      },
    ];
    setLoading(arr.findIndex((item) => item.loading === true) !== -1);
  }, [brandDataFromUrl?.loading, brandItemProductsDataFromUrl?.loading]);

  useEffect(() => {
    if (brandItemProductsDataFromUrl?.data) {
      topDealsSelected &&
        brandItemProductsDataFromUrl?.data &&
        setResult(
          brandItemProductsDataFromUrl?.data?.brandItemProducts.filter(
            (item) => item.categoryKeyProductName === topDealsSelected
          )
        );
      !topDealsSelected &&
        brandItemProductsDataFromUrl?.data &&
        setResult(brandItemProductsDataFromUrl?.data?.brandItemProducts);
    }
  }, [
    topDealsSelected,
    brandItemProductsDataFromUrl?.loading,
    brandItemProductsDataFromUrl?.data?.brandItemProducts?.length,
    activeSortType,
    loading,
  ]);

  useEffect(() => {
    return () => {
      setResult(null);
    };
  }, []);

  if (loading) {
    return <Spinner />;
  } else {
    if (result === false) {
      return null;
    } else if (result?.length === 0) {
      return <p>nie znaleźliśmy promocji</p>;
    } else {
      const filteredBySelected = sortBySelected(result, activeSortType);
      if (topDealsSelected) {
        if (filteredBySelected && filteredBySelected.length > 0) {
          return filteredBySelected.map((item, index) => (
            <SingleDeal
              key={item.is}
              item={item}
              id={item.id}
              brandDataFromUrl={brandDataFromUrl}
            />
          ));
        }
      } else {
        if (filteredBySelected && filteredBySelected.length > 0) {
          return (
            <PaginationDeals
              deals={filteredBySelected}
              brandDataFromUrl={brandDataFromUrl}
              brandItemProductsDataFromUrl={brandItemProductsDataFromUrl}
              howMany={theme?.isMobile ? 8 : 15}
              isMobile={theme.isMobile}
            />
          );
        }
      }
    }
  }
};
