import React from "react";
import {
  buttonBackStyle,
  buttonNextStyle,
  btnStyle,
  BtnHover,
} from "./BrandPostPosition.style";

import { Theme } from "../../store/theme/theme";
import { backArrow, nextArrow } from "../../media/svgPath/SvgPath";
import { BtnFabric } from "../common/Btn.Fabric";

export const BtnBack = ({ isDark }) => (
  <BtnFabric
    style={{
      buttonStyle: buttonBackStyle,
      isDark,
      scale: 0.85,
    }}
    svg={{
      viewBox: backArrow.viewBox,
      d: backArrow.d,
      style: {
        ...btnStyle,
        color: ({ isDark }) =>
          !isDark
            ? Theme.dark.primaryColor.dark
            : Theme.light.primaryColor.white,
      },
    }}
  />
);

export const BtnNext = ({ isDark }) => (
  <BtnFabric
    style={{
      buttonStyle: buttonNextStyle,
      isDark,
      scale: 0.85,
    }}
    svg={{
      viewBox: nextArrow?.viewBox,
      d: nextArrow?.d,
      style: {
        ...btnStyle,
        color: ({ isDark }) =>
          !isDark
            ? Theme.dark.primaryColor.dark
            : Theme.light.primaryColor.white,
      },
    }}
  />
);
