import React from "react";
import { useParams, Redirect } from "react-router-dom";
import { NewsPaper } from "../components/newspaper/NewsPaper";

export const MagazinePage = () => {
  // if (typeof document === "undefined" || typeof window === "undefined") {
  //   return null;
  // }
  const { name, alternativeFinalName, duringTime, pageNr } = useParams();
  const regNameMultiLng =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšśŚžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9\s %,.'-]+$/u;
  const regNum = /^[0-9\s ,.'-]+$/u;
  if (
    !regNameMultiLng.test(name) ||
    !regNameMultiLng.test(alternativeFinalName) ||
    !regNameMultiLng.test(duringTime) ||
    !regNum.test(pageNr)
  ) {
    console.log("test uncomplete");
    return (
      <>
        <Redirect to={{ pathname: "/" }} />
      </>
    );
  }
  return (
    <>
      <NewsPaper />
    </>
  );
};
