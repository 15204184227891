import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
// import { ReactComponent as Close } from "../../../media/svg/times-solid.svg";
import {
  SectionWrapper,
  CloseWraper,
} from "../shopsInNeibourhood/styleForShopsInNeibourhood";
import { TopDeals } from "../../topDealsSection/TopDeals";
const closePath =
  "M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z";
const _BookTopDeals = ({
  theme,
  select,
  setTopDealsShoppage,
  topDealsPage,
  overflowHidden,
  bookData,
}) => {
  const containerHeight = useRef(null);
  const [wrapperHeight, setWraperHeight] = useState(-1000);
  useEffect(() => {
    setWraperHeight(
      -(
        containerHeight?.current?.scrollHeight +
        containerHeight?.current?.offsetHeight
      )
    );
    return () => {
      setWraperHeight(-1000);
    };
  }, [containerHeight?.current?.scrollHeight, select.newspaperId]);
  return topDealsPage ? (
    <SectionWrapper
      topDealsPage={topDealsPage}
      isDark={theme.isDark}
      ref={containerHeight}
      bookTopDealsHeight={wrapperHeight}
    >
      {topDealsPage && (
        <CloseWraper
          isDark={theme.isDark}
          onClick={() => {
            setTopDealsShoppage(false);
            overflowHidden();
          }}
        >
          {/* <Close /> */}
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times"
            className="svg-inline--fa fa-times fa-w-11"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
            width={25}
            heigth={25}
          >
            <path fill="currentColor" d={closePath}></path>
          </svg>
        </CloseWraper>
      )}
      <TopDeals
        setTopDealsShoppageFromNewspaper={setTopDealsShoppage}
        topDealsPageFromNewsPaper={topDealsPage}
        fromBookTopDeals={true}
        bookData={bookData}
        dealsType="NEWSPAPER_PAGE"
      />
    </SectionWrapper>
  ) : (
    <SectionWrapper
      isDark={theme.isDark}
      topDealsPage={topDealsPage}
      bookShopsInNeibourhood={300}
    />
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
});

export const BookTopDeals = connect(mapStateToProps, {})(_BookTopDeals);
