import { Theme } from "../../store/theme/theme";
export const sliderStyle = {
  height: "50vh",
  width: "100%",
  marginTop: "25px",
  textAlign: "center",
  color: `${Theme.light.primaryColor.white}`,

  // backgroundColor: 'red',
};

export const sliderStyleHorizontal = {
  height: "260px",
  width: "100%",
  marginTop: "0",
  textAlign: "center",
  color: `${Theme.light.primaryColor.white}`,

  // backgroundColor: 'red',
};
export const providerStyle = {
  border: "none",
  userSelect: "none",
};
export const slideStyle = {
  height: "230px",
  width: "160px",
  paddingBottom: 0,
  margin: "10px",
  // border: "none",
  // borderRadius: "5px",
  // overflow: "hidden",
  // cursor: "pointer",
  // transition: ".3s",
  // position: "relative",
  // dislay: 'flex',
  // flexDirection: 'column',
  // alignItems: 'center',
  // justifyContent: 'flex-start',
};
export const buttonBackStyle = {
  position: "absolute",
  top: "0",
  left: "50%",
  transform: "translate(-50%,0)",
  width: "50px",
  height: "30px",
  backgroundColor: "transparent",
  border: "none",
  // color: `${Theme.light.primaryColor.white}`,
};
export const buttonBackStyleHorizontal = {
  position: "absolute",
  top: "50%",
  left: "-30px",
  transform: "translate(0,-50%) rotate(-90deg)",
  width: "50px",
  height: "30px",
  backgroundColor: "transparent",
  border: "none",
  // color: `${Theme.light.primaryColor.white}`,
};
export const buttonNextStyle = {
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translate(-50%,0)",
  width: "50px",
  height: "30px",
  backgroundColor: "transparent",
  border: "none",
  // color: `${Theme.light.primaryColor.white}`,
};
export const buttonNextStyleHorizontal = {
  position: "absolute",
  top: "50%",
  right: "-30px",
  transform: "translate(0,-50%) rotate(-90deg)",
  width: "50px",
  height: "30px",
  backgroundColor: "transparent",
  border: "none",
  // color: `${Theme.light.primaryColor.white}`,
};
export const btnStyle = {
  width: "25px",
};
export const imgSuspenseStyle = {
  width: "55%",
  color: `${Theme.dark.fiveColor.white}`,
};
