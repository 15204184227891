import React, { useEffect } from "react";
import { UserSection } from "../components/userSection/UserSection";
import { Footer } from "../components/footerSection/Footer";
import { Helmet } from "react-helmet";

export const UserPage = () => {
  // if (typeof document === "undefined" || typeof window === "undefined") {
  //   return null;
  // }
  //   document.documentElement.scrollTop = 0;
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://cenniczek.com/twoje-zakupy`} />
        <meta
          name="description"
          content="Zaplanuj swój domowy budżet,panuj nad wydatkami,świadomie wydawaj pieniądze,analizuj zakupy,skorzystaj kategoryzacji,wykresów &#8986; Cenniczek.com &#128640;"
        />
        <meta
          property="og:description"
          content="Zaplanuj swój domowy budżet,świadomie wydawaj pieniądze,analizuj zakupy &#8986; Cenniczek.com &#128640;"
        />
        <meta
          name="keywords"
          content="analiza zakupów,lista zakupowa,domowy budżet,wydatki zakupowe"
        ></meta>
        <meta
          property="og:title"
          content="Analiza zakuów,Użytkownik-Cenniczek.com"
        />
        <meta
          property="og:url"
          content={`https://cenniczek.com/twoje-zakupy`}
        />
        <title>Analiza zakupów,Użytkownik-Cenniczek.com</title>
      </Helmet>
      <UserSection />

      <Footer />
    </>
  );
};
