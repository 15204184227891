import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { Map, TileLayer, Marker } from "react-leaflet";
import styled from "styled-components";
import { GetShops } from "./GetShops";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { Theme } from "../../store/theme/theme";
const MapWrraper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media${Theme.device.mobileL} {
    z-index: 4;
  }
`;
const _MapContainer = ({ select, localization }) => {
  const [name, setName] = useState("");
  const [L, setL] = useState(null);
  const [reactLeaflet, setReactLeaflet] = useState(null);

  const arroundOption = localization.arround.filter(
    (option) => option.active === true
  );
  const options = select.options;
  const checkName = (options) => {
    const name = options.filter((item) => item.active === true);
    if (name) {
      return name[0].valueName;
    }
    return null;
  };

  const coordinates = [
    localization.coordinates.lat,
    localization.coordinates.lng,
  ];
  useEffect(() => {
    return () => {
      setL(null);
      setReactLeaflet(null);
    };
  }, []);
  useEffect(() => {
    const loadLeaflet = async () => {
      if (typeof window !== "undefined") {
        try {
          const LInstance = await import("leaflet");
          setL(LInstance);
          const reactLeafletInstance = await import("react-leaflet");
          setReactLeaflet(reactLeafletInstance);
        } catch (error) {
          console.error("Error loading Leaflet:", error);
        }
      }
    };

    loadLeaflet();
  }, []);
  useEffect(() => {
    setName(select.options.filter((item) => item.active)[0].valueName);
    return () => {
      setName("");
    };
  }, [select.options.filter((item) => item.active)[0].valueName]);
  if (reactLeaflet) {
    return (
      <>
        <ErrorBoundary>
          <MapWrraper>
            <reactLeaflet.Map
              center={coordinates}
              zoom={arroundOption[0].zoom}
              scrollWheelZoom={false}
              doubleClickZoom={false}
              style={{ height: "60vh" }}
            >
              <reactLeaflet.TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <reactLeaflet.Marker
                position={coordinates}
                zIndexOffset={998}
              ></reactLeaflet.Marker>
              <GetShops name={name} arroundOption={arroundOption[0].value} />
            </reactLeaflet.Map>
          </MapWrraper>
        </ErrorBoundary>
      </>
    );
  } else return null;
};
const mapStateToProps = (state) => ({
  select: state.select,
  localization: state.localization,
});
export const MapContainer = connect(mapStateToProps, {})(_MapContainer);
