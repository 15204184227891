import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Theme } from "../../../../store/theme/theme";
import Arrow from "../../../../media/svg/chevron-down-solid.svg";
import { arrowDown } from "../../../../media/svgPath/SvgPath";
import { SvgDisplay } from "../../../tools/spiner/svgDisplay/SvgDisplay";
import { useQuery } from "@apollo/client";
import { getBrandsQuery } from "../../../../queries/queries";

import { connect } from "react-redux";
import {
  selectId,
  chose,
  clearTopDeals,
} from "../../../../store/select/duck/actions/actions";
import { deleteShopsNearUser } from "../../../../store/localization/duck/actions/actions";
import { updateUserItemOnList } from "../../../../store/shopingList/duck/actions/actions";
import { motion } from "framer-motion";
import { ErrorFromData } from "../../../error/ErrorFromData";
import { SelectContentForBrand } from "./SelectContentForBrand";
import { Spinner } from "../../../tools/spiner/Spinner";
const loginOptionsVariants = {
  hidden: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
  visible: {
    opacity: 1,
    // scale:1,
    y: 0,
    // transformOrigin:"bottom",
    transition: { duration: 0.3 },
  },
  exit: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
};
const SortSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 30px;
  line-height: 30px;
  width: 400px;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  user-select: none;

  & > span {
    text-transform: uppercase;
    padding: 10px;
  }
  @media${Theme.device.tablet} {
    padding-right: 10px;
  }
  @media${Theme.device.mobileL} {
    // margin-bottom: 20px;
    // justify-content: flex-start;
    width: 260px;
  }
`;
const SortSelect = styled.div`
  height: 30px;
  width: 150px;
  line-height: 30px;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  border: 1px solid
    ${({ isDark }) => (isDark ? "black" : Theme.light.thirdColor.dark)};
  border-radius: 5px;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.fiveColor.white : Theme.dark.sevenColor.dark};
  transition: background 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: 2px 2px 7px black;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 91;
`;
const SelectContent = styled.div`
  text-transform: uppercase;
  padding-left: 10px;
  font-size: 0.9rem;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  line-height: 12px !important;
`;
const UlSelectOption = styled(motion.div)`
  position: absolute;
  top:150px;
  left: 0;
  width: 100%;
  min-height:300px;
  z-index:92;
  transition:.3s all;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  & > ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    z-index:93;
  }
  & > ul > li {
    width: 33%;
    box-sizing: border-box;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 0.9rem;
    z-index:94;
    cursor:pointer;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
    transition: 0.3s;
  }
  & > ul > li:hover {
    border: 1px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  }
  @media${Theme.device.tablet} {
    & > ul > li {
        width: 50%;
  }
  }
  @media${Theme.device.mobileL} {
    min-height:480px;
    & > ul > li {
        width: 50%;
  }
`;
const useBrandForTopDeals = () => {
  return useQuery(getBrandsQuery, { ssr: true });
};
const UlPortal = ({ userItems, addUserItemIndex }) => {
  const refToItme = useRef(null);
  let pureData = useBrandForTopDeals();
  useEffect(() => {
    return () => {
      pureData = "";
    };
  });
  if (pureData.error) {
    return (
      <ErrorFromData
        errorInfo={pureData.error}
        queryName={"useBrandForTopDeals.UlPortal"}
      />
    );
  }
  // if (pureData?.loading === true) {
  //   return <Spinner />;
  // }
  if (pureData.data && pureData?.loading === false) {
    const data = pureData.data;
    const sortedData = data.brands.sort((a, b) => {
      const nameA = a.name;
      const nameB = b.name;
      return nameA < nameB ? -1 : 1;
    });

    const createSelectOptions = sortedData.map((item) => (
      <SelectContentForBrand
        key={item.id}
        item={item}
        addUserItemIndex={addUserItemIndex}
        userItems={userItems}
      />
    ));
    return <ul>{createSelectOptions}</ul>;
  } else return <Spinner />;
};
const _SectionSelectBrandShopingList = ({
  isDark,
  select,
  selectId,
  deleteShopsNearUser,
  clearTopDeals,
  chose,
  brandFromCroped,
  targetRef,
  userItems,
  addUserItemIndex,
  updateUserItemOnList,
}) => {
  const [isActive, setIsActive] = useState(false);
  const refs = React.createRef();

  const arrowStyle = {
    width: "15px",
    color: ` ${
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
    paddingRight: "5px",
    transform: isActive ? `rotate(180deg)` : `rotate(0)`,
    transition: "transform .3s",
    transformOrigin: "25% 50%",
  };

  const handleClickOutside = (e) => {
    if (refs && refs?.current && refs?.current.contains(e.target)) {
      return document.removeEventListener("click", handleClickOutside);
    } else {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  });

  const TypeSortActive = ({ brand }) => {
    return brand || "marka";
  };

  return (
    <SortSectionWrapper isDark={isDark}>
      <SortSelect
        onClick={() => setIsActive(!isActive)}
        ref={refs}
        isDark={isDark}
      >
        <SelectContent isDark={isDark}>
          <TypeSortActive brand={userItems[addUserItemIndex]?.brand} />
        </SelectContent>
        {/* <img src={Arrow} style={arrowStyle} /> */}
        <SvgDisplay
          d={arrowDown.d}
          viewBox={arrowDown.viewBox}
          style={arrowStyle}
        />

        {isActive && targetRef
          ? ReactDOM.createPortal(
              <UlSelectOption
                variants={loginOptionsVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                isDark={isDark}
              >
                <UlPortal
                  userItems={userItems}
                  addUserItemIndex={addUserItemIndex}
                  updateUserItemOnList={updateUserItemOnList}
                  brandFromCroped={brandFromCroped}
                />
              </UlSelectOption>,
              targetRef
            )
          : null}
      </SortSelect>
    </SortSectionWrapper>
  );
};

const mapStateToProps = (state) => ({
  select: state.select,
});
const mapDispatchToProps = (dispatch) => ({
  selectId: (item) => dispatch(selectId(item)),
  chose: (item) => dispatch(chose(item)),
  deleteShopsNearUser: (item) => dispatch(deleteShopsNearUser(item)),
  clearTopDeals: (item) => dispatch(clearTopDeals(item)),
  updateUserItemOnList: (item) => dispatch(updateUserItemOnList(item)),
});

export const SectionSelectBrandShopingList = connect(
  mapStateToProps,
  mapDispatchToProps
)(_SectionSelectBrandShopingList);
