import React, { useState } from "react";
import { connect } from "react-redux";
import { MainWrapper, MainWrapperLayer } from "./styleConfirmation";
import { Confirmation } from "./Confirmation";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
const _RegisterConfirmation = ({
  theme,
  confirmed,
  timeExpired,
  alreadyConfirmed,
}) => {
  return (
    <>
      <MainWrapper>
        <MainWrapperLayer isDark={theme.isDark}>
          <ErrorBoundary>
            <Confirmation
              confirmed={confirmed}
              timeExpired={timeExpired}
              alreadyConfirmed={alreadyConfirmed}
              isDark={theme.isDark}
            />
          </ErrorBoundary>
        </MainWrapperLayer>
      </MainWrapper>
    </>
  );
};
const mapDispatchToProps = (state) => ({
  theme: state.theme,
});

export const RegisterConfirmation = connect(
  mapDispatchToProps,
  {}
)(_RegisterConfirmation);
