import React, { useEffect, useState } from "react";
import { Spinner } from "../../tools/spiner/Spinner";
import { SingleDeal } from "../singleDeal/SingleDeal";
import { sortBySelected } from "./loadDeals.helper";

export const LoadDealsNewsPaperPage = ({
  bookData,
  topDealsSelected,
  setTopDealsShoppageFromNewspaper,
  topDealsPageFromNewsPaper,
  activeSortType,
  theme,
}) => {
  const [result, setResult] = useState(false);
  const [loading, setLoading] = useState(false);
  if (
    !result &&
    bookData?.loading === false &&
    bookData?.data?.bookNotId[0]?.productsItems
  ) {
    setResult(bookData?.data?.bookNotId[0]?.productsItems);
  }
  useEffect(() => {
    const arr = [{ name: "bookData", loading: bookData?.loading }];
    setLoading(arr.findIndex((item) => item.loading === true) !== -1);
  }, [bookData?.loading]);

  useEffect(() => {
    if (bookData?.data?.bookNotId[0]?.productsItems) {
      topDealsSelected &&
        bookData?.data?.bookNotId[0]?.productsItems &&
        setResult(
          bookData?.data?.bookNotId[0]?.productsItems.filter(
            (item) => item.categoryKeyProductName === topDealsSelected
          )
        );
    }
    if (
      result.length !== bookData?.data?.bookNotId[0]?.productsItems?.length &&
      bookData?.data?.bookNotId[0]?.productsItems.length &&
      !topDealsSelected
    ) {
      setResult(bookData?.data?.bookNotId[0]?.productsItems);
    }
  }, [
    topDealsSelected,
    bookData?.data?.bookNotId[0]?.productsItems.length,
    activeSortType,
    loading,
  ]);

  useEffect(() => {
    return () => {
      setResult(null);
    };
  }, []);

  if (loading) {
    return <Spinner />;
  } else {
    if (result === false) {
      return null;
    } else if (result?.length === 0) {
      return <p>nie znaleźliśmy promocji</p>;
    } else {
      const filteredBySelected = sortBySelected(result, activeSortType);
      if (filteredBySelected && filteredBySelected.length > 0) {
        return filteredBySelected.map((item, index) => {
          return (
            <SingleDeal
              item={item}
              key={item.id}
              setTopDealsShoppageFromNewspaper={
                setTopDealsShoppageFromNewspaper
              }
              topDealsPageFromNewsPaper={topDealsPageFromNewsPaper}
            />
          );
        });
      }
    }
  }
};
