import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import { useParams, Link, Redirect, useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import {
  sliderStyle,
  slideStyle,
  buttonBackStyle,
  buttonNextStyle,
  btnStyle,
} from "./sliderStylePack";
import { connect } from "react-redux";
import { Theme } from "../../store/theme/theme";
import MainBackground from "../../media/img/main-cenniczek-background5.jpg";
import MainBackgroundMobile from "../../media/img/main-cenniczek-background5_mobile_midQ.jpg";
import { Spinner } from "../tools/spiner/Spinner";
import { SinglePageSwitcher } from "./SinglePageSwitcher";
import Cenniczek from "../../media/svg/cenniczek-icon-192.svg";
// import alertify from "alertifyjs";
// import "alertifyjs/build/css/alertify.css";
// import "alertifyjs/build/css/themes/default.css";
import { useQuery, useMutation } from "@apollo/client";
import {
  getBookQuery,
  getBookNotIdQuery,
  popularItemProductMutation,
} from "../../queries/queries";
import {
  clearSelectPageFromDeal,
  clearTargetCordinates,
  selectId,
  chose,
  clearTopDeals,
  clearSugestedNewspapers,
  removeOneFromSugestedNewspapers,
  newspaperId,
} from "../../store/select/duck/actions/actions";
import { deleteShopsNearUser } from "../../store/localization/duck/actions/actions";
import {
  pushItemToList,
  userCanCrop,
  userCanNotCrop,
  updateUserItemOnList,
} from "../../store/shopingList/duck/actions/actions";
import {
  overflowHidden,
  overflowAuto,
} from "../../store/theme/duck/actions/actions";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
  DotGroup,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Helmet } from "react-helmet";
import { NumberOfPagesContainer } from "./NumberOfPagesContainer";
import { LeftBar } from "../leftBar/LeftBar";
import { RightBar } from "../rightBar/RightBar";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { ErrorHandler } from "../error/ErrorHandler";
import { firstLetterCapitalisation } from "../../helpers/convert/firstLetter";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { ShopsInNeibourhoods } from "./shopsInNeibourhood/ShopsInNeibourhoods";
import { BookTopDeals } from "./bookTopDeals/BookTopDeals";
import { motion, AnimatePresence } from "framer-motion";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { cenniczekPath } from "./cenniczekPath";
import { ErrorFromData } from "../error/ErrorFromData";
import { API_URL, FRONT_URL } from "../../constant/constant";
// import {
//   encryptFrontData,
//   decryptFrontData,
// } from "../../utils/Crypto/cryptoFront.controller";
import { ImageWrap } from "./imageWrap/ImageWrap";
const DotGroupWrapper = styled.div`
  // width: ${({ isMobileL }) => (isMobileL ? "90%" : "50%")};
  width: 50% !important;
  height: 40px !important;
  overflow-y: auto !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
  transition: 0.3s all;
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 5px;
    background-color: transparent;
    transition: 0.3s all;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    background-color: transparent;
    transition: 0.3s all;
  }
  &:hover {
    &::-webkit-scrollbar {
      background-color: ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    }
    &::-webkit-scrollbar-thumb {
      border-left: 3px solid
        ${({ isDark }) =>
          !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
      border-right: 3px solid
        ${({ isDark }) =>
          !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
      background-color: ${({ isDark }) =>
        isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    }
  }
  @media${Theme.device.desktopM} {
    height: 40px !important;
    overflow-y: auto !important;
  }
  @media${Theme.device.tablet} {
    height: auto !important;
    overflow-y: auto !important;
    width: 90% !important;
  }
  // @media${Theme.device.mobileL} {
  // }
`;
const StopRedirect = styled.div`
  display: block;
  margin: 10px auto;
  width: 300px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid
    ${({ isDark }) => (isDark ? "black" : Theme.dark.thirdColor.white)};
  border-radius: 30px;
  transform: scale(1);
  transition: 0.1s;
  cursor: pointer;
  background: ${({ holdRedirect }) =>
    holdRedirect
      ? Theme.light.secondaryColor.green
      : Theme.light.fourthColor.red};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
  &:hover {
    background: none;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
`;

const SvgWraper = styled.div`
  width: 150px !important;
  height: 150px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  // bottom: 60px;
  // left: 50%;
  // transform: translateX(-50%);
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px !important;
    height: 150px !important;
  }
  & > div > p {
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%) !important;
    font-size: 22px;
  }
  @media${Theme.device.mobileL} {
    width: 100px !important;
    height: 100px !important;
    & > div {
      width: 100px !important;
      height: 100px !important;
    }
  }
`;
const divVariants = {
  hidden: {
    opacity: 0,
    scale: 1.2,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};
const imgSuspenseStyle = {
  width: "90%",
};
const imgSuspenseVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};
const pathDefinition = "M25,50a25,25 0 1,0 50,0a25,25 0 1,0 -50,0";
const calculateDimennsion = (imgDim, setPercentage, isMobileL, singlePage) => {
  if (imgDim?.width && imgDim?.height) {
    if (isMobileL || singlePage) return setPercentage("100%");
    const { innerHeight, innerWidth } = window;
    const { width, height } = imgDim;
    const objDim = {
      innerWidth: innerWidth - 0.3 * innerWidth,
      innerHeight: innerHeight - 86,
      imgWidth: width,
      imgHeight: height,
      widthRatio: (innerWidth - 0.3 * innerWidth) / (width * 2),
      heightRatio: (innerHeight - 86) / height,
      dim: (2 * (width / height) * (innerHeight - 86)).toFixed(2),
      boxWidth:
        ((2 * (width / height) * (innerHeight - 86)) /
          (innerWidth - 0.3 * innerWidth)) *
        100,
      boxWidthHorizontal:
        ((2 * (width / height) * (innerHeight - 86) -
          (2 * (width / height) * (innerHeight - 86) -
            (innerWidth - 0.3 * innerWidth))) /
          (innerWidth - 0.3 * innerWidth)) *
        100,
    };

    if (objDim.dim > objDim.innerWidth) {
      // console.log("first scenario");
      return setPercentage(`${objDim.boxWidthHorizontal}%`);
    } else {
      // console.log("second scenario");
      return setPercentage(`${objDim.boxWidth}%`);
    }
  }
};

const resize = (imgDim, setPercentage, isMobileL, singlePage) => {
  // console.log("resize");
  calculateDimennsion(imgDim, setPercentage, isMobileL, singlePage);
};
const load = (imgDim, setPercentage, isMobileL, singlePage) => {
  // console.log("load");
  calculateDimennsion(imgDim, setPercentage, isMobileL, singlePage);
};
const checkPercentage = (imgDim, setPercentage, isMobileL, singlePage) => {
  // console.log("load");
  calculateDimennsion(imgDim, setPercentage, isMobileL, singlePage);
};
const startPath =
  "M436 160H12c-6.627 0-12-5.373-12-12v-36c0-26.51 21.49-48 48-48h48V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h128V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h48c26.51 0 48 21.49 48 48v36c0 6.627-5.373 12-12 12zM12 192h424c6.627 0 12 5.373 12 12v260c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V204c0-6.627 5.373-12 12-12zm333.296 95.947l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L194.12 364.665l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068l-28.397 28.17c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z";
const endPath =
  "M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm257.3 160l48.1-48.1c4.7-4.7 4.7-12.3 0-17l-28.3-28.3c-4.7-4.7-12.3-4.7-17 0L224 306.7l-48.1-48.1c-4.7-4.7-12.3-4.7-17 0l-28.3 28.3c-4.7 4.7-4.7 12.3 0 17l48.1 48.1-48.1 48.1c-4.7 4.7-4.7 12.3 0 17l28.3 28.3c4.7 4.7 12.3 4.7 17 0l48.1-48.1 48.1 48.1c4.7 4.7 12.3 4.7 17 0l28.3-28.3c4.7-4.7 4.7-12.3 0-17L269.3 352z";
const backPath =
  "M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z";
const nextPath =
  "M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z";

const resetPath =
  "M212.333 224.333H12c-6.627 0-12-5.373-12-12V12C0 5.373 5.373 0 12 0h48c6.627 0 12 5.373 12 12v78.112C117.773 39.279 184.26 7.47 258.175 8.007c136.906.994 246.448 111.623 246.157 248.532C504.041 393.258 393.12 504 256.333 504c-64.089 0-122.496-24.313-166.51-64.215-5.099-4.622-5.334-12.554-.467-17.42l33.967-33.967c4.474-4.474 11.662-4.717 16.401-.525C170.76 415.336 211.58 432 256.333 432c97.268 0 176-78.716 176-176 0-97.267-78.716-176-176-176-58.496 0-110.28 28.476-142.274 72.333h98.274c6.627 0 12 5.373 12 12v48c0 6.627-5.373 12-12 12z";
const plusPath =
  "M304 192v32c0 6.6-5.4 12-12 12h-56v56c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-56h-56c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h56v-56c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v56h56c6.6 0 12 5.4 12 12zm201 284.7L476.7 505c-9.4 9.4-24.6 9.4-33.9 0L343 405.3c-4.5-4.5-7-10.6-7-17V372c-35.3 27.6-79.7 44-128 44C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 48.3-16.4 92.7-44 128h16.3c6.4 0 12.5 2.5 17 7l99.7 99.7c9.3 9.4 9.3 24.6 0 34zM344 208c0-75.2-60.8-136-136-136S72 132.8 72 208s60.8 136 136 136 136-60.8 136-136z";
const minusPath =
  "M304 192v32c0 6.6-5.4 12-12 12H124c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm201 284.7L476.7 505c-9.4 9.4-24.6 9.4-33.9 0L343 405.3c-4.5-4.5-7-10.6-7-17V372c-35.3 27.6-79.7 44-128 44C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 48.3-16.4 92.7-44 128h16.3c6.4 0 12.5 2.5 17 7l99.7 99.7c9.3 9.4 9.3 24.6 0 34zM344 208c0-75.2-60.8-136-136-136S72 132.8 72 208s60.8 136 136 136 136-60.8 136-136z";
const mapPath =
  "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z";

const LastPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 600px;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  text-transform: uppercase;
  & > p {
    width: 350px;
    margin: 20px;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > div {
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media${Theme.device.desktopM} {
    min-height: auto;
    & > p {
      width: 240px;
    }
    & > div > img {
      width: 100px;
    }
  }
`;
const BtnImageHandlers = styled(motion.button)`
  margin: 7px;
  padding: 3px;
  border: none;
  border-radius: 5px;
  transition: 0.2s ease-out;
  background: transparent;
  // background: ${({ isDark }) =>
    !isDark ? Theme.light.fiveColor.white : Theme.light.thirdColor.dark};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: scale(1);
  &:hover {
    box-shadow: 2px 2px 7px black;
    color: ${({ isDark }) => (!isDark ? "black" : "white")};
    transform: scale(1.1);
  }
  & > span {
    text-transform: uppercase;
  }
  @media${Theme.device.tablet} {
    background: transparent;
    border: none;
    & > span {
      display: none;
    }
  }
`;
const CarouselBox = styled.div`
  position: relative;
  // max-height: 80vh;
`;
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  // background: url(${MainBackground});
  background-image: url(${typeof window !== "undefined"
    ? MainBackground
    : `${FRONT_URL}server-media/img/main-cenniczek-background5.jpg`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow-x: hidden !important;
  overflow-y: ${({ shopsPage, topDealsPage }) =>
    shopsPage || topDealsPage ? "" : "hidden !important"};
  z-index: ${({ shopsPage, topDealsPage }) =>
    shopsPage || topDealsPage ? -1 : "auto"};
  @media${Theme.device.mobileL} {
    // background: url(${MainBackgroundMobile});
    background-image: url(${typeof window !== "undefined"
      ? MainBackgroundMobile
      : `${FRONT_URL}server-media/img/main-cenniczek-background5_mobile_midQ.jpg`});
  }
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 0 5px 5px 0;
    background-color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border-left: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border-right: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    background-color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  @media${Theme.device.mobileL} {
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 0 2px 2px 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      border-right: none;
      border-left: none;
    }
  }
`;
const MainWrapperLayer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  transition: background 0.3s;
  display: flex;
  justify-content: space-around;
  @media${Theme.device.mobileL} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  // align-items:flex-start;
`;
const MainContent = styled.div`
  position: relative;
  width: 70%;
  min-height: 70vh;
  height: 100%;
  margin-top: 2px;
  margin-bottom: 88px;
  max-width: ${Theme.breakPoint.desktop};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  font-family: montserrat;
  @media${Theme.device.mobileL} {
    width: 100%;
    min-height: 55vh;
    margin-bottom: 45px;
  }
`;
const HeaderMainContent = styled.div`
  position: fixed;
  height: 86px;
  z-index: 88;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  user-select: none;
  padding-top: 0px;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.primaryColor.white};
  background-image: linear-gradient(
    315deg,
    ${({ isDark }) => (isDark ? "#0a0a0a " : "#ccc3c3")} 0%,
    ${({ isDark }) =>
        isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white}
      74%
  );
  border-top: 1px solid ${({ isDark }) => (isDark ? "black" : "white")};
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  & > div {
    width: 90%;
    height: 40px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
  }
  & > div > h1 {
    font-size: 16px;
    text-transform: uppercase;
  }
  & > div > h3,
  & > div > h3 > span {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > div > h3 > span:nth-of-type(2) {
    margin: 5px;
  }
  @media${Theme.device.desktop} {
    padding-top: 0px;
  }
  @media${Theme.device.tablet} {
    height: 60%;
    padding-top: 70px;
    position: static;
    box-shadow: none;
    border-top: none;
    background: transparent;
    // padding-top: 45px;
    margin-top: 60px;
    & > div {
      height: auto;
    }
    & > div > h3 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & > div > h1 {
      width: 100%;
      text-align: center;
    }
  }
  @media${Theme.device.mobileL} {
    height: 60%;
    margin-top: 0;
    padding-top: 50px;
    & > div {
      height: 60%;
    }
  }
`;
// ---------------------
const CaruselWrapper = styled.div`
  width: ${({ percentage }) => percentage};
  // width: 100%;
  margin: 0 auto;
  height: 100%;
  position: relative;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
`;
const SlideHover = styled.div`
  position: relative;
  min-height: 80vh;
  // ?-----------------------------------------------
  // min-width: 25vw;
  display: flex;
  align-items: center;
  justify-content: center;
  // & > div:last-of-type {
  //   display: none;
  // }
  & > img {
    display: none;
    // tututututu
    // height: 90vh;

    // max-height: 100%;
    max-width: 100%;
    // margin:0 auto;
  }
  @media${Theme.device.tablet} {
    min-height: 30vh;
  }
`;

const BtnHover = styled.div`
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  border: 1px solid
    ${({ isDark }) => (isDark ? Theme.dark.primaryColor.dark : "white")};
  transform: scale(1);
  transition: 0.3s;
  &:hover {
    transform: scale(1.2);
  }
`;

const ShopLocal = styled(motion.div).attrs((props) => ({
  style: {
    background: `${
      props.isDark ? Theme.light.thirdColor.dark : Theme.light.fiveColor.white
    }`,
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  background: transparent !important;
  height: 70px;
  width: 70px;
  transform-origin: left;
  margin: 10px;
  // border: 1px solid
  //   ${({ isDark }) => (isDark ? "black" : Theme.light.thirdColor.dark)};
  // border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 10px;

  &:hover {
    // transform:scale(1.01);
    transition: 0.3s transform;
    box-shadow: 2px 2px 7px black;
  }
  & > a {
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > a > p {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 10px;
  }
  & > a > p > img {
    height: 70px;
  }
  @media${Theme.device.tablet} {
    background: transparent !important;
    min-width: 25px;
    margin: 0;
    border: none;
    & > a > p > img {
      height: 50px;
    }
  }
`;
const styleForSvg = {
  height: "18px",
  marginRight: "10px",
  textAlign: "right",
};

const LastPage = ({
  index,
  item,
  isDark,
  removeOneFromSugestedNewspapers,
  pageNrFromCarusel,
  singlePage,
  setPageNrFromCarusel,
  isMobile,
}) => {
  const [seconds, setSeconds] = useState(10);
  const [holdRedirect, setHoldRedirect] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let redirectCounter;
    if (singlePage === true && index - Number(pageNrFromCarusel) === -1) {
      if (!holdRedirect) {
        redirectCounter = setInterval(() => {
          setSeconds((state) => state - 1);
        }, 1000);
      }
      if (holdRedirect) {
        clearTimeout(redirectCounter);
      }
      return () => {
        clearTimeout(redirectCounter);
      };
    } else if (
      singlePage === false &&
      index - Number(pageNrFromCarusel) === 0
    ) {
      if (!holdRedirect) {
        redirectCounter = setInterval(() => {
          setSeconds((state) => state - 1);
        }, 1000);
      }
      if (holdRedirect) {
        clearTimeout(redirectCounter);
      }
      return () => {
        clearTimeout(redirectCounter);
      };
    }
  }, [pageNrFromCarusel, holdRedirect, singlePage]);
  useEffect(() => {
    if (seconds === -1) {
      removeOneFromSugestedNewspapers(item);
      history.push(
        `/gazetka-promocyjna/${item.name}-${item.alternativeFinalName}/${item.startDuringTime}-${item.endDuringTime}/1`
      );
      setPageNrFromCarusel(1);
    }
  }, [seconds]);
  return (
    <LastPageWrapper key={index} index={index + 1} isDark={isDark}>
      <p>
        {isMobile
          ? `sugerowana następna gazetka ${item.name},od ${item.startDuringTime} do ${item.endDuringTime}`
          : `za chwile zostaniesz przekierowany na sugerowaną gazetkę ${item.name},czas obowiązywania od ${item.startDuringTime} do ${item.endDuringTime} `}
      </p>
      <div>
        <img
          loading="lazy"
          src={
            (item.thumbnail && `${API_URL}${item.thumbnail}`) ||
            (item.imgUrl && `${API_URL}${item.imgUrl}`)
          }
          alt={`gazetka ${item.name} czas obowiązywania od ${item.startDuringTime} do ${item.endDuringTime}`}
        />
      </div>
      <SvgWraper>
        <motion.svg
          aria-hidden="true"
          className="svgItemForModal"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="19 19 62 62"
        >
          <motion.path
            // fill="none"
            fill={Theme.light.secondaryColor.green}
            d={pathDefinition}
            stroke={Theme.light.primaryColor.white}
            stroke-width="20"
            initial={{ pathLength: 1 }}
            animate={{ pathLength: seconds / 10 }}
            transition={{ duration: 1 }}
          ></motion.path>
        </motion.svg>

        <div>
          <AnimatePresence>
            <motion.p
              variants={divVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              {seconds < 0 ? 0 : seconds}
            </motion.p>
          </AnimatePresence>
        </div>
      </SvgWraper>
      <StopRedirect
        isDark={isDark}
        onClick={() => {
          setHoldRedirect(!holdRedirect);
        }}
        holdRedirect={holdRedirect}
      >
        {holdRedirect ? "Wznów" : "Zatrzymaj"}
      </StopRedirect>
    </LastPageWrapper>
  );
};

const ShowBook = (
  imgUrl,
  pages,
  name,
  duringTime,
  targetCord,
  targetPage,
  productsItems,
  pushItemToList,
  cropedActive,
  userCanCrop,
  userCanNotCrop,
  token,
  updateUserItemOnList,
  indexOfUserList,
  userItems,
  isMobileL,
  pageNrFromCarusel,
  setImgDim,
  sugestedNewspapers,
  removeOneFromSugestedNewspapers,
  isDark,
  singlePage,
  setPageNrFromCarusel,
  isMobile
) => {
  if (pages && imgUrl) {
    let array = [];
    for (let number = 1; number <= pages; number++) {
      let item = {
        index: number,
        imgUrl: `${imgUrl.slice(0, imgUrl.length - 5)}${number}.jpg`,
      };
      array = [...array, item];
    }
    sugestedNewspapers && array.push(sugestedNewspapers);

    return array.map((item, index) => {
      const filteredByPageProductsItems = productsItems.filter(
        (item) => item.page === index + 1
      );
      if (index < array.length - 1) {
        return (
          <Slide key={index} index={index} style={slideStyle}>
            <SlideHover>
              <ImageWrap
                itemUrl={item.imgUrl}
                name={name}
                duringTime={duringTime}
                targetCord={targetCord}
                targetPage={targetPage}
                index={index}
                productsItems={filteredByPageProductsItems}
                pushItemToList={pushItemToList}
                cropedActive={cropedActive}
                userCanCrop={userCanCrop}
                userCanNotCrop={userCanNotCrop}
                token={token}
                updateUserItemOnList={updateUserItemOnList}
                indexOfUserList={indexOfUserList}
                userItems={userItems}
                isMobileL={isMobileL}
                pageNrFromCarusel={pageNrFromCarusel}
                setImgDim={setImgDim}
              />
            </SlideHover>
          </Slide>
        );
      } else {
        return (
          <Slide key={index} index={index} style={slideStyle}>
            <LastPage
              index={index}
              key={index}
              item={item}
              isDark={isDark}
              removeOneFromSugestedNewspapers={removeOneFromSugestedNewspapers}
              pageNrFromCarusel={pageNrFromCarusel}
              singlePage={singlePage}
              setPageNrFromCarusel={setPageNrFromCarusel}
              isMobile={isMobile}
            />
          </Slide>
        );
      }
    });
  }
};

const checkOrientationeOnLoad = (singlePage, setSinglePage) => {
  if (window.innerWidth > Theme.breakPoint.tablet) {
    setSinglePage(false);
  } else {
    setSinglePage(true);
  }
};
const checkOrientationeOnResize = (singlePage, setSinglePage) => {
  window.addEventListener("resize", () => {
    if (window.innerWidth > Theme.breakPoint.tablet) {
      setSinglePage(false);
    } else {
      setSinglePage(true);
    }
  });
};

const resizeScreen = (isMobileL, setIsMobileL, breakPoint) => {
  window.addEventListener("resize", () => {
    const docWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    if (docWidth > breakPoint) {
      setIsMobileL(false);
    } else {
      setIsMobileL(true);
    }
  });
};

const onLoadScreen2 = (isMobileL, setIsMobileL, breakPoint) => {
  const docWidth =
    document.documentElement.clientWidth || document.body.clientWidth;

  if (docWidth > breakPoint) {
    setIsMobileL(false);
  } else {
    setIsMobileL(true);
  }
};

const _NewsPaper = ({
  theme,
  select,
  clearSelectPageFromDeal,
  clearTargetCordinates,
  selectId,
  chose,
  deleteShopsNearUser,
  pushItemToList,
  clearTopDeals,
  shopingList,
  userCanCrop,
  userCanNotCrop,
  updateUserItemOnList,
  overflowHidden,
  overflowAuto,
  clearSugestedNewspapers,
  removeOneFromSugestedNewspapers,
  newspaperId,
}) => {
  const [singlePage, setSinglePage] = useState(false);
  const [shopsPage, setShoppage] = useState(false);
  const [topDealsPage, setTopDealsShoppage] = useState(false);
  const [isMobileL, setIsMobileL] = useState(false);
  const [imgDim, setImgDim] = useState({});
  const [percentage, setPercentage] = useState("100%");

  let startTime,
    endTime = "";
  let pages = 0;
  let imgUrl = "";
  let flag1 = false;
  let productsItems;
  let brandName;
  let brandItem;
  const wraperRef = React.createRef();
  let { name, alternativeFinalName, duringTime, pageNr } = useParams();
  const indexof = duringTime.indexOf("-");
  const startDuringTime = duringTime.slice(0, indexof);
  const endDuringTime = duringTime.slice(indexof + 1, duringTime.length);
  const transformRef = useRef(null);
  const [pageNrFromCarusel, setPageNrFromCarusel] = useState(
    select?.selectedPage ? select.selectedPage : pageNr
  );
  const [canPaningDisabled, setCanPaningDisabled] = useState(true);
  let history = useHistory();
  // console.log(pageNrFromCarusel, "pageNrFromCarusel");
  let bookData = useQuery(getBookNotIdQuery, {
    fetchPolicy: "network-only",
    variables: {
      name: `${name}`,
      startDuringTime: `${startDuringTime}`,
      endDuringTime: `${endDuringTime}`,
      alternativeFinalName: `${alternativeFinalName}`,
    },
    ssr: true,
  });

  useEffect(() => {
    return () => {
      clearSugestedNewspapers();
    };
  }, []);
  useEffect(() => {
    onLoadScreen2(isMobileL, setIsMobileL, Theme.breakPoint.mobileM);
    resizeScreen(isMobileL, setIsMobileL, Theme.breakPoint.mobileM);
  }, []);

  useEffect(() => {
    if (!select?.selectedPage) {
      if (pageNr != pageNrFromCarusel) {
        setPageNrFromCarusel(pageNr);
      }
      // console.log({ pageNr, pageNrFromCarusel });
      // ----------------------------------------------####################################
      if (
        bookData &&
        bookData.data &&
        pageNr > bookData?.data?.bookNotId[0]?.numberOfPages + 1
      ) {
        // console.log({ pageNr, pageNrFromCarusel });
        history.push(
          `/gazetka-promocyjna/${name}-${alternativeFinalName}/${duringTime}/1`
        );
      }
    }
  }, [pageNr]);

  useEffect(() => {
    // ----------------------------------------------####################################
    setPageNrFromCarusel(select.selectedPage || pageNr);
  }, [select?.selectedPage]);

  useEffect(() => {
    if (pageNrFromCarusel != pageNr) {
      history.push(
        `/gazetka-promocyjna/${name}-${alternativeFinalName}/${duringTime}/${
          pageNrFromCarusel ? pageNrFromCarusel : pageNr
        }`
      );
    }
    return () => {
      bookData = "";
      setShoppage(false);
      setTopDealsShoppage(false);
    };
  }, [pageNrFromCarusel]);
  useEffect(() => {
    history.listen((location, action) => {
      if (action === "POP") {
        // console.log(location, "newspaper");
        history.replace(location?.pathname);
        if (location.pathname === "/") {
          console.log(
            "redirect in production from newspaper POP action detected"
          );
          chose("kategorie");
        }
        // history.push(`/${name}`);
      }
    });
  }, []);

  if (
    bookData?.data &&
    bookData?.loading === false &&
    !bookData?.data?.bookNotId[0]
  ) {
    // console.log("redirect");
    history.push(`/${name}`);
  }
  if (bookData.data && !bookData.loading && bookData.data.bookNotId[0]) {
    flag1 = true;
    startTime = bookData.data.bookNotId[0].startDuringTime;
    endTime = bookData.data.bookNotId[0].endDuringTime;
    pages = bookData.data.bookNotId[0].numberOfPages;
    imgUrl = bookData.data.bookNotId[0].imgUrl;
    startTime = startTime.slice(0, startTime.length - 5);
    endTime = endTime.slice(0, endTime.length - 5);
    productsItems = bookData.data.bookNotId[0].productsItems;
    brandName = bookData.data.bookNotId[0].brand[0].name;
    brandItem = bookData.data.bookNotId[0].brand[0];
  }
  useEffect(() => {
    if (bookData?.client) {
      bookData.client.resetStore();
    }
  }, []);
  useEffect(() => {
    bookData?.data?.bookNotId[0]?.id &&
      removeOneFromSugestedNewspapers(bookData.data.bookNotId[0]);
  }, [bookData?.loading, bookData?.data?.bookNotId[0]?.id]);

  useEffect(() => {
    checkOrientationeOnLoad(singlePage, setSinglePage);
    checkOrientationeOnResize(singlePage, setSinglePage);
  }, []);
  useEffect(() => {
    if (wraperRef && wraperRef.current) {
      wraperRef.current.scrollIntoView();
    }
    if (brandItem) {
      // console.log("redirect-2");
      chose("sklepy");
      selectId(brandItem);
    }
  }, [select?.newspaperId, shopsPage, topDealsPage]);
  useEffect(() => {
    if (brandItem) {
      // console.log("redirect-3");
      chose("sklepy");
      selectId(brandItem);
    }
  }, [brandItem]);
  useEffect(() => {
    bookData?.data?.bookNotId[0]?.id &&
      newspaperId(bookData.data.bookNotId[0].id);
    return () => {
      newspaperId("");
    };
  }, [bookData?.data?.bookNotId[0]?.id]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", () =>
        resize(imgDim, setPercentage, isMobileL, singlePage)
      );
      window.addEventListener(
        "load",
        load(imgDim, setPercentage, isMobileL, singlePage)
      );
      checkPercentage(imgDim, setPercentage, isMobileL, singlePage);
      return () => {
        window.removeEventListener("resize", resize, true);
        window.removeEventListener("load", load, true);
        // setPercentage("100%");
      };
    }
  });
  if (bookData?.error) {
    return (
      <ErrorFromData
        errorInfo={bookData.error}
        queryName={"bookData._NewsPaper"}
      />
    );
  }

  const portalContainer =
    typeof window !== "undefined" && document.getElementById("root");

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://cenniczek.com/gazetka-promocyjna/${name}-${alternativeFinalName}/${duringTime}/${
            pageNrFromCarusel ? pageNrFromCarusel : pageNr
          }`}
        />

        <meta
          property="og:description"
          content={`Aktualna gazetka promocyjna ${name} ${alternativeFinalName}-ważna w dniach ${duringTime}-sprawdź co warto kupić na czym zaoszczędzisz.Najnowsze gazetki online i najlepsze promocje z gazetek - Cenniczek.com`}
        />
        <meta
          name="keywords"
          content={`${name} gazetka,${name} gazetki promocyjne,${name} aktualna gazetka,cenniczek,${duringTime} ${name} gazetka,promocje,tanie zakupy,przeceny,oferty promocyjne,okazje,rabaty`}
        ></meta>
        <meta
          property="og:title"
          content={`${firstLetterCapitalisation(
            name
          )} gazetka promocyjna ${alternativeFinalName} aktualna w ${duringTime}-strona ${
            pageNrFromCarusel ? pageNrFromCarusel : pageNr
          }-cenniczek.com`}
        />
        <meta
          property="og:image"
          content={`https://cenniczek.com${imgUrl}`}
          data-react-helmet="true"
        />

        <meta
          property="og:url"
          content={`https://cenniczek.com/gazetka-promocyjna/${name}-${alternativeFinalName}/${duringTime}/${
            pageNrFromCarusel ? pageNrFromCarusel : pageNr
          }`}
        />
        <meta name="robots" content="index,follow " />
        <meta
          name="robots"
          content={`unavailable_after:${duringTime.slice(11)}`}
        ></meta>
        <title>{`${firstLetterCapitalisation(
          name
        )} gazetka promocyjna ${alternativeFinalName} aktualna w ${duringTime}-strona ${
          pageNrFromCarusel ? pageNrFromCarusel : pageNr
        }-cenniczek.com`}</title>
        <meta
          name="description"
          content={`Aktualna gazetka promocyjna ${name} ${alternativeFinalName}-ważna w dniach ${duringTime}-sprawdź co warto kupić na czym zaoszczędzisz.Najnowsze gazetki online i najlepsze promocje z gazetek - Cenniczek.com`}
        />
      </Helmet>
      <ErrorBoundary>
        <MainWrapper
          ref={wraperRef}
          isDark={theme.isDark}
          shopsPage={shopsPage}
          topDealsPage={topDealsPage}
        >
          {select?.options.filter((item) => item.active === true)[0]
            ?.valueName &&
            portalContainer?.children?.length > 0 &&
            ReactDOM.createPortal(
              <ShopsInNeibourhoods
                shopsPage={shopsPage}
                setShopsPage={setShoppage}
                overflowHidden={overflowHidden}
              />,
              portalContainer
            )}
          {select?.options.filter((item) => item.active === true)[0]
            ?.valueName &&
            portalContainer?.children?.length > 0 &&
            ReactDOM.createPortal(
              <BookTopDeals
                topDealsPage={topDealsPage}
                setTopDealsShoppage={setTopDealsShoppage}
                overflowHidden={overflowHidden}
                bookData={bookData}
              />,
              portalContainer
            )}
          <MainWrapperLayer isDark={theme.isDark}>
            <LeftBar />
            <MainContent isDark={theme.isDark}>
              {!bookData?.data?.bookNotId[0] && <Spinner />}

              <CaruselWrapper isDark={theme.isDark} percentage={percentage}>
                <CarouselProvider
                  totalSlides={pages + 1}
                  visibleSlides={singlePage ? 1 : 2}
                  orientation={"horizontal"}
                  isIntrinsicHeight={true}
                  currentSlide={
                    select.selectedPage
                      ? select.selectedPage - 1
                      : select.selectedPage
                  }
                  dragEnabled={
                    shopingList.cropedFromNewspaper.active ? false : true
                  }
                  touchEnabled={
                    shopingList.cropedFromNewspaper.active ? false : true
                  }
                >
                  <CarouselBox>
                    <TransformWrapper
                      initialScale={1}
                      initialPositionX={0}
                      initialPositionY={0}
                      options={{ minScale: 1, maxScale: 4 }}
                      zoomIn={{ step: 5 }}
                      zoomOut={{ step: 5 }}
                      panning={{
                        disabled: canPaningDisabled,
                      }}
                      // pinch={{ disabled: true }}
                      wheel={{
                        step: 0.2,
                        limitsOnWheel: true,
                        wheelDisabled: isMobileL ? true : false,
                      }}
                      doubleClick={{ disabled: true }}
                      ref={transformRef}
                    >
                      {({
                        zoomIn,
                        zoomOut,
                        resetTransform,
                        centerView,
                        positionX,
                        positionY,
                        ...rest
                      }) => (
                        <>
                          {transformRef?.current?.state?.scale > 1
                            ? canPaningDisabled === true
                              ? setCanPaningDisabled(false)
                              : null
                            : canPaningDisabled === false
                            ? setCanPaningDisabled(true)
                            : null}
                          <div
                            className={theme.isDark ? "tools dark" : "tools"}
                          >
                            <SinglePageSwitcher
                              toggleTheme={setSinglePage}
                              theme={theme.isDark}
                              singlePage={singlePage}
                            />

                            <ShopLocal
                              whileTap={{ scale: 0.95 }}
                              isDark={theme.isDark}
                              onClick={() => {
                                chose("sklepy");
                                selectId(brandItem);
                                deleteShopsNearUser();
                              }}
                            >
                              <Link
                                to={`/${
                                  brandName && brandName.split(" ").join("-")
                                }`}
                                onClick={() => clearTopDeals()}
                              >
                                {brandItem && (
                                  <p>
                                    <img
                                      loading="lazy"
                                      src={brandItem && `${brandItem.imgUrl}`}
                                      alt={`Informacje o ${brandName},lokalizacje sklepu,godziny otwarcia,nr telefonu`}
                                    />
                                  </p>
                                )}
                              </Link>
                            </ShopLocal>
                            <BtnImageHandlers
                              whileTap={{ scale: 0.95 }}
                              isDark={theme.isDark}
                              onClick={() => {
                                setTopDealsShoppage(true);
                                overflowAuto();
                              }}
                            >
                              <img
                                loading="lazy"
                                src={
                                  // typeof window !== "undefined"
                                  //   ? `${FRONT_URL}${Cenniczek}`
                                  //   :
                                  `${FRONT_URL}server-media/img/cenniczek-icon-192.svg`
                                }
                                style={(btnStyle, { marginRight: "5px" })}
                              />
                            </BtnImageHandlers>
                            <BtnImageHandlers
                              whileTap={{ scale: 0.95 }}
                              isDark={theme.isDark}
                              onClick={() => {
                                setShoppage(true);
                                overflowAuto();
                              }}
                            >
                              {/* <Map style={btnStyle} /> */}
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="map-marker-alt"
                                className="svg-inline--fa fa-map-marker-alt fa-w-16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                style={btnStyle}
                                width={25}
                              >
                                <path fill="currentColor" d={mapPath}></path>
                              </svg>
                              {/* <span>Sklepy</span> */}
                            </BtnImageHandlers>
                            <BtnImageHandlers
                              whileTap={{ scale: 0.95 }}
                              isDark={theme.isDark}
                              onClick={() => zoomIn()}
                            >
                              {/* <Plus style={btnStyle} /> */}
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="search-plus"
                                className="svg-inline--fa fa-search-plus fa-w-16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                style={btnStyle}
                                width={25}
                              >
                                <path fill="currentColor" d={plusPath}></path>
                              </svg>
                            </BtnImageHandlers>
                            <BtnImageHandlers
                              whileTap={{ scale: 0.95 }}
                              isDark={theme.isDark}
                              onClick={() => zoomOut()}
                            >
                              {/* <Minus style={btnStyle} /> */}
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="search-minus"
                                className="svg-inline--fa fa-search-minus fa-w-16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                style={btnStyle}
                                width={25}
                              >
                                <path fill="currentColor" d={minusPath}></path>
                              </svg>
                            </BtnImageHandlers>
                            <BtnImageHandlers
                              whileTap={{ scale: 0.95 }}
                              isDark={theme.isDark}
                              onClick={() => resetTransform()}
                            >
                              {/* <Reset style={btnStyle} /> */}
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="undo"
                                className="svg-inline--fa fa-undo fa-w-16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                style={btnStyle}
                                width={25}
                              >
                                <path fill="currentColor" d={resetPath}></path>
                              </svg>
                            </BtnImageHandlers>
                            {/* <BtnImageHandlers
                              whileTap={{ scale: 0.95 }}
                              isDark={theme.isDark}
                              onClick={() => centerView()}
                            >
                              <Center style={btnStyle} />
                            </BtnImageHandlers> */}
                          </div>
                          <TransformComponent>
                            <Slider
                              style={sliderStyle}
                              moveThreshold={isMobileL ? 0.1 : 0.8}
                            >
                              {ShowBook(
                                imgUrl,
                                pages,
                                name,
                                duringTime,
                                select.targetCordinates,
                                select.selectedPage,
                                productsItems,
                                pushItemToList,
                                shopingList.cropedFromNewspaper.active,
                                userCanCrop,
                                userCanNotCrop,
                                shopingList.userAcceptRodo.token,
                                updateUserItemOnList,
                                shopingList.cropedFromNewspaper.index,
                                shopingList.userItems,
                                isMobileL,
                                pageNrFromCarusel,
                                setImgDim,
                                select?.sugestedNewspapers[0],
                                removeOneFromSugestedNewspapers,
                                theme?.isDark,
                                singlePage,
                                setPageNrFromCarusel,
                                theme?.isMobile
                              )}
                            </Slider>
                          </TransformComponent>
                        </>
                      )}
                    </TransformWrapper>
                    <ButtonBack
                      style={buttonBackStyle}
                      onClick={() => {
                        clearSelectPageFromDeal();
                        clearTargetCordinates();
                      }}
                    >
                      <BtnHover isDark={theme.isDark}>
                        {/* <BackArrow style={btnStyle} /> */}
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="chevron-left"
                          className="svg-inline--fa fa-chevron-left fa-w-10"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          style={btnStyle}
                          width={25}
                        >
                          <path fill="currentColor" d={backPath}></path>
                        </svg>
                      </BtnHover>
                    </ButtonBack>
                    <ButtonNext
                      style={buttonNextStyle}
                      onClick={() => {
                        clearSelectPageFromDeal();
                        clearTargetCordinates();
                      }}
                    >
                      <BtnHover isDark={theme.isDark}>
                        {/* <NextArrow style={btnStyle} /> */}
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="chevron-right"
                          className="svg-inline--fa fa-chevron-right fa-w-10"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          style={btnStyle}
                          width={25}
                        >
                          <path fill="currentColor" d={nextPath}></path>
                        </svg>
                      </BtnHover>
                    </ButtonNext>
                  </CarouselBox>

                  <HeaderMainContent isDark={theme.isDark}>
                    <div>
                      <h1>Aktualna gazetka - {name}</h1>
                      <NumberOfPagesContainer
                        isDark={theme.isDark}
                        numberPages={pages}
                        pageFromDeals={select.selectedPage}
                        singlePage={singlePage}
                        setPageNrFromCarusel={setPageNrFromCarusel}
                        pageNr={pageNr}
                        newspaperId={select.newspaperId}
                      />
                      <h3>
                        <span>
                          {/* <StartTime style={styleForSvg} /> */}
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="calendar-check"
                            className="svg-inline--fa fa-calendar-check fa-w-14"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            style={styleForSvg}
                            height={18}
                          >
                            <path fill="currentColor" d={startPath}></path>
                          </svg>
                          {duringTime && startTime}
                        </span>
                        <span>{` - `}</span>
                        <span>
                          {/* <EndTime style={styleForSvg} /> */}
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="calendar-times"
                            className="svg-inline--fa fa-calendar-times fa-w-14"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            style={styleForSvg}
                            height={18}
                          >
                            <path fill="currentColor" d={endPath}></path>
                          </svg>
                          {duringTime && endTime}
                        </span>
                      </h3>
                    </div>
                    <DotGroupWrapper
                      isMobileL={isMobileL}
                      isDark={theme.isDark}
                    >
                      <DotGroup
                        dotNumbers
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                          padding: "0px 0 0px 0",
                          // width: `${isMobileL ? "90%" : "50%"}`,
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          clearSelectPageFromDeal();
                          clearTargetCordinates();
                        }}
                      />
                    </DotGroupWrapper>
                  </HeaderMainContent>
                </CarouselProvider>
              </CaruselWrapper>
            </MainContent>
            <RightBar bookData={bookData} isDark={theme.isDark} />
          </MainWrapperLayer>
        </MainWrapper>
      </ErrorBoundary>
      {/* {!select.newspaperId && (
        <Redirect
          to={{
            pathname: `/${name}`,
          }}
        />
      )} */}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  clearSelectPageFromDeal: (item) => dispatch(clearSelectPageFromDeal(item)),
  clearTargetCordinates: (item) => dispatch(clearTargetCordinates(item)),
  selectId: (item) => dispatch(selectId(item)),
  chose: (item) => dispatch(chose(item)),
  deleteShopsNearUser: (item) => dispatch(deleteShopsNearUser(item)),
  pushItemToList: (item) => dispatch(pushItemToList(item)),
  clearTopDeals: (item) => dispatch(clearTopDeals(item)),
  userCanCrop: (item) => dispatch(userCanCrop(item)),
  userCanNotCrop: (item) => dispatch(userCanNotCrop(item)),
  updateUserItemOnList: (item) => dispatch(updateUserItemOnList(item)),
  overflowHidden: () => dispatch(overflowHidden()),
  overflowAuto: () => dispatch(overflowAuto()),
  clearSugestedNewspapers: (item) => dispatch(clearSugestedNewspapers(item)),
  removeOneFromSugestedNewspapers: (item) =>
    dispatch(removeOneFromSugestedNewspapers(item)),
  newspaperId: (item) => dispatch(newspaperId(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
  shopingList: state.shopingList,
});

export const NewsPaper = connect(
  mapStateToProps,
  mapDispatchToProps
)(_NewsPaper);
