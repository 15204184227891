import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
import { motion } from "framer-motion";

export const WrraperSection = styled.div`
  width: 100%;
  position: relative;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.thirdColor.dark};
  background-image: linear-gradient(
    315deg,
    #e8dbfc 0%,
    ${({ isDark }) =>
        isDark ? Theme.dark.primaryColor.dark : Theme.light.thirdColor.dark}
      74%
  );
  user-select: none;
  @media${Theme.device.mobileL} {
    z-index: 5;
  }
`;
export const MainWrapperLayer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  transition: background 0.3s;
  display: flex;
  justify-content: space-around;
  // align-items:flex-start;
`;
export const MainContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 25vh;
  max-width: ${Theme.breakPoint.desktop}px;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  font-family: montserrat;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
export const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 100px;
  & > h4 {
    font-size: 30px;
    margin-top: 10px;
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
  }

  & > h4 > span {
    text-align: left;
    // width: 130%;
    padding-bottom: 20px;
    display: block;
    text-transform: uppercase;
    border-bottom: 1px solid
      ${({ isDark }) =>
        !isDark
          ? Theme.dark.primaryColor.dark
          : Theme.light.primaryColor.white};
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  }
  @media${Theme.device.mobileL} {
    padding-left: 10px;
    width: 95%;
    & > h4 > span {
      width: 100%;
      padding-bottom: 20px;
    }
    & > h4 {
      font-size: 20px;
    }
  }
`;
export const SingleItemInfo = styled(motion.div)`
  width: 70%;
  height: auto;
  max-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 5px;
  overflow-y: hidden;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  border: 1px solid
    ${({ isDark }) =>
      isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  border-radius: 5px;
  position: relative;
  transition: max-height 1s;
  & > div > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 20px;
    margin-right: 40px;
  }
  & > div > div > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-right: 10px;
  }
  & > div > div > div > p {
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-right: 10px;
  }
  & > div > div > div > span {
    font-weight: bold;
    padding-left: 5px;
    text-transform: uppercase;
  }
  & > div > div > div > span > a {
    display: block;
    font-weight: bold;
    text-decoration: none;
    transform: scale(1);
    transition: 0.3s transform;
    color: ${Theme.light.fourthColor.red};
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    transform-origin: left;
  }
  & > div > div > div > span > a:hover {
    transform: scale(1.1);
  }
  & > div > div > div > span > a > span {
    padding-right: 10px;
  }
  &.extended {
    max-height: 800px;
  }
  @media${Theme.device.mobileL} {
    width: 95%;
  }
  @media${Theme.device.mobileM} {
    & > div > div {
      font-size: ${Theme.size.fontSizeMobileM};
    }
  }
`;
export const BtnHover = styled(motion.div)`
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  border: 1px solid
    ${({ isDark }) => (isDark ? Theme.dark.primaryColor.dark : "white")};
  // transition: 0.3s;
  // &:hover {
  //   box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  // }
`;
export const DotWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const buttonBackStyle = {
  width: "30px",
  height: "50px",
  backgroundColor: "transparent",
  border: "none",
  margin: "5px",
};
export const buttonNextStyle = {
  width: "30px",
  height: "50px",
  backgroundColor: "transparent",
  border: "none",
  margin: "5px",
};
export const btnStyle = {
  width: "25px",
};
