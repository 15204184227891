import React, { useEffect } from "react";
import styled from "styled-components";

import { connect } from "react-redux";
import { toggleTheme } from "../../../store/theme/duck/actions/actions";
import { Theme } from "../../../store/theme/theme";
import { PageReloaded } from "../../../utils/PageReloaded";

const pathSun =
  "M256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm246.4 80.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.4-94.8c-6.4-12.8-24.6-12.8-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.4-33.5 47.3 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3c13-6.5 13-24.7.2-31.1zm-155.9 106c-49.9 49.9-131.1 49.9-181 0-49.9-49.9-49.9-131.1 0-181 49.9-49.9 131.1-49.9 181 0 49.9 49.9 49.9 131.1 0 181z";
const pathMoon =
  "M283.211 512c78.962 0 151.079-35.925 198.857-94.792 7.068-8.708-.639-21.43-11.562-19.35-124.203 23.654-238.262-71.576-238.262-196.954 0-72.222 38.662-138.635 101.498-174.394 9.686-5.512 7.25-20.197-3.756-22.23A258.156 258.156 0 0 0 283.211 0c-141.309 0-256 114.511-256 256 0 141.309 114.511 256 256 256z";

const SwitchWrapper = styled.div`
  width: 70px !important;
  height: 30px;
  border: 1px solid black;
  border-radius: 12.5px;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease-out, background 1s;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.fiveColor.white : Theme.light.thirdColor.dark};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.fiveColor.white : Theme.light.thirdColor.dark};
  &:hover {
    box-shadow: 2px 2px 7px black;
  }
  @media${Theme.device.mobileM} {
    width: 30px !important;
    height: 15px;
    & > svg {
      display: none;
    }
  }
`;
const ActiveThemePointer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform 0.3s ease-out;
  transform: ${({ isDark }) =>
    isDark
      ? "translate(0, -50%) scale(1.05)"
      : " translate(-100%, -50%) scale(1.05)"};
  width: 35px !important;
  height: 30px !important;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  border: 1px solid black;
  border-radius: 12.5px;
  transition: transform 0.2s ease-out;
  @media${Theme.device.mobileM} {
    width: 17px !important;
    height: 17px !important;
  }
`;

const _ThemeSwitcher = ({ theme: { isDark }, toggleTheme }) => {
  const sunSvgForLight = {
    position: "absolute",
    top: "50%",
    left: "0",
    transform: "translate(25%, -50%)",
    width: "20px",
    color: Theme.light.thirdColor.dark,
    transition: "color .2s",
  };
  const sunSvgForDark = {
    position: "absolute",
    top: "50%",
    left: "0",
    transform: " translate(25%, -50%)",
    width: "20px",
    color: Theme.dark.thirdColor.white,
    transition: "color .2s",
  };
  const moonSvgForLight = {
    position: "absolute",
    top: "50%",
    right: "0",
    transform: "translate(-25%, -50%)",
    width: "20px",
    color: Theme.light.primaryColor.white,
    transition: "color .2s",
  };
  const moonSvgForDark = {
    position: "absolute",
    top: "50%",
    right: "0",
    transform: " translate(-25%, -50%)",
    width: "20px",
    color: Theme.light.primaryColor.white,
    transition: "color .2s",
  };

  const reload = PageReloaded();
  useEffect(() => {
    if (localStorage && localStorage.getItem("isDark")) {
      toggleTheme(JSON.parse(localStorage.getItem("isDark")));
    }
  }, [reload]);

  // console.log(isDark, "isdark");
  return (
    <>
      <SwitchWrapper onClick={() => toggleTheme(!isDark)} isDark={isDark}>
        <ActiveThemePointer isDark={isDark} />
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="sun"
          className="svg-inline--fa fa-sun fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          // isDark={theme.isDark}
          style={isDark ? sunSvgForDark : sunSvgForLight}
        >
          <path
            fill={
              isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
            }
            d={pathSun}
          ></path>
        </svg>

        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="moon"
          className="svg-inline--fa fa-moon fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          // isDark={theme.isDark}
          style={isDark ? moonSvgForDark : moonSvgForLight}
        >
          <path
            fill={
              isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white
            }
            d={pathMoon}
          ></path>
        </svg>
      </SwitchWrapper>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  toggleTheme: (item) => dispatch(toggleTheme(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
});
export const ThemeSwitcher = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ThemeSwitcher);
