import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
// import AdSense from "react-adsense";

const imagePath =
  "M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z";
const AdWrapper = styled.div`
  width: 100%;
  height: 35%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 20px;
  & > p {
    text-transform: uppercase;
    font-family: montserrat;
    font-size: 0.7rem;
    margin: 5px 0 0 5px;
  }
  & > div {
    width: 100%;
    height: 300px;
    border: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.fiveColor.white : Theme.light.fiveColor.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.fiveColor.white : Theme.light.fiveColor.white};
  }
  @media${Theme.device.mobileL} {
    & > div {
      height: 250px;
      // width:250px;
    }
  }
`;
export const AdvForInfo = ({ isDark }) => {
  const [AdSense, setAdSense] = useState(null);
  const [adv, setAdv] = useState(null);
  useEffect(() => {
    const loadAdsense = async () => {
      if (typeof window !== "undefined") {
        try {
          const adsenseInstance = (await import("react-adsense")).default;

          setAdSense(adsenseInstance);
        } catch (error) {
          console.error("Error loading AdSense:", error);
        }
      }
    };

    loadAdsense();
  }, []);
  useEffect(() => {
    return () => {
      setAdSense(null);
    };
  }, []);
  useEffect(() => {
    if (process?.env?.NODE_ENV === "production") {
      setAdv(true);
    }
  }, []);
  return (
    <AdWrapper isDark={isDark}>
      <p>reklama</p>
      {!adv && (
        <div>
          {/* <ImgSuspense style={{ width: '25%' }}> */}
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="image"
            className="svg-inline--fa fa-image fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{ width: "25%" }}
          >
            <path fill="currentColor" d={imagePath}></path>
          </svg>
        </div>
      )}
      {adv && AdSense && (
        <div>
          <AdSense.Google
            client="ca-pub-9031422111285471"
            slot="6417759377"
            // style={{ display: 'block' }}
            format="auto"
            style={{ display: "block", width: "100%", height: "100%" }}
            responsive="true"
          />
        </div>
      )}
    </AdWrapper>
  );
};
