import React, { Component } from "react";
import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
import { API_URL } from "../../constant/constant";
const ErrorWraper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media${Theme.device.mobileL} and (orientation:landscape) {
    min-height: 140vh;
  }
`;
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      errorInfo: "",
      hasError: false,
    };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  logErrorToServer({ error, errorInfo }) {
    fetch(`${API_URL}/api/error`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        error: error.stack,
        errorInfo: errorInfo,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.logged) {
          console.log(data.logged);
          return;
        }
      });
  }
  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
    this.setState({ errorInfo });
    this.logErrorToServer({ error, errorInfo });
  }
  render() {
    if (this.state.hasError) {
      return (
        <ErrorWraper>
          <section className="error-wrapper">
            <h3>
              Upps. Nasze devsy już tupią nóżkami bo coś poszło nie po naszej
              myśli - Przepraszamy.
            </h3>
            <h4>Spróbuj przeładować stronę.</h4>
            <section className="walking"></section>
          </section>
        </ErrorWraper>
      );
    }
    return this.props.children;
  }
}
