import styled, { keyframes } from "styled-components";
import { Theme } from "../../store/theme/theme";
import { motion } from "framer-motion";

const rotate = keyframes`
  0%{
    transform: scale(1) rotate(0deg);
  }
  25%{
    transform: scale(1.35) rotate(10deg);
  }
  50%{
    transform: scale(1) rotate(0deg);
  }
  75%{
    transform: scale(1.35) rotate(-10deg);
  }
  100%{
    transform: scale(1) rotate(0deg);
  }
`;
export const ConentListWraper = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  // padding-top: 55px;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 0 5px 5px 0;
    background-color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border-left: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border-right: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    background-color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }

  & > h2 {
    padding: 20px;
    font-weight: 400;
    font-family: Montserrat;
  }
  & > p {
    font-family: Montserrat;
    padding-bottom: 10px;
  }
  @media${Theme.device.mobileL} {
    height: calc(93vh - 70px);
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 0 2px 2px 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      box-shadow: inset 0 0 2px
        ${({ isDark }) =>
          !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
      border-right: none;
      border-left: none;
    }
  }
`;
export const MainList = styled.section.attrs((props) => ({
  style: {
    background: `${
      props.isDark
        ? Theme.dark.primaryColor.dark
        : Theme.light.primaryColor.white
    }`,
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
    border: `1px solid ${props.isDark ? "black" : Theme.light.thirdColor.dark}`,
    left: `${props.isVisible ? 0 : props.isMobileL ? "-100%" : "-400px"}`,
    width: `${props.isMobileL ? "100%" : "400px"}`,
  },
}))`
  position: fixed;
  // top:70px;
  // height:90vh;
  top: 0;
  height: 100vh;
  transition: 0.3s;
  z-index: 101;
  border-radius: 0 5px 5px 0;
  // margin-top:70px;
  // @media${Theme.device.tablet}{
  //     // margin-top:0;
  //     height:80vh;
  // }
  // @media${Theme.device.mobileL}{
  //     // top:0;
  //     height:80vh;
  // }
  // @media${Theme.device.mobileS}{
  //     // top:0;
  //     height:85vh;
  // }
`;
export const ShopingListIco = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 0 5px 5px 0;
  // line-height:40px;
  // font-size:1em;
  background: ${Theme.light.secondaryColor.green};
  color: ${({ isDark }) =>
    isDark ? Theme.light.thirdColor.dark : Theme.dark.thirdColor.white};
  border-top: ${({ isDark }) =>
    isDark ? "1px solid black" : `1px solid ${Theme.light.thirdColor.dark}`};
  border-right: ${({ isDark }) =>
    isDark ? "1px solid black" : `1px solid ${Theme.light.thirdColor.dark}`};
  border-bottom: ${({ isDark }) =>
    isDark ? "1px solid black" : `1px solid ${Theme.light.thirdColor.dark}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 100px;
  right: -50px;
  transition: 0.3s;
  z-index: 90;
  animation: ${rotate} 1s linear 4s 5;
  & > * {
    transform: scale(1);
    transition: 0.3s transform;
    user-select: none;
  }
  & > svg:hover {
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
    transform: scale(1.1);
  }
  @media${Theme.device.mobileL} {
    bottom: 70px;
  }
`;
export const styleForIcoSvg = {
  height: "90%",
  width: "70%",
};
export const ItemWraper = styled(motion.div).attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
    borderBottom: `1px solid ${
      props.isDark ? "black" : Theme.light.thirdColor.dark
    }`,
  },
}))`
  height: ${({ moreOptionsVisible, isItem, sendList }) =>
    moreOptionsVisible && isItem && !sendList
      ? "275px"
      : !moreOptionsVisible && isItem && !sendList
      ? "200px"
      : !moreOptionsVisible && !isItem && sendList
      ? "100px"
      : "65px"};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  position: relative;
  transition: 0.3s;
  & > p {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    height: 60px;
  }
  &:last-of-type {
    border-bottom: none !important;
  }
`;
export const SendList = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
    borderBottom: `1px solid ${
      props.isDark ? "black" : Theme.light.thirdColor.dark
    }`,
  },
}))`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 40px;
  & > svg {
    width: 40px;
  }
  & > * {
    transform: scale(1);
    transition: 0.3s;
  }
  &:hover > p {
    color: ${Theme.light.secondaryColor.green};
  }
  &:hover > svg {
    transform: scale(1.1);
    color: ${Theme.light.secondaryColor.green};
  }
  @media${Theme.device.mobileL} {
    margin-bottom: 60px;
  }
`;
export const LoginItemWraper = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  height: 60px;
  cursor: pointer;
  position: relative;
  overflow: visible;
  & > svg {
    width: 40px;
  }
  & > * {
    transform: scale(1);
    transition: 0.3s;
  }
  &:hover > p {
    color: ${Theme.light.secondaryColor.green};
  }
  &:hover > svg {
    transform: scale(1.1);
    color: ${Theme.light.secondaryColor.green};
  }
`;
export const TotalPrice = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  height: 60px;
  cursor: pointer;
  & > svg {
    width: 40px;
  }
  & > p > span {
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 3px;
  }
  & > * {
    transform: scale(1);
    transition: 0.3s;
  }
  &:hover > p {
    color: ${Theme.light.secondaryColor.green};
  }
  &:hover > svg {
    transform: scale(1.1);
    color: ${Theme.light.secondaryColor.green};
  }
`;
export const ItemContent = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  height: 145px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 5px;
  padding-top: 5px;
`;
export const InputItems = styled(motion.div).attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  width: 100%;
  // height:20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  & > input,
  & > select {
    display: block;
    width: 30%;
    font-size: 1em;
    padding: 5px;
    margin: 2px;
    border: 1px solid black;
    border-radius: 12.5px;
    transition: background 1s;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    background: ${({ isDark }) =>
      isDark ? Theme.dark.fiveColor.white : Theme.dark.sevenColor.dark};
  }
  & > input:last-child {
    width: 95%;
  }
  & > input::placeholder,
  & > select::placeholder {
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > input:focus {
    outline: none;
  }
  & > select:focus {
    outline: none;
    // color:${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    color: black;
  }
  & > input:hover,
  & > select:hover {
    box-shadow: 2px 2px 7px black;
  }
  & > p {
    color: ${Theme.light.fourthColor.red};
    padding-top: 5px;
    font-size: 14px;
  }
`;
export const ImgWraper = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
    border: `${
      props.isDark
        ? `1px solid black`
        : `1px solid ${Theme.light.thirdColor.dark}`
    }`,
  },
}))`
  width: 30%;
  height: 130px;
  border-radius: 5px;
  // display:flex;
  // justify-content:center;
  // align-items:center;
  padding: 5px;
  position: relative;
  & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  }
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    transition: 0.3s;
  }
  & > div:hover {
    transform: scale(1.1);
  }
`;
export const InfoWraper = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  max-width: 50%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  & > p {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    padding-left: 3px;
    padding-right: 3px;
  }
  & > p > span {
    font-weight: 700;
    padding-left: 5px;
  }
`;
export const BrandWraper = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  height: 135px;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  & > img,
  & > p {
    width: 100%;
  }
  & > p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
  }
  & > p > svg {
    vertical-align: -2px;
  }
`;
export const ActionsItem = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 10px;
  & > a {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transform: scale(1);
    transition: 0.3s transform;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > div {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    // transform: scale(1);
    transition: 0.3s transform;
    color: ${Theme.light.fourthColor.red};
    cursor: pointer;
    z-index: 91;
  }
  // & > div > div > div > ul {
  //   z-index: 95;
  // }
  & > div:last-child {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > div:last-child > svg {
    display: block;
    width: 30px;
    padding: 5px;
    transition: 0.3s transform;
    transform-origin: center;
  }
  & > a:hover,
  & > :first-child:hover {
    transform: scale(1);
  }
  & > div:hover {
    transform: scale(1.1);
  }
  @media${Theme.device.mobileL} {
    font-size: ${Theme.size.fontSizeMobileM};
    & > a {
      width: 45%;
    }
  }
`;
export const UserItemContainer = styled.div.attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  height: 60px;
  cursor: pointer;
  & > svg {
    width: 40px;
  }
  & > p > span {
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 3px;
  }
  & > * {
    transform: scale(1);
    transition: 0.3s;
  }
  &:hover > p {
    color: ${Theme.light.secondaryColor.green};
  }
  &:hover > svg {
    transform: scale(1.1);
    color: ${Theme.light.secondaryColor.green};
  }
`;
export const DescribeFutureContainer = styled(motion.div)`
  position: absolute;
  bottom: 100px;
  right: -220px;
  width: 130px;
  border-radius: 5px;
  background: ${Theme.light.secondaryColor.green};
  color: ${Theme.dark.thirdColor.white};
  // border: 1px solid black;
  z-index: 99;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  line-height: 16px;
  & > h3 {
    text-transform: uppercase;
    margin: 10px;
    font-size: 16px;
    text-align: center;
  }
  & > p {
    text-align: center;
  }
  &:after {
    display: block;
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 20px;
    left: -15px;
    background: ${Theme.light.secondaryColor.green};
    transform: rotate(45deg);
  }
`;
export const AcceptButton = styled(motion.div)`
  width: 80px;
  height: 40px;
  border-radius: 5px;
  line-height: 40px;
  font-size: 1em;
  background: ${Theme.light.fourthColor.red};
  color: ${Theme.light.primaryColor.white};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  margin: 10px;
  &:hover {
    box-shadow: 2px 2px 7px black;
  }
  & > p {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  & > svg {
    padding-right: 16px;
  }
  @media${Theme.device.mobileL} {
    margin-bottom: 30px;
    & > svg {
      padding: 0;
    }
  }
`;
export const styleForSvg = {
  height: "16px",
  paddingRight: "2px",
};
export const styleForActionsSvg = {
  height: "20px",
  paddingRight: "10px",
};
export const styleForMoreActionsSvg = {
  height: "30px",
};
export const styleForActiveLigth = {
  color: `${Theme.dark.sixColor.dark}`,
  transform: "rotate(180deg)",
};
export const styleForActiveDark = {
  color: `${Theme.light.fiveColor.white}`,
  transform: "rotate(180deg)",
};
export const styleForDisactiveLight = {
  color: `${Theme.dark.sixColor.dark}`,
  transform: "rotate(0)",
};
export const styleForDisactiveDark = {
  color: `${Theme.light.fiveColor.white}`,
  transform: "rotate(0)",
};
// export const styleForActionsSvg = {
//   height: "20px",
//   paddingRight: "10px",
// };
export const animationInputVariants = {
  hidden: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
  visible: {
    opacity: 1,
    // scale:1,
    y: 0,
    // transformOrigin:"bottom",
    transition: { duration: 0.5 },
  },
  exit: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
};
export const modalWraperVariants = {
  hidden: {
    opacity: 0,
  },
  visible: (index) => ({
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
    },
  }),
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      delay: 0.3,
    },
  },
};
