import {
  GET_USER_LOCALIZATION,
  ARROUND_OPTION,
  PARTNERS_ARROUND_USER_FOUNDED,
  SORT_SHOPS_BY_CORDS,
  CLEAR_SHOPS,
  ADD_PARTNERS_NEAR_USER,
  ERROR_FROM_PARTNERS_NEAR_USER,
  DISALOW_TO_USER_GEOLOCATION,
} from "../types/types";

let myStorage;
if (process?.env?.NODE_ENV === "development" || "production") {
  if (typeof window === "undefined") {
    myStorage = null;
  } else {
    myStorage = localStorage;
  }
}
const INITIAL_LOCALIZATION_STATE = {
  coordinates: {
    lat: 52.237049,
    lng: 21.017532,
  },
  cityName: "",
  bySearchBar: false,
  setLocalization: false,
  // setLocalization: localStorage.getItem('userLocalization') ? JSON.parse(localStorage.getItem('userLocalization')) : false,
  arround: [
    { name: "10 km", value: 10000, active: true, zoom: 11 },
    { name: "20 km", value: 20000, active: false, zoom: 10 },
    { name: "50 km", value: 50000, active: false, zoom: 9 },
    { name: "100 km", value: 100000, active: false, zoom: 8 },
    { name: "kraj", value: 100000000000, active: false, zoom: 7 },
  ],
  partnersArroundUser: [],
  shopsByCoord: [],
  partnersNearUser: [],
  errorFromPartnersNearUser: "",
};
export const localizationReducer = (
  state = INITIAL_LOCALIZATION_STATE,
  actions
) => {
  switch (actions.type) {
    case GET_USER_LOCALIZATION:
      myStorage &&
        myStorage.setItem("userLocalization", JSON.stringify(actions?.item));
      return {
        ...state,
        cityName: actions?.item?.valueName,
        bySearchBar: actions?.item?.searchBar,
        coordinates: {
          lat: actions?.item?.coords?.latitude,
          lng: actions?.item?.coords?.longitude,
        },
        setLocalization: actions?.item?.coords?.latitude ? true : false,
      };

    case ARROUND_OPTION:
      state.partnersArroundUser.length = 0;
      state.shopsByCoord.length = 0;
      state.arround.map((option) => {
        if (option.value === actions.item.value) {
          option.active = true;
        } else {
          option.active = false;
        }
        return null;
      });
      return { ...state };
    case PARTNERS_ARROUND_USER_FOUNDED:
      state.partnersArroundUser = [...actions.item];
      return { ...state };
    case SORT_SHOPS_BY_CORDS:
      state.shopsByCoord = [...actions.item];
      return { ...state };
    case CLEAR_SHOPS:
      state.partnersArroundUser.length = 0;
      state.shopsByCoord.length = 0;
      return { ...state };
    case ADD_PARTNERS_NEAR_USER:
      state.partnersNearUser = [...actions.item];
      return { ...state };
    case ERROR_FROM_PARTNERS_NEAR_USER:
      state.errorFromPartnersNearUser = actions.item;
      return { ...state };
    case DISALOW_TO_USER_GEOLOCATION:
      state.setLocalization = false;
      state.partnersArroundUser = [];
      state.shopsByCoord = [];
      state.partnersNearUser = [];
      state.cityName = "";
      state.bySearchBar = false;
      state.coordinates = {
        lat: 52.237049,
        lng: 21.017532,
      };
      return { ...state };
    default:
      return state;
  }
};
// export default localizationReducer;
