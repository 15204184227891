import React from "react";
import { SvgDisplay } from "../tools/spiner/svgDisplay/SvgDisplay";
import { Theme } from "../../store/theme/theme";
import styled from "styled-components";
import { motion } from "framer-motion";

export const BtnHover = styled(motion.div)`
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  border: 1px solid
    ${({ isDark }) => (isDark ? Theme.dark.primaryColor.dark : "white")};
  // transition: 0.3s;
  // &:hover {
  //   box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  // }
`;
export const BtnFabric = ({ style, svg }) => (
  <div style={style?.buttonStyle}>
    <BtnHover isDark={style?.isDark} whileTap={{ scale: style?.scale }}>
      <SvgDisplay viewBox={svg?.viewBox} d={svg?.d} style={svg?.style} />
    </BtnHover>
  </div>
);
