import { Theme } from "../../../store/theme/theme";
import { motion } from "framer-motion";
import styled from "styled-components";

export const RedirectBtnWrapper = styled(motion.div)`
  position: absolute;
  top: ${({ isMobile, position }) =>
    isMobile && position?.top ? position.top : isMobile ? "83%" : "75%"};
  right: 5px;
  width: 175px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  border-radius: 25px;
  border: 1px solid
    ${({ isDark }) =>
      !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    box-shadow: 1px 1px 15px ${Theme.dark.primaryColor.dark};
  }
`;
