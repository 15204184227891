import React, { useEffect, useState, useRef } from "react";
import { useParams, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { SingleBrand } from "../components/singleBrand/SingleBrand";
import { BrandOrCategoryContainer } from "../components/brandOrCategoryContainer/BrandOrCategoryContainer";
import { MapContainer } from "../components/mapSection/MapContainer";
import { CloseToUserShops } from "../components/closeToUserShops/CloseToUserShops";
import { InfoSection } from "../components/infoSection/InfoSection";
import { Footer } from "../components/footerSection/Footer";
import { TopDeals } from "../components/topDealsSection/TopDeals";
import {
  getBrandByNameQuery,
  getBrandItemProductsQuery,
} from "../queries/queries";
import { ErrorFromData } from "../components/error/ErrorFromData";
import { API_URL, BRAND_NAME } from "../constant/constant";
import { useQuery } from "@apollo/client";
import { BrandPostSection } from "../components/brandPostSection/BrandPostSection";

const _BrandPage = ({
  setUrlChecked,
  selectViewData,
  setSelectViewData,
  select,
  theme,
}) => {
  const { name, section } = useParams();
  const sectionRef = useRef();

  const regNameMultiLng =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšśŚžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9\s ,.'-]+$/u;

  const titleParams = name && name.split("-").join(" ");
  const urlMatchedBrand = BRAND_NAME.filter(
    (item) => item === titleParams.toLowerCase()
  );
  const variableForSortDeals =
    select?.sortDeals.filter((item) => item.active)[0]?.name || "recomended";
  // console.log(titleParams, urlMatchedBrand[0]);
  const brandDataFromUrl = useQuery(getBrandByNameQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    variables: {
      name: urlMatchedBrand && urlMatchedBrand[0] ? urlMatchedBrand[0] : "",
    },
    ssr: true,
  });
  const brandItemProductsDataFromUrl = useQuery(getBrandItemProductsQuery, {
    fetchPolicy: "network-only",
    variables: {
      brandName:
        urlMatchedBrand && urlMatchedBrand[0] ? urlMatchedBrand[0] : "",
    },
    ssr: true,
  });
  // console.log(
  //   brandDataFromUrl,
  //   "brandDataFromUrl",
  //   brandItemProductsDataFromUrl,
  //   "brandItemProductsDataFromUrl"
  // );

  useEffect(() => {
    if (brandDataFromUrl?.client) {
      brandDataFromUrl.client.resetStore();
    }
  }, []);
  // useEffect(() => {
  //   brandDataFromUrl.refetch();
  //    brandItemProductsDataFromUrl.refetch();
  // }, [name, variableForSortDeals]);
  useEffect(() => {
    setSelectViewData(brandDataFromUrl?.data?.brandByName[0]);
  }, [brandDataFromUrl?.data?.brandByName[0]?.name]);

  useEffect(() => {
    if (sectionRef && sectionRef?.current && theme?.isMobile) {
      const y = sectionRef.current.offsetTop - 160;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [name]);

  if (!urlMatchedBrand[0]) {
    return (
      <>
        <Redirect to={{ pathname: "/" }} />
      </>
    );
  }
  if (!regNameMultiLng.test(name)) {
    return (
      <>
        <Redirect to={{ pathname: "/" }} />
      </>
    );
  }
  if (section) {
    const check = ["promocje", "sklepy"].filter((item) =>
      item.includes(section)
    ).length;
    if (check === 0) {
      return (
        <>
          <Redirect to={{ pathname: "/" }} />
        </>
      );
    }
  }

  if (brandDataFromUrl?.error) {
    return (
      <ErrorFromData
        errorInfo={brandDataFromUrl.error}
        queryName={"brandDataFromUrl._BrandPage"}
      />
    );
  }
  return (
    <>
      <div ref={sectionRef} />
      <SingleBrand
        setUrlChecked={setUrlChecked}
        brandDataFromUrl={brandDataFromUrl}
      />
      <TopDeals
        brand={true}
        brandDataFromUrl={brandDataFromUrl}
        brandItemProductsDataFromUrl={brandItemProductsDataFromUrl}
        dealsType="BRAND_PAGE"
      />
      <BrandOrCategoryContainer
        section={section}
        brandDataFromUrl={brandDataFromUrl}
      />
      <MapContainer />
      <CloseToUserShops howMany={10} />
      <BrandPostSection brandDataFromUrl={brandDataFromUrl} itemsPerPage={4} />
      <InfoSection brandDataFromUrl={brandDataFromUrl} />
      <Footer brandDataFromUrl={brandDataFromUrl} />
    </>
  );
};
const mapStateToProps = (state) => ({
  select: state.select,
  theme: state.theme,
});
export const BrandPage = connect(mapStateToProps, {})(_BrandPage);
