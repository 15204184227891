import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { Theme } from "../../../store/theme/theme";

import {
  DescribeFutureContainer,
  AcceptButton,
  styleForActionsSvg,
  modalWraperVariants,
} from "./style";
import { userAcceptMetricsFuture } from "../../../store/shopingList/duck/actions/actions";
import { PageReloaded } from "../../../utils/PageReloaded";

const userPath =
  "M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z";
const chartPath =
  "M304 240V16.6c0-9 7-16.6 16-16.6C443.7 0 544 100.3 544 224c0 9-7.6 16-16.6 16H304zM32 272C32 150.7 122.1 50.3 239 34.3c9.2-1.3 17 6.1 17 15.4V288L412.5 444.5c6.7 6.7 6.2 17.7-1.5 23.1C371.8 495.6 323.8 512 272 512C139.5 512 32 404.6 32 272zm526.4 16c9.3 0 16.6 7.8 15.4 17c-7.7 55.9-34.6 105.6-73.9 142.3c-6 5.6-15.4 5.2-21.2-.7L320 288H558.4z";

const thumbPath =
  "M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z";

const UserWrapper = styled.div`
  position: relative;
  width: 120px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  color: ${({ isDark }) =>
    !isDark ? Theme.light.thirdColor.dark : Theme.light.primaryColor.white};
  background: ${({ isDark }) =>
    isDark ? Theme.dark.eightColor.dark : Theme.dark.sevenColor.dark};
  transition: transform 0.3s ease-out, background 1s;
  & > a {
    width: 100%;
    height: 100%;
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    color: inherit;
  }

  &:hover {
    box-shadow: 2px 2px 7px black;
  }
`;

const _User = ({ theme, userAcceptMetricsFuture, shopingList, children }) => {
  const [isDescriberVisible, setDescriberVisible] = useState(false);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const reload = PageReloaded();

  const userSvgForLight = {
    height: "14px",
    color: Theme.light.thirdColor.dark,
    transition: "color .2s",
    marginBottom: "3px",
    marginRight: "2px",
  };
  const userSvgForDark = {
    height: "14px",
    color: Theme.light.primaryColor.white,
    transition: "color .2s",
    marginBottom: "3px",
    marginRight: "2px",
  };

  const userSvgStyle = theme.isDark ? userSvgForDark : userSvgForLight;
  useEffect(() => {
    if (
      !shopingList.userAcceptMetricsFuture &&
      localStorage.getItem("userAcceptMetricsFuture") &&
      JSON.parse(localStorage.getItem("userAcceptMetricsFuture"))
    ) {
      setDescriberVisible(false);
      userAcceptMetricsFuture();
    }
  }, [reload]);
  useEffect(() => {
    if (
      !shopingList.userAcceptMetricsFuture &&
      !localStorage.getItem("userAcceptMetricsFuture")
    ) {
      setTimeout(() => {
        !shopingList.userAcceptMetricsFuture && setDescriberVisible(true);
      }, 10000);
    }
  }, []);

  return (
    <>
      <UserWrapper
        isDark={theme.isDark}
        isDescriberVisible={isDescriberVisible}
        onMouseOver={() => setOptionsVisible(true)}
        onMouseLeave={() => {
          setOptionsVisible(false);
        }}
      >
        <Link to="/twoje-zakupy">
          {/* <UserIcon isDark={theme.isDark} style={userSvgStyle} /> */}
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="user"
            className="svg-inline--fa fa-user fa-w-14"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            style={userSvgStyle}
          >
            <path fill="currentColor" d={chartPath}></path>
          </svg>
          {children}
        </Link>
        {isDescriberVisible && (
          <DescribeFutureContainer
            variants={modalWraperVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <h3>generuj statystyki</h3>
            <p>Stwórz liste, wyślij ją, zarządzaj swoimi wydatkami</p>
            <AcceptButton
              whileTap={{ scale: 0.95 }}
              whileHover={{ scale: 1.05 }}
              onClick={() => {
                setDescriberVisible(false);
                userAcceptMetricsFuture();
              }}
            >
              <p>
                {/* <Thumbs style={styleForActionsSvg} /> */}
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="thumbs-up"
                  className="svg-inline--fa fa-thumbs-up fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  style={styleForActionsSvg}
                >
                  <path fill="currentColor" d={thumbPath}></path>
                </svg>
                ok
              </p>
            </AcceptButton>
          </DescribeFutureContainer>
        )}
      </UserWrapper>
    </>
  );
};
const mapDisaptchToProps = (dispatch) => ({
  userAcceptMetricsFuture: () => dispatch(userAcceptMetricsFuture()),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  shopingList: state.shopingList,
});
export const User = connect(mapStateToProps, mapDisaptchToProps)(_User);
