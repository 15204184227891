import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { CategoryAnalise } from "./style";
import { Theme } from "../../../../store/theme/theme";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export const CategoryChart = ({ dataAnalise, isDark }) => {
  const options = {
    responsive: true,
    radius: 5,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItem, data) {
            return tooltipItem[0].label;
          },
          label: function (tooltipItem, data) {
            const index = tooltipItem.dataIndex;
            const dataFromAll = tooltipItem.dataset.data.map((item) =>
              item ? parseFloat(item) : 0
            );
            const total = dataFromAll.reduce((all, item) => all + item, 0);

            const percentage = parseFloat(
              ((tooltipItem.dataset.data[index] / total) * 100).toFixed(1)
            );

            return `${tooltipItem.dataset.data[index].toFixed(
              2
            )} PLN - ${percentage}% `;
          },
        },
      },
      filler: {
        propagate: true,
      },
    },
    datasetFill: false,
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return value + " PLN";
          },
        },
      },
    },
  };

  const labels = dataAnalise.shopingList
    .map((item, index, self) => {
      return self.findIndex(
        (selfItem) =>
          selfItem.categoryKeyProductName === item.categoryKeyProductName
      ) !== index
        ? null
        : item.categoryKeyProductName;
    })
    .filter((item) => item);

  const dataChart = {
    labels,
    datasets: [
      {
        label: "Kategorie",
        fill: true,
        data: labels.map((label) => {
          const res = dataAnalise.shopingList.filter(
            (item) => item.categoryKeyProductName === label
          );

          const resPrice = res.map((item) =>
            parseFloat(
              (
                (parseFloat(item.price) || 0) * (parseFloat(item.howMany) || 0)
              ).toFixed(2)
            )
          );

          return resPrice.reduce((total, item) => total + item, 0);
        }),
        // backgroundColor: "rgba(244, 144, 128, 0.8)",
        backgroundColor: [
          "rgba(255,0,0, 1)",
          "rgba(0,0,255, 1)",
          "rgba(0,255,255, 1)",
          "rgba(255,0,255, 1)",
          "rgba(128,0,128, 1)",
          "rgba(255,255,0, 1)",
          "rgba(0,128,0, 1)",
          "rgba(0,255,0, 1)",
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
          "rgba(75, 192, 192,0.8)",
          "rgba(255, 99, 132,0.8)",
          "rgba(153, 102, 255,0.8)",
          "rgba(54, 162, 235,0.8)",
          "rgba(255, 206, 86,0.8)",
          "rgba(255, 12, 214,0.8)",
          "rgba(54, 62, 135,0.8)",
          "rgba(25, 16, 86,0.8)",
          "rgba(215, 59, 124,0.8)",
        ].reverse(),
        borderColor: [
          "rgba(255,0,0, 1)",
          "rgba(0,0,255, 1)",
          "rgba(0,255,255, 1)",
          "rgba(255,0,255, 1)",
          "rgba(128,0,128, 1)",
          "rgba(255,255,0, 1)",
          "rgba(0,128,0, 1)",
          "rgba(0,255,0, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 12, 214, 1)",
          "rgba(54, 62, 135, 1)",
          "rgba(25, 16, 86, 1)",
          "rgba(215, 59, 124, 1)",
        ].reverse(),
        borderWidth: 1,
      },
    ],
  };

  defaults.fontFamily = ["Montserrat", "Helvetica Neue"];
  defaults.color = isDark
    ? Theme.dark.thirdColor.white
    : Theme.light.thirdColor.dark;
  defaults.borderColor = isDark
    ? Theme.light.fiveColor.white
    : Theme.dark.sixColor.dark;

  return (
    <CategoryAnalise>
      <Line
        data={dataChart}
        options={options}
        style={{
          background: `${
            isDark
              ? Theme.dark.primaryColor.dark
              : Theme.light.primaryColor.white
          }`,
          padding: "15px",
          borderRadius: "5px",
        }}
      />
    </CategoryAnalise>
  );
};
