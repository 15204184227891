import styled from "styled-components";
import { Theme } from "../../../../store/theme/theme";
export const ItemsAnalise = styled.div`
  width: 95%;
  // height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  @media${Theme.device.mobileL} {
    margin-bottom: 70px;
  }
`;
