import { Theme } from "../../store/theme/theme";
export const bgColor = (isDark, carusel) => {
  if (isDark && !carusel) {
    return Theme.dark.sixColor.dark;
  }
  if (isDark && carusel) {
    return Theme.dark.sixColor.dark;
  }
  if (!isDark && !carusel) {
    return Theme.light.fiveColor.white;
  }
  if (!isDark && carusel) {
    return Theme.dark.sixColor.dark;
  }
};
export const fColor = (isDark, carusel) => {
  if (isDark && !carusel) {
    return Theme.dark.thirdColor.white;
  }
  if (isDark && carusel) {
    return Theme.dark.thirdColor.white;
  }
  if (!isDark && !carusel) {
    return Theme.light.thirdColor.dark;
  }
  if (!isDark && carusel) {
    return Theme.dark.thirdColor.white;
  }
};

export const defineThemeClassName = (isDark, carusel) => {
  if (isDark && !carusel) {
    return "citySearchDark";
  }
  if (isDark && carusel) {
    return "citySearchDark";
  }
  if (!isDark && !carusel) {
    return "citySearchLigth";
  }
  if (!isDark && carusel) {
    return "citySearchDark";
  }
};
