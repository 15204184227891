import React from "react";
import { connect } from "react-redux";
import { StyledWraperForNumber } from "./style/style";
const _NumbersOfItems = ({ theme, item }) => {
  return (
    <>
      {item && item.categoryItems && (
        <StyledWraperForNumber isDark={theme.isDark}>
          {item.categoryItems}
        </StyledWraperForNumber>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
});
export const NumbersOfItems = connect(mapStateToProps, {})(_NumbersOfItems);
