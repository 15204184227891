import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ShopingListIco,
  styleForIcoSvg,
  MainList,
  DescribeFutureContainer,
  AcceptButton,
  styleForActionsSvg,
  modalWraperVariants,
} from "./styleForShopingList";

import {
  trigerVisibleList,
  deleteItemFromList,
  userAcceptShopingListFuture,
  updateItemsFromLocalStorage,
  updateUserItemsFromLocalStorage,
} from "../../store/shopingList/duck/actions/actions";
import {
  overflowHidden,
  overflowAuto,
  menuClose,
} from "../../store/theme/duck/actions/actions";
import { NumberOfShopingItems } from "./NumberOfShopingItems";
import { ContentList } from "./contentList/ContentList";
import { Theme } from "../../store/theme/theme";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { ShopingChartSwitcher } from "./shopingChartSwitcher/ShopingChartSwitcher";
import { ChartAnalise } from "./chartAnalise/ChartAnalise";
import { CloseList } from "./closeList/CloseList";
// import Thumbs from "../../media/svg/thumbs-up-solid.svg";
import { API_URL } from "../../constant/constant";
import { PageReloaded } from "../../utils/PageReloaded";
import { SvgColorStyle } from "../../utils/SvgColorStyle";

const thumbPath =
  "M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z";
const listPath =
  "M464 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zM128 120c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm0 96c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm288-136v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12zm0 96v-32c0-6.627-5.373-12-12-12H204c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h200c6.627 0 12-5.373 12-12z";
const resizeScreen = (isMobileL, setIsMobileL, breakPoint) => {
  window.addEventListener("resize", () => {
    const docWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    if (docWidth > breakPoint) {
      setIsMobileL(false);
    } else {
      setIsMobileL(true);
    }
  });
};

const onLoadScreen2 = (isMobileL, setIsMobileL, breakPoint) => {
  const docWidth =
    document.documentElement.clientWidth || document.body.clientWidth;

  if (docWidth > breakPoint) {
    setIsMobileL(false);
  } else {
    setIsMobileL(true);
  }
};
const checkIsListItemsActive = async (list, deleteItemFromList) => {
  fetch(`${API_URL}/api/checkIsListItemsActive`, {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({
      list: list,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      // console.log(data)
      if (data && data.length) {
        // console.log(data)
        data.map((item) => deleteItemFromList(item));
      } else {
        return null;
      }
    })
    .catch((err) => console.log(err));
};

const _ShopingList = ({
  theme,
  shopingList,
  trigerVisibleList,
  deleteItemFromList,
  overflowHidden,
  overflowAuto,
  menuClose,
  userAcceptShopingListFuture,
  updateItemsFromLocalStorage,
  updateUserItemsFromLocalStorage,
}) => {
  const [chart, setChart] = useState(false);
  const [isDescriberVisible, setDescriberVisible] = useState(false);
  const [isMobileL, setIsMobileL] = useState(false);
  const reload = PageReloaded();
  const [pageR, setPageR] = useState(false);
  useEffect(() => {
    if (!pageR) setPageR(true);
  });

  useEffect(() => {
    if (
      !shopingList?.listItems?.length &&
      localStorage &&
      localStorage.getItem("items")
    ) {
      updateItemsFromLocalStorage(JSON.parse(localStorage.getItem("items")));
    }
  }, [reload]);
  useEffect(() => {
    if (
      !shopingList?.userItems?.length &&
      localStorage &&
      localStorage.getItem("userItems")
    ) {
      updateUserItemsFromLocalStorage(
        JSON.parse(localStorage.getItem("userItems"))
      );
    }
  }, [reload]);
  useEffect(() => {
    onLoadScreen2(isMobileL, setIsMobileL, Theme.breakPoint.mobileM);
    resizeScreen(isMobileL, setIsMobileL, Theme.breakPoint.mobileM);
  }, []);
  useEffect(() => {
    checkIsListItemsActive(shopingList.listItems, deleteItemFromList);
  }, []);
  useEffect(() => {
    if (isMobileL) {
      if (shopingList.isListVisible === true) {
        overflowAuto();
      }
      if (shopingList.isListVisible === false) {
        overflowHidden();
      }
    }
  }, [isMobileL, shopingList.isListVisible]);

  useEffect(() => {
    if (
      localStorage &&
      localStorage.getItem("userAcceptShopingListFuture") &&
      JSON.parse(localStorage.getItem("userAcceptShopingListFuture"))
    ) {
      setDescriberVisible(false);
      userAcceptShopingListFuture();
    }
  }, [reload]);
  useEffect(() => {
    if (
      !shopingList.userAcceptShopingListFuture &&
      !localStorage.getItem("userAcceptShopingListFuture")
    ) {
      setTimeout(() => {
        setDescriberVisible(true);
      }, 5000);
    }
  }, []);
  return (
    <ErrorBoundary>
      <MainList
        isDark={theme.isDark}
        isVisible={shopingList.isListVisible}
        isMobileL={isMobileL}
      >
        {isMobileL && <CloseList />}
        {isDescriberVisible && (
          <DescribeFutureContainer
            variants={modalWraperVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <h3>analizuj planuj zakupy</h3>
            <p>Zapoznaj Się z funkcjami listy</p>
            <AcceptButton
              whileTap={{ scale: 0.95 }}
              whileHover={{ scale: 1.05 }}
              onClick={() => {
                setDescriberVisible(false);
                userAcceptShopingListFuture();
              }}
            >
              <p>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="thumbs-up"
                  className="svg-inline--fa fa-thumbs-up fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  style={styleForActionsSvg}
                  heigth={20}
                  width={20}
                >
                  <path fill="currentColor" d={thumbPath}></path>
                </svg>
                ok
              </p>
            </AcceptButton>
          </DescribeFutureContainer>
        )}
        {!theme?.isMobile && (
          <ShopingListIco
            isDark={theme.isDark}
            isMobile={theme.isMobile}
            onClick={() => {
              trigerVisibleList();
              isMobileL && theme.isMenuOpen && menuClose();
            }}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="list-alt"
              className="svg-inline--fa fa-list-alt fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width={35}
              heigth={35}
            >
              <path fill={SvgColorStyle(theme)} d={listPath}></path>
            </svg>
            <NumberOfShopingItems />
          </ShopingListIco>
        )}
        <ShopingChartSwitcher chart={chart} setChart={setChart} />
        {/* {!chart && reload && <ContentList isMobileL={isMobileL} />} */}
        {!chart && pageR && <ContentList isMobileL={isMobileL} />}
        {chart && <ChartAnalise />}
      </MainList>
    </ErrorBoundary>
  );
};

const mapDisaptchToProps = (dispatch) => ({
  trigerVisibleList: (item) => dispatch(trigerVisibleList(item)),
  deleteItemFromList: (item) => dispatch(deleteItemFromList(item)),
  overflowHidden: () => dispatch(overflowHidden()),
  overflowAuto: () => dispatch(overflowAuto()),
  menuClose: () => dispatch(menuClose()),
  userAcceptShopingListFuture: () => dispatch(userAcceptShopingListFuture()),
  updateItemsFromLocalStorage: (item) =>
    dispatch(updateItemsFromLocalStorage(item)),
  updateUserItemsFromLocalStorage: (item) =>
    dispatch(updateUserItemsFromLocalStorage(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  shopingList: state.shopingList,
});
export const ShopingList = connect(
  mapStateToProps,
  mapDisaptchToProps
)(_ShopingList);
