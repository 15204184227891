import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
import { motion } from "framer-motion";
export const WrraperSection = styled.div`
  // background-color: #f8f9d2;
  width: 100%;
  min-height: 34vh;
  position: relative;
  background: ${({ isDark, metrics }) =>
    isDark && !metrics
      ? Theme.light.fiveColor.white
      : !isDark && !metrics
      ? Theme.dark.sixColor.dark
      : "transparent"};
  background-image: linear-gradient(
    315deg,
    ${({ isDark, metrics }) =>
        isDark && !metrics
          ? Theme.dark.primaryColor.dark
          : !isDark && !metrics
          ? Theme.light.primaryColor.white
          : "transparent"}
      0%,
    ${({ isDark, metrics }) =>
        isDark && !metrics
          ? Theme.dark.sixColor.dark
          : !isDark && !metrics
          ? Theme.light.fiveColor.white
          : "transparent"}
      74%
  );
  user-select: none;
  overflow: hidden;
`;
export const MainWrapperLayer = styled.div`
  width: 100%;
  height: 100%;
  //   background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  //   transition: background 0.3s;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;
export const LeftSide = styled.div`
  width: 70%;
  height: 400px;
  @media${Theme.device.mobileL} {
    width: 100%;
    height: auto;
  }
`;
export const RigthSide = styled.div`
  width: 300px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media${Theme.device.mobileL} {
    margin-bottom: 40px;
  }
`;
