import React, { useEffect } from "react";
import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
import { API_URL } from "../../constant/constant";
const ErrorWraper = styled.div`
  width: 100%;
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media${Theme.device.mobileL} and (orientation:landscape) {
    min-height: 140vh;
  }
`;

const logErrorToServer = (errorInfo, queryName) => {
  console.log({ errorInfo, name: queryName });
  fetch(`${API_URL}/api/error`, {
    method: "POST",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      error: `error from database `,
      errorInfo: errorInfo,
      queryName: queryName,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.logged) {
        console.log(data.logged);
        return;
      }
    });
};
export const ErrorFromData = ({ errorInfo, queryName }) => {
  useEffect(() => {
    if (errorInfo) {
      logErrorToServer(errorInfo, queryName);
    }
  }, [errorInfo]);

  return (
    <ErrorWraper>
      <section className="error-wrapper">
        <h3>Upps.Przepraszamy coś nie tak z naszą bazą danych.</h3>
        <h4> Spróbuj ponownie za chwilę..</h4>
        <section className="walking"></section>
      </section>
    </ErrorWraper>
  );
};
