import React, { useState, useEffect } from "react";
import { Theme } from "../../../../../store/theme/theme";

import Cenniczek from "../../../../../media/svg/cenniczek-icon-192.svg";
import { motion } from "framer-motion";

import {
  caruselWraperVariants,
  SlideHoverShopingList,
  ImageAndDataHover,
  BrandDataHover,
  imgSuspenseStyleDark,
  imgSuspenseStyleLight,
  AddNewItemToList,
  imgPlusStyleDark,
  imgPlusStyleLight,
  AddHover,
} from "../../../style";
import { connect } from "react-redux";
import { overflowAuto } from "../../../../../store/theme/duck/actions/actions";
const imagePath =
  "M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z";
const plusPath =
  "M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z";
const ItemBrandImage = ({ imgUrl, isDark }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <>
      {isLoaded ? null : (
        <img
          loading="lazy"
          src={Cenniczek}
          style={isDark ? imgSuspenseStyleDark : imgSuspenseStyleLight}
        />
      )}
      {imgUrl && (
        <img
          loading="lazy"
          onLoad={() => setIsLoaded(true)}
          src={`${imgUrl}`}
        />
      )}
    </>
  );
};
const BrandImage = ({ imgUrl, isDark }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <>
      {isLoaded ? null : (
        <img
          loading="lazy"
          src={Cenniczek}
          style={{
            width: "55px",
            padding: "5px",
          }}
        />
      )}
      {imgUrl && (
        <img
          loading="lazy"
          onLoad={() => setIsLoaded(true)}
          src={`${imgUrl}`}
        />
      )}
    </>
  );
};
const _SingleItemCaruselMobile = ({
  item,
  index,
  isDark,
  activeItemIndex,
  setActiveItemIndex,
  indexCarusel,
  setActiveItemIndexFromCarusel,
  activeItemIndexFromCarusel,
  setActiveIndexFromSlide,
  overflowAuto,
  lastItem,
}) => {
  const [isActive, setIsActive] = useState(false);
  const slideStyle = {
    height: "225px",
    width: "250px",
    margin: "10px",
    border: `1px solid ${
      isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white
    }`,
    borderRadius: "5px",
    overflow: "hidden",
    cursor: "pointer",
    transition: ".3s",
    position: "relative",
  };
  const slideStyleActive = {
    height: "225px",
    width: "250px",
    margin: "10px",
    border: `5px solid ${
      isDark
        ? Theme.light.secondaryColor.green
        : Theme.light.secondaryColor.green
    }`,
    borderRadius: "5px",
    overflow: "hidden",
    cursor: "pointer",
    transition: ".3s",
    position: "relative",
  };
  useEffect(() => {
    if (activeItemIndex !== index) {
      setIsActive(false);
    }
  }, [activeItemIndex]);
  useEffect(() => {
    if (indexCarusel !== activeItemIndexFromCarusel) {
      setIsActive(false);
      setActiveItemIndex(null);
    }
  }, [activeItemIndexFromCarusel]);

  if (lastItem === index + 1) {
    return (
      <motion.div
        variants={caruselWraperVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        custom={index}
        key={index}
        whileTap={{ scale: 0.95 }}
        whileHover={{ scale: 1.05 }}
      >
        <div
          isDark={isDark}
          index={index}
          style={isActive ? slideStyleActive : slideStyle}
          onClick={() => {
            overflowAuto();
            if (!isActive && activeItemIndex !== index) {
              setIsActive(true);
              setActiveItemIndex(index);
              setActiveItemIndexFromCarusel(indexCarusel);
              setActiveIndexFromSlide(index);
            }
            if (isActive && activeItemIndex === index) {
              setIsActive(false);
              setActiveItemIndex(null);
              setActiveItemIndexFromCarusel(null);
              setActiveIndexFromSlide(null);
            }
            console.log("dodaj");
          }}
        >
          <SlideHoverShopingList isDark={isDark}>
            <h2>dodaj</h2>
            <AddHover>
              <AddNewItemToList isDark={isDark}>
                {/* <Plus
                  style={isDark ? imgPlusStyleDark : imgPlusStyleLight}
                  isDark={isDark}
                /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  style={isDark ? imgPlusStyleDark : imgPlusStyleLight}
                >
                  <path fill="currentColor" d={plusPath} />
                </svg>
              </AddNewItemToList>
            </AddHover>
          </SlideHoverShopingList>
        </div>
      </motion.div>
    );
  } else {
    return (
      <motion.div
        variants={caruselWraperVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        custom={index}
        key={index}
        whileTap={{ scale: 0.95 }}
        whileHover={{ scale: 1.05 }}
      >
        <div
          isDark={isDark}
          index={index}
          style={isActive ? slideStyleActive : slideStyle}
          onClick={() => {
            overflowAuto();
            if (!isActive && activeItemIndex !== index) {
              setIsActive(true);
              setActiveItemIndex(index);
              setActiveItemIndexFromCarusel(indexCarusel);
              setActiveIndexFromSlide(index);
            }
            if (isActive && activeItemIndex === index) {
              setIsActive(false);
              setActiveItemIndex(null);
              setActiveItemIndexFromCarusel(null);
              setActiveIndexFromSlide(null);
            }
            // console.log(item)
          }}
        >
          <SlideHoverShopingList isDark={isDark}>
            <h2>{`${item.name ? item.name : ""} ${
              item.aditionalName ? item.aditionalName : ""
            }`}</h2>
            <ImageAndDataHover isDark={isDark}>
              <ItemBrandImage imgUrl={item.imgUrl} isDark={isDark} />
              <div>
                <div>
                  <p>Ile</p>
                  <p>{item.howMany}</p>
                </div>
                <div>
                  <p>Cena za {item.typeSelect}</p>
                  <p>{item.price} PLN</p>
                </div>
                <div>
                  <p>Łącznie</p>
                  <p>
                    {item.howMany && item.price
                      ? `${(
                          parseFloat(item.price) * parseFloat(item.howMany)
                        ).toFixed(2)} PLN`
                      : "0 PLN"}
                  </p>
                </div>
              </div>
            </ImageAndDataHover>
            <BrandDataHover isDark={isDark}>
              <BrandImage
                isDark={isDark}
                imgUrl={item?.brandData ? item?.brandData[0]?.imgUrl : ""}
              />
              <p>{item.brand || "brak"}</p>
            </BrandDataHover>
          </SlideHoverShopingList>
        </div>
      </motion.div>
    );
  }
};
const mapDispatchToProps = (dispatch) => ({
  overflowAuto: () => dispatch(overflowAuto()),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
});
export const SingleItemCaruselMobile = connect(
  mapStateToProps,
  mapDispatchToProps
)(_SingleItemCaruselMobile);
