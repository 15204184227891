import React, { useState, useEffect } from "react";
// import { ReactComponent as MailIcon } from "../../media/svg/envelope-solid.svg";
import { MainContent, MailIconStyle, SectionHeader } from "./stylesForContact";
import { TermsAcceptContact } from "./TermsAcceptContact";
import { Theme } from "../../store/theme/theme";
import { motion, AnimatePresence } from "framer-motion";
// import ReCAPTCHA from "react-google-recaptcha";
const mailIconPath =
  "M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z";
const pVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
export const BeforeValidationeContact = ({
  isDark,
  validate,
  setBodyEmail,
}) => {
  const [ReCAPTCHA, setReCAPTCHA] = useState(null);
  const recaptchaSiteKey =
    process?.env?.NODE_ENV === "development"
      ? process.env.REACT_APP_RECAPTCHA_SITE_KEY
      : "6LeJQA4bAAAAAPNPnqEEFjPt4z0u7LTJAjFNHUt0";

  const recaptchaRef = React.createRef();
  const [human, setHuman] = useState(false);
  const [inputTextValue, setInputTextValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [validError, setValidError] = useState("");
  const [nameError, setNameError] = useState("");
  const [msgValue, setMsgValue] = useState("");
  const [msgError, setMsgError] = useState("");
  const [validMsg, setValidMsg] = useState(false);
  const [acceptTerm, setAcceptTerms] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validName, setValidName] = useState(false);
  const _validEmail = React.useRef(validEmail);
  const _validName = React.useRef(validName);
  const _validMsg = React.useRef(validMsg);
  const [emailError, setEmailError] = useState("");

  // const regName = /^[a-zA-Z '.-]*$/i
  const regNameMultiLng =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9\s ,.'-]+$/u;
  const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const _setValidEmail = (data) => {
    _validEmail.current = data;
    setValidEmail(data);
  };
  const _setValidName = (data) => {
    _validName.current = data;
    setValidName(data);
  };
  const _setValidMsg = (data) => {
    _validMsg.current = data;
    setValidMsg(data);
  };

  const onChange = () => {
    setHuman(true);
    console.log("recaptcha resolved");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!human) {
      recaptchaRef.current.reset();
      // recaptchaRef.current.execute()
    }

    const nameValidationHandle = async () => {
      if (nameValue.length >= 3 && nameValue.length < 33) {
        setNameError("");
      }
      if (!nameValue) {
        setNameError("Wpisz proszę swoje Imię");
      }
      if (nameValue && nameValue.length < 3) {
        setNameError("Wpisane imie jest zbyt krótkie");
      }
      if (nameValue.length > 33) {
        setNameError("Wpisane imie jest zbyt długie");
      }
      if (!regNameMultiLng.test(nameValue)) {
        setNameError("Wpisz poprawnie swoje imie");
      }
      if (
        regNameMultiLng.test(nameValue) &&
        nameValue.length >= 3 &&
        nameValue.length <= 33
      ) {
        setNameError("");
        _setValidName(true);

        return true;
      }
    };

    const msgValidationHandle = async () => {
      if (msgValue.length >= 3 && msgValue.length < 33) {
        setMsgError("");
      }
      if (!msgValue) {
        setMsgError("Wpisz proszę treść wiadomości");
      }
      if (msgValue && msgValue.length < 10) {
        setMsgError("Wpisana wiadomość jest zbyt krótka");
      }
      if (msgValue.length > 500) {
        setMsgError(
          "Wpisane wiadomość jest zbyt długia,dostępne jest 500 znaków"
        );
      }
      if (!regNameMultiLng.test(msgValue)) {
        setMsgError(
          "Wpisz poprawnie wiadomość,dostępne są znaki alfanumeryczne ( a-z , 0-9 )"
        );
      }
      if (
        regNameMultiLng.test(msgValue) &&
        msgValue.length >= 10 &&
        msgValue.length <= 500
      ) {
        setMsgError("");
        _setValidMsg(true);

        return true;
      }
    };

    const emailValidationHandle = async () => {
      if (!inputTextValue) {
        setEmailError("Wpisz proszę swój e-mail");
      }
      if (inputTextValue && !regEmail.test(inputTextValue)) {
        setEmailError("Wpisany e-mail nie jest poprawny");
      }
      if (regEmail.test(inputTextValue)) {
        setEmailError("");
        _setValidEmail(true);

        return true;
      }
    };
    if (!acceptTerm) {
      setValidError("Musisz zaakceptować nasz regulamin");
    }

    const name = await nameValidationHandle();
    const email = await emailValidationHandle();
    const msg = await msgValidationHandle();
    // console.log(`name-${name},email-${email}`)
    // console.log(`validName - ${validName},validEmail-${validEmail},acceptTerm-${acceptTerm}`)
    if (human && name && email && msg && acceptTerm) {
      setBodyEmail({
        first_name: nameValue,
        email: inputTextValue,
        msg: msgValue,
      });
      validate(true);
    }
  };
  useEffect(() => {
    const loadReCAPTCHA = async () => {
      if (typeof window !== "undefined") {
        try {
          const ReCAPTCHAInstance = (await import("react-google-recaptcha"))
            .default;

          setReCAPTCHA(ReCAPTCHAInstance);
        } catch (error) {
          console.error("Error loading ReCAPTCHAInstance:", error);
        }
      }
    };

    loadReCAPTCHA();
  }, []);
  useEffect(() => {
    return () => {
      setReCAPTCHA(null);
    };
  }, []);

  return (
    <MainContent isDark={isDark} layout>
      <SectionHeader isDark={isDark}>
        <h1>
          <span>kontakt</span>
        </h1>
      </SectionHeader>
      <div>
        {/* <MailIcon style={MailIconStyle} /> */}
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="envelope"
          className="svg-inline--fa fa-envelope fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          style={MailIconStyle}
          width={70}
        >
          <path fill="currentColor" d={mailIconPath}></path>
        </svg>
        <p>napisz do nas to nic nie kosztuje.</p>
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="twoje imie"
          name="name"
          value={nameValue}
          onChange={(e) => setNameValue(e.target.value)}
        />
        <AnimatePresence>
          {nameError && (
            <motion.p
              variants={pVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              style={{
                backgroundColor: `${Theme.light.fourthColor.red}`,
                padding: "5px",
              }}
            >
              {nameError}
            </motion.p>
          )}
        </AnimatePresence>
        <input
          type="text"
          placeholder="twój adres e-mail"
          name="email"
          value={inputTextValue}
          onChange={(e) => setInputTextValue(e.target.value)}
        />
        <AnimatePresence>
          {emailError && (
            <motion.p
              variants={pVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              style={{
                backgroundColor: `${Theme.light.fourthColor.red}`,
                padding: "5px",
              }}
            >
              {emailError}
            </motion.p>
          )}
        </AnimatePresence>
        <textarea
          placeholder="treść wiadomości"
          value={msgValue}
          onChange={(e) => setMsgValue(e.target.value)}
        ></textarea>
        <AnimatePresence>
          {msgError && (
            <motion.p
              variants={pVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              style={{
                backgroundColor: `${Theme.light.fourthColor.red}`,
                padding: "5px",
              }}
            >
              {msgError}
            </motion.p>
          )}
        </AnimatePresence>
        <TermsAcceptContact
          active={acceptTerm}
          click={() => {
            setAcceptTerms(!acceptTerm);
            setValidError("");
          }}
        />
        {validError && (
          <motion.p
            style={{
              backgroundColor: `${Theme.light.fourthColor.red}`,
              padding: "5px",
            }}
          >
            {validError}
          </motion.p>
        )}
        {ReCAPTCHA && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptchaSiteKey}
            onChange={onChange}
            size={"normal"}
            hl={"pl"}
            theme={isDark ? "dark" : "light"}
          />
        )}
        <motion.button type="submit" whileTap={{ scale: 0.95 }}>
          wyślij
        </motion.button>
      </form>
    </MainContent>
  );
};
