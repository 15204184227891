import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { SectionWrapper, CloseWraper } from "./styleForShopsInNeibourhood";
// import { ReactComponent as Close } from "../../../media/svg/times-solid.svg";
import { BrandOrCategoryContainer } from "../../brandOrCategoryContainer/BrandOrCategoryContainer";
import { MapContainer } from "../../mapSection/MapContainer";
import { CloseToUserShops } from "../../closeToUserShops/CloseToUserShops";
const closePath =
  "M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z";
const _ShopsInNeibourhoods = ({
  shopsPage,
  setShopsPage,
  theme,
  select,
  overflowHidden,
}) => {
  const [nameForShops, setNameForShops] = useState("");
  const containerHeight = useRef(null);

  const [wrapperHeight, setWraperHeight] = useState(-1000);
  useEffect(() => {
    setWraperHeight(
      -(
        containerHeight?.current?.scrollHeight +
        containerHeight?.current?.offsetHeight
      )
    );
    return () => {
      setWraperHeight(-1000);
    };
  }, [containerHeight?.current?.scrollHeight]);
  useEffect(() => {
    setNameForShops(select.options.filter((item) => item.active)[0].valueName);
    return () => {
      setNameForShops("");
    };
  }, [select.options.filter((item) => item.active)[0].valueName]);
  return shopsPage ? (
    <SectionWrapper
      shopsPage={shopsPage}
      isDark={theme.isDark}
      ref={containerHeight}
      bookShopsInNeibourhood={wrapperHeight}
    >
      {shopsPage && (
        <CloseWraper
          isDark={theme.isDark}
          onClick={() => {
            setShopsPage(false);
            overflowHidden();
          }}
        >
          {/* <Close /> */}
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times"
            className="svg-inline--fa fa-times fa-w-11"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
            width={25}
            heigth={25}
          >
            <path fill="currentColor" d={closePath}></path>
          </svg>
        </CloseWraper>
      )}
      <BrandOrCategoryContainer />
      <MapContainer />
      {nameForShops && <CloseToUserShops howMany={10} name={nameForShops} />}
    </SectionWrapper>
  ) : (
    <SectionWrapper
      isDark={theme.isDark}
      shopsPage={shopsPage}
      bookShopsInNeibourhood={300}
    />
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
});

export const ShopsInNeibourhoods = connect(
  mapStateToProps,
  {}
)(_ShopsInNeibourhoods);
