import React from "react";
import { RedirectBtnWrapper } from "./RedirectBtn.style.js";
import { useHistory } from "react-router-dom";

const handleClick = (path, history) => {
  history.push(path);
};
export const RedirectBtn = ({ isDark, isMobile, path, position }) => {
  let history = useHistory();

  return (
    <RedirectBtnWrapper
      whileTap={{ scale: 0.8 }}
      isDark={isDark}
      isMobile={isMobile}
      onClick={() => handleClick(path, history)}
      position={position}
    >
      sprawdź
    </RedirectBtnWrapper>
  );
};
