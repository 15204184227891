import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { Helmet } from "react-helmet";
const WrraperSection = styled.div`
  width: 100%;
  position: relative;
  // margin-top: 70px;
  user-select: none;
  // height:50vh;
`;
const MainWrapperLayer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  transition: background 0.3s;
  display: flex;
  justify-content: space-around;
  // align-items:flex-start;
`;
const MainContent = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  padding: 20px 0 20px 0;
  max-width: ${Theme.breakPoint.desktop};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  font-family: montserrat;
  line-height: 2rem;
  user-select: none;
  & > h4 {
    margin: 20px;
  }
  & > h4 > span {
    text-transform: uppercase;
    padding: 10px;
    border-bottom: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > div {
    margin-bottom: 30px;
  }
  & > div > p,
  & > div > h4 {
    margin-bottom: 20px;
  }
  @media${Theme.device.tablet} {
    width: 90%;
  }
`;
const _Terms = ({ theme }) => {
  if (typeof window !== "undefined") document.documentElement.scrollTop = 0;
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <WrraperSection isDark={theme.isDark}>
        <MainWrapperLayer isDark={theme.isDark}>
          <MainContent isDark={theme.isDark}>
            <ErrorBoundary>
              <h4>
                <span>Regulamin-ogólne warunki użytkowania</span>
              </h4>
              <div>
                <h4>1 – Informacje o Serwisie Cenniczek.com</h4>
                <p>1.1. Dostawca usługi</p>
                <p>
                  Strona internetowa https://cenniczek.com/ i jej subdomeny
                  (zwane dalej „Witryną”) są administrowane i wykorzystywane pod
                  nadzorem PrudentCode Sp. z o.o., z siedzibą pod adresem ul.
                  Towarowa 23, 43-100 Tychy (Polska) zwaną dalej
                  PrudentCode.Wszelkie pytania lub skargi związane z
                  korzystaniem z Witryny lub funkcjonowaniem usług proponowanych
                  przez PrudentCode (zwanych dalej „Usługami”) można kierować do
                  PrudentCode:za pomocą formularza kontaktowego udostępnionego w
                  Serwisie; pocztą elektroniczną wysłaną na adres
                  e-mail:kontakt@cenniczek.com, kontakt@prudentcode.pl;
                  wysyłając pismo na następujący adres: PrudentCode Sp. z o.o.,
                  ul. Towarowa 23, 43-100 Tychy (Polska).
                </p>
                <p>1.2. Ogólne warunki</p>
                <p>
                  Dostęp do Witryny i korzystanie z niej podlegają niniejszym
                  Ogólnym Warunkom korzystania (zwanym dalej „Warunkami”) oraz
                  Polityce prywatności, a także prawu polskiemu. W związku z tym
                  dostęp do Witryny oznacza pełną zgodę internauty bez żadnych
                  zastrzeżeń z niniejszym Regulaminem i Polityką prywatności.
                  Polityka prywatności dostępna pod adresem
                  https://cenniczek.com/polityka. Warunki i Polityka Prywatności
                  regulują wyłącznie relacje między odwiedzającym Witrynę
                  (zwanym dalej „Użytkownikiem”) lub osobą zarejestrowaną
                  zgodnie z warunkami Serwisu zwaną dalej "Członkiem".Z
                  niniejszymi Warunkami można zapoznać się przez cały czas pod
                  tym adresem  https://cenniczek.com/regulamin. PrudentCode
                  zastrzega sobie prawo do zmiany i aktualizacji niniejszych
                  Warunków, dostępu do Witryny i jej zawartości w dowolnym
                  momencie i bez uprzedniego powiadomienia. Takie modyfikacje są
                  przedstawiane Użytkownikom i Członkom przy każdej wizycie w
                  Serwisie. PrudentCode zaleca regularne zapoznawanie się z
                  nimi.
                </p>
                <p>1.3. Informacje o aplikacjach osób trzecich</p>
                <p>
                  Witryna integruje aplikacje stron trzecich (zwane dalej
                  „Aplikacjami stron trzecich”), takie jak Twitter, Facebook,
                  Instagram, Twilio Sengrid, OpenStreetMap. Aplikacje te
                  podlegają własnym warunkom i polityce prywatności, z którymi
                  można zapoznać się na odpowiednich stronach internetowych
                  zainteresowanych stron trzecich.Aplikacje stron trzecich nie
                  są kontrolowane przez firmę PrudentCode, która w konsekwencji
                  nie ponosi w tym zakresie odpowiedzialności.
                </p>
              </div>

              <div>
                <h4>2 – Treść strony internetowej</h4>
                <p>2.1. Ogólny opis</p>
                <p>
                  Witryna https://cenniczek.com to serwis internetowy
                  zawierający informacje o sklepach,sieciach handlowych w
                  Polsce, przedstawia ich gazetki promocyjne oraz aktualne
                  promocje,informuje o ich lokalizacjach, godzinach otwarcia,
                  telefonach. Umożliwia organizacje zakupów poprzez tworzenie
                  listy zakupowej oraz wysłanie jej na adres mailowy.
                </p>
                <p>
                  2.2. Dostęp do Serwisu, jego funkcjonalności oraz warunki ich
                  dotyczące
                </p>
                <p>
                  Samo odwiedzanie i przegladanie Witryny jest nieograniczone i
                  bezpłatne. Jednak dostęp do niektórych funkcji opisanych w
                  artykule 3 jest możliwy tylko po uprzedniej rejestracji jako
                  „Członek” jest to możliwe poprzez logowanie za pomocą serwisów
                  autoryzujących takich jak Google,Facebook. Rejestracja
                  "Członka" online na Stronie nastepuje automatycznie po
                  pierwszej autoryzacji ze strony dostawcy usługi.
                  Funkcjonalności takie jak wysłanie listy zakupowej drogą
                  emailową są bezpłatne. PrudentCode zastrzega sobie prawo do
                  zaprzestania oferowania bezpłatnej usługi i rozpoczęcia
                  oferowania jej odpłatnie, lub dodania nowych odpłatnych
                  funkcjonalności. W takim przypadku Członkowie zostaną
                  powiadomieni za pośrednictwem Witryny i poczty elektronicznej
                  oraz będą mieli możliwość wyboru kontynuacji lub
                  niekorzystania z danej usługi. Usługi z zastrzeżonym dostępem
                  Subskrypcja jako „Subskrynent” jest bezpłatna i bedzie
                  wykorzystywana w celach email marketingu(opracowanie
                  propozycji aktualnych atrakcyjnych promocji, wydarzenia,
                  sezonowe wyprzedaże).
                </p>
              </div>
              <div>
                <h4>3 – Umowa elektroniczna</h4>
                <p>3.1. Przekazywanie istotnych informacji</p>
                <p>
                  Podstawowe informacje związane z Usługą są przekazywane
                  Użytkownikowi,Członkowi poprzez Warunki znajdującje się w
                  serwisie pod adresem:https://cenniczek.com/regulamin,
                  korzystanie serwisu oznacza ich akceptacje
                </p>
                <p>3.2. Dowód elektroniczny</p>
                <p>
                  W przypadku sporu strony zgadzają się, że jakiekolwiek
                  powielanie przez PrudentCode danych przechowywanych na jej
                  serwerach dotyczących korzystania z Witryny, np. dane z
                  geolokalizacj Członka będzie miało pierwszeństwo pomiędzy
                  stronami.
                </p>
              </div>
              <div>
                <h4>4 – Status Członka,Subskrynenta.</h4>
                <p>4.1. Subskrypcja</p>
                <p>
                  Subskrypcja "„Subskrynent” jest zarezerwowana dla osób
                  prawnych i fizycznych w wieku co najmniej 18 lat,
                  posiadających pełną zdolność do czynności prawnych.
                  PrudentCode zastrzega sobie prawo do zażądania w dowolnym
                  momencie dowodu potwierdzającego zdolności prawne Członka lub
                  Użytkownika, który chce zapisać się jako Członek lub
                  Subskrynent. Po przesłaniu formularza subskrypcji w Serwisie,
                  Użytkownik otrzymuje na wskazany w formularzu adres e-mail
                  potwierdzenie z linkiem którego należy kliknąć w celu
                  potwierdzenia subskrybji ( link jest ważny 24h po rejestracji
                  w formularzu). Subskrypcja zostaje potwierdzona po otrzymaniu
                  przez Użytkownika wiadomości e-mail wysłanej przez
                  PrudentCode. Rejestracja jako Członek,Subskrynent prowadzi do
                  zarejestrowania niektórych danych Użytkownika w bazie danych
                  PrudentCode. W stosownych przypadkach wszystkie dane zebrane
                  podczas rejestracji są przetwarzane zgodnie z ustawą z dnia 8
                  grudnia 1992 r. o ochronie prywatności w związku z
                  przetwarzaniem danych osobowych , zgodnie z opisem w Polityce
                  prywatności. Członek,Subskrynent będący osobą fizyczną może w
                  każdej chwili zażądać anulowania rejestracji jako
                  Członek,Subskrynent. Jego dane osobowe i konto użytkownika
                  zostaną wtedy usunięte w odpowiednim czasie.
                </p>
                <p>4.2. Prawa i obowiązki Członka</p>
                <p>
                  Członek zobowiązuje się przestrzegać wszystkich wskazówek
                  postępowania i wymagań przedstawionych w Witrynie, a także
                  wszelkich uzasadnionych próśb lub instrukcji przekazanych
                  przez PrudentCode w odniesieniu do Witryny lub Usługi. Członek
                  musi zapewnić, że wszystkie podane informacje są prawidłowe i
                  aktualne. Członek jest odpowiedzialny za korzystanie i dostęp
                  do swojego konta. W związku z tym Członek musi zapewnić, że
                  żadna inna osoba nie ma dostępu do Witryny za pomocą swojego
                  hasła. W przypadku, gdy Członek ma wiedzę o jakiejkolwiek
                  osobie uzyskującej dostęp do Witryny za pomocą swojego hasła,
                  musi o tym niezwłocznie poinformować PrudentCode pocztą
                  elektroniczną na następujący adres: kontak@prudentcode.pl,
                  kontakt@cenniczek.com. Członek ponosi wyłączną i całkowitą
                  odpowiedzialność za wszelkie podejmowane działania,miejsca, w
                  którym korzysta ze swojego konta, a PrudentCode nie może
                  ponosić odpowiedzialności za jakiekolwiek straty lub szkody
                  poniesione przez Członka z powodu przywłaszczenia hasła przez
                  inne osoby, nawet bez wiedzy Członka.
                </p>
              </div>
              <div>
                <h4>
                  5. Kontrahent- treści umieszczane online przez Kontrahent.
                </h4>
                <p>
                  Kontrahent może przesyłać treści do publikacji w Witrynie
                  (obrazy, tekst lub inne) (zwane dalej „Treścią”). PrudentCode
                  kontroluje przydatność treści przesyłanych do publikacji w
                  Witrynie wyłącznie na podstawie informacji dostarczonych przez
                  Kontrahenta. Ta skrócona kontrola przeprowadzona przez
                  PrudentCode przed publikacją nie przesądza ani nie gwarantuje
                  w żaden sposób, że wszystkie informacje i dane w Witrynie są
                  kompletne, dokładne. Niektóre treści gazetki,loga informcje o
                  sieciach czy sklepach publikowane w witrynie pochodzą ze
                  żródeł ogólnodostepnych. PrudentCode wyraźnie zrzeka się
                  wszelkiej możliwej odpowiedzialności za Treści publikowane w
                  Witrynie pochodzące ze źródeł ogólnodostepnych.
                  Kontrahent,sieć handlowa przyjmuje do wiadomości, że ponosi
                  wyłączną odpowiedzialność za Treści, które są przez niego
                  umieszczane w Internecie i wszelkie konsekwencje wynikające z
                  ich komunikacji. Kontrahent oświadcza i gwarantuje, że
                  dysponuje wszystkimi niezbędnymi uprawnieniami do umieszczenia
                  Treści w Internecie i do jej komunikacji. Kontrahent
                  zobowiązuje się nie komunikować, przesyłać, nie wyświetlać
                  żadnych Treści, do których strony trzecie mogą mieć prawa (w
                  tym prawa do prywatności lub prawa do reklamy, w szczególności
                  w odniesieniu do zdjęć), chyba że uzyskano wyraźną, uprzednią
                  zgodę tych osób trzecich.Kontrahenci zwolniaja PrudentCode z
                  odpowiedzialności za jakiekolwiek działania lub skargi osób
                  trzecich (w tym organów publicznych) w związku z Treścią.
                  PrudentCode może, według własnego uznania, kontrolować Treść
                  po publikacji. Zastrzega sobie prawo do usunięcia wszelkich
                  Treści naruszających Regulamin lub na które otrzymał
                  reklamację. PrudentCode może skorzystać z tego prawa w
                  dowolnym momencie, bez uprzedzenia i według własnego uznania.
                  PrudentCode zastrzega sobie prawo do anulowania lub
                  zawieszenia statusu Kontrahenta w dowolnym momencie, gdy pewne
                  elementy mogą prowadzić do wniosku, że obowiązujące prawo,
                  niniejsze Warunki lub prawa osób trzecich są naruszone lub że
                  status danego Kontrahenta szkodzi lub może szkodzić interesom
                  PrudentCode lub innych Kontrahentów. PrudentCode zastrzega
                  sobie prawo do odrzucenia, usunięcia lub modyfikacji wszelkich
                  najlepszych propozycji gazetek, dokumentów lub ofert według
                  własnego uznania i bez powiadomienia. W takim przypadku
                  Kontrahent zostanie o tym poinformowany drogą mailową na adres
                  e-mail wskazany w formularzu zapisu. PrudentCode dąży do
                  opublikowania przesłanych treści w ciągu 48 godzin. Jednakże
                  PrudentCode nie ponosi żadnej odpowiedzialności za
                  jakiekolwiek szkody lub utratę zysku poniesione przez
                  Kontrahent w wyniku publikacji po tym okresie.
                </p>
              </div>
              <div>
                <h4>6 – Prawa własności intelektualnej</h4>
                <p>
                  6.1. Witryna i wszystkie jej elementy (znaki, logo, grafiki,
                  zdjęcia, animacje, filmy, muzyka, tekst itp.) są własnością
                  PrudentCode lub jej Kontrahentów. Są one chronione prawami
                  własności intelektualnej (w szczególności prawami autorskimi i
                  prawami pokrewnymi, prawami do znaków towarowych itp.) i nie
                  mogą być powielane, wykorzystywane ani przekazywane bez
                  uprzedniej pisemnej zgody PrudentCode lub posiadacza praw, w
                  przeciwnym razie takie działania stanowią naruszenie prawa
                  autorskie i/lub projekty i/lub znaki towarowe, zagrożone karą
                  pozbawienia wolności i grzywną, bez uszczerbku dla szkód
                  cywilnych.{" "}
                </p>
                <p>
                  6.2. PrudentCode niniejszym udziela Użytkownikowi i Członkowi
                  niewyłącznej, nieprzenoszalnej licencji na czas nieokreślony i
                  możliwej do odwołania w każdym czasie bez podania przyczyny,
                  na dostęp do zawartości Witryny, wyświetlanie i pobieranie
                  takich treści wyłącznie w celu wyświetlania . Użytkownik i
                  Członek mogą również wydrukować kopię wyświetlanych treści w
                  Serwisie do użytku osobistego, pod warunkiem, że nie zmieniają
                  w żaden sposób treści Serwisu i zachowują wszelkie zasługi dla
                  twórcy i pochodzenia Serwisu . Użytkownik i Członek
                  zobowiązują się nie obchodzić technicznych zabezpieczeń
                  dokumentów i elementów multimedialnych. Powielanie nie jest
                  dozwolone, chyba że do celów ściśle osobistych. Po obejrzeniu
                  zawartości Witryny lub cofnięciu niniejszego upoważnienia,
                  Użytkownik i Członek muszą zniszczyć wszelkie posiadane
                  pobrane materiały, zarówno w formie elektronicznej, jak i
                  drukowanej.
                </p>
                <p>
                  6.3. Użytkownik i Członek zobowiązują się w szczególności do
                  nie: – modyfikować lub kopiować materiałów znajdujących się na
                  Stronie, z wyjątkiem warunków określonych w Artykule 6.2; –
                  używać tego materiału do jakichkolwiek celów komercyjnych lub
                  jakiejkolwiek części jakiegokolwiek publicznego pokazu
                  (niezależnie od tego, czy ma on charakter komercyjny); –
                  podejmować prób dekompilacji lub inżynierii wstecznej
                  oprogramowania zawartego w Witrynie; – usunąć z dokumentacji
                  wszelkich wzmianek o prawach autorskich lub wyłącznych prawach
                  własności; – przenieść materiały na stronę trzecią lub
                  powielić je na innym serwerze.
                </p>
                <p>
                  6.4. Każde naruszenie któregokolwiek z powyższych ograniczeń
                  spowoduje natychmiastowe wygaśnięcie niniejszego upoważnienia,
                  które PrudentCode zastrzega sobie do wypowiedzenia w dowolnym
                  momencie.
                </p>
                <p>
                  6.5. Witryna może być podłączona wyłącznie za pomocą
                  hiperłączy do strony głównej pod adresem URL
                  https://cenniczek.com. Wszelkie hiperłącza do innych
                  wewnętrznych stron Witryny są zabronione, o ile PrudentCode
                  nie udzieli na to wyraźnej, uprzedniej zgody.
                </p>
                <p>
                  6.6. Jakiekolwiek inne korzystanie z Witryny i jej elementów,
                  nieopisane wyraźnie w tym artykule, jest surowo zabronione.
                </p>
              </div>
              <div>
                <h4>7 – Odpowiedzialność związana z korzystaniem z Serwisu</h4>
                <p>7.1. Dostępność i funkcjonowanie Serwisu</p>
                <p>
                  PrudentCode zapewnia, w miarę możliwości, że Witryna
                  pozostanie dostępna dla normalnej liczby Użytkowników i
                  Członków. PrudentCode nie może zagwarantować normalnego
                  funkcjonowania Witryny przez cały czas bez przerw lub błędów,
                  ani też natychmiastowego usunięcia wszelkich wad ani też, że
                  Witryna i serwer ją hostujący są całkowicie wolne od wirusów
                  lub innych szkodliwych elementów. PrudentCode zastrzega sobie
                  prawo do zawieszenia lub zatrzymania całości lub części
                  Witryny w dowolnym momencie bez wcześniejszego powiadomienia.
                  PrudentCode nie może ponosić odpowiedzialności za jakiekolwiek
                  straty i/lub szkody wynikające z zawieszenia, przerwania,
                  zakłóceń (technicznych), opóźnień, utrudnionego dostępu i/lub
                  zablokowania dostępu do całości lub części Witryny.
                  Użytkownicy lub Członkowie, którzy zauważą obecność wirusów
                  lub innego złośliwego oprogramowania w Witrynie, proszeni są o
                  powiadomienie o tym PrudentCode na adres:
                  kontakt@prudentcode.pl, kontakt@cenniczek.com w celu
                  umożliwienia PrudentCode podjęcia odpowiednich działań.
                  PrudentCode doradza Użytkownikom i Członkom, aby instalowali
                  odpowiednie zapory ogniowe, programy antywirusowe i inne
                  oprogramowanie na swoich urządzeniach końcowych, aby chronić
                  je przed wszelkimi uszkodzeniami.{" "}
                </p>
                <p>
                  7.2. Treści i korzystanie z Serwisu Użytkownicy i Członkowie
                  korzystają z Serwisu na własne ryzyko. W granicach
                  obowiązującego prawa Serwis, jego elementy oraz wszelkie
                  informacje, oprogramowanie, instalacje i usługi dotyczące
                  Serwisu są dostarczane jako dostępne bez jakichkolwiek
                  gwarancji (nie wyraźnych ani dorozumianych) oraz w granicach
                  określonych przez prawo . Są między innymi wykluczone,
                  dorozumiane gwarancje lub warunki przydatności handlowej,
                  przydatności do określonego celu, nienaruszania praw własności
                  intelektualnej lub nienaruszania innych praw. Ponadto
                  PrudentCode nie udziela żadnych gwarancji i nie składa żadnych
                  oświadczeń dotyczących dokładności, prawdopodobnych wyników
                  lub wiarygodności korzystania z treści witryny internetowej
                  lub jakiegokolwiek jej elementu w jakikolwiek sposób lub
                  informacji zawartych na innych witrynach internetowych, do
                  których odnosi się witryna internetowa PrudentCode poprzez
                  hiperłącza. W żadnym przypadku firma PrudentCode ani jej
                  partnerzy nie ponoszą odpowiedzialności za jakiekolwiek straty
                  lub szkody (bezpośrednie, pośrednie, materialne lub
                  niematerialne, w tym – bez ograniczeń – szkody nieodłącznie
                  związane z utratą danych lub zysków, lub powodujące stratę
                  operacyjną ) wynikających z korzystania lub niemożności
                  korzystania z Witryny lub jej zawartości, nawet jeśli firma
                  PrudentCode lub jeden z jej należycie upoważnionych
                  przedstawicieli został ustnie lub pisemnie poinformowany o
                  możliwości wystąpienia takich szkód. Nie można wykluczyć
                  występowania błędów technicznych lub typograficznych w
                  Serwisie. PrudentCode nie gwarantuje dokładności, kompletności
                  ani aktualizacji treści swojej strony internetowej.
                  PrudentCode zastrzega sobie prawo do modyfikowania zawartości
                  Witryny w dowolnym czasie i bez uprzedzenia. Jednak
                  PrudentCode nie zobowiązuje się do aktualizacji jego treści.
                </p>
                <p>
                  7.3 Linki do innych witryn w Witrynie mogą być umieszczone w
                  postaci hiperłącza do innych witryn. Niektóre witryny
                  internetowe mogą zawierać łącze do Witryny. Strony internetowe
                  osób trzecich nie są kontrolowane przez
                  PrudentCode,PrudentCode nie ponosi żadnej odpowiedzialności za
                  ich funkcjonowanie, zawartość lub wykorzystanie. O ile w
                  Witrynie internetowej nie zaznaczono poparcia w imieniu
                  PrudentCode tych witryn internetowych ani ich wykorzystania,
                  ani też te linki nie oznaczają powiązania lub partnerstwa z
                  właścicielami takich witryn internetowych. Użytkownik i
                  Członek ponoszą pełną i całkowitą odpowiedzialność za
                  przeglądanie takich stron internetowych związanych z Serwisem.
                </p>
              </div>
              <div>
                <h4>8 – Ważność postanowień umownych</h4>
                <p>
                  8.1. Niewykorzystanie jakiegokolwiek postanowienia niniejszych
                  Warunków przez PrudentCode nie może być interpretowane jako
                  dające w przyszłości wykorzystanie praw opisanych w takim
                  postanowieniu.
                </p>
                <p>
                  8.2. Nieważność lub niewykonalność całości lub części
                  postanowień niniejszych Warunków nie wpłynie na ważność lub
                  wykonalność jakiegokolwiek innego postanowienia niniejszych
                  Warunków. W przypadku częściowej lub całkowitej nieważności
                  lub niewykonalności postanowienia uważa się, że jest ono
                  niepisane. PrudentCode wyraża zgodę na zastąpienie takiego
                  postanowienia innym postanowieniem o takim samym skutku w
                  możliwym zakresie.
                </p>
              </div>
              <div>
                <h4>9 – Prawo właściwe i sąd właściwy</h4>
                <p>
                  9.1. Wszelkie roszczenia związane z Serwisem będą składane i
                  interpretowane zgodnie z prawem obowiązującym w Polsce, bez
                  względu na przepisy kolizyjne.
                </p>
                <p>
                  9.2. Sądy w Polsce są wyłącznie właściwe do rozstrzygania
                  wszelkich sporów dotyczących niniejszych Warunków.
                </p>
              </div>
            </ErrorBoundary>
          </MainContent>
        </MainWrapperLayer>
      </WrraperSection>
    </>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
});
export const Terms = connect(mapStateToProps, {})(_Terms);
