import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { Theme } from "../../../store/theme/theme";
import { DisplaySelect } from "./displaySelect/DisplaySelect";
import { CaruselCategory } from "./carusel/CaruselCategory";
import { CaruselBrand } from "./carusel/CaruselBrand";
import { CaruselNeighbor } from "./carusel/CaruselNeighbor";
import { ErrorBoundary } from "../../errorBoundary/ErrorBoundary";
import { useLocation } from "react-router-dom";
// import FetchingData from '../../header/FetchingData'

const SelectWrapper = styled.section`
  width: 100%;
  min-height: 200px;
  // background: ${Theme.light.fourthColor.red};
  // background-image: linear-gradient(
  //   150deg,
  //   #ff512f 30%,
  //   ${Theme.light.fourthColor.red} 70%
  // );
  // background-image: linear-gradient(
  //   150deg,
  //   #7b4397 30%,
  //   ${Theme.light.fourthColor.red} 70%
  // );
  background-image: linear-gradient(
    150deg,
    #ff512f 0%,
    ${Theme.light.fourthColor.red} 70%
  );
  position: relative;
  // top:0;
  // left:0;
  // margin-top:70px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media${Theme.device.tablet} {
    min-height: 170px;
  }
  @media${Theme.device.mobileL} {
    position: sticky;
    top: 0;
    z-index: 4;
    min-height: 170px;
  }
`;
const ContentWrapper = styled.div`
  // ----------
  max-width: ${Theme.breakPoint.desktop}px;
  // width:100%;
  margin: 0 auto;
  min-height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  @media${Theme.device.tablet} {
    width: 100%;
  }
`;
const ItemsListWrapper = styled.div`
  height: 140px;
  width: 100%;
  background: ${Theme.light.thirdColor.dark};
  @media${Theme.device.desktop} {
    height: 185px;
  }
  @media${Theme.device.tablet} {
    height: 100px;
  }
`;
const _SelectViev = ({
  select,
  localization,
  urlChecked,
  setUrlChecked,
  selectViewData,
}) => {
  const path = useLocation();
  // console.log(selectViewData);
  // const categoryData = useMemo(() => {
  //   if (selectViewData?.allCategories) {
  //     return selectViewData?.allCategories;
  //   }
  // }, [selectViewData?.allCategories?.length]);
  // const brandData = useMemo(
  //   () => selectViewData?.allBrands,
  //   [selectViewData?.allBrands?.length]
  // );

  useEffect(() => {
    if (path?.pathname === "/" || path?.pathname === "/gazetki-promocyjne") {
      setUrlChecked(true);
    }
  }, [path?.pathname]);

  return (
    <>
      <SelectWrapper>
        <ErrorBoundary>
          <ContentWrapper>
            <DisplaySelect urlChecked={urlChecked} />
          </ContentWrapper>
          <ItemsListWrapper>
            {select?.options[0]?.active && (
              <CaruselCategory
                urlChecked={urlChecked}
                data={selectViewData?.allCategories}
                // data={categoryData}
              />
            )}
            {select?.options[1]?.active && (
              <CaruselBrand
                urlChecked={urlChecked}
                data={selectViewData?.allBrands}
                // data={brandData}
              />
            )}
            {select?.options[2]?.active && (
              <CaruselNeighbor data={selectViewData?.allBrands} />
              // <CaruselNeighbor data={brandData} />
            )}
          </ItemsListWrapper>
        </ErrorBoundary>
      </SelectWrapper>
    </>
  );
};
const mapStateToProps = (state) => ({
  select: state.select,
  localization: state.localization,
});

export const SelectViev = connect(mapStateToProps, {})(_SelectViev);
