import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  selectId,
  chose,
  clearTopDeals,
} from "../../store/select/duck/actions/actions";
import { deleteShopsNearUser } from "../../store/localization/duck/actions/actions";
const _ItemsFromData = ({
  brandData,
  categoryData,
  type,
  selectId,
  chose,
  length,
  deleteShopsNearUser,
  clearTopDeals,
}) => {
  if (type === "sklepy") {
    return brandData?.map((item) => {
      return (
        <li
          key={item.id}
          onClick={() => {
            chose(type);
            selectId(item);
            deleteShopsNearUser();
            clearTopDeals();
            document.documentElement.scrollTop = 0;
            // console.log(item)
          }}
        >
          <Link
            to={`/${item.name.split(" ").join("-")}`}
            title={`aktualne gazetki promocyjne ${item.name}`}
          >
            {item.name}
          </Link>
        </li>
      );
    });
  } else {
    return categoryData?.map((item) => {
      return (
        <li
          key={item.id}
          onClick={() => {
            chose("kategorie");
            selectId(item);
            deleteShopsNearUser();
            clearTopDeals();
            document.documentElement.scrollTop = 0;
          }}
        >
          <Link
            to={`/${item.name.split(" ").join("-")}`}
            title={`aktualne ${item.name}`}
          >
            {item.name}
          </Link>
        </li>
      );
    });
  }
};

const mapDispatchToProps = (dispatch) => ({
  selectId: (item) => dispatch(selectId(item)),
  chose: (item) => dispatch(chose(item)),
  deleteShopsNearUser: (item) => dispatch(deleteShopsNearUser(item)),
  clearTopDeals: (item) => dispatch(clearTopDeals(item)),
});
const mapStateToProps = (state) => ({
  select: state.select,
});

export const ItemsFromData = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ItemsFromData);
