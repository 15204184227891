import React, { useEffect, useState } from "react";
import { Spinner } from "../../tools/spiner/Spinner";
import { SingleDeal } from "../singleDeal/SingleDeal";
import { sortBySelected } from "./loadDeals.helper";

export const LoadDealsTopDealsPage = ({
  dealsForTopDeals,
  dataFromTopDealsPage,
  topDealsSelected,
  activeSortType,
  setNumberOfDeals,
  setDealsNumber,
  theme,
}) => {
  const [result, setResult] = useState(false);
  const [cleaning, setCleaning] = useState(false);
  const [loading, setLoading] = useState(false);
  const numberOfTopDeals = theme?.isMobile ? 15 : 35;
  // console.log(dealsForTopDeals, result);
  // if (
  //   !result &&
  //   !cleaning &&
  //   dealsForTopDeals?.loading === false &&
  //   dealsForTopDeals?.data?.dealsTopDealsPage
  // ) {
  //   setResult(dealsForTopDeals?.data?.dealsTopDealsPage);
  // }
  useEffect(() => {
    const arr = [
      { name: "dealsForTopDeals", loading: dealsForTopDeals?.loading },
    ];
    setLoading(arr.findIndex((item) => item.loading === true) !== -1);
  }, [dealsForTopDeals?.loading]);
  useEffect(() => {
    setCleaning(true);
    setResult(false);
  }, [topDealsSelected]);
  useEffect(() => {
    if (cleaning) {
      setNumberOfDeals(0);
      setDealsNumber({
        offset: 0,
        limit: numberOfTopDeals,
      });
      setResult([]);
    } else {
      setNumberOfDeals(result?.length);
    }
  }, [result?.length, cleaning, topDealsSelected]);

  useEffect(() => {
    if (cleaning && dealsForTopDeals?.loading === false) {
      // console.log("czyszcze2");
      setCleaning(false);
    }
    if (!cleaning && dealsForTopDeals?.loading === false) {
      if (dealsForTopDeals?.data?.dealsTopDealsPage) {
        topDealsSelected &&
          dealsForTopDeals?.data?.dealsTopDealsPage &&
          setResult(
            dealsForTopDeals?.data?.dealsTopDealsPage.filter(
              (item) => item.categoryKeyProductName === topDealsSelected
            )
          );

        if (
          dealsForTopDeals?.data?.dealsTopDealsPage?.length &&
          !topDealsSelected
        ) {
          const res = result?.length ? [...result] : [];
          const helpArr = dealsForTopDeals?.data?.dealsTopDealsPage;
          helpArr.forEach((newDeal) => {
            if (res.findIndex((oldItem) => oldItem.id === newDeal.id) === -1) {
              res.push(newDeal);
            }
          });

          setResult(res);
        }
      }
      // if (
      //   result.length !== dealsForTopDeals?.data?.dealsTopDealsPage?.length &&
      //   dealsForTopDeals?.data?.dealsTopDealsPage?.length &&
      //   !topDealsSelected
      // ) {
      //   const helpArr = dealsForTopDeals?.data?.dealsTopDealsPage.filter(
      //     (item) => item.categoryKeyProductName === topDealsSelected
      //   );
      //   const res = result?.length ? [...result] : [];
      //   helpArr.forEach((newDeal) => {
      //     if (res.findIndex((oldItem) => oldItem.id === newDeal.id) === -1) {
      //       res.push(newDeal);
      //     }
      //   });

      //   setResult(res);
      // }
      if (
        result.length !== dealsForTopDeals?.data?.dealsTopDealsPage?.length &&
        dealsForTopDeals?.data?.dealsTopDealsPage?.length &&
        topDealsSelected
      ) {
        setResult(dealsForTopDeals?.data?.dealsTopDealsPage);
      }
    }
  }, [
    topDealsSelected,
    dealsForTopDeals?.data?.dealsTopDealsPage?.length,
    dealsForTopDeals?.previousData?.dealsTopDealsPage?.length,
    result?.length,
    activeSortType,
    loading,
    cleaning,
    dealsForTopDeals?.loading,
  ]);

  useEffect(() => {
    return () => {
      setResult(null);
    };
  }, []);

  if ((loading && result === false) || (loading && cleaning)) {
    return <Spinner />;
  }

  if (!loading && result?.length === 0) {
    return <p>nie znaleźliśmy promocji</p>;
  }
  if ((result !== false) & !cleaning) {
    const filteredBySelected = sortBySelected(result, activeSortType);
    return (
      <>
        {filteredBySelected.map((item, index) => {
          return <SingleDeal item={item} key={item.id} />;
        })}
        {loading && (
          <div style={{ position: "relative", width: "100%", height: "25vh" }}>
            <Spinner />
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};
