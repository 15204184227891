import React, { useState, useEffect, useRef } from "react";
import { Theme } from "../../../../../store/theme/theme";
import { Spinner } from "../../../../tools/spiner/Spinner";
import { SingleItemCausel } from "./SingleItemCarusel";
import { SingleItemCaruselMobile } from "./SingleItemCaruselMobile";
// import { ReactComponent as BackArrow } from "../../../../../media/svg/chevron-left-solid.svg";
// import { ReactComponent as NextArrow } from "../../../../../media/svg/chevron-right-solid.svg";

import {
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  CaruselWrapper,
  caruselWraperVariants,
  WraperMobile,
} from "../../../style";

const backPath =
  "M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z";
const nextPath =
  "M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z";
const resizeScreen = (btnVisible, setBtnVisible, percent, width, maxWidth) => {
  const getNumberVisibleSLides = (percent, width, innerWidth, maxWidth) => {
    if (innerWidth > maxWidth) {
      return (maxWidth * percent) / width;
    }
    return (innerWidth * percent) / width;
  };
  window.addEventListener("resize", () => {
    // console.log('resize')
    const docWidth =
      document.documentElement.clientWidth || document.body.clientWidth;
    const nrSlides = getNumberVisibleSLides(percent, width, docWidth, maxWidth);
    if (window.innerWidth > Theme.breakPoint.desktop) {
      // console.log('wieksze niz desktop')
      setBtnVisible({ visible: true, slidesNr: nrSlides });
    } else if (window.innerWidth > Theme.breakPoint.tablet) {
      // console.log('wieksze niz tablet')
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    } else if (window.innerWidth > Theme.breakPoint.mobileL) {
      // console.log('wieksze niz mobileL')
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    } else if (window.innerWidth > Theme.breakPoint.mobileM) {
      // console.log('wieksze niz mobileM')
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    } else {
      // console.log('wieksze niz')
      setBtnVisible({ visible: false, slidesNr: nrSlides });
    }
  });
};

const onLoadScreen2 = (btnVisible, setBtnVisible, percent, width, maxWidth) => {
  const getNumberVisibleSLides = (percent, width, innerWidth, maxWidth) => {
    if (innerWidth > maxWidth) {
      return (maxWidth * percent) / width;
    }
    return (innerWidth * percent) / width;
  };
  const docWidth =
    document.documentElement.clientWidth || document.body.clientWidth;
  const nrSlides = getNumberVisibleSLides(percent, width, docWidth, maxWidth);
  if (window.innerWidth > Theme.breakPoint.desktop) {
    // console.log('wieksze niz desktop')
    setBtnVisible({ visible: true, slidesNr: nrSlides });
  } else if (window.innerWidth > Theme.breakPoint.tablet) {
    // console.log('wieksze niz tablet')
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  } else if (window.innerWidth > Theme.breakPoint.mobileL) {
    // console.log('wieksze niz mobileL')
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  } else if (window.innerWidth > Theme.breakPoint.mobileM) {
    // console.log('wieksze niz mobileM')
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  } else {
    // console.log('wieksze niz')
    setBtnVisible({ visible: false, slidesNr: nrSlides });
  }
};

export const CaruselShopingList = ({
  day,
  isDark,
  setActiveItemIndexFromCarusel,
  activeItemIndexFromCarusel,
  indexCarusel,
  setActiveIndexFromSlide,
}) => {
  const [activeItemIndex, setActiveItemIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [tap, setTap] = useState(false);
  // const { name } = useParams();
  const mobileWrapRef = useRef();

  const sliderStyle = {
    maxWidth: `${Theme.breakPoint.desktop}px`,
    height: "250px",
    textAlign: "center",
    color: `${Theme.light.primaryColor.white}`,
  };

  const buttonBackStyle = {
    position: "absolute",
    top: "50%",
    left: "0",
    transform: "translate(-45px,-50%)",
    width: "30px",
    height: "40px",
    backgroundColor: "transparent",
    border: "none",
  };
  const buttonNextStyle = {
    position: "absolute",
    top: "50%",
    right: "0",
    transform: "translate(45px,-50%)",
    width: "30px",
    height: "40px",
    backgroundColor: "transparent",
    border: "none",
  };
  const btnStyle = {
    width: "25px",
  };
  const [btnVisible, setBtnVisible] = useState({ visible: true, slidesNr: 6 });
  useEffect(() => {
    return () => {
      day = "";
    };
  });
  useEffect(() => {
    if (day?.result?.item?.list) {
      const data = day?.result?.item?.list;
      data.push({});
    }
  }, []);
  // useEffect(() => {
  //   if (isMobile && name && data) {
  //     const foundedBrand = data.findIndex(
  //       (item) => item.name === name.split("-").join(" ")
  //     );

  //     if (foundedBrand && mobileWrapRef) {
  //       mobileWrapRef.current.scrollLeft = 220 * foundedBrand;
  //     }
  //   }
  // }, [name, data, isMobile]);
  useEffect(() => {
    if (tap) {
      setTimeout(() => {
        if (tap) {
          setTap(false);
        }
      }, 1000);
    }
  }, [tap]);
  useEffect(() => {
    if (window.innerWidth < Theme.breakPoint.tablet) {
      setIsMobile(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < Theme.breakPoint.tablet) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
  }, []);
  const showBrands = () => {
    if (day) {
      const sortedData = day?.result?.item?.list;
      // .sort((a, b) => {
      //   const nameA = a.brand;
      //   const nameB = b.brand;
      //   return nameA < nameB ? -1 : 1;
      // });
      if (sortedData) {
        return sortedData.map((item, index) => (
          <SingleItemCausel
            key={index}
            item={item}
            index={index}
            isDark={isDark}
            activeItemIndex={activeItemIndex}
            setActiveItemIndex={setActiveItemIndex}
            indexCarusel={indexCarusel}
            setActiveItemIndexFromCarusel={setActiveItemIndexFromCarusel}
            activeItemIndexFromCarusel={activeItemIndexFromCarusel}
            setActiveIndexFromSlide={setActiveIndexFromSlide}
            lastItem={day?.result?.item?.list.length}
          />
        ));
      }
    }
  };
  const showBrandsMobile = () => {
    if (day) {
      const sortedData = day?.result?.item?.list;
      // .sort((a, b) => {
      //   const nameA = a.brand;
      //   const nameB = b.brand;
      //   return nameA < nameB ? -1 : 1;
      // });
      if (sortedData) {
        return sortedData.map((item, index) => (
          <SingleItemCaruselMobile
            key={index}
            item={item}
            index={index}
            isDark={isDark}
            activeItemIndex={activeItemIndex}
            setActiveItemIndex={setActiveItemIndex}
            indexCarusel={indexCarusel}
            setActiveItemIndexFromCarusel={setActiveItemIndexFromCarusel}
            activeItemIndexFromCarusel={activeItemIndexFromCarusel}
            setActiveIndexFromSlide={setActiveIndexFromSlide}
            lastItem={day?.result?.item?.list.length}
          />
        ));
      }
    }
  };
  useEffect(() => {
    onLoadScreen2(btnVisible, setBtnVisible, 1, 280, Theme.breakPoint.desktop);
    resizeScreen(btnVisible, setBtnVisible, 1, 280, Theme.breakPoint.desktop);
  }, []);
  return (
    <CaruselWrapper
      variants={caruselWraperVariants}
      initial="hidden"
      animate="visible"
    >
      {!day && <Spinner />}
      {day && isMobile === false && (
        <CarouselProvider
          naturalSlideWidth={270}
          naturalSlideHeight={250}
          totalSlides={day?.result?.item?.list.length}
          visibleSlides={btnVisible.slidesNr}
          orientation={"horizontal"}
          isIntrinsicHeight={true}
          step={btnVisible.slidesNr}
          dragStep={1}
        >
          <Slider style={sliderStyle}>{showBrands()} </Slider>
          <ButtonBack
            style={btnVisible.visible ? buttonBackStyle : { display: "none" }}
          >
            {/* <BackArrow style={btnStyle} /> */}
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-left"
              className="svg-inline--fa fa-chevron-left fa-w-10"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              style={btnStyle}
            >
              <path fill="currentColor" d={backPath}></path>
            </svg>
          </ButtonBack>
          <ButtonNext
            style={btnVisible.visible ? buttonNextStyle : { display: "none" }}
          >
            {/* <NextArrow style={btnStyle} /> */}
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-right"
              className="svg-inline--fa fa-chevron-right fa-w-10"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              style={btnStyle}
            >
              <path fill="currentColor" d={nextPath}></path>
            </svg>
          </ButtonNext>
          {window.innerWidth < Theme.breakPoint.desktop ? (
            <DotGroup
              className="caruselDots"
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                padding: "10px",
              }}
            >
              <div style={{ width: "10px", margin: "2px" }}></div>
            </DotGroup>
          ) : null}
        </CarouselProvider>
      )}
      {day && isMobile === true && (
        <>
          <WraperMobile
            ref={mobileWrapRef}
            tap={tap}
            isDark={isDark}
            onScroll={() => {
              if (!tap) setTap(true);
              //
            }}
          >
            {showBrandsMobile()}
          </WraperMobile>
        </>
      )}
    </CaruselWrapper>
  );
};
