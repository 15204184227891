import React from "react";
import { WithStore } from "pure-react-carousel";
class _PaginationSelect extends React.Component {
  componentDidMount() {
    this.props.setPagePagination(this.props.currentSlide + 1);
  }
  componentDidUpdate(prevProps) {
    const currentSelect = this.props.currentSlide;
    const prevSelect = prevProps.currentSlide;
    if (currentSelect !== prevSelect) {
      this.props.setPagePagination(this.props.currentSlide + 1);
    }
  }
  render() {
    return null;
  }
}

export const PaginationSelect = WithStore(_PaginationSelect, (state) => ({
  currentSlide: state.currentSlide,
}));
