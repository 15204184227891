import React, { useState, useEffect } from "react";
export const PageReloaded = () => {
  const [reloaded, setReloaded] = useState(false);
  useEffect(() => {
    if (
      (window.performance.navigation &&
        window.performance.navigation.type === 1) ||
      window.performance
        .getEntriesByType("navigation")
        .map((nav) => nav.type)
        .includes("reload")
    ) {
      setReloaded(true);
    }
  });
  return reloaded;
};
