import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from "chart.js";
import styled from "styled-components";
import { Bar } from "react-chartjs-2";
import { Theme } from "../../../../store/theme/theme";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const squareVariants = {
  visible: { opacity: 1, scale: 1, transition: { duration: 1.6 } },
  hidden: { opacity: 0, scale: 0.7 },
};
const WraperForMainBrand = styled(motion.div)`
  width: 49%;
  // min-height: 660px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  border-radius: 5px;
  position: relative;
  @media${Theme.device.tablet} {
    min-height: 200px;
    margin: 0;
  }
  @media${Theme.device.mobileL} {
    width: 100%;
    min-height: 185px;
    margin: 5px 0;
  }
`;
export const MainBrandBar = ({ days, isDark, isMobileL }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [showBars, setShowBars] = useState(false);
  useEffect(() => {
    if (inView) {
      controls.start("visible");
      isMobileL
        ? setTimeout(() => {
            setShowBars(true);
          }, 700)
        : setShowBars(true);
    }
  }, [controls, inView]);
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    radius: 5,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      labels: {
        render: "percentage",
      },
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItem, data) {
            return tooltipItem[0].label;
          },
          label: function (tooltipItem, data) {
            const index = tooltipItem.dataIndex;
            const dataFromAll = tooltipItem.dataset.data.map((item) =>
              item ? parseFloat(item) : 0
            );
            const total = dataFromAll.reduce((all, item) => all + item, 0);

            const percentage = parseFloat(
              ((tooltipItem.dataset.data[index] / total) * 100).toFixed(1)
            );

            return `${tooltipItem.dataset.data[index]} PLN - ${percentage}% `;
          },
        },
      },
    },
    datasetFill: false,
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return value + " PLN";
          },
        },
      },
    },
    tooltips: {
      enabled: false,
    },
  };
  //   const labels = day.result.item.list
  //     .map((item, index, self) => {
  //       return self.findIndex((selfItem) => selfItem.brand === item.brand) !==
  //         index
  //         ? null
  //         : item.brand;
  //     })
  //     .filter((item) => item);
  const shopingDays = days.time.shoping.filter((item) => item.result);
  const shopingBrands = shopingDays.map((shoping) =>
    shoping.result.item.list
      .map((item, index, self) => {
        if (!item.brand) return "brak";
        return self.findIndex((selfItem) => selfItem.brand === item.brand) !==
          index
          ? null
          : item.brand;
      })
      .filter((item) => item)
  );
  const singleShopingBrands = [];
  shopingBrands.map((item) => {
    item.map((brand) => {
      singleShopingBrands.push(brand);
    });
  });
  const labels = singleShopingBrands
    .map((item, index, self) => {
      return self.findIndex((selfItem) => selfItem === item) !== index
        ? null
        : item;
    })
    .filter((item) => item);
  const itemsFromLabels = labels
    .map((item) => {
      return shopingDays.map((day) => {
        return day.result.item.list.filter((singleBrand) => {
          if (item === "brak") {
            return singleBrand.brand === "";
          } else {
            return singleBrand.brand === item;
          }
        });
      });
    })
    .map((item) => item.filter((single) => single.length))
    .map((item) => {
      return item
        .map((res) => {
          const resPrice = res.map((item) =>
            parseFloat(
              (
                (parseFloat(item.price) || 0) * (parseFloat(item.howMany) || 0)
              ).toFixed(2)
            )
          );

          return resPrice.reduce((total, item) => total + item, 0);
        })
        .reduce((total, item) => total + item, 0)
        .toFixed(2);
    });
  //   console.log(
  //     days,
  //     shopingDays,
  //     shopingBrands,
  //     singleShopingBrands,
  //     labels,
  //     itemsFromLabels
  //   );
  const dataChart = {
    labels,
    datasets: [
      {
        label: `Sklepy w których kupowałeś z ${days.number} dni`,
        data: itemsFromLabels,
        backgroundColor: [
          "#ea5545",
          "#f46a9b",
          "#ef9b20",
          "#edbf33",
          "#ede15b",
          "#bdcf32",
          "#87bc45",
          "#27aeef",
          "#b33dc6",
          "rgba(0,255,0, 1)",
          "rgba(0,0,255, 1)",
          "rgba(255,0,0, 1)",
          "rgba(255,255,0, 1)",
          "rgba(0,255,255, 1)",
          "rgba(255,0,255, 1)",
          "rgba(128,0,128, 1)",
          "rgba(0,128,0, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 12, 214, 1)",
          "rgba(54, 62, 135, 1)",
          "rgba(25, 16, 86, 1)",
          "rgba(215, 59, 124, 1)",
        ],
        borderColor: [
          "#ea5545",
          "#f46a9b",
          "#ef9b20",
          "#edbf33",
          "#ede15b",
          "#bdcf32",
          "#87bc45",
          "#27aeef",
          "#b33dc6",

          "rgba(0,255,0, 1)",
          "rgba(0,0,255, 1)",
          "rgba(255,0,0, 1)",
          "rgba(255,255,0, 1)",
          "rgba(0,255,255, 1)",
          "rgba(255,0,255, 1)",
          "rgba(128,0,128, 1)",
          "rgba(0,128,0, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 12, 214, 1)",
          "rgba(54, 62, 135, 1)",
          "rgba(25, 16, 86, 1)",
          "rgba(215, 59, 124, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  defaults.fontFamily = ["Montserrat", "Helvetica Neue"];
  defaults.color = isDark
    ? Theme.dark.thirdColor.white
    : Theme.light.thirdColor.dark;
  defaults.borderColor = isDark
    ? Theme.light.fiveColor.white
    : Theme.dark.sixColor.dark;
  defaults.animation.delay = 300;

  return (
    <WraperForMainBrand
      ref={ref}
      animate={isMobileL && controls}
      initial={isMobileL ? "hidden" : ""}
      variants={isMobileL && squareVariants}
    >
      {showBars && isMobileL ? (
        <Bar
          data={dataChart}
          options={options}
          style={{
            background: `${
              isDark
                ? Theme.dark.primaryColor.dark
                : Theme.light.primaryColor.white
            }`,
            padding: "15px",
            borderRadius: "5px",
            // marginTop: "25px",
          }}
        />
      ) : isMobileL === false ? (
        <Bar
          data={dataChart}
          options={options}
          style={{
            background: `${
              isDark
                ? Theme.dark.primaryColor.dark
                : Theme.light.primaryColor.white
            }`,
            padding: "15px",
            borderRadius: "5px",
            // marginTop: "25px",
          }}
        />
      ) : null}
    </WraperForMainBrand>
  );
};
