export const CONSTANT = {
  url: process.env.NODE_ENV === "development" ? "http://localhost:3000/" : null,
};

export const FRONT_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/"
    : "https://cenniczek.com/";

export const FRONT_URL_IMG =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/"
    : "https://cenniczek.com";

export const SERVER_URL_IMG =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/static"
    : "https://cenniczek.com";

export const API_URL =
  process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";

export const GRAPHQL_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : "https://cenniczek.com";

export const BRAND_NAME = [
  "abc",
  "auchan",
  "carrefour",
  "castorama",
  "dino",
  "eurocash",
  "groszek",
  "kaufland",
  "leroy merlin",
  "lidl",
  "makro",
  "max elektro",
  "media expert",
  "media markt",
  "mycenter",
  "netto",
  "obi",
  "odido",
  "polomarket",
  "rossmann",
  "rtv euro agd",
  "selgros",
  "stokrotka",
  "ziko dermo",
];
export const CATEGORY_NAME = [
  "markety",
  "media i agd",
  "dom i ogród",
  "zdrowie i uroda",
  "�wiateczne"
];
