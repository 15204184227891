import React from "react";
import { WithStore } from "pure-react-carousel";
class _ChoseSelectedSlide extends React.Component {
  changeSlide(select, data) {
    if (data) {
      const selectedOption = select.filter((item) => item.active);
      // console.log(selectedOption)
      // console.log(data,'data')
      // console.log(selectedOption[0].valueName)
      if (
        selectedOption &&
        selectedOption.length &&
        selectedOption[0].valueName
      ) {
        const indexSlide = data.findIndex(
          (item) => item.name === selectedOption[0].valueName
        );
        // console.log(indexSlide)
        if (indexSlide !== -1) {
          this.props.carouselStore.setStoreState({ currentSlide: indexSlide });
        }
      }
    }
  }
  componentDidMount() {
    this.changeSlide(this.props.select, this.props.data);
  }
  // componentDidUpdate(prevProps){
  //     const currentSelect=this.props.select.filter(item=>item.active)[0].selectedItemID
  //     const prevSelect=prevProps.select.filter(item=>item.active)[0].selectedItemID
  //     if(currentSelect !== prevSelect){
  //         this.changeSlide(this.props.select,this.props.data)
  //     }

  // }
  render() {
    return null;
  }
}

export const ChoseSelectedSlide = WithStore(_ChoseSelectedSlide, (state) => ({
  currentSlide: state.currentSlide,
}));
