import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { rootReducer } from "./reducers";

export const storeForClient = createStore(
  rootReducer,
  window.__INITIAL_STATE__,
  composeWithDevTools(applyMiddleware(thunk))
);
delete window.__INITIAL_STATE__;
