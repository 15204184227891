import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import { ReactComponent as ImgSuspense } from "../../media/svg/image-regular.svg";
// import { ReactComponent as StartTime } from "../../media/svg/calendar-check-solid.svg";
// import { ReactComponent as EndTime } from "../../media/svg/calendar-times-solid.svg";
import { Theme } from "../../store/theme/theme";
import { motion, AnimatePresence } from "framer-motion";
import { API_URL } from "../../constant/constant";
const imagePath =
  "M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z";
const startPath =
  "M436 160H12c-6.627 0-12-5.373-12-12v-36c0-26.51 21.49-48 48-48h48V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h128V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h48c26.51 0 48 21.49 48 48v36c0 6.627-5.373 12-12 12zM12 192h424c6.627 0 12 5.373 12 12v260c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V204c0-6.627 5.373-12 12-12zm333.296 95.947l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L194.12 364.665l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068l-28.397 28.17c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z";
const endPath =
  "M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm257.3 160l48.1-48.1c4.7-4.7 4.7-12.3 0-17l-28.3-28.3c-4.7-4.7-12.3-4.7-17 0L224 306.7l-48.1-48.1c-4.7-4.7-12.3-4.7-17 0l-28.3 28.3c-4.7 4.7-4.7 12.3 0 17l48.1 48.1-48.1 48.1c-4.7 4.7-4.7 12.3 0 17l28.3 28.3c4.7 4.7 12.3 4.7 17 0l48.1-48.1 48.1 48.1c4.7 4.7 12.3 4.7 17 0l28.3-28.3c4.7-4.7 4.7-12.3 0-17L269.3 352z";
const ImgForSuspenseStyle = styled(motion.div)`
  width: 90%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  // color:${Theme.light.thirdColor.dark};
`;
const imgSuspenseVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};
const divVariants = {
  hidden: {
    opacity: 0,
    scale: 1.2,
  },
  visible: (index) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: index * 0.01,
    },
  }),
  exit: {
    opacity: 0,
    scale: 1.05,
    transition: {
      duration: 0.01,
    },
  },
};
const imgSuspenseStyle = {
  width: "90%",
};
const DivWrappper = styled(motion.article)`
  & > a {
    width: 200px;
    height: 350px;
    margin: 20px;
    border: 1px solid
      ${({ isDark }) => (isDark ? "black" : Theme.dark.thirdColor.white)};
    border-radius: 12px;
    background: ${({ isDark }) =>
      isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    box-shadow: 0px 7px 29px 0px
      ${({ isDark }) =>
        isDark ? Theme.dark.eightColor.dark : Theme.light.eightColor.ligthGray};
    transition: 0.1s;
    text-align: center;
    text-transform: uppercase;
    transform: scale(1);
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & > a:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
  & > a > div {
    width: 100%;
    height: 65%;
    position: relative;
    overflow: hidden;
  }
  & > a > div > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  & > a > div > div > div > h6 {
    font-weight: normal;
    font-size: 16px;
  }
  & > a > div > div > div {
    display: flex;
  }
  &:last-of-type {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:last-of-type:hover {
    transform: scale(1);
    box-shadow: none;
    cursor: auto;
  }
  & > a > div:last-of-type {
    height: 35% !important;
    background: ${({ isDark }) =>
      isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
    z-index: 10;
    margin: 0 !important;
  }
  & > a > div > div > h3 {
    margin-top: 10px;
  }

  @media${Theme.device.mobileL} {
    & > a {
      margin: 12px;
      width: 165px;
      height: 320px;
    }
  }
  @media${Theme.device.mobileM} {
    & > a {
      margin: 7px;
      width: 160px;
      height: 320px;
    }
    & > a > div > div > img {
      bottom: 10px !important;
      left: 10px !important;
      height: 55px !important;
    }
    & > a > div > div {
      left: 70px !important;
    }
    & > a > div > div > h3 {
      margin-top: 5px;
    }
  }
  @media${Theme.device.mobileXS} {
    & > a {
      width: 124px;
      height: 290px;
      margin: 5px;
    }

    & > a > div > div > img {
      bottom: 15px !important;
      left: 0 !important;
      height: 42px !important;
    }
    & > a > div > div {
      left: 50px !important;
      bottom: 10px !important;
    }
    & > a > div > div > div > h3 {
      font-size: 12px !important;
    }
  }
`;
export const SingleItem = ({
  item,
  clearSelectPageFromDeal,
  newspaperId,
  isDark,
  index,
  isMobile,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <AnimatePresence exitBeforeEnter>
      <DivWrappper
        key={item.id}
        variants={divVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        custom={index}
        whileTap={{ scale: 0.95 }}
        isDark={isDark}
      >
        <Link
          to={`/gazetka-promocyjna/${item.name}-${item.alternativeFinalName}/${item.startDuringTime}-${item.endDuringTime}/1`}
          onClick={() => {
            clearSelectPageFromDeal();
            newspaperId(item.id);
          }}
          style={{}}
        >
          {isLoaded ? null : (
            <AnimatePresence>
              <ImgForSuspenseStyle
                isLoaded={isLoaded}
                variants={imgSuspenseVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                isDark={isDark}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="image"
                  className="svg-inline--fa fa-image fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  style={imgSuspenseStyle}
                  width={30}
                >
                  <path fill="currentColor" d={imagePath}></path>
                </svg>
              </ImgForSuspenseStyle>
            </AnimatePresence>
          )}
          <div
            style={{
              position: "absolute",
              left: "0",
              top: "0",
            }}
          >
            <img
              loading="lazy"
              src={
                item.thumbnail
                  ? `${API_URL}${item.thumbnail}`
                  : `${API_URL}${item.imgUrl}`
              }
              onLoad={() => setIsLoaded(true)}
              alt={`gazetka ${item.name} czas obowiązywania od ${item.startDuringTime} do ${item.endDuringTime}`}
            />
          </div>
          <div
            style={{
              width: "100%",
              height: `${isMobile ? "35%" : "30%"}`,
              display: "flex",
              justifyContent: "space-around",
              margin: "5px",
              position: "absolute",
              left: "0",
              bottom: "0",
              zIndex: "2",
            }}
          >
            <div>
              <h3
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                }}
              >
                gazetka {item.name}
              </h3>
            </div>
            <div>
              <img
                loading="lazy"
                src={item?.brand[0]?.imgUrl}
                alt={`gazetka ${item.name} obowiązująca od ${item.startDuringTime} do ${item.endDuringTime}`}
                style={{
                  height: "60px",
                  position: "absolute",
                  left: "5px",
                  bottom: "5px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                left: "80px",
                bottom: "10px",
              }}
            >
              {item.status === "active" && (
                <div
                  style={{
                    color: "green",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  <h3
                    style={{
                      color: "green",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    aktualna
                  </h3>
                </div>
              )}
              {item.status === "future" && (
                <div
                  style={{
                    color: "blue",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  <h3
                    style={{
                      color: "blue",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    następna
                  </h3>
                </div>
              )}

              <div style={{ textAlign: "left" }}>
                <span>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="calendar-check"
                    className="svg-inline--fa fa-calendar-check fa-w-14"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    style={{
                      width: "13px",
                      marginRight: "10px",
                      textAlign: "right",
                    }}
                    width={13}
                  >
                    <path fill="currentColor" d={startPath}></path>
                  </svg>
                </span>
                <h6>
                  {item.startDuringTime.slice(
                    0,
                    item.startDuringTime.length - 5
                  )}
                </h6>
              </div>
              <div style={{ textAlign: "left" }}>
                <span>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="calendar-times"
                    className="svg-inline--fa fa-calendar-times fa-w-14"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    style={{ width: "13px", marginRight: "10px" }}
                    width={13}
                  >
                    <path fill="currentColor" d={endPath}></path>
                  </svg>
                </span>
                <h6>
                  {item.endDuringTime.slice(0, item.endDuringTime.length - 5)}
                </h6>
              </div>
            </div>
          </div>
        </Link>
      </DivWrappper>
    </AnimatePresence>
  );
};
