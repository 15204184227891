import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Theme } from "../../../store/theme/theme";
import { Spinner } from "../../../components/tools/spiner/Spinner";
import { SearchResult } from "./SearchResult";
import { API_URL } from "../../../constant/constant";
import { ErrorBoundary } from "../../errorBoundary/ErrorBoundary";
import { ErrorHandler } from "../../error/ErrorHandler";
import {
  encryptFrontData,
  decryptFrontData,
} from "../../../utils/Crypto/cryptoFront.controller";

const svgPath =
  "M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z";
const SearchWrapper = styled.div.attrs((props) => ({
  style: {
    width: `${props.mobile === true ? "70%" : "30%"}`,
    paddingLeft: `${props.mobile === true ? "5px" : "0"}`,
  },
}))`
  // width:30%;
  height: 40px;
  position: relative;
  ${({ mobile }) =>
    !mobile &&
    `@media${Theme.device.tablet}{
    display:none;
}`}
`;
const SearchResultWraper = styled.div`
  width: 100%;
  height: 500px;
  position: absolute;
  top: 40px;
  left: 0;
  border-radius: 0 0 5px 5px;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  z-index: 91;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 0 5px 5px 0;
    background-color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border-left: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border-right: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    background-color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  @media${Theme.device.mobileL} {
    width: 100vw;
    padding: 0 5px;
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 0 2px 2px 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      border-right: none;
      border-left: none;
    }
  }
`;
const SearchInput = styled.input.attrs(({ isDark }) => ({
  type: "text",
  placeholder: "szukaj".toUpperCase(),
}))`
  width: 100%;
  height: 40px;
  padding: 10px 10px 10px 35px;
  font-size: 1em;
  border: 1px solid black;
  border-radius: 12.5px;
  transition: background 1s;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  background: ${({ isDark }) =>
    isDark ? Theme.dark.fiveColor.white : Theme.light.primaryColor.white};
  ::placeholder {
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  :focus {
    outline: none;
  }
  :hover {
    box-shadow: 2px 2px 7px black;
  }
`;
const Icon = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(10px, -50%);
  width: 20px !important;
  height: 20px !important;
  z-index: 2;
`;
const Sign = styled.img`
  display: ${({ sized }) => (sized ? "block" : "none")};
  mix-width: 20px !important;
  mix-height: 20px !important;
  width: 20px !important;
  height: 20px !important;
`;
const forLight = {
  color: Theme.light.thirdColor.dark,
  width: "20px",
  height: "20px",
};
const forDark = {
  color: Theme.dark.thirdColor.white,
  width: "20px",
  height: "20px",
};

export const SearchBar = ({ isDark, mobile, closeSearchInput }) => {
  const [isActive, setIsActive] = useState(false);
  const [isResponse, setIsResponse] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [dataSearchResult, setDataSearchResult] = useState([]);
  const [errorFromCrypto, setErrorFromCrypto] = useState("");

  const delay = 3000;

  const refs = React.createRef();
  const inputRef = React.createRef();
  const regNameMultiLng =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžśŚÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9\s ,.'-]+$/u;
  // const controller = new AbortController();
  // const signal = controller.signal;
  const handleChange = (value) => {
    if (regNameMultiLng.test(value)) {
      setDataSearchResult([]);
      setSearchTxt(value);
      setIsActive(true);
      setIsResponse(false);
    } else {
      setSearchTxt("");
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.target.blur();
    }
  };
  const handleClickOutside = (e) => {
    if (refs.current) {
      if (refs.current.contains(e.target)) {
        return document.removeEventListener("click", handleClickOutside);
      } else {
        setIsActive(false);
        setSearchTxt("");
      }
    }
  };
  const handleDealyedSearchBar = () => {
    const frontDataToEncrypt = {
      searchTxt,
    };

    // encrypt data for fetch
    const ciphertext = encryptFrontData(
      frontDataToEncrypt,
      setErrorFromCrypto,
      null,
      "SearchBar.js"
    );
    fetch(`${API_URL}/api/search`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        // "Content-type": "application/json; charset=UTF-8",
        "Content-type": "application/json",
      },
      body: JSON.stringify({ data: ciphertext }),
      // signal: signal,
    })
      .then((res) => res.json())
      .then((result) => {
        // decrypt data from response
        return decryptFrontData(
          result.data,
          setErrorFromCrypto,
          null,
          "SearchBar.js"
        );
      })
      .then((data) => {
        // console.log(data);
        setDataSearchResult(data);
        setIsResponse(true);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (searchTxt.length > 2) {
      const delayedFetch = setTimeout(() => {
        handleDealyedSearchBar();
      }, delay);
      return () => clearTimeout(delayedFetch);
    }
  }, [searchTxt]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  });

  return (
    <SearchWrapper ref={refs} mobile={mobile}>
      <SearchInput
        isDark={isDark}
        value={searchTxt}
        onChange={(e) => handleChange(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
      />
      {searchTxt.length > 2 && isActive && !isResponse && (
        <SearchResultWraper isDark={isDark}>
          <Spinner />
        </SearchResultWraper>
      )}
      {isResponse &&
        searchTxt.length > 2 &&
        isActive &&
        dataSearchResult.length > 0 && (
          <SearchResultWraper isDark={isDark}>
            <ErrorBoundary>
              {errorFromCrypto && <ErrorHandler errorMsg={errorFromCrypto} />}
              <SearchResult
                data={dataSearchResult}
                setIsActive={setIsActive}
                setSearchTxt={setSearchTxt}
                closeSearchInput={closeSearchInput}
              />
            </ErrorBoundary>
          </SearchResultWraper>
        )}

      <Icon>
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="search"
          className="svg-inline--fa fa-search fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          style={isDark ? forDark : forLight}
          // isDark={isDark}
        >
          <path
            // isDark={isDark}
            fill={
              isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
            }
            d={svgPath}
          ></path>
        </svg>
      </Icon>
    </SearchWrapper>
  );
};
