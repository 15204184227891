import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Theme } from "../../../../store/theme/theme";
import { motion } from "framer-motion";
import { API_URL } from "../../../../constant/constant";

const googlePath =
  "M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z";
const facebookPath =
  "M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z";
const loginPath =
  "M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z";
const signinPath =
  "M352 128c0 70.7-57.3 128-128 128s-128-57.3-128-128S153.3 0 224 0s128 57.3 128 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z";
const loginOptionsVariants = {
  hidden: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
  visible: {
    opacity: 1,
    // scale:1,
    y: 0,
    // transformOrigin:"bottom",
    transition: { duration: 0.3 },
  },
  exit: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
};

const LoginOptionsWraper = styled(motion.div)`
  width: 280px;
  border-radius: 5px;
  font-size: 1em;
  background: ${Theme.light.secondaryColor.green};
  color: ${Theme.light.primaryColor.white};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40px;
  right: -50px;
  overflow: hidden;
  user-select: none;
  z-index: 12;
  &:hover {
    box-shadow: 2px 2px 7px black;
  }
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 45px;
    padding: 15px;
  }
  & > div > a {
    text-decoration: none;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;
const GoogleLogin = styled.div`
  background: #cb4023;
  transform: scale(1);
  transition: 0.3s transform;
  &:hover {
    transform: scale(1.1);
  }
  & > a {
    color: ${Theme.dark.thirdColor.white} !important;
  }
  & > a > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
  }
`;
const FacebookLogin = styled.div`
  background: #3b5999;
  transform: scale(1);
  transition: 0.3s transform;
  &:hover {
    transform: scale(1.1);
  }
  & > a {
    color: ${Theme.dark.thirdColor.white} !important;
  }
  & > a > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
  }
`;
const Entry = styled.div`
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  transform: scale(1);
  transition: 0.3s transform;
  &:hover {
    transform: scale(1.1);
  }
  & > a > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
  }
`;
export const styleForSvg = {
  height: "18px",
};
export const LoginOptions = ({ isDark, setLoginOtionsVisible }) => {
  return (
    <LoginOptionsWraper
      variants={loginOptionsVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      isDark={isDark}
    >
      <Entry isDark={isDark}>
        <Link
          to={`/logowanie`}
          rel="nofollow, noindex, noreferrer"
          onClick={() => setLoginOtionsVisible(false)}
        >
          <div>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="google"
              className="svg-inline--fa fa-google fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              style={styleForSvg}
            >
              <path fill="currentColor" d={loginPath}></path>
            </svg>
          </div>
          Zaloguj się
        </Link>
      </Entry>
      <Entry isDark={isDark}>
        <Link
          to={`/rejestracja`}
          rel="nofollow, noindex, noreferrer"
          onClick={() => setLoginOtionsVisible(false)}
        >
          <div>
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              style={styleForSvg}
            >
              <path fill="currentColor" d={signinPath}></path>
            </svg>
          </div>
          Rejestruj się
        </Link>
      </Entry>
      <GoogleLogin onClick={() => setLoginOtionsVisible(false)}>
        <a href={`${API_URL}/auth/google`} rel="nofollow, noindex, noreferrer">
          <div>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="google"
              className="svg-inline--fa fa-google fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 488 512"
              style={styleForSvg}
            >
              <path fill="currentColor" d={googlePath}></path>
            </svg>
          </div>
          Kontynuuj z Google
        </a>
      </GoogleLogin>
      <FacebookLogin onClick={() => setLoginOtionsVisible(false)}>
        <a
          href={`${API_URL}/auth/facebook`}
          rel="nofollow, noindex, noreferrer"
        >
          <div>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="facebook-f"
              className="svg-inline--fa fa-facebook-f fa-w-10"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              style={styleForSvg}
            >
              <path fill="currentColor" d={facebookPath}></path>
            </svg>
          </div>
          Kontynuuj z Facebook
        </a>
      </FacebookLogin>
    </LoginOptionsWraper>
  );
};
